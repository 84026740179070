import '../styles/FileUpload.css'
import { useRef } from 'react'
import checkmarkIcon from '../assets/checkmark-2.svg'
import deleteIcon from '../assets/icn-delete.svg'
import LoadingIndicator from './LoadingIndicator'
import placeholderImg from '../assets/placeholder-img.svg'

const FileItemVariant = {
  LARGE: 'large',
  SMALL: 'small',
}

const FileUpload = ({
  id, // pass id prop if multiple instances of FileUpload is on the same page
  text,
  buttonText = 'Take Photo',
  fileTypes = '',
  isUploading = false,
  style,
  onSelect,
  variant = FileItemVariant.SMALL,
  allowMultiple = false,
  useCamera = false,
}) => {
  const inputRef = useRef()

  const handleDragEnter = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (!isUploading) {
      if (fileTypes && !isValidFiles(e.dataTransfer.files, fileTypes)) {
        console.log('[FileUpload] Unacceptable file type')
        return false
      }

      const targetID = id ? id : 'id-file-upload'
      onSelect(e.dataTransfer.files, targetID)
    }
  }

  const handleFileOnChange = (e) => {
    if (!isUploading) {
      onSelect(e.target.files, e.target.id)
    }
  }

  const handleFileOnClick = (e) => {
    if (e.target.value) {
      e.target.value = ''
    }
  }

  function isValidFiles(files, fileTypes) {
    if (!fileTypes) {
      return true
    }
    const acceptedTypes = fileTypes.split(',')
    if (acceptedTypes.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const fileParts = files[i].name.split('.')
        if (!fileParts) {
          return false
        }
        const ext = fileParts[fileParts.length - 1]
        if (!acceptedTypes.includes(`.${ext.toLowerCase()}`)) {
          return false
        }
      }

      return true
    }

    return true
  }

  return (
    <div
      className={
        variant === FileItemVariant.SMALL ? 'file-upload small' : 'file-upload'
      }
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      style={style}
    >
      <div className="file-upload-box">
        {!isUploading ? (
          <p>{text}</p>
        ) : (
          <LoadingIndicator text="Uploading..." />
        )}

        {!isUploading && (
          <div className="choose-file">
            <label htmlFor={id ? id : 'id-file-upload'}>{buttonText}</label>
            {useCamera ? (
              <input
                id={id ? id : 'id-file-upload'}
                type="file"
                ref={inputRef}
                accept={fileTypes}
                capture="environment"
                disabled={isUploading}
                onClick={handleFileOnClick}
                onChange={handleFileOnChange}
              />
            ) : (
              <input
                id={id ? id : 'id-file-upload'}
                type="file"
                ref={inputRef}
                accept={fileTypes}
                disabled={isUploading}
                onClick={handleFileOnClick}
                onChange={handleFileOnChange}
                multiple={allowMultiple}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

const FileUploadButton = ({
  id,
  onSelect = undefined,
  buttonText = 'Take Photo',
  fileTypes = '',
  isUploading = false,
  useCamera = false,
  style = {},
}) => {
  const onChange = (e) => {
    if (!isUploading && typeof onSelect === 'function') {
      onSelect(e.target.files, e.target.id)
    }
  }

  return (
    <div className="file-upload-button" style={style}>
      <label htmlFor={id ? id : 'id-file-upload'}>
        {isUploading ? 'Uploading...' : `${buttonText}`}
      </label>

      {useCamera ? (
        <input
          id={id ? id : 'id-file-upload'}
          className="file-upload-button"
          type="file"
          accept={fileTypes}
          capture="environment"
          disabled={isUploading}
          hidden={true}
          onChange={onChange}
        />
      ) : (
        <input
          id={id ? id : 'id-file-upload'}
          className="file-upload-button"
          type="file"
          accept={fileTypes}
          disabled={isUploading}
          hidden={true}
          onChange={onChange}
        />
      )}
    </div>
  )
}

const FilePreview = ({
  id = 'id-file-preview',
  fileURL,
  imageURL,
  text,
  showCheckmark = true,
  onDelete,
  callbackData,
  variant = FileItemVariant.SMALL,
}) => {
  const handleDelete = (e) => {
    e.preventDefault()
    const id = e.target.dataset.id
    const callbackData = e.target.dataset.data

    onDelete(id, callbackData)
  }

  return (
    <div id={id}>
      <a href={fileURL}>
        <div
          className={
            variant === FileItemVariant.SMALL
              ? 'file-preview small'
              : 'file-preview'
          }
        >
          <div className="left">
            <img
              className="file-image"
              src={imageURL ? imageURL : placeholderImg}
              alt=""
            />
            <div className="file-details">{text}</div>
          </div>
          <div className="right">
            {showCheckmark && (
              <img className="checkmark" src={checkmarkIcon} alt="check mark" />
            )}
            <img
              className="trash"
              src={deleteIcon}
              alt="delete icon"
              data-id={id}
              data-data={callbackData}
              onClick={handleDelete}
            />
          </div>
        </div>
      </a>
    </div>
  )
}

export default FileUpload

export { FileUploadButton, FilePreview, FileItemVariant }
