import { useState, useEffect } from 'react'
import '../styles/DateTimePicker.css'

/**
 * DateTimePicker handles Firefox issue where input type `datetime-local` is not supported.
 * @param {function} onChange - callback with `value` param as datetime string.
 * @param {date} max - Optional. Sets max selectable date value.
 * @param {string} id - Optional. If more than one instance, pass unique id.
 */
export default function DateTimePicker({
  onChange,
  max = null,
  id = 'datetime-picker',
}) {
  const isFirefox = window.navigator.userAgent.includes('Firefox')
  const [dateTimeValue, setDateTimeValue] = useState('')
  const [dateValue, setDateValue] = useState('')
  const [timeValue, setTimeValue] = useState('')
  const [maxDateValue] = useState(() => {
    if (!max) {
      return ''
    }

    const d = new Date(max)
    const year = d.getFullYear().toString()

    let month = (d.getMonth() + 1).toString()
    if (month.length < 2) {
      month = '0' + month
    }

    let day = d.getDate().toString()
    if (day.length < 2) {
      day = '0' + day
    }

    if (isFirefox) {
      return [year, month, day].join('-')
    }

    let hours = d.getHours().toString()
    if (hours.length < 2) {
      hours = '0' + hours
    }

    let minutes = d.getMinutes().toString()
    if (minutes.length < 2) {
      minutes = '0' + minutes
    }

    return `${year}-${month}-${day}T${hours}:${minutes}`
  })

  function setToNow() {
    if (dateValue.length === 0 && timeValue.length === 0) {
      const d = new Date()
      const year = d.getFullYear().toString()

      let month = (d.getMonth() + 1).toString()
      if (month.length < 2) {
        month = '0' + month
      }

      let day = d.getDate().toString()
      if (day.length < 2) {
        day = '0' + day
      }

      setDateValue([year, month, day].join('-'))

      const t = new Date()
      let hours = t.getHours().toString()
      if (hours.length < 2) {
        hours = '0' + hours
      }

      let minutes = t.getMinutes().toString()
      if (minutes.length < 2) {
        minutes = '0' + minutes
      }

      setTimeValue([hours, minutes].join(':'))
    }
  }

  useEffect(() => {
    if (typeof onChange !== 'function') {
      return
    }

    if (!isFirefox) {
      if (dateTimeValue.length > 0) {
        onChange(dateTimeValue)
      } else {
        onChange('')
      }
    }

    if (isFirefox) {
      if (dateValue.length > 0 && timeValue.length > 0) {
        // format 2021-07-07T15:34
        onChange(`${dateValue}T${timeValue}`)
      } else {
        onChange('')
      }
    }
    // eslint-disable-next-line
  }, [dateTimeValue, dateValue, timeValue])

  if (!isFirefox) {
    return (
      <input
        className={
          dateTimeValue.length > 0
            ? 'non-ff-datetime-picker'
            : 'non-ff-datetime-picker placeholder'
        }
        id={id}
        type="datetime-local"
        value={dateTimeValue}
        max={maxDateValue}
        onChange={(e) => setDateTimeValue(e.target.value)}
      />
    )
  }

  return (
    <div className="ff-datetime-picker">
      <input
        id={id}
        className={
          dateValue.length > 0 ? 'ff-date-picker' : 'ff-date-picker placeholder'
        }
        type="date"
        value={dateValue}
        max={maxDateValue}
        onClick={setToNow}
        onChange={(e) => setDateValue(e.target.value)}
      />
      <input
        id={`${id}_time`}
        className={
          dateValue.length > 0 ? 'ff-time-picker' : 'ff-time-picker placeholder'
        }
        type="time"
        value={timeValue}
        onClick={setToNow}
        onChange={(e) => setTimeValue(e.target.value)}
      />
    </div>
  )
}
