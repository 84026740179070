import API from './API'
import Constants from './Constants'

function ClaimsRepairFlowModel() {
  this.createState = async (claimID, claimantID, stateName) => {
    const { claimFlowState, err } = await API()
      .post(`/v3/claims/${claimID}/claimants/${claimantID}/repair_states`, {
        state: stateName,
      })
      .then((res) => {
        console.log('API res[createState]:', res)
        if (res.status === 200) {
          return { claimFlowState: res.data, err: null }
        }

        return { claimFlowState: [], err: Constants.genericNetworkError() }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[createState]:', error)
          return { claimFlowState: null, err: error }
        }

        console.log('API err[createState]:', error.response.data)
        return { claimFlowState: null, err: error.response.data }
      })

    return { claimFlowState, err }
  }

  this.repairShopSearch = async (q = '', limit = 5, lat = 0, lng = 0) => {
    const path = `/v3/claims/repair_shops?lat=${lat}&lng=${lng}&limit=${limit}&q=${q}`

    const { repairShops, err } = await API()
      .get(path)
      .then((res) => {
        console.log('API res[repairShopSearch]:', res)
        if (res.status === 200) {
          return { repairShops: res.data.repair_shops, err: null }
        }

        return { repairShops: [], err: Constants.genericNetworkError() }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[repairShopSearch]:', error)
          return { repairShops: [], err: error }
        }

        console.log('API err[repairShopSearch]:', error.response.data)
        return { repairShops: [], err: error.response.data }
      })

    return { repairShops, err }
  }

  this.createRepairShop = async (claimID, claimantID, repairShop) => {
    const err = await API()
      .post(`/v3/claims/${claimID}/claimants/${claimantID}/repair_shops`, {
        ...repairShop,
      })
      .then((res) => {
        console.log('API res[createRepairShop]:', res)
        if (res.status === 200) {
          return null
        }

        return Constants.genericNetworkError()
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[createRepairShop]:', error)
          return error
        }

        console.log('API err[createRepairShop]:', error.response.data)
        return error.response.data
      })

    return err
  }
}

export default new ClaimsRepairFlowModel()
