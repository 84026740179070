import React, { useState, useEffect } from 'react'
import { CenteredContainer } from '../components/Container'
import VehicleModel from '../models/VehicleModel'
import StateSelector from '../components/StateSelector'
import { Label, ErrorLabel } from '../components/Label'
import closeBtnIcon from '../assets/close-btn-icon.svg'
import vinIcon from '../assets/vin.svg'
import lienholderIcon from '../assets/lienholder.svg'
import locationIcon from '../assets/location.svg'
import addIcon from '../assets/add.svg'
import Strings from '../utils/Strings'
import Utils from '../utils/Utils'
import { Link } from 'react-router-dom'
import UserModel from '../models/UserModel'
import NumberFormat from 'react-number-format'
import Breadcrumb from '../components/Breadcrumb'
import Store from '../models/Store.js'
import Sentry from '../logger/Sentry'
import bellWhiteIcon from '../assets/icn-bell-white.svg'
import emailIcon from './../assets/email.svg'
import BrokerInfo from '../components/BrokerInfo'
import LoadingIndicator from '../components/LoadingIndicator'

function Cars(props) {
  const user = Store.getUser()
  const userID = user ? user.userID : ''

  const [agent, setAgent] = useState(null)
  const [showLoading, setShowLoading] = useState(false)
  const [userState, setUserState] = useState(null)
  const [license, setLicense] = useState(null)
  const [cars, setCars] = useState([])
  const [editingVehicle, setEditingVehicle] = useState(null)
  const [showForm, setShowForm] = useState(false)
  const [formValues, setFormValues] = useState({
    prettyMake: '',
    vin: '',
    make: '',
    model: '',
    year: '',
    lienholderName: '',
    lienholderAddress1: '',
    lienholderCity: '',
    lienholderState: '',
    lienholderZipcode: '',
  })
  const [toDelete, setToDelete] = useState(null)
  const [vinError, setVINError] = useState('')
  const [isPhotosChecked, setIsPhotosChecked] = useState(true)
  const [showLienholderAddress, setShowLienholderAddress] = useState(false)
  const [overlayTitle, setOverlayTitle] = useState('')
  const [yearMakeModel, setYearMakeModel] = useState('')
  const [hasGoodCredit, setHasGoodCredit] = useState(true)
  const [showUWException, setShowUWException] = useState(false)
  const [hasNewVehicle, setHasNewVehicle] = useState(false)
  const [showUWNotify, setShowUWNotify] = useState(false)
  const [uwNotifySubmitted, setUWNotifySubmitted] = useState(false)
  const [uwNotifyHeading, setUWNotifyHeading] = useState(false)
  const [uwIssueCars, setUWIssueCars] = useState([])
  const [email, setEmail] = useState('')
  const [processingUWNotify, setProcessingUWNotify] = useState(false)

  const fetchUser = async () => {
    setShowLoading(true)
    const {
      user,
      license,
      vehicles,
      userState,
      goodCredit,
      agent,
      err,
    } = await UserModel.getUser(userID, 'cars')
    if (err) {
      console.log('error getting user:', err)
      return
    }

    if (user && user.email) {
      setEmail(user.email)
    }

    if (userState) {
      setUserState(userState)
    }

    if (license) {
      setLicense(license)
    }

    if (vehicles) {
      const newVehicle = vehicles.find((v) => v.year >= 2018)
      if (newVehicle) {
        setHasNewVehicle(true)
      } else {
        setHasNewVehicle(false)
      }

      setCars(vehicles)

      const uwIssueCars = vehicles.filter((v) => v.year >= 2018 && !goodCredit)
      if (uwIssueCars && !goodCredit) {
        let uwHeadingText = `Have Go notify you when your ${getCarsListText(
          uwIssueCars
        )} qualifies`

        setUWNotifyHeading(uwHeadingText)
      }
      setUWIssueCars(uwIssueCars)
    }

    if (agent) {
      setAgent(agent)
    }

    setHasGoodCredit(goodCredit)

    setShowLoading(false)

    if (user && license) {
      Utils.sendGTMEvent('Vehicles', user, license)
    }
  }

  function getCarsListText(cars) {
    let carsText = ''
    if (cars.length === 1) {
      carsText += Utils.getVehicleTitle(cars[0])
    } else {
      for (let i = 0; i < cars.length; i++) {
        if (i !== 0 && i !== cars.length - 1) {
          carsText += ', '
        }

        if (i === cars.length - 1) {
          carsText += ' and '
        }
        carsText += Utils.getVehicleTitle(cars[i])
      }
    }

    return carsText
  }

  const resetForm = () => {
    setFormValues({
      prettyMake: '',
      vin: '',
      make: '',
      model: '',
      year: '',
      lienholderName: '',
      lienholderAddress1: '',
      lienholderCity: '',
      lienholderState: '',
      lienholderZipcode: '',
    })
  }

  useEffect(() => {
    const getUser = async () => {
      fetchUser()
    }
    getUser()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (hasNewVehicle && !hasGoodCredit) {
      setShowUWNotify(true)
    } else {
      setShowUWNotify(false)
    }
  }, [hasNewVehicle, hasGoodCredit])

  const handleInputChange = (e) => {
    const fieldName = e.target.name
    const fieldValue = e.target.value

    if (e.target.name === 'vin') {
      setYearMakeModel('')
      if (Strings.isValidVIN(e.target.value)) {
        getVehicleDetails(e.target.value)
      } else {
        setVINError('Please enter a valid VIN')
      }
    }

    setFormValues({
      ...formValues,
      [fieldName]: fieldValue,
    })
  }

  const handleVINBlur = (e) => {
    setYearMakeModel('')
    if (Strings.isValidVIN(e.target.value)) {
      getVehicleDetails(e.target.value)
    } else {
      setVINError('Please enter a valid VIN')
    }
  }

  const getVehicleDetails = async (vin) => {
    const [vehicleDetails, err] = await VehicleModel.getVehicleByVIN(
      userID,
      vin
    )

    let inValidVehicle = false
    if (vehicleDetails) {
      if (
        vehicleDetails.make.length === 0 &&
        vehicleDetails.model.length === 0
      ) {
        inValidVehicle = true
      }
    }

    if (err || inValidVehicle) {
      setVINError('Please enter a valid VIN')

      Sentry.error('GetVehicleByVINError', err, {
        inValidVehicle,
        error: err,
      })
      return
    }

    let prettyMake = `${vehicleDetails.year} ${vehicleDetails.make} ${vehicleDetails.model}`
    setFormValues({
      ...formValues,
      prettyMake: prettyMake,
      vin: vin,
      make: vehicleDetails.make,
      model: vehicleDetails.model,
      year: vehicleDetails.year,
    })

    setYearMakeModel(prettyMake)
    setVINError('')
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault()

    var vehicle = {
      vin: formValues.vin,
      license_id: license.id,
    }
    if (formValues.lienholderName.length > 0) {
      vehicle['lien_holder'] = {
        name: formValues.lienholderName,
        address: formValues.lienholderAddress1,
        city: formValues.lienholderCity,
        state: formValues.lienholderState,
        zipcode: formValues.lienholderZipcode,
      }
    }
    if (editingVehicle) {
      vehicle.id = editingVehicle.id
      const { err } = await VehicleModel.updateVehicle(userID, vehicle)
      if (err) {
        if (err.err && err.err === 'vehicle exists with active policy') {
          setVINError('This vehicle is already in an active policy')
        } else {
          setVINError('Please enter a valid VIN')
        }
        return
      }

      fetchUser()
      toggleAddMore()
      if (formValues.year >= 2018 && !hasGoodCredit) {
        setShowUWException(true)
      }
      resetForm()
      setEditingVehicle(null)
      Sentry.info('EditedVehicle', 'edited vehicle', { vehicle })
    } else {
      const { err } = await VehicleModel.addVehicle(userID, vehicle)
      if (err) {
        if (err.err && err.err === 'vehicle exists with active policy') {
          setVINError('This vehicle is already in an active policy')
        } else {
          setVINError('Please enter a valid VIN')
        }

        Sentry.error('AddVehicleError', 'add vehicle error', {
          ...vehicle,
        })
        return
      }

      fetchUser()
      toggleAddMore()
      if (formValues.year >= 2018 && !hasGoodCredit) {
        setShowUWException(true)
      }
      resetForm()
      Sentry.info('AddedVehicle', 'added vehicle', { vehicle })
    }
  }

  const editVehicle = (v) => {
    setYearMakeModel('')
    setToDelete(null)
    setEditingVehicle(v)
    setFormValues({
      prettyMake: v.year + ' ' + v.make + ' ' + v.model,
      vin: v.vin,
      make: v.make,
      model: v.model,
      year: v.year,
      lienholderName: v.lien_holder ? v.lien_holder.name : '',
      lienholderAddress1: v.lien_holder ? v.lien_holder.address : '',
      lienholderCity: v.lien_holder ? v.lien_holder.city : '',
      lienholderState: v.lien_holder ? v.lien_holder.state : '',
      lienholderZipcode: v.lien_holder ? v.lien_holder.zipcode : '',
    })
    setOverlayTitle('Edit Car')
    setShowForm(!showForm)
    if (v.lien_holder) {
      setShowLienholderAddress(true)
    }
    if (Strings.isValidVIN(v.vin)) {
      getVehicleDetails(v.vin)
    } else {
      setVINError('Please enter a valid VIN')
    }
  }

  const toggleAddMore = () => {
    setYearMakeModel('')
    setEditingVehicle(null)
    setToDelete(null)
    setOverlayTitle('Add Car')
    resetForm()
    setShowForm(!showForm)
    setVINError('')
  }

  const isValid = () => {
    let isVinValid = Strings.isValidVIN(formValues.vin)
    let lienholderValid =
      formValues.lienholderName.length === 0 ||
      (formValues.lienholderName.length > 0 &&
        formValues.lienholderAddress1.length > 0 &&
        formValues.lienholderCity.length > 0 &&
        formValues.lienholderState.length === 2 &&
        formValues.lienholderZipcode.length === 5)

    console.log('formValue', formValues)
    console.log('isValid', isVinValid && lienholderValid)
    return isVinValid && lienholderValid
  }

  const removeVehicle = async function (vehicleID) {
    let success = await VehicleModel.removeVehicle(userID, vehicleID)
    if (success) {
      fetchUser()
    }
  }

  const handlePhotosCheckboxChanged = () => {
    setIsPhotosChecked(!isPhotosChecked)
  }

  const disableNext = () => {
    if (!cars.length || !isPhotosChecked) {
      return true
    }

    if (!hasGoodCredit && hasNewVehicle) {
      return true
    }

    return false
  }

  async function fetchUWNotification() {
    setProcessingUWNotify(true)
    const { uwNotification, err } = await VehicleModel.getUWNotification()
    if (err) {
      setProcessingUWNotify(false)
      return
    }

    if (uwNotification && uwIssueCars) {
      setUWNotifyHeading(
        `Thank you, Go will notify you when your ${getCarsListText(
          uwIssueCars
        )} qualifies.`
      )
      setUWNotifySubmitted(true)
    }
    setProcessingUWNotify(false)
  }

  useEffect(() => {
    if (uwIssueCars.length === 0) {
      return
    }
    fetchUWNotification()
    // eslint-disable-next-line
  }, [uwIssueCars])

  const handleUWNotifySubmit = async (e) => {
    e.preventDefault()
    setProcessingUWNotify(true)

    const { err } = await VehicleModel.createUWNotification(email)
    if (err) {
      alert('Error saving notification')
      setProcessingUWNotify(false)
      return
    }

    setUWNotifyHeading(
      `Thank you, Go will notify you when your ${getCarsListText(
        uwIssueCars
      )} qualifies.`
    )
    setUWNotifySubmitted(true)
    setProcessingUWNotify(false)
  }

  const carsList = cars.map((car) => (
    <div key={car['id']}>
      <div className="list-item">
        <p>
          {car.valid ? <span>􀆅</span> : ''}
          {Utils.getVehicleTitle(car)}
        </p>
        <div className="list-controls">
          <button className="delete" onClick={() => setToDelete(car['id'])}>
            Delete
          </button>
          <button onClick={() => editVehicle(car)}>Edit</button>
        </div>
        {toDelete === car['id'] && (
          <div className="overlay">
            <div className="remove-dialog">
              <div className="dialog-title">Delete Car</div>
              <br />
              <p>
                Are you sure you want to delete {car['year']} {car['make']}{' '}
                {car['model']}?
              </p>
              <br />
              <div>
                <button className="cancel" onClick={() => setToDelete(null)}>
                  Cancel
                </button>
              </div>
              <div>
                <button
                  className="primary"
                  onClick={() => removeVehicle(car['id'])}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {car.year >= 2018 && !hasGoodCredit && (
        <div
          className="tip warning"
          style={{ marginTop: '-8px', marginBottom: '18px' }}
        >
          <img src={bellWhiteIcon} alt="" />
          <p>
            Underwriting Exception: Sorry, for 2018-2021 cars, Go requires
            drivers with a certain financial history. Your financial history
            does not qualify at this time.
          </p>
        </div>
      )}
    </div>
  ))

  return (
    <div className="container">
      <Breadcrumb page={'cars'} userState={userState} license={license} />

      <CenteredContainer>
        <div className="mobile-page-heading">Cars</div>
        {agent && <BrokerInfo agent={agent} style={{ margin: '28px 0' }} />}

        <div className="interactive-list">
          {showLoading && (
            <LoadingIndicator text="Loading..." style={{ margin: '20px 0' }} />
          )}
          {carsList}
          {!showForm ? (
            <>
              {!showUWNotify && (
                <div className="add-more">
                  <button onClick={toggleAddMore}>
                    <img src={addIcon} alt="add icon" /> Add Car
                  </button>
                </div>
              )}
            </>
          ) : (
            <div className="overlay">
              <form className="add-more-form" onSubmit={handleFormSubmit}>
                <button className="add-more-close" onClick={toggleAddMore}>
                  <img src={closeBtnIcon} alt="close button" />
                </button>
                <h3>{overlayTitle}</h3>
                <div className="add-more-content">
                  <div className="form-row">
                    <div className="form-column">
                      <Label icon={vinIcon} text="VIN"></Label>
                      <input
                        className={vinError && 'error'}
                        name="vin"
                        type="text"
                        value={formValues.vin}
                        onChange={handleInputChange}
                        onBlur={handleVINBlur}
                      />
                      <ErrorLabel errorMessage={vinError} />
                      <div style={{ marginTop: '4px' }}>{yearMakeModel}</div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-column">
                      <Label icon={lienholderIcon} text="LIENHOLDER"></Label>
                      <input
                        name="lienholderName"
                        type="text"
                        placeholder="None"
                        value={formValues.lienholderName}
                        onChange={handleInputChange}
                        onFocus={(e) => setShowLienholderAddress(true)}
                        onBlur={(e) =>
                          setShowLienholderAddress(
                            formValues.lienholderName.length > 0
                          )
                        }
                      />
                    </div>
                  </div>
                  {showLienholderAddress && (
                    <div>
                      <div className="form-row">
                        <div className="form-column">
                          <Label
                            icon={locationIcon}
                            text="LIENHOLDER ADDRESS"
                          ></Label>
                          <input
                            name="lienholderAddress1"
                            value={formValues.lienholderAddress1}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-column">
                          <Label icon={locationIcon} text="CITY"></Label>
                          <input
                            name="lienholderCity"
                            value={formValues.lienholderCity}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="form-column">
                          <Label icon={locationIcon} text="STATE"></Label>
                          <StateSelector
                            name="lienholderState"
                            value={formValues.lienholderAddress1}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="form-column">
                          <Label icon={locationIcon} text="ZIP CODE" />
                          <NumberFormat
                            format="#####"
                            name="lienholderZipcode"
                            value={formValues.lienholderZipcode}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <button className="primary" type="submit" disabled={!isValid()}>
                  Done
                </button>
              </form>
            </div>
          )}
        </div>
        {!showUWNotify && (
          <>
            <div
              className="checkbox-container"
              onClick={handlePhotosCheckboxChanged}
            >
              <input type="checkbox" checked={isPhotosChecked} readOnly />
              <label />
              <div style={{ color: '#7e7e7e' }}>
                <div style={{ marginTop: '6px' }}>
                  Yes, I will add car photos later in the Go Insurance app.
                </div>
              </div>
            </div>

            <Link to={`/coverages/${userID}`}>
              <button className="primary" disabled={disableNext()}>
                Next
              </button>
            </Link>
          </>
        )}
        {showUWNotify && !processingUWNotify && (
          <div>
            <h4 className="heading">{uwNotifyHeading}</h4>
            {!uwNotifySubmitted && (
              <div className="form-container">
                <form id="un-notify-form" onSubmit={handleUWNotifySubmit}>
                  <div className="form-row">
                    <div className="form-column">
                      <Label icon={emailIcon} text="EMAIL"></Label>
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="primary"
                    disabled={!Strings.isValidEmail(email)}
                  >
                    Continue
                  </button>
                </form>
              </div>
            )}
          </div>
        )}
        {showUWException && (
          <div className="modal">
            <div className="modal-box">
              <h3 className="modal-title warning">Underwriting Exception</h3>

              <p className="modal-text" style={{ marginLeft: '6px' }}>
                Sorry, for 2018-2021 cars, Go requires drivers with a certain
                financial history. Your financial history does not qualify at
                this time.
              </p>

              <button
                className="primary"
                style={{ fontWeight: '500' }}
                onClick={() => setShowUWException(false)}
              >
                Okay
              </button>
            </div>
          </div>
        )}
      </CenteredContainer>
    </div>
  )
}

export default Cars
