import API from './API'
import Constants from './Constants'

class DriverModel {
  addDriver = async (userID, driver) => {
    const response = await API()
      .post('/v3/drivers', driver)
      .then((res) => {
        console.log('API res[addDriver]:', res)
        if (res.status === 200) {
          return {
            driver: res.data,
            err: null,
          }
        }

        return { driver: null, err: Constants.genericNetworkError() }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[addDriver]:', error)
          return { driver: null, err: error }
        }

        console.log('API err[addDriver]:', error.response.data)
        return { driver: null, err: error.response.data }
      })

    return response
  }

  removeDriver = async (userID, driverID) => {
    return API()
      .delete('/v3/drivers/' + driverID)
      .then((resp) => {
        console.log('API res:', resp)
        if (resp.status === 200) {
          return true
        }
        return false
      })
  }

  updateDriver = async (userID, driver) => {
    const response = await API()
      .put(`/v3/drivers/${driver.id}`, driver)
      .then((res) => {
        console.log('API res[updateDriver]:', res)
        if (res.status === 200) {
          return {
            driver: res.data,
            err: null,
          }
        }

        return { driver: null, err: Constants.genericNetworkError() }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[updateDriver]:', error)
          return { driver: null, err: error }
        }

        console.log('API err[updateDriver]:', error.response.data)
        return { driver: null, err: error.response.data }
      })

    return response
  }

  setSpouse = async (userID, driverID) => {
    return API()
      .put('/v3/licenses/married', {
        driver_id: driverID,
      })
      .then((resp) => {
        console.log('API res:', resp)
        if (resp.status === 200) {
          return true
        }
        return false
      })
  }
}

export default new DriverModel()
