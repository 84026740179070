import '../../styles/ClaimsPay.css'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CenteredContainer } from '../../components/Container'
import goLogo from '../../assets/app-icon.svg'

export default function ClaimsPay(props) {
  let history = useHistory()

  const [policyNumber] = useState(() => {
    const params = new URLSearchParams(props.location.search)
    return params.get('policy')
  })

  const [claimNumber] = useState(() => {
    const params = new URLSearchParams(props.location.search)
    return params.get('claim')
  })

  const [claimsPaymentID] = useState(() => {
    const params = new URLSearchParams(props.location.search)
    return params.get('claimpaymentid')
  })

  return (
    <div className="container">
      <CenteredContainer className="claims-pay-container">
        <div className="claims-pay-intro">
          <img className="logo" src={goLogo} alt="Go Logo" />
          <h2 className="title">Claims paid fast</h2>
          <p className="title-text">
            Verify your bank account and get
            <br />
            your payment quickly.
          </p>

          <button
            className="primary"
            onClick={() =>
              history.push('/claims-pay/verify', {
                policyNumber,
                claimNumber,
                claimsPaymentID,
              })
            }
          >
            Continue
          </button>
        </div>
      </CenteredContainer>
    </div>
  )
}
