import { assign, createMachine } from 'xstate'

/**
 * State machine to handle API fetch
 *
 * states: idle | loading | success | failure
 *
 * actions: FETCH | RESOLVE | REJECT
 */
const fetchMachine = createMachine(
  {
    id: 'fetch',
    initial: 'idle',
    context: {
      response: undefined,
      error: undefined,
    },
    states: {
      idle: {
        on: {
          FETCH: { target: 'loading' },
        },
      },
      loading: {
        invoke: {
          src: 'fetchData',
        },
        on: {
          RESOLVE: { target: 'success', actions: ['setResponse'] },
          REJECT: { target: 'failure', actions: ['setError'] },
        },
      },
      success: {
        on: {
          FETCH: { target: 'loading' },
        },
      },
      failure: {
        on: {
          FETCH: { target: 'loading' },
        },
      },
    },
  },
  {
    actions: {
      setResponse: assign((context, event) => ({ response: event.response })),
      setError: assign((context, event) => ({ error: event.error })),
    },
  }
)

export default fetchMachine
