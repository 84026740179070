import React from 'react'
import { v4 as uuid } from 'uuid';


function StackedMobile({left, right, leftWidth, rightWidth, className, inverted, verticalSpacing}) {
  var c = 'stacked-mobile'
  if (className) {
    c += ' ' + className
  }
  if (!leftWidth) {
    leftWidth = '50%'
  }
  if (!rightWidth) {
    rightWidth = '50%'
  }
  if (!verticalSpacing) {
    verticalSpacing = '15px'
  }
  let id = uuid()
  return (
    <div className={c}>
      <style scoped>
        .stacked-mobile-left-{id} {'{'}
            width: {leftWidth};
        {'}'}
        .stacked-mobile-right-{id} {'{'}
          width: {rightWidth};
        {'}'}
        @media (max-width: 600px) {'{'}
          .stacked-mobile-left-{id}, .stacked-mobile-right-{id} {'{'}
              width: 100%;
          {'}'}
          .stacked-mobile-left-{id} {'{'}
            {inverted ? 'padding-top: ' + verticalSpacing + ';' : 'padding-bottom: ' + verticalSpacing + ';'}
          {'}'}
          .stacked-mobile-right-{id} {'{'}
              {inverted ? 'padding-bottom: ' + verticalSpacing + ';' : 'padding-top: ' + verticalSpacing + ';'}
          {'}'}
        {'}'}
      </style>
      {inverted && <div className={'stacked-mobile-right stacked-mobile-right-' + id}>{right}</div>}
      <div className={'stacked-mobile-left stacked-mobile-left-' + id}>{left}</div>
      {!inverted && <div className={'stacked-mobile-right stacked-mobile-right-' + id}>{right}</div>}
    </div>
  )
}

export default StackedMobile
