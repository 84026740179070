import '../../styles/ClaimsPay.css'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Label } from '../../components/Label'
import einIcon from '../../assets/icn-code.svg'
import NumberFormat from 'react-number-format'
import ClaimsPayModel from '../../models/ClaimsPayModel'

export default function ClaimsPayEIN(props) {
  let location = useLocation()
  const claimsPaymentID = props.match.params.claims_payment_id

  const claimsPayment =
    location.state && location.state.claimsPayment
      ? location.state.claimsPayment
      : null

  //   console.log({ claimsPayment })

  const [ein, setEin] = useState('')
  const [isSubmittting, setIsSubmittting] = useState(false)
  const [errMsg, setErrMsg] = useState('')

  const onSubmit = async (e) => {
    e.preventDefault()
    if (errMsg) {
      setErrMsg('')
    }
    setIsSubmittting(true)

    const err = await ClaimsPayModel.createVendorEIN(claimsPaymentID, ein)
    if (err) {
      setErrMsg(
        `Sorry, we ran into an unexpected issue and we're still figuring it out.`
      )
      setIsSubmittting(false)
      return
    }

    setIsSubmittting(false)
    window.location = `/claims-pay/license/${claimsPaymentID}`
  }

  function isValid() {
    if (ein.length < 9) {
      return false
    }
    return true
  }

  return (
    <div className="container">
      <div className="claims-pay-ein-container">
        <h2 className="title" style={{ marginBottom: '9px' }}>
          EIN
        </h2>

        <p>
          Also known as a “Tax ID number,” the EIN is a business federal tax ID
          that works like a Social Security Number for your business. This is to
          ensure payment is being sent to the right business account.
        </p>

        <h3 className="vendor-name">{claimsPayment.name}</h3>

        <form onSubmit={onSubmit}>
          <div className="form-row">
            <div className="form-column">
              <Label icon={einIcon} text="EMPLOYER IDENTIFICATION NUMBER" />
              <NumberFormat
                format="##-#######"
                isNumericString={true}
                name="ein"
                value={ein}
                onValueChange={(values) => setEin(values.value)}
              />
            </div>
          </div>

          {errMsg && (
            <div
              className="claims-pay-error-text"
              style={{ margin: '5px 0 0 0', fontSize: '14px' }}
            >
              {errMsg ? errMsg : ' '}
            </div>
          )}

          <button
            className="primary"
            type="submit"
            disabled={!isValid() || isSubmittting}
          >
            {isSubmittting ? 'Saving...' : 'Continue'}
          </button>
        </form>
      </div>
    </div>
  )
}
