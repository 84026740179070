import '../styles/FinalizeQuote.css'
import React from 'react'
import { CenteredContainer } from '../components/Container'
import flagIcon from '../assets/flag.svg'
import cardIcon from '../assets/cardback.svg'
import emailIcon from './../assets/email.svg'
import phoneIcon from '../assets/phone-number.svg'
import deviceIcon from '../assets/icn-device.svg'
import { ErrorLabel, Label } from '../components/Label'
import StateSelector from '../components/StateSelector'
import BookModel from '../models/BookModel'
import { useEffect } from 'react'
import { useState } from 'react'
import LoadingIndicator from '../components/LoadingIndicator'
import LicenseModel from '../models/LicenseModel'
import Store from '../models/Store'
import agentIcon from '../assets/profile-ashley-lawrence@3x.png'
import timeIcon from '../assets/time.svg'
import Strings from '../utils/Strings'
import UserModel from '../models/UserModel'
import NumberFormat from 'react-number-format'

const FinalizeQuote = (props) => {
  const userID = props.match.params.user_id

  const [isLoading, setIsLoading] = useState(true)
  const [isProcessing, setIsProcessing] = useState(false)
  const [missingFields, setMissingFields] = useState([])
  const [emailMissing, setEmailMissing] = useState(false)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [phoneMissing, setPhoneMissing] = useState(false)
  const [phone, setPhone] = useState('')
  const [phoneError, setPhoneError] = useState('')
  const [platformMissing, setPlatformMissing] = useState(false)
  const [platform, setPlatform] = useState('')
  const [primaryDOBMissing, setPrimaryDOBMissing] = useState(false)
  const [primaryDOB, setPrimaryDOB] = useState('')
  const [primaryDOBError, setPrimaryDOBError] = useState('')
  const [license, setLicense] = useState(null)
  const [brokerInfo, setBrokerInfo] = useState(null)
  const [primaryDLMissing, setPrimaryDLMissing] = useState(false)
  const [primaryDLState, setPrimaryDLState] = useState('')
  const [primaryDLNumber, setPrimaryDLNumber] = useState('')
  const [primaryDLError, setPrimaryDLError] = useState('')
  const [driversMissingFields, setDriversMissingFields] = useState({ list: [] })
  const [driversDLList, setDriversDLList] = useState({ list: [] })
  const [drivers, setDrivers] = useState([])
  const [invalidDriverDL, setInvalidDriverDL] = useState(null)
  const [invalidDriverDOB, setInvalidDriverDOB] = useState(null)

  const fetchMissingFields = async () => {
    const { response, err } = await BookModel.getMissingFields(userID)
    if (err) {
      console.log('error fetching missing fields:', err)
      return
    }

    if (response) {
      if (response.missing_fields.length === 0) {
        window.location = `/quote/${userID}`
      }

      console.log('missing fields:', response.missing_fields)

      if (
        !Store.getUser() ||
        (Store.getUser() && Store.getUser().userID !== userID)
      ) {
        const user = {
          userID: response.user_id,
          token: response.token,
        }

        Store.setUser(user)
      }

      const emailMissingField = response.missing_fields.find(
        (f) => f.is_primary_driver === true && f.missing_field === 'Email'
      )

      if (emailMissingField) {
        setEmailMissing(true)
        if (response.user.email) {
          setEmail(response.user.email)
        }
      }

      const phoneMissingField = response.missing_fields.find(
        (f) => f.is_primary_driver === true && f.missing_field === 'Phone'
      )

      if (phoneMissingField) {
        setPhoneMissing(true)
      }

      const platformMissingField = response.missing_fields.find(
        (f) => f.is_primary_driver === true && f.missing_field === 'Platform'
      )

      if (platformMissingField) {
        setPlatformMissing(true)
      }

      const primaryDOBMissingField = response.missing_fields.find(
        (f) => f.is_primary_driver === true && f.missing_field === 'DOB'
      )

      if (primaryDOBMissingField) {
        setPrimaryDOBMissing(true)
      }

      const primaryMissingField = response.missing_fields.find(
        (f) => f.is_primary_driver === true && f.missing_field === 'DLNumber'
      )

      if (primaryMissingField) {
        setPrimaryDLMissing(true)
      } else {
        setPrimaryDLMissing(false)
      }

      if (response.missing_fields) {
        setMissingFields(response.missing_fields)

        const driverFields = response.drivers.map((d) => {
          let fields = []

          const missingDL = response.missing_fields.find(
            (f) => f.driver_id === d.id && f.missing_field === 'DLNumber'
          )

          if (missingDL) {
            fields.push({
              missingFieldType: 'DLNumber',
              dlState: d.dl_state || '',
              dlNumber: d.dl_number || '',
            })
          }

          const missingDOB = response.missing_fields.find(
            (f) => f.driver_id === d.id && f.missing_field === 'DOB'
          )

          if (missingDOB) {
            fields.push({
              missingFieldType: 'DOB',
              dob: d.birth_date || '',
            })
          }

          return {
            driverID: d.id,
            fields: fields,
          }
        })

        setDriversMissingFields({ list: driverFields })
      }

      if (response.drivers.length > 0) {
        setDrivers(response.drivers)
      } else {
        setDrivers([])
      }

      if (response.license) {
        setLicense(response.license)

        if (response.license.number) {
          setPrimaryDLNumber(response.license.number)
        }

        if (response.license.state) {
          setPrimaryDLState(response.license.state)
        }

        if (response.license.birth_date) {
          setPrimaryDOB(Strings.dateStringToInput(response.license.birth_date))
        }
      }

      if (response.broker_info) {
        setBrokerInfo(response.broker_info)
      }

      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchMissingFields()
    // eslint-disable-next-line
  }, [])

  const handleEmailBlur = async (e) => {
    const error = await UserModel.validateEmail(email)
    setEmailError(error)
  }

  const handlePhoneBlur = async (e) => {
    const phoneNumber = phone.replace(/\s/g, '')
    if (phoneNumber.length !== 10) {
      setPhoneError('Please enter a valid Phone')
    } else {
      setPhoneError('')
    }
  }

  const getDriver = (driverID) => {
    return drivers.find((d) => d.id === driverID)
  }

  const getDriverDL = (driverID) => {
    const fields = driversMissingFields.list.find(
      (d) => d.driverID === driverID
    ).fields

    return fields.find((f) => f.missingFieldType === 'DLNumber')
  }

  const driversDLValid = () => {
    const nonFilled = driversDLList.list.find(
      (d) => d.dlState.length === 0 || d.dlNumber.length === 0
    )

    if (nonFilled) {
      return false
    }

    return true
  }

  const handleLicenseBlur = async (e) => {
    const isValid = await LicenseModel.validateLicense(
      userID,
      primaryDLNumber,
      primaryDLState
    )

    if (!isValid) {
      setPrimaryDLError('Please enter a valid license number')
    } else {
      setPrimaryDLError('')
    }
  }

  const handlePrimaryDOBBlur = (e) => {
    const dateStr = Strings.inputToDateString(primaryDOB)

    if (!Strings.isValidDate(dateStr)) {
      setPrimaryDOBError('Please enter a valid date')
    } else {
      setPrimaryDOBError('')
    }
  }

  const handleDriverDLStateChange = (e) => {
    const driverID = e.target.name
    const value = e.target.value

    setDriversMissingFields((prevList) => {
      const dIndex = prevList.list.findIndex((d) => d.driverID === driverID)

      const updatedList = prevList.list

      const fieldIndex = updatedList[dIndex].fields.findIndex(
        (f) => f.missingFieldType === 'DLNumber'
      )

      updatedList[dIndex].fields[fieldIndex].dlState = value

      return { list: updatedList }
    })
  }

  const handleDriversDLNumberChange = (e) => {
    const driverID = e.target.dataset.id
    const value = e.target.value

    setDriversMissingFields((prevList) => {
      const dIndex = prevList.list.findIndex((d) => d.driverID === driverID)

      const updatedList = prevList.list

      const fieldIndex = updatedList[dIndex].fields.findIndex(
        (f) => f.missingFieldType === 'DLNumber'
      )

      updatedList[dIndex].fields[fieldIndex].dlNumber = value

      return { list: updatedList }
    })
  }

  const handleDriverDLBlur = async (e) => {
    const driverDL = getDriverDL(e.target.dataset.id)

    const isValid = await LicenseModel.validateLicense(
      userID,
      driverDL.dlNumber,
      driverDL.dlState
    )

    if (!isValid) {
      setInvalidDriverDL(driverDL.driverID)
    } else {
      setInvalidDriverDL(null)
    }
  }

  const handleDriversDOBChange = (e) => {
    const driverID = e.target.dataset.id
    const value = e.target.value

    setDriversMissingFields((prevList) => {
      const dIndex = prevList.list.findIndex((d) => d.driverID === driverID)

      const updatedList = prevList.list

      const fieldIndex = updatedList[dIndex].fields.findIndex(
        (f) => f.missingFieldType === 'DOB'
      )

      updatedList[dIndex].fields[fieldIndex].dob = Strings.inputToDateString(
        value
      )

      return { list: updatedList }
    })
  }

  const handleDriversDOBBlur = (e) => {
    const driverID = e.target.dataset.id
    const value = e.target.value

    const dateStr = Strings.inputToDateString(value)

    if (!Strings.isValidDate(dateStr)) {
      setInvalidDriverDOB(driverID)
    } else {
      setInvalidDriverDOB(null)
    }
  }

  const isValid = () => {
    if (primaryDOBMissing && primaryDOBError) {
      return false
    }

    if ((emailMissing && emailError) || (emailMissing && email.length === 0)) {
      return false
    }

    if (phoneMissing && phoneError) {
      return false
    }

    if (phoneMissing) {
      const phoneNumber = phone.replace(/\s/g, '')
      if (phoneNumber.length !== 10) {
        return false
      }
    }

    if (invalidDriverDL) {
      return false
    }

    if (!driversDLValid()) {
      return false
    }

    if (primaryDLMissing) {
      if (
        primaryDLState.length > 0 &&
        primaryDLNumber.length > 0 &&
        !primaryDLError
      ) {
        return true
      } else {
        return false
      }
    }

    return true
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!isValid()) {
      return
    }

    setIsProcessing(true)

    const phoneNumber = phone.replace(/\s/g, '')

    const { err } = await BookModel.updateMissingFields({
      email,
      phone: phoneNumber || '',
      primaryDOB: primaryDOB ? Strings.inputToDateString(primaryDOB) : '',
      primaryDLState,
      primaryDLNumber,
      drivers: driversMissingFields.list,
      platform: platform,
    })

    if (err) {
      console.log('error updating missing fields:', err)
      alert('Error updating missing fields')
      setIsProcessing(false)
      return
    }

    window.location = `/quote/${userID}`
  }

  return (
    <div className="container">
      <CenteredContainer>
        <h2 className="title">Finalize Application</h2>
        {isLoading ? (
          <LoadingIndicator text="Loading..." />
        ) : (
          <p className="title-text">
            {license && `${license.first_name},`} please complete the
            information below.
          </p>
        )}

        {brokerInfo && (
          <div className="broker-info">
            <p>Your Broker:</p>
            <div>
              {brokerInfo.email === 'ashley@csivegas.insure' ? (
                <div className="initials">
                  <img src={agentIcon} alt="" />
                </div>
              ) : (
                <div className="initials">
                  {`${brokerInfo.firstname[0]}${brokerInfo.lastname[0]}`}
                </div>
              )}

              <div className="broker">
                <p>{`${brokerInfo.firstname} ${brokerInfo.lastname}`}</p>
                <p>{brokerInfo.agency_name}</p>
                {brokerInfo.license_state && brokerInfo.license_number && (
                  <p>
                    LIC:{' '}
                    {`${brokerInfo.license_state}-brokerInfo.license_number`}
                  </p>
                )}
              </div>
            </div>
          </div>
        )}

        {missingFields.length > 0 && !isLoading && (
          <div className="form-container" style={{ marginTop: '43px' }}>
            <form onSubmit={handleSubmit}>
              {(primaryDLMissing || emailMissing || primaryDOBMissing) && (
                <h3 className="heading">{`${license.first_name} ${license.last_name}`}</h3>
              )}

              {emailMissing && (
                <div className="form-row">
                  <div className="form-column">
                    <Label icon={emailIcon} text="Email" />
                    <input
                      className={emailError && 'error'}
                      name="email"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={handleEmailBlur}
                    />
                    <ErrorLabel errorMessage={emailError} />
                  </div>
                </div>
              )}

              {phoneMissing && (
                <div className="form-row">
                  <div className="form-column">
                    <Label icon={phoneIcon} text="Phone Number"></Label>
                    <NumberFormat
                      type="tel"
                      format="### ### ####"
                      name="phoneNumber"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      onBlur={handlePhoneBlur}
                    />
                    <ErrorLabel errorMessage={phoneError} />
                  </div>
                </div>
              )}

              {platformMissing && (
                <div className="form-row">
                  <div className="form-column">
                    <Label icon={deviceIcon} text="DEVICE TYPE" iconSize={25} />
                    <select
                      className={platform ? '' : 'placeholder'}
                      name="platform"
                      value={platform}
                      onChange={(e) => setPlatform(e.target.value)}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="ios">iPhone</option>
                      <option value="android">Android</option>
                    </select>
                  </div>
                </div>
              )}

              {primaryDOBMissing && (
                <div className="form-row">
                  <div className="form-column">
                    <Label icon={timeIcon} text="BIRTHDAY (MM DD YYYY)"></Label>
                    <NumberFormat
                      className={primaryDOBError && 'error'}
                      format="## ## ####"
                      mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
                      name="primaryDOB"
                      value={primaryDOB}
                      onChange={(e) => setPrimaryDOB(e.target.value)}
                      onBlur={handlePrimaryDOBBlur}
                    />
                    <ErrorLabel errorMessage={primaryDOBError} />
                  </div>
                </div>
              )}

              {primaryDLMissing && (
                <div className="form-row">
                  <div className="form-column">
                    <Label icon={flagIcon} text="DRIVER’S LICENSE STATE" />
                    <StateSelector
                      excludeMichigan="true"
                      value={primaryDLState ? primaryDLState : ''}
                      onChange={(e) => setPrimaryDLState(e.target.value)}
                    />
                  </div>
                  <div className="form-column">
                    <Label icon={cardIcon} text="DRIVER'S LICENSE" />
                    <input
                      className={primaryDLError && 'error'}
                      name="license"
                      type="text"
                      value={primaryDLNumber}
                      onChange={(e) => setPrimaryDLNumber(e.target.value)}
                      onBlur={handleLicenseBlur}
                    />
                    <ErrorLabel errorMessage={primaryDLError} />
                  </div>
                </div>
              )}

              {driversMissingFields.list.map((driverFields, i) => {
                const driver = getDriver(driverFields.driverID)

                return (
                  <div key={i}>
                    {i === 0 && (
                      <h3 className="heading">{`${driver.first_name} ${driver.last_name}`}</h3>
                    )}
                    {driverFields.fields.map((field, j) => {
                      if (field.missingFieldType === 'DLNumber') {
                        return (
                          <div className="form-row" key={j}>
                            <div className="form-column">
                              <Label
                                icon={flagIcon}
                                text="DRIVER’S LICENSE STATE"
                              />
                              <StateSelector
                                excludeMichigan="true"
                                value={field.dlState || ''}
                                name={driverFields.driverID}
                                onChange={handleDriverDLStateChange}
                              />
                            </div>
                            <div className="form-column">
                              <Label icon={cardIcon} text="DRIVER'S LICENSE" />
                              <input
                                type="text"
                                className={
                                  invalidDriverDL === driverFields.driverID
                                    ? 'error'
                                    : ''
                                }
                                name={`license_${driverFields.driverID}`}
                                value={field.dlNumber}
                                data-id={driverFields.driverID}
                                onChange={handleDriversDLNumberChange}
                                onBlur={handleDriverDLBlur}
                              />
                              {invalidDriverDL === driverFields.driverID && (
                                <ErrorLabel errorMessage="Please enter a valid license" />
                              )}
                            </div>
                          </div>
                        )
                      }

                      if (field.missingFieldType === 'DOB') {
                        return (
                          <div className="form-row" key={j}>
                            <div className="form-column">
                              <Label
                                icon={timeIcon}
                                text="BIRTHDAY (MM DD YYYY)"
                              />
                              <NumberFormat
                                className={
                                  invalidDriverDOB === driverFields.driverID
                                    ? 'error'
                                    : ''
                                }
                                format="## ## ####"
                                mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
                                name={`dob_${driverFields.driverID}`}
                                value={Strings.dateStringToInput(field.dob)}
                                data-id={driverFields.driverID}
                                onChange={handleDriversDOBChange}
                                onBlur={handleDriversDOBBlur}
                              />
                              {invalidDriverDOB === driverFields.driverID && (
                                <ErrorLabel errorMessage="Please enter a valid date" />
                              )}
                            </div>
                          </div>
                        )
                      }

                      return null
                    })}
                  </div>
                )
              })}

              <button className="primary" type="submit" disabled={!isValid()}>
                {isProcessing ? (
                  <LoadingIndicator style={{ justifyContent: 'center' }} />
                ) : (
                  'Continue'
                )}
              </button>
            </form>
          </div>
        )}
      </CenteredContainer>
    </div>
  )
}

export default FinalizeQuote
