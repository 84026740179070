import './Intro.css'
import { CenteredContainer } from '../../components/Container'
import icnThirdParty from '../../assets/icn-third-party.svg'
import icnAddress from '../../assets/icn-address-pin.svg'
import icnPhone from '../../assets/icn-phone.svg'
import icnBank from '../../assets/icn-bank.svg'
import icnDriver from '../../assets/icn-driver.svg'
import { useHistory } from 'react-router-dom'

export default function Intro() {
  const history = useHistory()
  return (
    <div className="container">
      <CenteredContainer className="vendor-register-wrapper">
        <img className="icn-third-party" src={icnThirdParty} alt="" />

        <h1 className="title">Register your profile to get paid fast</h1>
        <p className="sub-title">
          Please ensure you have the
          <br />
          following handy
        </p>

        <div className="register-requirements">
          <ul>
            <li>
              <img src={icnAddress} alt="" />
              Contact address
            </li>
            <li>
              <img src={icnPhone} alt="" />
              Phone number
            </li>
            <li>
              <img src={icnBank} alt="" />
              Bank account information
            </li>
            <li>
              <img src={icnDriver} alt="" />
              Identity details
            </li>
          </ul>
        </div>

        <button
          className="primary"
          onClick={() => history.push('/business/register')}
        >
          Continue
        </button>
        <button
          className="link grey"
          onClick={() => history.push('/business/verify')}
        >
          Already Registered
        </button>
      </CenteredContainer>
    </div>
  )
}
