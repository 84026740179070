import API from './API'
import Constants from './Constants'
import Store from './Store'

class BookModel {
  getMissingFields = async (userID) => {
    const response = await API()
      .get(`/v3/brokerages/books/missing_fields/${userID}`)
      .then((res) => {
        console.log('API res:', res)
        if (res.status === 200) {
          return { response: res.data, err: null }
        }

        return { response: null, err: Constants.genericNetworkError() }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err:', error)
          return { response: null, err: error }
        }

        return { response: null, err: error.response.data }
      })

    return response
  }

  updateMissingFields = async ({
    email,
    phone,
    primaryDOB,
    primaryDLState,
    primaryDLNumber,
    drivers = null,
    platform = null,
  }) => {
    const user = Store.getUser()

    const data = {
      email,
      phone_number: phone || '',
      primary_dob: primaryDOB || '',
      primary_dl_state: primaryDLState,
      primary_dl_number: primaryDLNumber,
      drivers,
      user_platform: platform,
    }

    const response = await API()
      .put(`/v3/brokerages/books/missing_fields/${user.userID}`, data)
      .then((res) => {
        console.log('API res:', res)
        if (res.status === 200) {
          return { err: null }
        }

        return { err: Constants.genericNetworkError() }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err:', error)
          return { err: error }
        }

        return { err: error.response.data }
      })

    return response
  }

  updatePolicyMissingFields = async ({
    policyID,
    primaryDLState,
    primaryDLNumber,
    drivers = null,
  }) => {
    const data = {
      primary_dl_state: primaryDLState,
      primary_dl_number: primaryDLNumber,
      drivers: null,
    }

    if (drivers && drivers.length > 0) {
      const driversData = drivers.map((d) => {
        return {
          driver_id: d.driverID,
          dl_state: d.dlState,
          dl_number: d.dlNumber,
        }
      })

      data.drivers = driversData
    }

    const response = await API()
      .put(`/v3/brokerages/books/policy_missing_fields_quote/${policyID}`, data)
      .then((res) => {
        console.log('API res:', res)
        if (res.status === 200) {
          return { downPayment: res.data.down_payment, err: null }
        }

        return { downPayment: null, err: Constants.genericNetworkError() }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err:', error)
          return { downPayment: null, err: error }
        }

        return { downPayment: null, err: error.response.data }
      })

    return response
  }

  policyMissingFieldsBind = async ({
    token,
    policyID,
    primaryDLState,
    primaryDLNumber,
    drivers = null,
  }) => {
    const data = {
      token,
      primary_dl_state: primaryDLState,
      primary_dl_number: primaryDLNumber,
      drivers: null,
    }

    if (drivers && drivers.length > 0) {
      const driversData = drivers.map((d) => {
        return {
          driver_id: d.driverID,
          dl_state: d.dlState,
          dl_number: d.dlNumber,
        }
      })

      data.drivers = driversData
    }

    const response = await API()
      .put(`/v3/brokerages/books/policy_missing_fields_bind/${policyID}`, data)
      .then((res) => {
        console.log('API res:', res)
        if (res.status === 200) {
          return { success: true, err: null }
        }

        return { success: false, err: Constants.genericNetworkError() }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err:', error)
          return { success: false, err: error }
        }

        return { success: false, err: error.response.data }
      })

    return response
  }
}

export default new BookModel()
