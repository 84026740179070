import '../../styles/ClaimsPay.css'
import { CenteredContainer } from '../../components/Container'
import bankImg from '../../assets/finance.svg'
import bankIcon from '../../assets/icn-finance.svg'
import acctNumIcon from '../../assets/icn-account-number.svg'
import { useState } from 'react'
import { ErrorLabel, Label } from '../../components/Label'
import ClaimsPayModel from '../../models/ClaimsPayModel'
import Store from '../../models/Store'

export default function ClaimsPayVerifyBank(props) {
  const user = Store.getUser()
  const claimsPaymentID = props.match.params.claims_payment_id

  const [showForm, setShowForm] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [acctError, setAcctError] = useState('')
  const [isSubmittting, setIsSubmittting] = useState(false)
  const [formValues, setFormValues] = useState({
    routing: '',
    account: '',
    account_confirm: '',
  })

  const handleFormChange = (e) => {
    const formName = e.target.name
    const formValue = e.target.value.toUpperCase().trim()

    setFormValues({
      ...formValues,
      [formName]: formValue,
    })

    if (
      formName === 'account_confirm' &&
      formValue.length >= 5 &&
      formValue !== formValues.account
    ) {
      setAcctError('The account number confirmation does not match')
    } else {
      setAcctError('')
    }
  }

  function isValid() {
    if (!formValues.routing) {
      return false
    }

    // account numbers have to be at least 8 digits
    if (formValues.account.length < 5) {
      return false
    }

    if (formValues.account !== formValues.account_confirm) {
      return false
    }

    return true
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault()
    if (errMsg) {
      setErrMsg('')
    }
    setIsSubmittting(true)

    const err = await ClaimsPayModel.createBankAccount(
      claimsPaymentID,
      formValues.routing,
      formValues.account
    )
    if (err) {
      if (err.err && err.err.includes('BDC_1143')) {
        setErrMsg(`Invalid routing or account number.`)
      } else {
        setErrMsg(
          `Sorry, we ran into an unexpected issue and we’re still figuring it out.`
        )
      }

      setIsSubmittting(false)
      return
    }

    setIsSubmittting(false)
    window.location = `/claims-pay/schedule/${claimsPaymentID}`
  }

  return (
    <div className="container">
      <CenteredContainer className="claims-pay-container">
        <div className="claims-pay-intro bank">
          <img className="icon" src={bankImg} alt="bank building" />
          <h2 className="title">Bank Verification</h2>
          {!showForm && (
            <>
              <p className="title-text">
                Use your routing and account number to verify your bank account.
              </p>

              <button className="primary" onClick={() => setShowForm(true)}>
                Continue
              </button>
            </>
          )}

          {showForm && (
            <form onSubmit={handleFormSubmit}>
              {/* <pre>{JSON.stringify(formValues, null, ' ')}</pre> */}
              <div className="form-row">
                <div className="form-column">
                  <Label icon={bankIcon} text="ROUTING NUMBER" />
                  <input
                    type="text"
                    name="routing"
                    value={formValues.routing}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-column">
                  <Label icon={acctNumIcon} text="ACCOUNT NUMBER" />
                  <input
                    type="text"
                    name="account"
                    value={formValues.account}
                    onChange={handleFormChange}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-column">
                  <Label icon={acctNumIcon} text="CONFIRM ACCOUNT NUMBER" />
                  <input
                    className={acctError && 'error'}
                    type="text"
                    name="account_confirm"
                    value={formValues.account_confirm}
                    onChange={handleFormChange}
                  />
                  <ErrorLabel errorMessage={acctError} />
                </div>
              </div>

              <div style={{ marginTop: '35px' }}>
                {errMsg && (
                  <div className="claims-pay-error-text">
                    {errMsg ? errMsg : ' '}
                  </div>
                )}

                <button
                  className="primary"
                  type="submit"
                  disabled={!isValid() || isSubmittting}
                >
                  {isSubmittting ? 'Saving...' : 'Continue'}
                </button>
              </div>
            </form>
          )}
        </div>
      </CenteredContainer>
    </div>
  )
}
