import '../styles/CodeVerifyInput.css'
import React, { createRef, useEffect, useState } from 'react'

export default function CodeVerifyInput({
  style = {},
  codeLength = 4,
  onComplete,
}) {
  const [inputValues, setInputValues] = useState(() =>
    Object.assign({}, Array(codeLength).fill(''))
  )

  let inputRefs = []

  const handleOnChange = (e) => {
    const id = parseInt(e.target.dataset.id)
    const value = e.target.value

    setInputValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }))
  }

  const handleOnKeyUp = (e) => {
    const id = parseInt(e.target.dataset.id)
    const keyCode = e.keyCode

    if (keyCode === 8 || keyCode === 46) {
      if (id > 0) {
        inputRefs[id - 1].current.focus()
      }
      return
    }

    if (id < codeLength - 1) {
      inputRefs[id + 1].current.focus()
      return
    }

    if (id === codeLength - 1) {
      inputRefs[id].current.blur()
      return
    }
  }

  const handleOnPaste = (e) => {
    e.preventDefault()
    let paste = (e.clipboardData || window.clipboardData).getData('text')
    paste = paste.trim()

    if (paste.length !== codeLength) {
      return
    }

    setInputValues((prevValues) => ({
      0: paste.charAt(0),
      1: paste.charAt(1),
      2: paste.charAt(2),
      3: paste.charAt(3),
    }))

    for (const ref of inputRefs) {
      ref.current.blur()
    }
  }

  const inputs = Array(codeLength)
    .fill()
    .map((_, i) => {
      const ref = createRef()
      inputRefs.push(ref)

      return (
        <input
          key={i}
          name={`code${i}`}
          data-id={i}
          ref={ref}
          value={inputValues[i]}
          type="text"
          maxLength="1"
          inputMode="numeric"
          autoComplete="one-time-code"
          onChange={handleOnChange}
          onKeyUp={handleOnKeyUp}
          onPaste={handleOnPaste}
        />
      )
    })

  useEffect(() => {
    let codeText = ''
    for (const id in inputValues) {
      codeText += inputValues[id]
    }

    if (codeText.length === codeLength && onComplete) {
      onComplete(codeText)
    }
  }, [inputValues, codeLength, onComplete])

  return (
    <div className="code-verify-input" style={style}>
      {inputs}
    </div>
  )
}
