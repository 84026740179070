import React, { useEffect, useRef, useState } from 'react'
import { CenteredContainer } from '../components/Container'
import LoadingIndicator from '../components/LoadingIndicator'
import AgentModel from '../models/AgentModel'
import Strings from '../utils/Strings'

export default function BrokerQuoteVerify(props) {
  const code1Ref = useRef(null)
  const code2Ref = useRef(null)
  const code3Ref = useRef(null)
  const code4Ref = useRef(null)

  const [agent] = useState(() => {
    if (props.location.state && props.location.state.agent) {
      return props.location.state.agent
    }

    return null
  })

  const [processing, setProcessing] = useState(false)

  const [formValues, setFormValues] = useState({
    code1: '',
    code2: '',
    code3: '',
    code4: '',
  })

  const handleOnKeyUp = (e) => {
    const fieldName = e.target.name
    const fieldValue = String.fromCharCode(e.keyCode)

    if (e.keyCode === 8 || e.keyCode === 46) {
      switch (fieldName) {
        case 'code1':
          code1Ref.current.focus()
          setFormValues((prevFormValues) => ({
            ...prevFormValues,
            code1: '',
          }))
          break
        case 'code2':
          code1Ref.current.focus()
          setFormValues((prevFormValues) => ({
            ...prevFormValues,
            code1: '',
          }))
          break
        case 'code3':
          code2Ref.current.focus()
          setFormValues((prevFormValues) => ({
            ...prevFormValues,
            code2: '',
          }))
          break
        case 'code4':
          code3Ref.current.focus()
          setFormValues((prevFormValues) => ({
            ...prevFormValues,
            code3: '',
          }))
          break
        default:
          break
      }

      return
    }

    if (isNaN(parseInt(fieldValue))) {
      return
    }

    setFormValues({
      ...formValues,
      [fieldName]: fieldValue,
    })

    switch (fieldName) {
      case 'code1':
        code2Ref.current.focus()
        break
      case 'code2':
        code3Ref.current.focus()
        break
      case 'code3':
        code4Ref.current.focus()
        break
      case 'code4':
        code4Ref.current.blur()
        break
      default:
        break
    }
  }

  const verifyQuoteCode = async () => {
    let verificationCodeStr =
      formValues.code1 + formValues.code2 + formValues.code3 + formValues.code4

    if (verificationCodeStr.length === 4) {
      setProcessing(true)
      let verificationCode = parseInt(verificationCodeStr)

      const err = await AgentModel.verifyQuote(verificationCode)
      if (err) {
        alert('Invalid verification code.')
        resetFields()
        setProcessing(false)
        return
      }

      setProcessing(false)
      window.location = '/quote'
    }
  }

  const handleOnPaste = (e) => {
    e.preventDefault()
    let paste = (e.clipboardData || window.clipboardData).getData('text')
    paste = paste.trim()

    if (paste.length !== 4) {
      return
    }

    if (isNaN(parseInt(paste))) {
      return
    }

    setFormValues({
      code1: paste[0],
      code2: paste[1],
      code3: paste[2],
      code4: paste[3],
    })
  }

  function resetFields() {
    setFormValues({
      code1: '',
      code2: '',
      code3: '',
      code4: '',
    })
    code1Ref.current.value = ''
    code2Ref.current.value = ''
    code3Ref.current.value = ''
    code4Ref.current.value = ''
  }

  useEffect(() => {
    verifyQuoteCode()
    // eslint-disable-next-line
  }, [formValues])

  return (
    <div className="container">
      <CenteredContainer
        style={{ maxWidth: '572px', margin: '0 auto', textAlign: 'center' }}
      >
        <h2 className="agent-verify-title">
          Call or text {agent.firstname} at
          <br />
          {agent.phone_number
            ? Strings.formatPhoneNumber(agent.phone_number)
            : Strings.formatPhoneNumber('8552892111')}
          <br />
          to receive your code
        </h2>
        <p className="title-text">This code verifies your application.</p>

        <div className="agent-verify-codes">
          <input
            name="code1"
            type="text"
            ref={code1Ref}
            defaultValue={formValues.code1}
            maxLength="1"
            onKeyUp={handleOnKeyUp}
            onPaste={handleOnPaste}
          />
          <input
            name="code2"
            type="text"
            ref={code2Ref}
            defaultValue={formValues.code2}
            maxLength="1"
            onKeyUp={handleOnKeyUp}
            onPaste={handleOnPaste}
          />
          <input
            name="code3"
            type="text"
            ref={code3Ref}
            defaultValue={formValues.code3}
            maxLength="1"
            onKeyUp={handleOnKeyUp}
            onPaste={handleOnPaste}
          />
          <input
            name="code4"
            type="text"
            ref={code4Ref}
            defaultValue={formValues.code4}
            maxLength="1"
            onKeyUp={handleOnKeyUp}
            onPaste={handleOnPaste}
          />
        </div>

        {processing && (
          <div style={{ margin: '0 auto', width: '100px', marginTop: '50px' }}>
            <LoadingIndicator text="Verifying..." />
          </div>
        )}
      </CenteredContainer>
    </div>
  )
}
