import React, { Fragment } from 'react'
import { useState, useEffect, useRef } from 'react'
import { createRef, useCallback } from 'react'

const imageContainer = {
  position: 'relative',
  display: 'inline-block',
}
const imageCanvas = {
  display: 'none',
}
const buttonStyle = {
  marginTop: '40px',
}

function ImageCapture(props) {
  const {
    onCapture,
    onError,
    userMediaConfig,
    width = 'auto',
    height = 'auto',
    btnText = 'Take Photo',
  } = props
  const [streaming, setStreaming] = useState(false)
  const playerRef = createRef()
  const canvasRef = createRef()
  const tracks = useRef()
  useEffect(() => {
    let timeout
    navigator.mediaDevices
      .getUserMedia(userMediaConfig)
      .then((stream) => {
        playerRef.current.srcObject = stream
        tracks.current = stream.getTracks()
        timeout = setTimeout(() => setStreaming(true), 2000)
      })
      .catch((error) => {
        if (onError) onError(error)
      })
    return () => {
      if (timeout) clearTimeout(timeout)
    }
    // eslint-disable-next-line
  }, [onError, userMediaConfig])

  useEffect(() => {
    return () => {
      // Stop the camera stream
      if (tracks.current) {
        tracks.current[0].stop()
      }
    }
  }, [])

  const captureImage = useCallback(() => {
    if (!canvasRef.current) {
      return
    }
    const imageWidth = playerRef.current.offsetWidth
    const imageHeight = playerRef.current.offsetHeight
    ;[canvasRef.current.width, canvasRef.current.height] = [
      imageWidth,
      imageHeight,
    ]
    const context = canvasRef.current.getContext('2d')
    context.drawImage(playerRef.current, 0, 0, imageWidth, imageHeight)
    // Trigger the callback function
    if (onCapture) {
      const jpeg = canvasRef.current.toDataURL('image/jpg')
      const jpegData = jpeg.replace(/^data:image\/(png|jpg);base64,/, '')

      canvasRef.current.toBlob((blob) => {
        onCapture({ blob, jpeg: jpeg, file: jpegData })
      })
    }
  }, [onCapture, canvasRef, playerRef])

  return (
    <>
      <div style={imageContainer}>
        <video
          ref={playerRef}
          autoPlay
          playsInline
          width={width}
          height={height}
        ></video>
        {streaming && <canvas style={imageCanvas} ref={canvasRef} />}
      </div>
      <>
        <button
          className="primary"
          type="submit"
          onClick={captureImage}
          style={buttonStyle}
        >
          {btnText}
        </button>
      </>
    </>
  )
}

export default ImageCapture
