import React, { useState } from 'react'
import {
  ColumnContainer,
  ColumnLeft,
  ColumnRight,
} from '../components/Container'
import { Label } from '../components/Label'
import NumberFormat from 'react-number-format'
import UserModel from '../models/UserModel'
import appImage from './../assets/appoftheday-img-2@3x.jpg'
import emailIcon from './../assets/email.svg'
import phoneIcon from './../assets/phone-number.svg'
import Strings from '../utils/Strings'
import Sentry from '../logger/Sentry'

function Login(props) {
  const [formValues, setFormValues] = useState({
    email: '',
    phoneNumber: '',
  })

  const handleInputChange = (e) => {
    const fieldName = e.target.name
    const fieldValue = e.target.value

    setFormValues({
      ...formValues,
      [fieldName]: fieldValue.trim(),
    })
  }

  const isValid = () => {
    return (
      Strings.isValidEmail(formValues.email) &&
      Strings.isValidPhone(formValues.phoneNumber)
    )
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault()

    const { userID, err } = await UserModel.login(
      formValues.email,
      formValues.phoneNumber.replace(/\s/g, '')
    )
    if (err) {
      console.log('login failed:', err)
      Sentry.error('LoginError', err, {
        ...formValues,
        error: err,
      })
      return
    }

    console.log('moving to verify userID:', userID)

    props.history.push('/verify')
  }

  return (
    <ColumnContainer>
      <ColumnLeft>
        <h2 className="display">
          Join the <span className="highlight">thousands</span> that save $972 a
          year when they switch to Go Insurance!
        </h2>

        <div className="form-container">
          <form onSubmit={handleFormSubmit}>
            <div className="form-row">
              <div className="form-column">
                <Label icon={emailIcon} text="EMAIL"></Label>
                <input
                  type="email"
                  name="email"
                  value={formValues.email}
                  onChange={handleInputChange}
                  inputMode="email"
                  autoComplete="email"
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-column">
                <Label icon={phoneIcon} text="PHONE NUMBER"></Label>
                <NumberFormat
                  type="tel"
                  format="### ### ####"
                  name="phoneNumber"
                  value={formValues.phoneNumber}
                  onChange={handleInputChange}
                  inputMode="tel"
                  autoComplete="tel"
                />
              </div>
            </div>
            <button
              className="primary"
              type="submit"
              style={{ width: '100%' }}
              disabled={!isValid()}
            >
              Switch to Go Insurance
            </button>

            <div className="form-row">
              <div className="form-column">
                <p className="disclaimer">
                  We don't sell your data to any third parties. By joining, I
                  agree to{' '}
                  <a href="https://gocarinsurance.com/privacy">
                    Go Insurance's Privacy Policy
                  </a>{' '}
                  &amp;{' '}
                  <a href="https://gocarinsurance.com/terms-conditions">TOS</a>
                </p>
              </div>
            </div>

            <div className="form-row">
              <div className="form-column">
                <p className="disclaimer">
                  Go Insurance policies are 100%  backed by a $200 billion
                  reinsurer Swiss Re. Go Insurance policies in Arizona,
                  California, Colorado, Utah, and Virginia are underwritten by
                  Topa Insurance Company NAIC #18031. Go Insurance policies in
                  Texas are underwritten by Redpoint County Mutual Insurance
                  Company NAIC #29300.
                </p>
              </div>
            </div>
          </form>
        </div>
      </ColumnLeft>
      <ColumnRight className="no-mobile">
        <img
          className="app-of-the-day-img"
          src={appImage}
          alt="App of the day"
        />
      </ColumnRight>
    </ColumnContainer>
  )
}

export default Login
