import * as _sentry from '@sentry/react'
import Store from '../models/Store'

class Sentry {
  // error
  error = (event, error, data = null) => {
    _sentry.withScope((scope) => {
      scope.setTag('event_type', 'custom')

      const user = Store.getUser()
      if (user && user.userID) {
        scope.setUser({ user_id: user.userID })
      }

      const errMsg = typeof error === 'object' ? JSON.stringify(error) : error
      let logErr = new Error(errMsg)
      logErr.name = event

      if (data) {
        scope.setContext(event, data)
        _sentry.captureException(logErr)
        return
      }

      _sentry.captureException(logErr)
    })
  }

  // info
  info = (event, message, data = null) => {
    _sentry.withScope((scope) => {
      scope.setTag('event_type', 'custom')

      scope.addBreadcrumb({
        category: event,
        message: message,
        level: _sentry.Severity.Info,
      })

      const user = Store.getUser()
      if (user && user.userID) {
        scope.setUser({ user_id: user.userID })
      }

      if (data) {
        scope.setContext('Event Data', data)
      }

      _sentry.captureMessage(event)
    })
  }
}

export default new Sentry()
