import '../styles/CalloutPopup.css'
import React, { useState, useEffect } from 'react'
import { CenteredContainer } from '../components/Container'
import CoveragesModel from '../models/CoveragesModel'
import Utils from '../utils/Utils'
import UserModel from '../models/UserModel'
import { ErrorLabel } from '../components/Label'
import Breadcrumb from '../components/Breadcrumb'
import StackedMobile from '../components/StackedMobile'
import Store from '../models/Store.js'
import Sentry from '../logger/Sentry'
import infoIcon from '../assets/icn-info-black.svg'
import BrokerInfo from '../components/BrokerInfo'
import bulbIcon from '../assets/lightbulb.svg'

function Coverages(props) {
  const user = Store.getUser()
  const userID = user ? user.userID : ''

  const [agent, setAgent] = useState(null)
  const [options, setOptions] = useState(null)
  const [pdError, setPDError] = useState('')
  const [umbiError, setUMBIError] = useState('')
  const [uimError, setUIMError] = useState('')
  const [collErrors, setCOLLErrors] = useState([])
  const [rentErrors, setRENTErrors] = useState([])
  const [cdwErrors, setCDWErrors] = useState([])
  const [glassErrors, setGlassErrors] = useState([])
  const [license, setLicense] = useState(null)
  const [address, setAddress] = useState(null)
  const [vehicles, setVehicles] = useState([])
  const [policyCoverages, setPolicyCoverages] = useState({})
  const [vehicleCoverages, setVehicleCoverages] = useState([])
  const [userState, setUserState] = useState(null)
  const [showCallout, setShowCallout] = useState(false)
  const [activeCalloutVehicle, setActiveCalloutVehicle] = useState('')

  useEffect(() => {
    fetchUser()
    // eslint-disable-next-line
  }, [])

  const fetchUser = async () => {
    const {
      user,
      license,
      address,
      vehicles,
      coverageOptions,
      policyCoverages,
      vehicleCoverages,
      userState,
      agent,
      err,
    } = await UserModel.getUser(userID, 'coverages')
    if (err) {
      console.log('error getting user:', err)
      return
    }

    setUserState(userState)
    setAddress(address)
    setLicense(license)
    setOptions(coverageOptions)
    setPolicyCoverages(policyCoverages)
    setVehicleCoverages(vehicleCoverages)
    setVehicles(vehicles)
    validateCoverages()

    if (agent) {
      setAgent(agent)
    }

    if (user && license) {
      Utils.sendGTMEvent('Coverages', user, license)
    }
  }

  function formatCoverage(type, o) {
    if (type === 'CDW' || type === 'TOW' || type === 'GLASS') {
      return o.has_coverage ? 'Included' : 'Not included'
    }
    if (!o.has_coverage) {
      return 'None'
    }
    switch (type) {
      case 'BI':
      case 'UMBI':
      case 'UIM':
        return (
          '$' +
          o.individual.toLocaleString() +
          ' / $' +
          o.occurrence.toLocaleString()
        )
      case 'RENT':
        if (o.individual) {
          return (
            '$' +
            o.individual.toLocaleString() +
            ' per day / $' +
            o.occurrence.toLocaleString() +
            ' per claim'
          )
        } else {
          return '$' + o.occurrence.toLocaleString() + ' per claim'
        }
      case 'MP':
      case 'PIP':
      case 'PD':
      case 'UMUIMPD':
        return '$' + o.occurrence.toLocaleString()
      case 'COMP':
      case 'COLL':
        return '$' + o.deductible.toLocaleString() + ' deductible'
      default:
        return ''
    }
  }

  function validateCoverages() {
    if (!options) {
      return
    }
    if (
      policyCoverages['bodily_injury_individual_limit'] <
      policyCoverages['property_damage_limit']
    ) {
      setPDError(
        'Property damage limit cannot be greater than the individual bodily injury limit'
      )
    } else {
      setPDError('')
    }
    if (
      policyCoverages['bodily_injury_individual_limit'] <
      policyCoverages['uninsured_bodily_injury_individual_limit']
    ) {
      setUMBIError(
        'Uninsured limits cannot be greater than bodily injury limits'
      )
    } else {
      setUMBIError('')
    }
    if (options.UIM && options.UIM.length > 0) {
      if (
        policyCoverages['bodily_injury_individual_limit'] <
        policyCoverages['underinsured_bodily_injury_individual_limit']
      ) {
        setUIMError(
          'Underinsured limits cannot be greater than bodily injury limits'
        )
      } else {
        setUIMError('')
      }
    }
    setCOLLErrors(
      vehicleCoverages.map((v) => {
        if (v['has_collision'] && !v['has_comprehensive']) {
          return 'Collision is unavailable without comprehensive'
        }
        return ''
      })
    )
    setRENTErrors(
      vehicleCoverages.map((v) => {
        if (v['has_rental_car'] && !v['has_comprehensive']) {
          return 'Rental is unavailable without collision'
        }
        return ''
      })
    )
    setCDWErrors(
      vehicleCoverages.map((v) => {
        if (v['has_collision_deductible_waiver'] && !v['has_collision']) {
          return 'Collision deductible waiver is unavailable without collision'
        }
        return ''
      })
    )
    setGlassErrors(
      vehicleCoverages.map((v) => {
        if (v['has_glass'] && !v['has_comprehensive']) {
          return 'Glass coverages is unavailable without comprehensive'
        }
        return ''
      })
    )
  }

  function isValid() {
    return (
      !pdError &&
      rentErrors.filter((e) => !!e).length === 0 &&
      collErrors.filter((e) => !!e).length === 0 &&
      cdwErrors.filter((e) => !!e).length === 0 &&
      glassErrors.filter((e) => !!e).length === 0
    )
  }

  function selectedIndex(name) {
    switch (name) {
      case 'BI':
        return options.BI.findIndex(
          (o) =>
            o['individual'] ===
            policyCoverages['bodily_injury_individual_limit']
        )
      case 'PD':
        return options.PD.findIndex(
          (o) => o['occurrence'] === policyCoverages['property_damage_limit']
        )
      case 'UMBI':
        if (address.state === 'VA') {
          return 0
        }
        return policyCoverages['uninsured_bodily_injury_individual_limit'] > 0
          ? 0
          : 1
      case 'MP':
        return options.MP.findIndex(
          (o) => o['individual'] === policyCoverages['medical_payment_limit']
        )
      case 'PIP':
        return options.PIP.findIndex(
          (o) => o['individual'] === policyCoverages['personal_injury_limit']
        )
      default:
        let index = 0
        vehicles.forEach((v, i) => {
          if (!name.endsWith(v['id'])) {
            return
          }
          let newVehicleCoverages = vehicleCoverages || []
          let cov = newVehicleCoverages.find(
            (cov) => cov['vehicle_id'] === v['id']
          )
          if (!cov) {
            return
          }
          if (name.startsWith('COMP-')) {
            index = options.COMP.findIndex(
              (o) =>
                o['has_coverage'] === cov['has_comprehensive'] &&
                o['deductible'] === cov['comprehensive_deductible']
            )
          } else if (name.startsWith('COLL-')) {
            index = options.COLL.findIndex(
              (o) =>
                o['has_coverage'] === cov['has_collision'] &&
                o['deductible'] === cov['collision_deductible']
            )
          } else if (name.startsWith('RENT-')) {
            index = options.RENT.findIndex(
              (o) =>
                o['has_coverage'] === cov['has_rental_car'] &&
                o['occurrence'] === cov['rental_car_occurrence']
            )
          } else if (name.startsWith('CDW-')) {
            index = options.CDW.findIndex(
              (o) =>
                o['has_coverage'] === cov['has_collision_deductible_waiver']
            )
          } else if (name.startsWith('GLASS-')) {
            index = options.GLASS.findIndex(
              (o) => o['has_coverage'] === cov['has_glass']
            )
          } else if (name.startsWith('TOW-')) {
            index = options.TOW.findIndex(
              (o) => o['has_coverage'] === cov['has_towing']
            )
          } else if (name.startsWith('UMUIMPD-')) {
            if (address.state === 'VA') {
              return 0
            }
            index = cov['has_uninsured_property'] ? 0 : 1
          }
        })
        if (index > 0) {
          return index
        }
    }
    return 0
  }

  const umpdWithCOLL = () => {
    return address.state === 'TX'
  }

  const umpdMatchesPD = () => {
    return address.state === 'TX' || address.state === 'VA'
  }

  const umpdRequired = () => {
    return address.state === 'VA'
  }

  const umbiRequired = () => {
    return address.state === 'VA'
  }

  const isUMPDDisabled = (v) => {
    if (!options.UMUIMPD) {
      return true
    }

    if (policyCoverages['uninsured_bodily_injury_individual_limit'] === 0) {
      return true
    }

    if (umpdWithCOLL()) {
      return false
    }

    return false
  }

  const hasCOLL = (vehicle) => {
    var has = false
    vehicleCoverages.forEach((cov, i) => {
      if (cov.vehicle_id === vehicle.id) {
        has = cov.has_collision
      }
    })
    return has
  }

  function handleChange(e) {
    const fieldName = e.target.name
    const fieldValue = e.target.value
    console.log('name: ' + fieldName + ', val: ' + fieldValue)

    let newPolicyCoverages = policyCoverages || {}
    switch (e.target.name) {
      case 'BI':
        {
          let selected = options.BI[e.target.value]
          newPolicyCoverages['bodily_injury_individual_limit'] =
            selected['individual']
          newPolicyCoverages['bodily_injury_occurrence_limit'] =
            selected['occurrence']
          if (
            newPolicyCoverages['uninsured_bodily_injury_individual_limit'] > 0
          ) {
            newPolicyCoverages['uninsured_bodily_injury_individual_limit'] =
              selected['individual']
            newPolicyCoverages['uninsured_bodily_injury_occurrence_limit'] =
              selected['occurrence']
            if (options.UIM) {
              newPolicyCoverages[
                'underinsured_bodily_injury_individual_limit'
              ] = selected['individual']
              newPolicyCoverages[
                'underinsured_bodily_injury_occurrence_limit'
              ] = selected['occurrence']
            }
          }
          setPolicyCoverages(newPolicyCoverages)
        }
        break
      case 'PD':
        {
          let selected = options.PD[e.target.value]
          newPolicyCoverages['property_damage_limit'] = selected['occurrence']
          setPolicyCoverages(newPolicyCoverages)
        }
        break
      case 'UMBI':
        if (e.target.value === 'ACCEPT') {
          newPolicyCoverages['uninsured_bodily_injury_individual_limit'] =
            newPolicyCoverages['bodily_injury_individual_limit']
          newPolicyCoverages['uninsured_bodily_injury_occurrence_limit'] =
            newPolicyCoverages['bodily_injury_occurrence_limit']
          if (options.UIM) {
            newPolicyCoverages['underinsured_bodily_injury_individual_limit'] =
              newPolicyCoverages['bodily_injury_individual_limit']
            newPolicyCoverages['underinsured_bodily_injury_occurrence_limit'] =
              newPolicyCoverages['bodily_injury_occurrence_limit']
          }
        } else {
          newPolicyCoverages['uninsured_bodily_injury_individual_limit'] = 0
          newPolicyCoverages['uninsured_bodily_injury_occurrence_limit'] = 0
          newPolicyCoverages['underinsured_bodily_injury_individual_limit'] = 0
          newPolicyCoverages['underinsured_bodily_injury_occurrence_limit'] = 0
          let newVehicleCoverages = vehicleCoverages.map((cov) => {
            cov.uninsured_property_limit = 0
            cov.has_uninsured_property = false
            return cov
          })
          setVehicleCoverages(newVehicleCoverages)
        }
        setPolicyCoverages(newPolicyCoverages)
        break
      case 'MP':
        {
          let selected = options.MP[e.target.value]
          newPolicyCoverages['medical_payment_limit'] = selected['individual']
          setPolicyCoverages(newPolicyCoverages)
        }
        break
      case 'PIP':
        {
          let selected = options.PIP[e.target.value]
          newPolicyCoverages['personal_injury_limit'] = selected['individual']
          setPolicyCoverages(newPolicyCoverages)
        }
        break
      default:
        vehicles.forEach((v, i) => {
          if (!e.target.name.endsWith(v['id'])) {
            return
          }
          let newVehicleCoverages = vehicleCoverages || []
          let index = newVehicleCoverages.findIndex(
            (cov) => cov['vehicle_id'] === v['id']
          )
          if (index < 0) {
            newVehicleCoverages.push({
              vehicle_id: v['id'],
            })
            index = newVehicleCoverages.length - 1
          }
          if (e.target.name.startsWith('COMP-')) {
            var selected = options.COMP[e.target.value]
            
            // If comp is selected - have to have coll
            newVehicleCoverages[index]['has_comprehensive'] = selected['has_coverage']
            newVehicleCoverages[index]['comprehensive_deductible'] = selected['deductible']
            
            if (!selected['has_coverage']) {
              newVehicleCoverages[index]['has_collision'] = false
              newVehicleCoverages[index]['collision_deductible'] = 0
              newVehicleCoverages[index]['has_collision_deductible_waiver'] = false
              newVehicleCoverages[index]['has_rental_car'] = false
              newVehicleCoverages[index]['rental_car_day'] = 0
              newVehicleCoverages[index]['rental_car_occurrence'] = 0
              newVehicleCoverages[index]['has_towing'] = false
              newVehicleCoverages[index]['has_glass'] = false
            } else {
              // If currently coll is none - default it to the comp value
              if (!newVehicleCoverages[index]['has_collision']) {
                newVehicleCoverages[index]['collision_deductible'] = selected['deductible']
                newVehicleCoverages[index]['has_collision'] = true
                if (options.UMUIMPD && !umpdWithCOLL()) {
                  newVehicleCoverages[index]['has_uninsured_property'] = false
                  newVehicleCoverages[index]['uninsured_property_limit'] = 0
                }
              }
            }
          } else if (e.target.name.startsWith('COLL-')) {
            var selected = options.COLL[e.target.value]
            
            // If coll is selected - have to have comp
            newVehicleCoverages[index]['has_collision'] = selected['has_coverage']
            newVehicleCoverages[index]['collision_deductible'] = selected['deductible']
            
            if (!selected['has_coverage']) {
              newVehicleCoverages[index]['has_comprehensive'] = false
              newVehicleCoverages[index]['comprehensive_deductible'] = 0
              newVehicleCoverages[index]['has_collision_deductible_waiver'] = false
              newVehicleCoverages[index]['has_rental_car'] = false
              newVehicleCoverages[index]['rental_car_day'] = 0
              newVehicleCoverages[index]['rental_car_occurrence'] = 0
              newVehicleCoverages[index]['has_towing'] = false
              newVehicleCoverages[index]['has_glass'] = false
            } else {
              // If currently comp is none - default it to the coll value
              if (!newVehicleCoverages[index]['has_comprehensive']) {
                newVehicleCoverages[index]['comprehensive_deductible'] = selected['deductible']
                newVehicleCoverages[index]['has_comprehensive'] = true
              }
              
              if (options.UMUIMPD && !umpdWithCOLL()) {
                newVehicleCoverages[index]['has_uninsured_property'] = false
                newVehicleCoverages[index]['uninsured_property_limit'] = 0
              }
            }
          } else if (e.target.name.startsWith('RENT-')) {
            let selected = options.RENT[e.target.value]
            newVehicleCoverages[index]['has_rental_car'] =
              selected['has_coverage']
            newVehicleCoverages[index]['rental_car_day'] =
              selected['individual']
            newVehicleCoverages[index]['rental_car_occurrence'] =
              selected['occurrence']
          } else if (e.target.name.startsWith('CDW-')) {
            let selected = options.CDW[e.target.value]
            newVehicleCoverages[index]['has_collision_deductible_waiver'] =
              selected['has_coverage']
          } else if (e.target.name.startsWith('GLASS-')) {
            let selected = options.GLASS[e.target.value]
            newVehicleCoverages[index]['has_glass'] = selected['has_coverage']
          } else if (e.target.name.startsWith('TOW-')) {
            let selected = options.TOW[e.target.value]
            newVehicleCoverages[index]['has_towing'] = selected['has_coverage']
          } else if (e.target.name.startsWith('UMUIMPD-')) {
            if (e.target.value === 'ACCEPT') {
              newVehicleCoverages[index]['has_uninsured_property'] = true
              if (umpdMatchesPD()) {
                newVehicleCoverages[index]['uninsured_property_limit'] =
                  policyCoverages['property_damage_limit']
              } else {
                newVehicleCoverages[index]['uninsured_property_limit'] =
                  options.UMUIMPD[1].occurrence
              }
            } else {
              newVehicleCoverages[index]['has_uninsured_property'] = false
              newVehicleCoverages[index]['uninsured_property_limit'] = 0
            }
          }
          setVehicleCoverages(newVehicleCoverages)
        })
    }
    validateCoverages()
  }

  if (!options) {
    return <div></div>
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault()

    let success = await CoveragesModel.saveSelections(
      policyCoverages,
      vehicleCoverages
    )
    if (success) {
      Sentry.info('SavedCoverages', 'saved coverages', {
        policy_coverages: policyCoverages,
        vehicle_coverages: vehicleCoverages,
      })

      window.location = '/quote'
    } else {
      alert('error saving coverages')
      Sentry.error('SaveCoveragesError', 'error saving coverages', {
        policy_coverages: policyCoverages,
        vehicle_coverages: vehicleCoverages,
      })
    }
  }

  const handleShowCallout = (vehicleID, show = false) => {
    setActiveCalloutVehicle(vehicleID)
    setShowCallout(show)
  }

  return (
    <div className="container">
      <Breadcrumb page={'coverages'} userState={userState} license={license} />

      <CenteredContainer>
        <div className="mobile-page-heading">Coverages</div>
        {agent && <BrokerInfo agent={agent} style={{ margin: '28px 0' }} />}
        <form onSubmit={handleFormSubmit}>
          <h2 className="title coverage-header">Policy Coverages</h2>
          <p>
            Made to match your prior coverage or we have licensed agents here to
            help you verify.
          </p>
          <hr className="hr" />
          <StackedMobile
            verticalSpacing="5px"
            left={
              <div>
                <div className="coverage-type-title">
                  Other Driver’s Medical Care
                </div>
                <div className="coverage-type-description">
                  AKA Bodily Injury and UMBI
                </div>
              </div>
            }
            right={
              <div>
                <select
                  className="coverage-option"
                  name="BI"
                  onChange={handleChange}
                >
                  {options.BI.map((x, i) => (
                    <option
                      key={i}
                      value={i}
                      selected={selectedIndex('BI') === i}
                    >
                      {formatCoverage('BI', x)}
                    </option>
                  ))}
                </select>
              </div>
            }
          />
          <StackedMobile
            verticalSpacing="5px"
            left={
              <div>
                <div className="coverage-type-title">Other Driver’s Car</div>
                <div className="coverage-type-description">
                  AKA Property Damage
                </div>
              </div>
            }
            right={
              <div>
                <select
                  className="coverage-option"
                  name="PD"
                  onChange={handleChange}
                >
                  {options.PD.map((x, i) => (
                    <option
                      key={i}
                      value={i}
                      selected={selectedIndex('PD') === i}
                    >
                      {formatCoverage('PD', x)}
                    </option>
                  ))}
                </select>
                <ErrorLabel errorMessage={pdError} />
              </div>
            }
          />
          {options.MP && (
            <StackedMobile
              verticalSpacing="5px"
              left={
                <div>
                  <div className="coverage-type-title">
                    Extra Medical Care For You
                  </div>
                  <div className="coverage-type-description">
                    AKA Medical Payments
                  </div>
                </div>
              }
              right={
                <div>
                  <select
                    className="coverage-option"
                    name="MP"
                    onChange={handleChange}
                  >
                    {options.MP.map((x, i) => (
                      <option
                        key={i}
                        value={i}
                        selected={selectedIndex('MP') === i}
                      >
                        {formatCoverage('MP', x)}
                      </option>
                    ))}
                  </select>
                </div>
              }
            />
          )}

          {options.PIP && (
            <StackedMobile
              verticalSpacing="5px"
              left={
                <div>
                  <div className="coverage-type-title">
                    Personal Injury Protection
                  </div>
                </div>
              }
              right={
                <div>
                  <select
                    className="coverage-option"
                    name="PIP"
                    onChange={handleChange}
                  >
                    {options.PIP.map((x, i) => (
                      <option
                        key={i}
                        value={i}
                        selected={selectedIndex('PIP') === i}
                      >
                        {formatCoverage('PIP', x)}
                      </option>
                    ))}
                  </select>
                </div>
              }
            />
          )}

          {options.UMBI && (
            <StackedMobile
              verticalSpacing="5px"
              left={
                <div>
                  <div className="coverage-type-title">
                    Uninsured Bodily Injury
                  </div>
                </div>
              }
              right={
                <div>
                  <select
                    className="coverage-option"
                    name="UMBI"
                    onChange={handleChange}
                    disabled={umbiRequired()}
                  >
                    <option
                      value="ACCEPT"
                      selected={selectedIndex('UMBI') === 0}
                    >
                      Include
                    </option>
                    <option
                      value="REJECT"
                      selected={selectedIndex('UMBI') === 1}
                    >
                      Reject
                    </option>
                  </select>
                </div>
              }
            />
          )}

          <div style={{ height: '30px' }}>{` `}</div>
          {vehicles.map((vehicle, i) => (
            <div key={i}>
              <h2 className="title coverage-header">
                {Utils.getVehicleTitle(vehicle)}
              </h2>
              <StackedMobile
                verticalSpacing="5px"
                left={
                  <div>
                    <div className="coverage-type-title">Comprehensive</div>
                  </div>
                }
                right={
                  <div>
                    <select
                      className="coverage-option"
                      name={'COMP-' + vehicle['id']}
                      onChange={handleChange}
                    >
                      {options.COMP.map((x, i) => (
                        <option
                          key={i}
                          value={i}
                          selected={
                            selectedIndex('COMP-' + vehicle['id']) === i
                          }
                        >
                          {formatCoverage('COMP', x)}
                        </option>
                      ))}
                    </select>
                  </div>
                }
              />
              <StackedMobile
                verticalSpacing="5px"
                left={
                  <div>
                    <div className="coverage-type-title">Collision</div>
                  </div>
                }
                right={
                  <div>
                    <select
                      className="coverage-option"
                      name={'COLL-' + vehicle['id']}
                      onChange={handleChange}
                    >
                      {options.COLL.map((x, i) => (
                        <option
                          key={i}
                          value={i}
                          selected={
                            selectedIndex('COLL-' + vehicle['id']) === i
                          }
                        >
                          {formatCoverage('COLL', x)}
                        </option>
                      ))}
                    </select>
                    <ErrorLabel errorMessage={collErrors[i]} />
                    <div className="tip" style={{ marginTop: '10px' }}>
                      <img src={bulbIcon} alt="" />
                      <p>
                        If comprehensive is selected collision coverage is 
                        required
                      </p>
                    </div>
                  </div>
                }
              />

              {options.GLASS && (
                <StackedMobile
                  verticalSpacing="5px"
                  left={
                    <div>
                      <div className="coverage-type-title">Glass</div>
                    </div>
                  }
                  right={
                    <div>
                      <select
                        className="coverage-option"
                        name={'GLASS-' + vehicle['id']}
                        onChange={handleChange}
                      >
                        {options.GLASS.map((x, i) => (
                          <option
                            key={i}
                            value={i}
                            selected={
                              selectedIndex('GLASS-' + vehicle['id']) === i
                            }
                          >
                            {formatCoverage('GLASS', x)}
                          </option>
                        ))}
                      </select>
                      <ErrorLabel errorMessage={glassErrors[i]} />
                    </div>
                  }
                />
              )}

              {options.CDW && (
                <StackedMobile
                  verticalSpacing="5px"
                  left={
                    <div>
                      <div className="coverage-type-title">
                        Collision Deductible Waiver
                      </div>
                    </div>
                  }
                  right={
                    <div>
                      <select
                        className="coverage-option"
                        name={'CDW-' + vehicle['id']}
                        onChange={handleChange}
                      >
                        {options.CDW.map((x, i) => (
                          <option
                            key={i}
                            value={i}
                            selected={
                              selectedIndex('CDW-' + vehicle['id']) === i
                            }
                          >
                            {formatCoverage('CDW', x)}
                          </option>
                        ))}
                      </select>
                      <ErrorLabel errorMessage={cdwErrors[i]} />
                    </div>
                  }
                />
              )}
              <StackedMobile
                verticalSpacing="5px"
                left={
                  <div>
                    <div className="coverage-type-title">Getting a Rental</div>
                    <div className="coverage-type-description">AKA Rental</div>
                  </div>
                }
                right={
                  <div>
                    <select
                      className="coverage-option"
                      name={'RENT-' + vehicle['id']}
                      onChange={handleChange}
                    >
                      {options.RENT.map((x, i) => (
                        <option
                          key={i}
                          value={i}
                          selected={
                            selectedIndex('RENT-' + vehicle['id']) === i
                          }
                        >
                          {formatCoverage('RENT', x)}
                        </option>
                      ))}
                    </select>
                    <ErrorLabel errorMessage={rentErrors[i]} />
                  </div>
                }
              />

              {options.TOW && (
                <div className="callout-container">
                  <StackedMobile
                    verticalSpacing="5px"
                    left={
                      <div>
                        <div className="coverage-type-title">
                          Roadside Assistance
                          <img
                            src={infoIcon}
                            alt="roadside info icon"
                            style={{ verticalAlign: 'middle' }}
                            onMouseOver={() =>
                              handleShowCallout(vehicle['id'], true)
                            }
                            onMouseOut={() => handleShowCallout(vehicle['id'])}
                          />
                        </div>
                      </div>
                    }
                    right={
                      <select
                        className="coverage-option"
                        name={'TOW-' + vehicle['id']}
                        disabled={!hasCOLL(vehicle)}
                        onChange={handleChange}
                      >
                        {options.TOW.map((x, i) => (
                          <option
                            key={i}
                            value={i}
                            selected={selectedIndex('TOW-' + vehicle.id) === i}
                          >
                            {formatCoverage('TOW', x)}
                          </option>
                        ))}
                      </select>
                    }
                  />
                  {showCallout && activeCalloutVehicle === vehicle.id && (
                    <div className="callout top roadside">
                      <h3>Go Roadside Highlights</h3>

                      <ul>
                        <li>3 service requests per 6 month term</li>
                        <li>
                          Towing to the nearest facility or max of 10 miles.
                        </li>
                        <li>
                          A text will be sent, from our provider, to the
                          customer once the service is requested. The text will
                          confirm the details and allow for changes to be made.
                        </li>
                        <li>All requests need to be made through the app.</li>
                      </ul>
                    </div>
                  )}
                </div>
              )}

              {options.UMUIMPD && address.state !== 'TX' && (
                <StackedMobile
                  verticalSpacing="5px"
                  left={
                    <div>
                      <div className="coverage-type-title">
                        Underinsured Motorist Property Damage
                      </div>
                    </div>
                  }
                  right={
                    <div>
                      <select
                        className="coverage-option"
                        onChange={handleChange}
                        name={'UMUIMPD-' + vehicle['id']}
                        disabled={isUMPDDisabled(vehicle)}
                      >
                        <option
                          value="ACCEPT"
                          selected={
                            selectedIndex('UMUIMPD-' + vehicle['id']) === 0
                          }
                        >
                          Include
                        </option>
                        <option
                          value="REJECT"
                          selected={
                            selectedIndex('UMUIMPD-' + vehicle['id']) === 1
                          }
                        >
                          Reject
                        </option>
                      </select>
                    </div>
                  }
                />
              )}
            </div>
          ))}

          <button className="primary" type="submit" disabled={!isValid()}>
            Next
          </button>
        </form>
      </CenteredContainer>
    </div>
  )
}

export default Coverages
