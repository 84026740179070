import '../styles/FinalizeQuote.css'
import React, { useEffect, useState } from 'react'
import { CenteredContainer } from '../components/Container'
import { ErrorLabel, Label } from '../components/Label'
import LoadingIndicator from '../components/LoadingIndicator'
import BookModel from '../models/BookModel'
import Store from '../models/Store'
import flagIcon from '../assets/flag.svg'
import cardIcon from '../assets/cardback.svg'
import StateSelector from '../components/StateSelector'
import LicenseModel from '../models/LicenseModel'
import Currency from '../utils/Currency'
import FinalizeCheckoutForm from '../components/FinalizeCheckoutForm'
import Sentry from '../logger/Sentry'
import Constants from '../models/Constants'

const FinalizePolicy = (props) => {
  const userID = props.match.params.user_id
  const [isLoading, setIsLoading] = useState(true)
  const [isProcessing, setIsProcessing] = useState(false)
  const [license, setLicense] = useState(null)
  const [missingFields, setMissingFields] = useState([])
  const [primaryDLMissing, setPrimaryDLMissing] = useState(false)
  const [primaryDLState, setPrimaryDLState] = useState('')
  const [primaryDLNumber, setPrimaryDLNumber] = useState('')
  const [primaryDLError, setPrimaryDLError] = useState('')
  const [driversDLList, setDriversDLList] = useState({ list: [] })
  const [drivers, setDrivers] = useState([])
  const [invalidDriverDL, setInvalidDriverDL] = useState(null)
  const [policy, setPolicy] = useState(null)
  const [hasGET, setHasGET] = useState(false)
  const [discountGET, setDiscountGET] = useState(null)
  const [installmentAmount, setInstallmentAmount] = useState(0)
  const [hasNewRate, setHasNewRate] = useState(false)
  const [binding, setBinding] = useState(false)
  const [bindingError, setBindingError] = useState('')

  const fetchMissingFields = async () => {
    const { response, err } = await BookModel.getMissingFields(userID)
    if (err) {
      console.log('error fetching missing fields:', err)
      alert('Error fetching user details')

      Sentry.error('MissingFieldsError', err.err ? err.err : err, {
        error: err.err ? err.err : err,
      })
      return
    }

    if (response) {
      const primaryMissingField = response.missing_fields.find(
        (f) => f.is_primary_driver === true && f.missing_field === 'DLNumber'
      )

      if (primaryMissingField) {
        setPrimaryDLMissing(true)
      } else {
        setPrimaryDLMissing(false)
      }

      setMissingFields(response.missing_fields)

      if (response.drivers && response.drivers.length > 0) {
        const drivers = response.drivers
        setDrivers(drivers)

        const driversList = response.drivers.map((driver) => {
          return {
            driverID: driver.id,
            dlState: driver.dl_state ? driver.dl_state : '',
            dlNumber:
              driver.dl_number && !driver.dl_number.startsWith('F8172635')
                ? driver.dl_number
                : '',
          }
        })

        setDriversDLList({ list: driversList })
      } else {
        setDrivers([])
        setDriversDLList({ list: [] })
      }

      if (response.license) {
        setLicense(response.license)
        setPrimaryDLState(response.license.state)
        if (response.license.number !== 'F81726354') {
          setPrimaryDLNumber(response.license.number)
        }
      }

      if (response.policy) {
        const policy = response.policy
        const hasGET =
          policy.has_essential_worker_discount ||
          policy.has_essential_worker_family_discount
        setHasGET(hasGET)

        if (hasGET) {
          const discountPercentage =
            policy.config.app_config.GET_discount_percentage
          setDiscountGET(discountPercentage / 100)
        }

        setInstallmentAmount(policy.down_payment)
        setPolicy(policy)
      }

      if (
        !Store.getUser() ||
        (Store.getUser() && Store.getUser().userID !== userID)
      ) {
        const user = {
          userID: response.user_id,
          token: response.token,
        }

        Store.setUser(user)
      }

      setIsLoading(false)
    }
  }

  useEffect(() => {
    Sentry.info('FinalizeProfileLoaded', 'Loaded finalize profile page', {
      userID: userID,
    })
    fetchMissingFields()
    // eslint-disable-next-line
  }, [])

  const handleLicenseBlur = async (e) => {
    const isValid = await LicenseModel.validateLicense(
      userID,
      primaryDLNumber,
      primaryDLState
    )

    if (!isValid) {
      setPrimaryDLError('Please enter a valid license number')
    } else {
      setPrimaryDLError('')
    }
  }

  const getDriver = (driverID) => {
    return drivers.find((d) => d.id === driverID)
  }

  const getDriverDL = (driverID) => {
    return driversDLList.list.find((d) => d.driverID === driverID)
  }

  const handleDLStateChange = (e) => {
    const driverID = e.target.name
    const value = e.target.value

    setDriversDLList((prevDriversDLList) => {
      const dlIndex = prevDriversDLList.list.findIndex(
        (d) => d.driverID === driverID
      )

      let updatedList = prevDriversDLList.list
      updatedList[dlIndex].dlState = value

      return { list: updatedList }
    })
  }

  const handleDLNumberChange = (e) => {
    const driverID = e.target.dataset.id
    const value = e.target.value

    setDriversDLList((prevDriversDLList) => {
      const dlIndex = prevDriversDLList.list.findIndex(
        (d) => d.driverID === driverID
      )

      let updatedList = prevDriversDLList.list
      updatedList[dlIndex].dlNumber = value

      return { list: updatedList }
    })
  }

  const handleDriverDLBlur = async (e) => {
    const driverDL = getDriverDL(e.target.dataset.id)

    const isValid = await LicenseModel.validateLicense(
      userID,
      driverDL.dlNumber,
      driverDL.dlState
    )

    if (!isValid) {
      setInvalidDriverDL(driverDL.driverID)
    } else {
      setInvalidDriverDL(null)
    }
  }

  const driversDLValid = () => {
    const nonFilled = driversDLList.list.find(
      (d) => d.dlState.length === 0 || d.dlNumber.length === 0
    )

    if (nonFilled) {
      return false
    }

    return true
  }

  const isValid = () => {
    if (invalidDriverDL) {
      return false
    }

    if (!driversDLValid()) {
      return false
    }

    if (primaryDLMissing) {
      if (
        primaryDLState.length > 0 &&
        primaryDLNumber.length > 0 &&
        !primaryDLError
      ) {
        return true
      } else {
        return false
      }
    }

    return true
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!isValid()) {
      return
    }

    setIsProcessing(true)

    const { downPayment, err } = await BookModel.updatePolicyMissingFields({
      policyID: policy.id,
      primaryDLState,
      primaryDLNumber,
      drivers: driversDLList.list,
    })

    if (err) {
      console.log('error updating missing fields:', err)
      alert('Error updating licenses')
      setIsProcessing(false)
      Sentry.error('MissingFieldsNewRateError', err.err ? err.err : err, {
        policyID: policy.id,
        primaryDLState,
        primaryDLNumber,
        drivers: driversDLList.list,
        error: err.err ? err.err : err,
      })
      return
    }

    if (downPayment) {
      console.log('new rate:', downPayment)
      setInstallmentAmount(downPayment)
      setIsProcessing(false)
      setHasNewRate(true)
      Sentry.info('MissingFieldsNewRate', 'got new rate', { downPayment })
    }
  }

  const onCreatePayment = async (token, error) => {
    if (error) {
      console.log('stripe returned error:', error)
      setBindingError('Payment declined')
      return
    }

    if (token) {
      console.log('got token:', token)
      setBinding(true)

      const { success, err } = await BookModel.policyMissingFieldsBind({
        token,
        policyID: policy.id,
        primaryDLState,
        primaryDLNumber,
        drivers: driversDLList.list,
      })

      if (err) {
        console.log('error binding policy:', err)
        if (err.err) {
          setBindingError(err.err)
          Sentry.error('MissingFieldsBindError', err.err, {
            error: err.err,
          })
        } else {
          setBindingError(
            'Sorry, requires additional underwriting and cannot bind at this time. Please chat Go Support.'
          )

          Sentry.error('MissingFieldsBindError', err, {
            error: err,
          })
        }

        setBinding(false)
        return
      }

      if (success) {
        Sentry.info('MissingFieldsBindDone', 'finalize profile done')
        window.location = '/policy'
      }
    }
  }

  return (
    <div className="container">
      <CenteredContainer width="572">
        <h2 className="title">Finalize Profile</h2>
        {isLoading ? (
          <LoadingIndicator text="Loading..." />
        ) : (
          <p className="title-text">Please complete the information below.</p>
        )}

        {!isLoading && (
          <a
            className="download-btn"
            style={{ marginTop: '10px' }}
            href={`${Constants.apiHost()}/v3/quotes/${policy.quote_id}/pdf`}
          >
            Download Quote
          </a>
        )}

        {missingFields.length > 0 && !isLoading && (
          <div className="form-container" style={{ marginTop: '43px' }}>
            <form onSubmit={handleSubmit}>
              {/* primary DL */}
              {primaryDLMissing && (
                <>
                  <h3 className="heading">{`${license.first_name} ${license.last_name}`}</h3>
                  <div className="form-row">
                    <div className="form-column">
                      <Label icon={flagIcon} text="DRIVER’S LICENSE STATE" />
                      <StateSelector
                        excludeMichigan="true"
                        value={primaryDLState}
                        onChange={(e) => setPrimaryDLState(e.target.value)}
                      />
                    </div>
                    <div className="form-column">
                      <Label icon={cardIcon} text="DRIVER'S LICENSE" />
                      <input
                        className={primaryDLError && 'error'}
                        name="license"
                        type="text"
                        value={primaryDLNumber}
                        onChange={(e) => setPrimaryDLNumber(e.target.value)}
                        onBlur={handleLicenseBlur}
                      />
                      <ErrorLabel errorMessage={primaryDLError} />
                    </div>
                  </div>
                </>
              )}

              {/* secondary drivers */}
              {driversDLList.list.map((driverDL, i) => {
                const driver = getDriver(driverDL.driverID)

                return (
                  <div key={i}>
                    <h3 className="heading">{`${driver.first_name} ${driver.last_name}`}</h3>
                    <div className="form-row">
                      <div className="form-column">
                        <Label icon={flagIcon} text="DRIVER’S LICENSE STATE" />
                        <StateSelector
                          excludeMichigan="true"
                          value={driverDL.dlState ? driverDL.dlState : ''}
                          name={driverDL.driverID}
                          onChange={handleDLStateChange}
                        />
                      </div>
                      <div className="form-column">
                        <Label icon={cardIcon} text="DRIVER'S LICENSE" />
                        <input
                          type="text"
                          className={
                            invalidDriverDL === driverDL.driverID ? 'error' : ''
                          }
                          name={`license_${driverDL.driverID}`}
                          value={driverDL.dlNumber}
                          data-id={driverDL.driverID}
                          onChange={handleDLNumberChange}
                          onBlur={handleDriverDLBlur}
                        />
                        {invalidDriverDL === driverDL.driverID && (
                          <ErrorLabel errorMessage="Please enter a valid license" />
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}

              {policy && (
                <div className="billing-item-policy">
                  <div>
                    <p className="pay-plan-text">Monthly Premium Quote</p>
                    <p className="price-text">
                      {hasGET && (
                        <span className="strike-out-price">
                          $
                          {Currency.format(
                            installmentAmount / (1 - discountGET)
                          )}
                        </span>
                      )}{' '}
                      ${Currency.format(installmentAmount)}
                    </p>
                  </div>
                </div>
              )}

              {!hasNewRate && (
                <button className="primary" type="submit" disabled={!isValid()}>
                  {isProcessing ? (
                    <LoadingIndicator style={{ justifyContent: 'center' }} />
                  ) : (
                    'Continue'
                  )}
                </button>
              )}
            </form>
          </div>
        )}

        {hasNewRate && (
          <FinalizeCheckoutForm
            onCreatePayment={onCreatePayment}
            error={bindingError}
            enabled={isValid()}
            binding={binding}
          />
        )}
      </CenteredContainer>
    </div>
  )
}

export default FinalizePolicy
