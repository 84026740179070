import './Renewals.css'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CenteredContainer } from '../../components/Container'
import Store from '../../models/Store'
import PolicyModel from '../../models/PolicyModel'
import autoPayIcon from '../../assets/autopay.svg'
import Currency from '../../utils/Currency'
import LoadingIndicator from '../../components/LoadingIndicator'
import checkmarkIcon from '../../assets/icn-checkmark-inset.svg'
import Dates from '../../utils/Dates'

export default function RenewalApproval() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const approvedFlag = params.get('approved')
  const user = Store.getUser()

  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [approved, setApproved] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [amount, setAmount] = useState(null)
  const [chargedNow, setChargedNow] = useState(null)
  const [expirationDate, setExpirationDate] = useState(null)
  const [renewalDate, setRenewalDate] = useState(null)
  const [lastFour, setLastFour] = useState(null)
  const [approvalMode, setApprovalMode] = useState(() => {
    if (approvedFlag === 'true') return true
    if (approvedFlag === 'false') return false

    return true
  })
  const [showApprovalConfirmation, setShowApprovalConfirmation] =
    useState(false)
  const [showDenyConfirmation, setShowDenyConfirmation] = useState(false)

  const fetchRenewal = async () => {
    setIsLoading(true)
    const [data, err] = await PolicyModel.unauthedConfirmRenewal()
    if (err) {
      console.log('error getting user:', err)
      return
    }
    setIsLoading(false)
    setAmount(data.amount)
    setChargedNow(data.charged_now)
    setExpirationDate(data.expiration_date)
    setLastFour(data.last_4)

    const rd = new Date(data.expiration_date)
    rd.setDate(rd.getDate() + 1)
    setRenewalDate(rd)

    if (data.approved !== null) {
      setApproved(data.approved)
      setShowConfirmation(true)
    }
  }

  useEffect(() => {
    fetchRenewal()
  }, [])

  const confirmRenewal = async (policyID, approved) => {
    setIsSubmitting(true)
    setApproved(approved)
    const err = await PolicyModel.updateRenewal(policyID, approved)
    if (err) {
      alert(`Sorry, we ran into an unexpected issue, please try again later.`)
      setIsSubmitting(false)
      return
    }
    setShowDenyConfirmation(false)
    setShowApprovalConfirmation(false)
    setIsSubmitting(false)
    setShowConfirmation(true)
  }

  return (
    <div className="container">
      <h2 className="title">Renewal</h2>
      <CenteredContainer className="renewal-approval-container">
        {isLoading && (
          <LoadingIndicator style={{ marginTop: '10vh' }} text="Loading..." />
        )}

        {showConfirmation && !isLoading && (
          <div className="show-confirmation-container">
            <div className="renewal-confirm-icon-wrapper">
              <img src={checkmarkIcon} alt="checkmark icon" />
            </div>
            {!approved && (
              <>
                <h3 className="title">Policy Canceled</h3>
                <p style={{ textAlign: 'center' }}>
                  You have denied renewal and your policy will end on{' '}
                  {Dates.toLongDateString(expirationDate)}.
                </p>
              </>
            )}
            {approved && (
              <>
                <h3 className="title">Renewal Confirmation</h3>
                <p style={{ textAlign: 'center' }}>
                  You have renewed your policy for 6 months for $
                  {Currency.format(amount)}. Renewal Date{' '}
                  {Dates.toLongDateString(renewalDate)}.
                </p>
              </>
            )}
            <button
              className="primary"
              style={{ fontWeight: '500', marginTop: '35px' }}
              onClick={() => {
                window.location = `/policy`
              }}
            >
              View Policy
            </button>
          </div>
        )}

        {!isLoading && !showConfirmation && (
          <>
            <div className="renewal-icon-wrapper">
              <img src={autoPayIcon} alt="auto pay icon" />
            </div>
            {approvalMode && (
              <>
                <p>
                  You are renewing your policy with payment in full for $
                  {Currency.format(amount)}. You will automatically be charged
                  with your card ending in {lastFour} on{' '}
                  {Dates.toLongDateString(expirationDate)}
                </p>
                {!showConfirmation && (
                  <div style={{ marginTop: '51px' }}>
                    <button
                      className="primary"
                      disabled={isSubmitting}
                      onClick={() => setShowApprovalConfirmation(true)}
                    >
                      Continue with Approval
                    </button>
                    <button
                      className="no-back"
                      disabled={isSubmitting}
                      onClick={() => setApprovalMode(false)}
                    >
                      Deny Renewal
                    </button>
                  </div>
                )}
              </>
            )}

            {!approvalMode && (
              <>
                <p>
                  You are canceling your policy. Your coverage will end on{' '}
                  {Dates.toLongDateString(expirationDate)}
                </p>
                {!showConfirmation && (
                  <div style={{ marginTop: '27px' }}>
                    <button
                      className="primary"
                      disabled={isSubmitting}
                      onClick={() => setShowDenyConfirmation(true)}
                    >
                      {isSubmitting && approved
                        ? 'Confirming...'
                        : 'Cancel Policy'}
                    </button>
                    <button
                      className="no-back"
                      disabled={isSubmitting}
                      onClick={() => setApprovalMode(true)}
                    >
                      Renew Policy
                    </button>
                  </div>
                )}
              </>
            )}
          </>
        )}
        {showApprovalConfirmation && (
          <div className="modal">
            <div className="modal-box">
              <h3 className="modal-title">Renewal</h3>

              <p className="modal-text" style={{ marginLeft: '6px' }}>
                You have selected Pay in Full and will be charged $
                {Currency.format(amount)}&nbsp;
                {chargedNow && <span className="modal-text">today</span>}
                {!chargedNow && (
                  <span className="modal-text">
                    on {Dates.toLongDateString(expirationDate)}
                  </span>
                )}
                .
              </p>

              <button
                className="cancel"
                style={{ fontWeight: '500', marginBottom: '12px' }}
                disabled={isSubmitting}
                onClick={() => setShowApprovalConfirmation(false)}
              >
                Cancel
              </button>
              <button
                className="primary"
                style={{ fontWeight: '500' }}
                disabled={isSubmitting}
                onClick={() => confirmRenewal(user.policyID, true)}
              >
                {isSubmitting ? 'Confirming...' : 'Confirm'}
              </button>
            </div>
          </div>
        )}
        {showDenyConfirmation && (
          <div className="modal">
            <div className="modal-box">
              <h3 className="modal-title">Renewal</h3>

              <p className="modal-text" style={{ marginLeft: '6px' }}>
                You have selected to deny renewal and your policy will end on{' '}
                {Dates.toLongDateString(expirationDate)}.
              </p>

              <button
                className="cancel"
                disabled={isSubmitting}
                style={{ fontWeight: '500', marginBottom: '12px' }}
                onClick={() => setShowDenyConfirmation(false)}
              >
                Cancel
              </button>
              <button
                className="primary"
                disabled={isSubmitting}
                style={{ fontWeight: '500' }}
                onClick={() => confirmRenewal(user.policyID, false)}
              >
                {isSubmitting ? 'Confirming...' : 'Confirm'}
              </button>
            </div>
          </div>
        )}
      </CenteredContainer>
    </div>
  )
}
