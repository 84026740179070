import { useLocation } from 'react-router-dom'
import { CenteredContainer } from '../../components/Container'

export default function VerifyBank() {
  const location = useLocation()

  const bank = location.state?.bank
  console.log({ bank })

  return (
    <div className="container">
      <CenteredContainer>Verify bank</CenteredContainer>
    </div>
  )
}
