import { Link } from 'react-router-dom'
import { CenteredContainer } from '../../components/Container'
import backIcon from '../../assets/icn-back-nav.svg'
import embarkIcon from '../../assets/embark.svg'

export default function Embark() {

  return (
    <div className="container">
      <div className="back-nav">
        <Link to="/claims/list">
          <img src={backIcon} alt="back button" /> All Claims
        </Link>
      </div>

      <h2 className="title">Claims</h2>

      <CenteredContainer>
        <div className="embark">
          <img src={embarkIcon}/>
          <h2>Please contact Embark</h2>
          <button
            className="primary"
            type="submit"
            onClick={() => window.open('tel:8333622751')}
          >
            Call (833) 362-2751
          </button>
          <p>You may also contact Embark General via the following:</p>
          <p>Embark General<br/>PO Box 500040<br/>Sandy Springs, GA 31150</p>
          <p>Tel: <a href="tel:7702343600"><span>(770) 234-3600</span></a><br/>Fax: <span>(470) 394-1941</span><br/>Web: <a href="https://www.embarkgeneral.com"><span>www.embarkgeneral.com</span></a></p>
        </div>
      </CenteredContainer>
    </div>
  )
}
