import '../../styles/Claims.css'
import { useEffect, useState } from 'react'
import {
  CenteredContainer,
  ColumnContainer,
  ColumnLeft,
  ColumnRight,
} from '../../components/Container'
import ClaimModel from '../../models/ClaimModel'
import goCloudIcon from '../../assets/icn-go-cloud.svg'
import claimIcon from '../../assets/icn-agreement.svg'
import timeIcon from '../../assets/icn-time.svg'
import driverIcon from '../../assets/driver.svg'
import carIcon from '../../assets/cars.svg'
import alertIcon from '../../assets/icn-alert-grey.svg'
import locationIcon from '../../assets/icn-lienholder-address.svg'
import descriptionIcon from '../../assets/icn-agreement.svg'
import injuryIcon from '../../assets/icn-injury.svg'
import photosIcon from '../../assets/icn-photo.svg'
import backIcon from '../../assets/icn-back-nav.svg'
import { Link, useHistory } from 'react-router-dom'
import ContactCard from '../../components/ContactCard'

export default function ClaimDetails(props) {
  const history = useHistory()
  const claimID = props.match.params.claim_id

  const claimStates = ['Submitted', 'Adjusting', 'Settled', 'Closed']

  const [loading, setLoading] = useState(true)
  const [claim, setClaim] = useState(null)
  const [claimStateIndex, setClaimStateIndex] = useState(0)
  const [claimNumber, setClaimNumber] = useState('')
  const [documentCount, setDocumentCount] = useState(0)
  const [incidentTime, setIncidentTime] = useState('')
  const [goContact, setGoContact] = useState(null)
  const [photoURLs, setPhotoURLs] = useState([])
  const [hasVideoURL, setHasVideoURL] = useState(false)
  const [isSafelite, setIsSafelite] = useState(false)

  const fetchClaim = async () => {
    const { claim, err } = await ClaimModel.getClaimByID(claimID)
    if (err) {
      alert('Error fetching claims')
      return
    }

    if (claim) {
      setClaim(claim)

      const stateIndex = claimStates.findIndex(
        (state) => state === claim.claim_state
      )
      if (stateIndex) {
        setClaimStateIndex(stateIndex)
      }

      if (claim.claim_number) {
        setClaimNumber(claim.claim_number)
      } else {
        setClaimNumber(' N/A')
      }

      setDocumentCount(claim.number_of_documents || 0)

      if (claim.incident_time) {
        const t = new Date(claim.incident_time)

        const dateOption = {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        }
        const timeOption = {
          hour12: true,
          hour: 'numeric',
          minute: 'numeric',
        }
        const dateFormatter = new Intl.DateTimeFormat('en-US', dateOption)
        const timeFormatter = new Intl.DateTimeFormat('en-US', timeOption)

        setIncidentTime(`${dateFormatter.format(t)} ${timeFormatter.format(t)}`)
      }

      if (claim.go_contact) {
        setGoContact(claim.go_contact)
      }

      if (claim.is_glass && claim.safelite) {
        setGoContact(claim.safelite.contact)
        setIsSafelite(true)
      }

      if (claim.photo_urls) {
        setPhotoURLs(claim.photo_urls)
        const photoURL = claim.photo_urls.find(
          (u) => u.includes('.mp4') || u.includes('.mov' || u.includes('.3gpp'))
        )
        if (photoURL) {
          setHasVideoURL(true)
        }
      }

      setLoading(false)
    }
  }

  const handleOpenVideo = (videoLink) => {
    window.open(videoLink, '_blank')
  }

  useEffect(() => {
    fetchClaim()
    // eslint-disable-next-line
  }, [])

  if (!claimID || claimID.length === 0) {
    return null
  }

  return (
    <div className="container">
      <div className="back-nav">
        <Link to="/claims/list">
          <img src={backIcon} alt="back button" /> All Claims
        </Link>
      </div>
      <h2 className="title">Claim #{loading ? ' Loading...' : claimNumber}</h2>
      {!loading && (
        <>
          <CenteredContainer>
            <div className="claim-progress-bar">
              <ul className="claim-progress-states">
                {claimStates.map((claimState, i) => (
                  <li key={i} className={i <= claimStateIndex ? 'active' : ''}>
                    {i !== 0 && <div className="progress-line"></div>}
                    <div className="state">
                      <span>{claimState}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </CenteredContainer>

          <ColumnContainer>
            <ColumnLeft className="claim-column-left">
              {goContact && (
                <>
                  <h4
                    className="heading"
                    style={{ margin: '0', marginBottom: '10px' }}
                  >
                    {isSafelite ? 'Safelite Contact' : 'Go Contact'}
                  </h4>
                  <ContactCard contact={goContact} />
                </>
              )}
            </ColumnLeft>

            <ColumnRight className="claim-column-right">
              <h3 className="heading">Claims Summary</h3>

              <ul className="claim-summary-list">
                <li
                  className="with-nav"
                  onClick={() =>
                    history.push(`/claims/gocloud/${claimID}`, { claim })
                  }
                >
                  <img className="icon" src={goCloudIcon} alt="" />
                  <div className="list-content">
                    <p className="item">GO CLOUD</p>
                    <p>
                      {documentCount === 1
                        ? `${documentCount} file`
                        : `${documentCount} files`}
                    </p>
                  </div>
                  <div className="nav-arrow"></div>
                </li>

                <li>
                  <img className="icon" src={claimIcon} alt="" />
                  <div>
                    <p className="item">CLAIM</p>
                    <p>#{claimNumber}</p>
                  </div>
                </li>

                {isSafelite && (
                  <li>
                    <img className="icon" src={claimIcon} alt="" />
                    <div>
                      <p className="item">WORK ORDER</p>
                      <p>#{claim.safelite_work_order_number}</p>
                    </div>
                  </li>
                )}

                <li>
                  <img className="icon" src={timeIcon} alt="" />
                  <div>
                    <p className="item">TIME</p>
                    <p>{incidentTime}</p>
                  </div>
                </li>
                {claim.driver && (
                  <li>
                    <img className="icon" src={driverIcon} alt="" />
                    <div>
                      <p className="item">DRIVER</p>
                      <p>
                        {`${claim.driver.first_name} ${claim.driver.last_name}`}
                      </p>
                    </div>
                  </li>
                )}

                <li>
                  <img className="icon" src={carIcon} alt="" />
                  <div>
                    <p className="item">CAR</p>
                    <p>
                      {`${claim.vehicle.year} ${claim.vehicle.make} ${claim.vehicle.model}`}
                    </p>
                  </div>
                </li>

                <li>
                  <img className="icon" src={alertIcon} alt="" />
                  <div>
                    <p className="item">INCIDENT TYPE</p>
                    <p>{claim.incident_reason || 'Other'}</p>
                  </div>
                </li>

                <li>
                  <img className="icon" src={locationIcon} alt="" />
                  <div>
                    <p className="item">LOCATION</p>
                    <p>{`${claim.street}, ${claim.city}, ${claim.state} ${
                      claim.zipcode || ''
                    }`}</p>
                  </div>
                </li>

                <li>
                  <img className="icon" src={descriptionIcon} alt="" />
                  <div>
                    <p className="item">DESCRIPTION</p>
                    <p>{claim.description || 'N/A'}</p>
                    {/* <p>adadsasd</p> */}
                  </div>
                </li>

                {claim.was_anyone_injured && (
                  <li>
                    <img className="icon" src={injuryIcon} alt="" />
                    <div>
                      <p className="item">INJURY</p>
                      <p>{claim.was_anyone_injured}</p>
                    </div>
                  </li>
                )}
              </ul>

              <div className="claims-photos">
                <div className="row">
                  <img className="icon" src={photosIcon} alt="" />
                  <p className="item">{hasVideoURL ? 'VIDEOS' : 'PHOTOS'}</p>
                </div>

                {photoURLs.length > 0 ? (
                  <ul className="row photos-list">
                    {photoURLs.map((video, i) => (
                      <video
                        key={i}
                        autoPlay={false}
                        onClick={() => handleOpenVideo(video)}
                      >
                        {/* #t=0.01 hack to show thumbnail on iOS Safari */}
                        <source src={video + `#t=0.01`} />
                      </video>
                    ))}
                  </ul>
                ) : (
                  <p className="no-photos">N/A</p>
                )}
              </div>
            </ColumnRight>
          </ColumnContainer>
        </>
      )}
    </div>
  )
}
