import Store from '../models/Store'
import Strings from './Strings'

let Utils = {
  getUserID: function() {
    let pathComps = window.location.pathname.split('/')
    return pathComps.pop() || pathComps.pop()    
  },
  
  isLeadsPage: function() {
    return window.location.pathname ==='/' || window.location.pathname.includes('/leads')
  },

  isLoginPage: function() {
    return window.location.pathname === '/login'
  },
  
  getAgentInitials: function() {
    const agent = Store.getAgent()
    if (!agent) {
      return ''
    }
    return `${agent.firstName[0]}${agent.lastName[0]}`
  },

  getAgentName: function() {
    const agent = Store.getAgent()
    if (!agent) {
      return ''
    }
    return Strings.titleCase(`${agent.firstName} ${agent.lastName}`)
  },

  getAgentEmail: function() {
    const agent = Store.getAgent()
    if (!agent) {
      return ''
    }
    return agent.email.toLowerCase()
  },
  
  getVehicleTitle: function(vehicle) {
    return Strings.titleCase(`${vehicle.year} ${vehicle.make} ${vehicle.model}`)
  },
  
  getFullName: function(user, defaultName = '') {
    if (!user) {
      return defaultName
    }
    return Strings.titleCase(`${user.first_name} ${user.last_name}`)
  },
  
  sendGTMEvent: function(eventName, user, license) {
    if (!eventName || !user || !license) {
      return
    }
    var event = {
      'event': eventName,
      'em': user.email ? user.email : '',
      'fn': license.first_name ? license.first_name.toLowerCase() : '',
      'ln': license.last_name ? license.last_name.toLowerCase() : '',
      'ph': user.phone_number ? '1' + user.phone_number : '',
      'external_id': user.id ? user.id : '',
      'ge': license.gender ? (license.gender === 'male' ? 'm' : license.gender === 'female' ? 'f' : '') : '',
      'db': license.birth_date ? license.birth_date.replace(/-/g, '') : '',
      'ct': license.city ? license.city.replace(/\s+/g, '').toLowerCase() : '',
      'st': license.state ? license.state.toLowerCase() : '',
      'zp': license.zipcode ? license.zipcode : '',
      'cn': 'us'
    }
    window.sendGTMEvent(event)
  },
  
  getStateFullName: function(abbrev) {
    const statesMap = {
      "AZ":"Arizona", "AL":"Alabama", "AK":"Alaska", "AR":"Arkansas",
      "CA":"California", "CO":"Colorado", "CT":"Connecticut", 
      "DC":"District of Columbia", "DE":"Delaware",
      "FL":"Florida",
      "GA":"Georgia",
      "HI":"Hawaii",
      "ID":"Idaho","IL":"Illinois","IN":"Indiana","IA":"Iowa",
      "KS":"Kansas","KY":"Kentucky",
      "LA":"Louisiana",
      "ME":"Maine","MD":"Maryland","MA":"Massachusetts","MI":"Michigan","MN":"Minnesota","MS":"Mississippi","MO":"Missouri","MT":"Montana",
      "NE":"Nebraska","NV":"Nevada","NH":"New Hampshire","NJ":"New Jersey","NM":"New Mexico","NY":"New York","NC":"North Carolina","ND":"North Dakota",
      "OH":"Ohio","OK":"Oklahoma","OR":"Oregon",
      "PA":"Pennsylvania",
      "RI":"Rhode Island",
      "SC":"South Carolina","SD":"South Dakota","TN":"Tennessee","TX":"Texas",
      "UT":"Utah","VT":"Vermont","VA":"Virginia",
      "WA":"Washington","WV":"West Virginia","WI":"Wisconsin","WY":"Wyoming",
      
      "AS":"American Samoa", "GU":"Guam", "MP":"Northern Mariana Islands", "PR": "Puerto Rico", "VI": "U.S. Virgin Islands", "UM": "U.S. Minor Outlying Islands"
    }
    if (statesMap[abbrev] === undefined) {
      return ''
    }
    return statesMap[abbrev]
  }
}

export default Utils