import logoChase from '../../assets/bank-logos/bank-chase@3x.png'
import logoBofA from '../../assets/bank-logos/bank-bankofamerica@3x.png'
import logoCiti from '../../assets/bank-logos/bank-citi@3x.png'
import logoWellsFargo from '../../assets/bank-logos/bank-wellsfargo@3x.png'
import logoPNC from '../../assets/bank-logos/bank-pnc@3x.png'
import logoUSBank from '../../assets/bank-logos/bank-usbank@3x.png'
import logoTruist from '../../assets/bank-logos/bank-truist@3x.png'
import logoTDBank from '../../assets/bank-logos/bank-tdbank@3x.png'
import logoCapitalOne from '../../assets/bank-logos/bank-capitalone@3x.png'
import logoHSBC from '../../assets/bank-logos/bank-hsbc@3x.png'
import logoUSAA from '../../assets/bank-logos/bank-usaa@3x.png'
import logoAlly from '../../assets/bank-logos/bank-ally@3x.png'
import logoChime from '../../assets/bank-logos/bank-chime@3x.png'
import logoUnionBank from '../../assets/bank-logos/bank-unionbank@3x.png'
import bankNoLogo from '../../assets/bank-logos/bank-no-logo@3x.png'

export const BanksList = [
  {
    name: 'Chase',
    url: 'www.chase.com',
    logo: logoChase,
  },
  {
    name: 'Bank of America',
    url: 'www.bankofamerica.com',
    logo: logoBofA,
  },
  {
    name: 'Citibank',
    url: 'www.citi.com',
    logo: logoCiti,
  },
  {
    name: 'Wells Fargo',
    url: 'www.wellsfargo.com',
    logo: logoWellsFargo,
  },
  {
    name: 'PNC Bank',
    url: 'www.pnc.com',
    logo: logoPNC,
  },
  {
    name: 'US Bank',
    url: 'www.usbank.com',
    logo: logoUSBank,
  },
  {
    name: 'Truist',
    url: 'www.truist.com',
    logo: logoTruist,
  },
  {
    name: 'TD Bank',
    url: 'www.td.com',
    logo: logoTDBank,
  },
  {
    name: 'Capital One',
    url: 'www.capitalone.com',
    logo: logoCapitalOne,
  },
  {
    name: 'HSBC',
    url: 'www.us.hsbc.com',
    logo: logoHSBC,
  },
  {
    name: 'USAA',
    url: 'www.usaa.com',
    logo: logoUSAA,
  },
  {
    name: 'Ally',
    url: 'www.ally.com',
    logo: logoAlly,
  },
  {
    name: 'Chime',
    url: 'www.chime.com',
    logo: logoChime,
  },
  {
    name: 'Union Bank',
    url: 'www.unionbank.com',
    logo: logoUnionBank,
  },
  {
    name: 'Other',
    url: '',
    logo: bankNoLogo,
  },
]
