import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import backIcon from '../assets/icn-back-nav.svg'

function BackButton({ text, to }) {
  return (
    <div className="back-nav">
      <Link to={to}>
        <img src={backIcon} alt="back button" /> {text}
      </Link>
    </div>
  )
}

BackButton.propTypes = {
  /** Description of prop "text". */
  text: PropTypes.string,
  /** Description of prop "to". */
  to: PropTypes.string.isRequired,
}

export default BackButton
