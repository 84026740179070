class Constants {
  constructor() {
    this.production = 'production'
    this.development = 'development'
    this.prime_development = 'prime-development'
    this.local = 'local'
  }

  environment = () => {
    switch (window.origin) {
      case 'https://join.gocarinsurance.com':
        return this.production
      case 'https://join-dev.gocarinsurance.com':
        return this.development
      case 'https://join-prime-dev.gocarinsurance.com':
        return this.prime_development
      case 'http://andy.local:3000':
      case 'http://jinsu.local:3000':
      case 'https://jinsu.local:3000':
        return this.local
      default:
        return this.development
    }
  }

  apiHost = () => {
    switch (this.environment()) {
      case this.production:
        return 'https://api.meetgo.me'
      case this.development:
        return 'https://development-api.meetgo.me'
      case this.prime_development:
        return 'https://prime-development-api.meetgo.me'
      default:
        if (
          window.origin === 'http://jinsu.local:3000' &&
          this.environment() === 'local'
        ) {
          return 'http://jinsu.local:8080'
        }
        if (
          window.origin === 'http://andy.local:3000' &&
          this.environment() === 'local'
        ) {
          return 'http://andy.local:8080'
        }
        return 'http://localhost:8080'
    }
  }

  isProduction = () => {
    return this.environment() === this.production
  }

  isPrimeDev = () => {
    return this.environment() === this.prime_development
  }

  isLocal = () => {
    return this.environment() === this.local
  }

  genericNetworkError = () => {
    return 'There were errors retrieving the data. Please try again'
  }

  stripeKey = () => {
    if (this.environment() === this.production) {
      return 'pk_live_Ykf75GtQEtNFMxuayV1ql8x9'
    }

    return 'pk_test_To00Vyiic8L3UpQfr76syhvY'
  }
}

export default new Constants()
