import './styles/index.css'
import { createRoot } from 'react-dom/client'
import App from './App'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/react'
import Constants from './models/Constants'

Sentry.init({
  dsn: 'https://fcba2d2f6ddd498280847521774451f7@o237750.ingest.sentry.io/5474641',
  environment: Constants.environment(),
})

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
