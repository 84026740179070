import API from './API'
import Constants from './Constants'
import Store from './Store'

class ClaimsPayModel {
  requestCodeToVerify = async (claimNumber, claimsPaymentID) => {
    const response = await API()
      .post('/v3/claim_payments/verify/request_code', {
        claim_number: claimNumber,
        claims_payment_id: claimsPaymentID,
      })
      .then((res) => {
        console.log('API res[requestCodeToVerify]:', res)
        if (res.status === 200) {
          return {
            phoneNumber: res.data.phone_number,
            err: null,
          }
        }

        return {
          phoneNumber: null,
          err: Constants.genericNetworkError(),
        }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API error[requestCodeToVerify]:', error)
          return {
            phoneNumber: null,
            err: error,
          }
        }

        console.log('API error[requestCodeToVerify]:', error.response.data)
        return {
          phoneNumber: null,
          err: error.response.data,
        }
      })

    return response
  }

  verify = async (claimNumber, claimsPaymentID, verificationCode) => {
    const response = await API()
      .post('/v3/claim_payments/verify', {
        claim_number: claimNumber,
        claims_payment_id: claimsPaymentID,
        verification_code: verificationCode,
      })
      .then((res) => {
        console.log('API res[verify]:', res)
        if (res.status === 200) {
          const user = {
            userID: res.data.user_id,
            policyID: res.data.policy_id,
            token: res.data.token,
            isVendor: res.data.is_vendor,
            isThirdParty: res.data.is_third_party,
            claimsPaymentID: res.data.claims_payment_id || null,
          }

          Store.setUser(user)

          return {
            user: user,
            err: null,
          }
        }

        return { user: null, err: Constants.genericNetworkError() }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[verify]:', error)
          return { user: null, err: error }
        }

        console.log('API err[verify]:', error.response.data)
        return { user: null, err: error.response.data }
      })

    return response
  }

  getPendingPayments = async (policyID, claimsPaymentID = null) => {
    let url = `/v3/claim_payments/${policyID}/pending`
    if (claimsPaymentID) {
      url = `/v3/claim_payments/${policyID}/pending?claims_payment_id=${claimsPaymentID}`
    }

    const response = await API()
      .get(url)
      .then((res) => {
        console.log('API res[getPendingPayments]:', res)
        if (res.status === 200) {
          return { claimsPayment: res.data, err: null }
        }

        return { claimsPayment: null, err: Constants.genericNetworkError() }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[getPendingPayments]:', error)
          return { claimsPayment: null, err: error }
        }

        console.log('API err[getPendingPayments]:', error.response.data)
        return { claimsPayment: null, err: error.response.data }
      })

    return response
  }

  createVendorEIN = async (claimsPaymentID, ein) => {
    const err = await API()
      .post(`/v3/claim_payments/${claimsPaymentID}/ein`, {
        ein,
      })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          return null
        }

        return Constants.genericNetworkError()
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[createVendorEIN]:', error)
          return error
        }

        console.log('API err[createVendorEIN]:', error.response.data)
        return error.response.data
      })

    return err
  }

  createBankAccount = async (claimsPaymentID, routing, account) => {
    const err = await API()
      .post(`/v3/claim_payments/${claimsPaymentID}/bank_details`, {
        routing,
        account,
      })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          return null
        }

        return Constants.genericNetworkError()
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[createBankAccount]:', error)
          return error
        }

        console.log('API err[createBankAccount]:', error.response.data)
        return error.response.data
      })

    return err
  }

  uploadImages = async (claimsPaymentID, front, back) => {
    const err = await API()
      .post(`/v3/claim_payments/${claimsPaymentID}/license_photos`, {
        front_photo: front,
        back_photo: back,
      })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          return null
        }

        return Constants.genericNetworkError()
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[uploadImages]:', error)
          return error
        }

        console.log('API err[uploadImages]:', error.response.data)
        return error.response.data
      })

    return err
  }

  getPaymentSchedules = async (claimsPaymentID) => {
    const response = await API()
      .get(`/v3/claim_payments/${claimsPaymentID}/schedule_payments`)
      .then((res) => {
        console.log('API res[getPaymentSchedules]:', res)
        if (res.status === 200) {
          return { paymentSchedules: res.data, err: null }
        }

        return { paymentSchedules: null, err: Constants.genericNetworkError() }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[getPaymentSchedules]:', error)
          return { paymentSchedules: null, err: error }
        }

        console.log('API err[getPaymentSchedules]:', error.response.data)
        return { paymentSchedules: null, err: error.response.data }
      })

    return response
  }

  postPaymentDate = async (claimsPaymentID, date) => {
    const response = await API()
      .post(`/v3/claim_payments/${claimsPaymentID}/schedule_payments`, {
        date,
      })
      .then((res) => {
        console.log('API res[postPaymentDate]:', res)
        if (res.status === 200) {
          return { paymentSchedules: res.data, err: null }
        }

        return { paymentSchedules: null, err: Constants.genericNetworkError() }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[postPaymentDate]:', error)
          return { paymentSchedules: null, err: error }
        }

        console.log('API err[postPaymentDate]:', error.response.data)
        return { paymentSchedules: null, err: error.response.data }
      })

    return response
  }
}

export default new ClaimsPayModel()
