import API from './API'
import Store from './Store'

class LicenseModel {
  createLicense = async (userID, license, address) => {
    const [newUserID, error] = await API()
      .post('/v1/licenses', {
        license: license,
        address: address,
      })
      .then((res) => {
        console.log('API res:', res)
        if (res.status === 200) {
          if (userID !== res.data.user.id) {
            const user = {
              userID: res.data.user.id,
              token: res.data.token,
            }
            Store.setUser(user)
          }

          return [res.data.user.id, null]
        }

        return { err: 'something went wrong' }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err:', error)
          return [null, error]
        }

        console.log('API err:', error.response.data)
        return [null, error.response.data]
      })

    return [newUserID, error]
  }

  validateLicense = async (userID, number, state) => {
    const isValid = await API()
      .post('/v1/validation/license', {
        number,
        state,
      })
      .then((res) => {
        console.log('API res:', res)
        if (res.status === 200) {
          if (res.data.valid) {
            return true
          }

          return false
        }

        return false
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err:', error)
          return false
        }

        console.log('API err:', error.response.data)
        return false
      })

    return isValid
  }
}

export default new LicenseModel()
