import API from './API'
import Constants from '../models/Constants'
import Store from './Store'

class PolicyModel {
  /**
   * Requests verification SMS code for auth
   *
   * @param {string} policyNumber Policy Number
   * @param {boolean} useExactNumber if not set, latest in force policy will be used
   * @returns {{}} { phoneNumber, err }
   */
  requestCode = async (policyNumber, useExactNumber = false) => {
    const { phoneNumber, err } = await API()
      .post('/v3/policies/request_code', {
        policy_number: policyNumber,
        use_exact_number: useExactNumber,
      })
      .then((res) => {
        console.log('API res[requestCode]:', res)
        if (res.status === 200) {
          return {
            phoneNumber: res.data.phone_number,
            err: null,
          }
        }

        return {
          phoneNumber: null,
          err: Constants.genericNetworkError(),
        }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API error[requestCode]:', error)
          return {
            phoneNumber: null,
            err: error,
          }
        }

        console.log('API error[requestCode]:', error.response.data)
        return {
          phoneNumber: null,
          err: error.response.data,
        }
      })

    return { phoneNumber, err }
  }

  /**
   * Requests verification SMS code for auth with policy id
   *
   * @param {string} policyID Policy UUID
   * @returns {{}} { phoneNumber, err }
   */
  requestCodeByID = async (policyID) => {
    const { phoneNumber, err } = await API()
      .post('/v3/policies/request_code', { policy_id: policyID })
      .then((res) => {
        console.log('API res[requestCodeByID]:', res)
        if (res.status === 200) {
          return {
            phoneNumber: res.data.phone_number,
            err: null,
          }
        }

        return {
          phoneNumber: null,
          err: Constants.genericNetworkError(),
        }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API error[requestCodeByID]:', error)
          return {
            phoneNumber: null,
            err: error,
          }
        }

        console.log('API error[requestCodeByID]:', error.response.data)
        return {
          phoneNumber: null,
          err: error.response.data,
        }
      })

    return { phoneNumber, err }
  }

  /**
   * Login from verification SMS code received from requestCode method.
   * Sets user auth object to local storage
   *
   * @param {string} verificationCode Verification code as string
   * @param {string} policyNumber Policy number
   * @param {boolean} useExactNumber if not set, latest in force policy will be used
   * @returns {{}} { user, err }
   */
  login = async (verificationCode, policyNumber, useExactNumber = false) => {
    const { user, err } = await API()
      .post('/v3/policies/login', {
        verification_code: verificationCode,
        policy_number: policyNumber,
        use_exact_number: useExactNumber,
      })
      .then((res) => {
        console.log('API res[login]:', res)
        if (res.status === 200) {
          const user = {
            userID: res.data.user_id,
            policyID: res.data.policy_id,
            token: res.data.token,
          }

          Store.setUser(user)

          return { user, err: null }
        }

        return { user: null, err: Constants.genericNetworkError }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API error[login]:', error)
          return { user: null, err: error }
        }

        console.log('API error[login]:', error.response.data)
        return { user: null, err: error.response.data }
      })

    return { user, err }
  }

  /**
   * Login from verification SMS code received from requestCodeByID method.
   * Sets user auth object to local storage
   *
   * @param {string} verificationCode Verification code as string
   * @param {string} policyID Policy UUID
   * @returns {{}} { user, err }
   */
  loginByID = async (policyID, verificationCode) => {
    const { user, err } = await API()
      .post('/v3/policies/login', {
        policy_id: policyID,
        verification_code: verificationCode,
      })
      .then((res) => {
        console.log('API res[loginByID]:', res)
        if (res.status === 200) {
          const user = {
            userID: res.data.user_id,
            policyID: res.data.policy_id,
            token: res.data.token,
          }

          Store.setUser(user)

          return { user, err: null }
        }

        return { user: null, err: Constants.genericNetworkError }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API error[loginByID]:', error)
          return { user: null, err: error }
        }

        console.log('API error[loginByID]:', error.response.data)
        return { user: null, err: error.response.data }
      })

    return { user, err }
  }

  createPolicy = async (
    userID,
    email,
    phoneNumber,
    quoteID,
    paymentToken,
    isPaymentAuth = false,
    isPayInFull = false,
    payLater = false
  ) => {
    const [policy, err] = await API()
      .post('/v1/user/policy', {
        email: email,
        phone_number: phoneNumber,
        quote_id: quoteID,
        payment_token: paymentToken,
        payment_auth: isPaymentAuth,
        pay_plan: isPayInFull ? 'pay_in_full' : 'monthly',
        pay_later: payLater,
      })
      .then((res) => {
        console.log('API res:', res)
        if (res.status === 200 || res.status === 201) {
          return [res.data, null]
        }

        return [null, { err: Constants.genericNetworkError() }]
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err:', error)
          return [null, error]
        }

        console.log('API err:', error.response.data)
        return [null, error.response.data]
      })

    return [policy, err]
  }

  renewalInfo = async () => {
    const [data, err] = await API()
      .get(`/v3/policies/renewal_info`)
      .then((res) => {
        console.log('API res[updateRenewal]:', res)
        if (res.status === 200) {
          return [res.data, null]
        }

        return Constants.genericNetworkError()
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API error[updateRenewal]:', error)
          return [null, error]
        }

        console.log('API error[updateRenewal]:', error.response.data)
        return [null, error.response.data]
      })

    return [data, err]
  }

  updateRenewal = async (policyID, approved = true) => {
    const err = await API()
      .put(`/v3/policies/${policyID}/renewals`, {
        approved,
      })
      .then((res) => {
        console.log('API res[updateRenewal]:', res)
        if (res.status === 200) {
          return null
        }

        return Constants.genericNetworkError()
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API error[updateRenewal]:', error)
          return error
        }

        console.log('API error[updateRenewal]:', error.response.data)
        return error.response.data
      })

    return err
  }

  confirmRenewal = async (userID, policyID, approved) => {
    const err = await API({ headers: { 'Go-User-ID': userID } })
      .post(`/v3/user_renewal_selections`, {
        current_policy_id: policyID,
        renewal_approved: approved,
      })
      .then((res) => {
        console.log('API res[confirmRenewal]:', res)
        if (res.status === 200) {
          return null
        }

        return Constants.genericNetworkError()
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API error[confirmRenewal]:', error)
          return error
        }

        console.log('API error[confirmRenewal]:', error.response.data)
        return error.response.data
      })

    return err
  }

  unauthedConfirmRenewal = async (userID, policyID, approved) => {
    const err = await fetch(
      `${Constants.apiHost()}/v3/user_renewal_selections`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'App-Version': '5.4',
          Platform: 'web',
        },
        body: JSON.stringify({
          user_id: userID,
          current_policy_id: policyID,
          renewal_approved: approved,
        }),
      }
    )
      .then((res) => {
        console.log('API res[confirmRenewal]:', res)
        if (res.status === 200) {
          return null
        }

        return Constants.genericNetworkError()
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API error[confirmRenewal]:', error)
          return error
        }

        console.log('API error[confirmRenewal]:', error.response.data)
        return error.response.data
      })

    return err
  }
}

export default new PolicyModel()
