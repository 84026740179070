const base64Encode = async (file) => {
  const response = await readFile(file)
    .then((result) => {
      return { data: result.data.replace(/^data:(.*,)?/, ''), err: null }
    })
    .catch((error) => {
      return { data: null, err: error }
    })

  return response
}

const objectURL = async (url) => {
  const objURL = await fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      return URL.createObjectURL(blob)
    })

  return objURL
}

const readFile = (file) => {
  let reader = new FileReader()

  return new Promise((resolve, reject) => {
    reader.onload = (event) => {
      file.data = event.target.result
      resolve(file)
    }

    reader.onerror = () => {
      return reject(this)
    }

    reader.readAsDataURL(file)
  })
}

export { base64Encode, objectURL }
