import React from 'react'
import '../styles/Label.css'

function Label({ icon, text, iconSize, style }) {
  let iconStyle = {}
  if (iconSize) {
    iconStyle = {
      width: `${iconSize}px`,
      height: `${iconSize}px`,
    }
  }

  return (
    <div className="label-container">
      {icon && <img src={icon} alt="icon" style={iconStyle}></img>}
      <label className="label-text" style={style}>
        {text}
      </label>
    </div>
  )
}

function ErrorLabel({ errorMessage }) {
  if (!errorMessage) {
    return null
  }

  return (
    <div className="error-label-container">
      <p>{errorMessage}</p>
    </div>
  )
}

export { Label, ErrorLabel }
