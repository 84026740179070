import '../../styles/ClaimsPay.css'
import licenseImgFront from '../../assets/icn-license-front.svg'
import licenseImgBack from '../../assets/icn-license-back.svg'
import { useState, useMemo, useCallback } from 'react'
import ClaimsPayModel from '../../models/ClaimsPayModel'
import ImageCapture from '../../components/ImageCapture'

export default function ClaimsPayLicense(props) {
  const claimsPaymentID = props.match.params.claims_payment_id

  const [frontSrc, setFrontSrc] = useState(null)
  const [frontFile, setFrontFile] = useState(null)
  const [backSrc, setBackSrc] = useState(null)
  const [backFile, setBackFile] = useState(null)
  const onError = useCallback((error) => {
    console.log(error)
  }, [])

  let viewPortWidth = document.documentElement.clientWidth
  viewPortWidth -= 32 // minus container padding

  const config = useMemo(
    () => ({
      video: {
        facingMode: 'environment',
        width: viewPortWidth,
        height: viewPortWidth,
      },
    }),
    [viewPortWidth]
  )

  const onCapture = (imageData) => {
    if (!frontSrc) {
      setFrontSrc(imageData.jpeg)
      setFrontFile(imageData.file)
    } else {
      setBackSrc(imageData.jpeg)
      setBackFile(imageData.file)
    }
  }

  const [imageState, setImageState] = useState(0)
  const [errMsg, setErrMsg] = useState('')
  const [isSubmittting, setIsSubmittting] = useState(false)

  const uploadImages = async () => {
    if (errMsg) {
      setErrMsg('')
    }
    setIsSubmittting(true)

    var err = await ClaimsPayModel.uploadImages(
      claimsPaymentID,
      frontFile,
      backFile
    )
    if (err) {
      setFrontSrc(null)
      setBackSrc(null)
      setErrMsg(
        `License is invalid, please try again.`
      )
      setImageState(0)

      setIsSubmittting(false)
      return
    }

    setIsSubmittting(false)
    window.location = `/claims-pay/verify-bank/${claimsPaymentID}`
  }

  return (
    <div className="container">
      {imageState === 0 && (
        <div className="claims-pay-license">
          <h2 className="title" style={{ marginBottom: '9px' }}>
            Add the front of your
            <br />
            driver’s license
          </h2>
          <p className="title-text">
            Take a photo of the front of your license.
          </p>

          <div className="icon-wrapper">
            <img
              className="icon"
              src={licenseImgFront}
              alt="front of license"
            />
          </div>
          {errMsg && <p className="err-msg">{errMsg}</p>}
          <button className="primary" onClick={() => setImageState(1) | setErrMsg('')}>
            Take Photo
          </button>
        </div>
      )}

      {imageState === 2 && (
        <div className="claims-pay-license">
          <h2 className="title">
            Add the back of your
            <br />
            driver’s license
          </h2>
          <p className="title-text">
            Take a photo of the back of your license.
          </p>

          <div className="icon-wrapper">
            <img className="icon" src={licenseImgBack} alt="front of license" />
          </div>

          <button className="primary" onClick={() => setImageState(3)}>
            Take Photo
          </button>
        </div>
      )}

      {imageState === 1 && window.navigator.mediaDevices && (
        <div className="claims-pay-license">
          <h2 className="title">
            Add the front of your
            <br />
            driver’s license
          </h2>
          {!frontSrc && (
            <ImageCapture
              onCapture={onCapture}
              onError={onError}
              userMediaConfig={config}
              width={viewPortWidth}
              height={viewPortWidth}
            />
          )}

          {frontSrc && (
            <>
              <img
                className="preview-image"
                src={frontSrc}
                alt="captured img"
                width={viewPortWidth}
                height={viewPortWidth}
              />

              <div
                className="replace-photo-btn"
                onClick={() => setFrontSrc(null)}
              >
                Replace
              </div>

              <button
                className="primary"
                style={{ marginTop: '25px' }}
                onClick={() => setImageState(2)}
              >
                Add Front
              </button>
            </>
          )}
        </div>
      )}

      {imageState === 3 && window.navigator.mediaDevices && (
        <div className="claims-pay-license">
          <h2 className="title">
            Add the back of your
            <br />
            driver’s license
          </h2>
          {!backSrc && (
            <ImageCapture
              onCapture={onCapture}
              onError={onError}
              userMediaConfig={config}
              width={viewPortWidth}
              height={viewPortWidth}
            />
          )}

          {backSrc && (
            <>
              <img
                className="preview-image"
                src={backSrc}
                alt="captured img"
                width={viewPortWidth}
                height={viewPortWidth}
              />

              <div
                className="replace-photo-btn"
                onClick={() => setBackSrc(null)}
              >
                Replace
              </div>

              <button
                className="primary"
                style={{ marginTop: '25px' }}
                onClick={uploadImages}
                disabled={isSubmittting}
              >
                {isSubmittting ? 'Processing...' : 'Add Back'}
              </button>
            </>
          )}
        </div>
      )}
    </div>
  )
}
