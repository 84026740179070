import API from './API'

class CoveragesModel {
  fetch = async (state) => {
    return await API()
      .get('/v3/coverages', {
        params: {
          state: state,
          carrier: 'Go',
        },
      })
      .then((resp) => {
        console.log('API res:', resp)
        if (resp.status === 200) {
          return true
        }

        return false
      })
  }

  saveSelections = async (policyCoverages, vehicleCoverages) => {
    return await API()
      .post('/v3/user_coverages', {
        policy_coverages: policyCoverages,
        vehicle_coverages: vehicleCoverages,
      })
      .then((resp) => {
        console.log('API res:', resp)
        if (resp.status === 200) {
          return true
        }
        return false
      })
  }
}

export default new CoveragesModel()
