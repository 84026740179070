import API from './API'
import Store from './Store'
import Constants from '../models/Constants'
import Sentry from '../logger/Sentry'

class UserModel {
  login = async (email, phoneNumber, userID = null) => {
    const response = await API()
      .post('/v3/users/login', {
        platform: 'web',
        email: email,
        phone_number: phoneNumber,
        user_id: userID,
      })
      .then((res) => {
        console.log('API res:', res)
        if (res.status === 200) {
          const user = {
            userID: res.data.user_id,
          }

          Store.setUser(user)

          return {
            userID: user.userID,
            err: null,
          }
        }

        return { userID: null, err: Constants.genericNetworkError }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err:', error)
          return { userID: null, err: error }
        }

        console.log('API err:', error.response.data)
        return { userID: null, err: error.response.data }
      })

    return response
  }

  /**
   * Requests verification SMS code for auth with user id
   *
   * @param {string} userID User UUID
   * @returns {{}} { phoneNumber, err }
   */
  requestCodeByID = async (userID) => {
    const { phoneNumber, err } = await API()
      .post('/v3/users/request_code', { user_id: userID })
      .then((res) => {
        console.log('API res[requestCodeByID]:', res)
        if (res.status === 200) {
          const user = {
            userID: res.data.user_id,
          }
          Store.setUser(user)

          return {
            phoneNumber: res.data.phone_number,
            err: null,
          }
        }

        return {
          phoneNumber: null,
          err: Constants.genericNetworkError(),
        }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API error[requestCodeByID]:', error)
          return {
            phoneNumber: null,
            err: error,
          }
        }

        console.log('API error[requestCodeByID]:', error.response.data)
        return {
          phoneNumber: null,
          err: error.response.data,
        }
      })

    return { phoneNumber, err }
  }

  verifyUser = async (code = '', resend = false) => {
    const { userID } = Store.getUser()

    if (!userID) {
      return {
        user: null,
        err: 'no user found',
      }
    }

    const response = await API()
      .post(`v3/users/verify?resend=${resend}`, {
        user_id: userID,
        verify_phone_code: code,
      })
      .then((res) => {
        console.log('API res:', res)
        if (res.status === 200) {
          if (resend) {
            return { err: null }
          }

          const user = {
            userID: res.data.user_id,
            token: res.data.token,
          }

          Store.setUser(user)

          return {
            user: user,
            err: null,
          }
        }

        return { user: null, err: Constants.genericNetworkError }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err:', error)
          return { user: null, err: error }
        }

        console.log('API err:', error.response.data)
        return { user: null, err: error.response.data }
      })

    return response
  }

  createUserAuth = async (userID) => {
    const response = await API()
      .post(`/v3/users`, {
        platform: 'web',
        user_id: userID,
      })
      .then((res) => {
        console.log('API res[createUserAuth]:', res)
        if (res.status === 200) {
          const user = {
            userID: res.data.user_id,
            token: res.data.token,
          }

          Store.setUser(user)

          return {
            userID: user.userID,
            err: null,
          }
        }

        return { userID: null, err: Constants.genericNetworkError }
      })
      .catch((error) => {
        console.log('API err[createUserAuth]:', error)
        if (!error.response) {
          return { userID: null, err: error }
        }

        return { userID: null, err: error.response.data }
      })

    return response
  }

  getUser = async (userID, page, getQuote = false, isGET = true) => {
    const user = Store.getUser()

    if (!user) {
      return {
        user: null,
        license: null,
        address: null,
        drivers: null,
        vehicles: null,
        coverageOptions: null,
        quote: null,
        policy: null,
        policyCoverages: null,
        vehicleCoverages: null,
        isBookQuote: false,
        goodCredit: false,
        agent: null,
        err: 'no user found',
      }
    }

    const response = await API()
      .get(
        `/v3/users/${user.userID}?page=${page}&quote=${getQuote}&is_get=${isGET}`
      )
      .then((res) => {
        console.log('API res[getUser]:', res)
        if (res.status === 200) {
          return {
            user: res.data.user,
            license: res.data.license,
            address: res.data.address,
            drivers: res.data.drivers,
            vehicles: res.data.vehicles,
            coverageOptions: res.data.coverage_options,
            quote: res.data.quote,
            policy: res.data.policy,
            policyCoverages: res.data.policy_coverages,
            vehicleCoverages: res.data.vehicle_coverages,
            userState: res.data.user_state,
            isBookQuote: res.data.is_book_quote,
            goodCredit: res.data.good_credit,
            agent: res.data.agent || null,
            err: null,
          }
        }

        return {
          user: null,
          license: null,
          address: null,
          drivers: null,
          vehicles: null,
          coverageOptions: null,
          quote: null,
          policy: null,
          policyCoverages: null,
          vehicleCoverages: null,
          isBookQuote: false,
          goodCredit: false,
          agent: null,
          err: Constants.genericNetworkError(),
        }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[getUser]:', error)

          Sentry.error('GetUserAPIError', error, {
            userID,
            page,
            getQuote,
            isGET,
            error: error,
          })

          return {
            user: null,
            license: null,
            address: null,
            drivers: null,
            vehicles: null,
            coverageOptions: null,
            quote: null,
            policy: null,
            policyCoverages: null,
            vehicleCoverages: null,
            isBookQuote: false,
            goodCredit: false,
            agent: null,
            err: error,
          }
        }

        console.log('API err[getUser]:', error.response.data)

        Sentry.error('GetUserAPIError', error.response.data, {
          userID,
          page,
          getQuote,
          isGET,
          error: error,
        })

        return {
          user: null,
          license: null,
          address: null,
          drivers: null,
          vehicles: null,
          coverageOptions: null,
          quote: null,
          policy: null,
          policyCoverages: null,
          vehicleCoverages: null,
          isBookQuote: false,
          goodCredit: false,
          agent: null,
          err: error.response.data,
        }
      })

    return response
  }

  updateUser = async (userID, email, phoneNumber, userPlatform) => {
    const err = await API()
      .put(`/v3/users/${userID}`, {
        email: email,
        phone_number: phoneNumber,
        user_platform: userPlatform || null,
      })
      .then((res) => {
        console.log('API res:', res)
        if (res.status === 200) {
          return null
        }

        return { err: 'something went wrong' }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err:', error)
          return [null, error]
        }

        console.log('API err:', error.response.data)
        return [null, error.response.data]
      })

    return err
  }

  getVehiclePhotos = async (userID) => {
    const response = await API()
      .get(`/v3/users/${userID}/photos`)
      .then((res) => {
        console.log('API res:', res)
        if (res.status === 200) {
          return {
            user: res.data.user,
            license: res.data.license,
            vehicles: res.data.vehicles,
            policy: res.data.policy,
            quote: res.data.quote,
            hasPolicy: res.data.has_policy,
            hasUWRequest: res.data.has_uw_request,
            underwritingRequest: res.data.underwriting_request,
            err: null,
          }
        }

        return {
          user: null,
          license: null,
          vehicles: null,
          policy: null,
          quote: null,
          hasPolicy: false,
          hasUWRequest: false,
          underwritingRequest: null,
          err: Constants.genericNetworkError(),
        }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err:', error)
          return {
            user: null,
            license: null,
            vehicles: null,
            policy: null,
            quote: null,
            hasPolicy: false,
            hasUWRequest: false,
            underwritingRequest: null,
            err: error,
          }
        }

        console.log('API err:', error.response.data)
        return {
          user: null,
          license: null,
          vehicles: null,
          policy: null,
          quote: null,
          hasPolicy: false,
          hasUWRequest: false,
          underwritingRequest: null,
          err: error.response.data,
        }
      })

    return response
  }

  uploadVehiclePhoto = async (
    userID,
    vehicleID,
    fileData,
    mimeType,
    isVideo = false
  ) => {
    const err = await API()
      .post(`/v3/users/${userID}/photos`, {
        file: fileData,
        mime_type: mimeType,
        vehicle_id: vehicleID,
        is_video: isVideo,
      })
      .then((res) => {
        console.log('API res:', res)
        if (res.status === 200 || res.status === 201) {
          return null
        }

        return { err: 'something went wrong' }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err:', error)
          return error
        }

        console.log('API err:', error.response.data)
        return error.response.data
      })

    return err
  }

  deleteVehiclePhoto = async (userID, vehiclePhotoID) => {
    const err = await API()
      .delete(`/v3/users/${userID}/photos`, {
        data: {
          vehicle_photo_id: vehiclePhotoID,
        },
      })
      .then((res) => {
        console.log('API res:', res)
        if (res.status === 200 || res.status === 201) {
          return null
        }

        return { err: 'something went wrong' }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err:', error)
          return error
        }

        console.log('API err:', error.response.data)
        return error.response.data
      })

    return err
  }

  submitVehiclePhotos = async (userID, uwRequestType = 'vehicle_photos') => {
    const err = await API()
      .post(`/v3/users/${userID}/photos_submit`, {
        uw_request_type: uwRequestType,
      })
      .then((res) => {
        console.log('API res:', res)
        if (res.status === 200 || res.status === 201) {
          return null
        }

        return { err: 'something went wrong' }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err:', error)
          return error
        }

        console.log('API err:', error.response.data)
        return error.response.data
      })

    return err
  }

  validateEmail = async (email) => {
    const error = await API()
      .get('/v1/validation/email', {
        params: {
          email: email,
        },
      })
      .then((res) => {
        console.log('API res:', res)
        if (res.status === 200) {
          if (!res.data.valid) {
            return res.data.message
          }
          return ''
        }

        return 'Please enter a valid email'
      })
      .catch((error) => {
        console.log('API err:', error)
        return 'Please enter a valid email'
      })

    return error
  }
}

export default new UserModel()
