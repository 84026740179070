import React, { useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import Constants from '../models/Constants'

const stripePromise = loadStripe(Constants.stripeKey())

const PaymentForm = ({ onCreatePayment, enabled, binding, error }) => {
  const stripe = useStripe()
  const elements = useElements()

  const [isProcessing, setIsProcessing] = useState(false)
  const [formComplete, setFormComplete] = useState(false)

  const handleChange = async (event) => {
    if (!event.complete && formComplete) {
      setFormComplete(false)
    }

    if (event.complete) {
      setFormComplete(true)
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setIsProcessing(true)

    const cardElement = elements.getElement(CardElement)

    const { token, err } = await stripe.createToken(cardElement)
    if (err) {
      console.log('stripe token error:', err)
      setIsProcessing(false)
      onCreatePayment(null, err)
      return
    }

    console.log('stripe token:', token)
    setIsProcessing(false)
    onCreatePayment(token.id, null)
  }

  return (
    <form
      className="checkout-form"
      onSubmit={handleSubmit}
      style={{ width: '100%', backgroundColor: '#FFFFFF', padding: '0' }}
    >
      <h4>Payment Information</h4>
      <div className="error-label show">
        <p>{error}</p>
      </div>

      <div className="form-row">
        <div className="form-column">
          <CardElement className="card-element" onChange={handleChange} />
        </div>
      </div>
      {binding ? (
        <button className="primary" disabled>
          Updating policy...
        </button>
      ) : (
        <button
          className="primary"
          type="submit"
          disabled={!stripe || isProcessing || !enabled || !formComplete}
        >
          Continue
        </button>
      )}
    </form>
  )
}

function FinalizeCheckoutForm({ onCreatePayment, enabled, binding, error }) {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm
        onCreatePayment={onCreatePayment}
        binding={binding}
        enabled={enabled}
        error={error}
      />
    </Elements>
  )
}

export default FinalizeCheckoutForm
