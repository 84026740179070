import API from './API'
import Constants from './Constants'

class VehicleVideoModel {
  getVehicleVideos = async (forPendingPolicy = false) => {
    const { vehicleVideos, err } = await API()
      .get(`/v3/vehicles/videos?for_pending_policy=${forPendingPolicy}`)
      .then((res) => {
        console.log('API res[getVideos]:', res)
        if (res.status === 200) {
          return { vehicleVideos: res.data, err: null }
        }

        return { vehicleVideos: [], err: Constants.genericNetworkError() }
      })
      .catch((error) => {
        console.log('API err[getVideos]:', error.toString())
        return { vehicleVideos: [], err: error.toString() }
      })

    return { vehicleVideos: vehicleVideos, err }
  }

  uploadVideo = async (vehicleID, fileData, mimeType) => {
    const err = await API()
      .post(`/v3/vehicles/${vehicleID}/videos`, {
        data: fileData,
        mime_type: mimeType,
      })
      .then((res) => {
        console.log('API res[uploadVideo]:', res)
        if (res.status === 200) {
          return null
        }

        return Constants.genericNetworkError()
      })
      .catch((error) => {
        console.log('API err[uploadVideo]:', error.toString())
        return error.toString()
      })

    return err
  }
}

export default new VehicleVideoModel()
