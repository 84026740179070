import '../styles/BrokerQuote.css'
import React, { useEffect, useState } from 'react'
import {
  CenteredContainer,
  ColumnContainer,
  ColumnLeft,
  ColumnRight,
} from '../components/Container'
import agentAshleyIcon from '../assets/profile-ashley-lawrence@3x.png'
import AgentModel from '../models/AgentModel'
import LoadingIndicator from '../components/LoadingIndicator'
import Store from '../models/Store.js'
import appHomeImage from './../assets/app-home@3x.png'

export default function BrokerQuote(props) {
  const shareCode = props.match.params.share_code

  const [loading, setLoading] = useState(true)
  const [agent, setAgent] = useState(null)

  const fetchAgent = async (shareCode) => {
    const { response, err } = await AgentModel.getAgentFromShareCode(shareCode)
    if (err) {
      alert('Not a valid quoting url, please contact your broker.')
      return
    }

    if (response) {
      setAgent(response)
      setLoading(false)
    }
  }

  useEffect(() => {
    Store.clearAll()
    fetchAgent(shareCode)
    // eslint-disable-next-line
  }, [])

  const handleStartQuoteClick = async () => {
    setLoading(true)
    const { err } = await AgentModel.createAgentUser(agent.token, shareCode)
    if (err) {
      alert('Something went wrong, please contact your broker.')
      return
    }

    window.location = `/app-first`
  }

  return (
    <div className="container">
      {!loading && (
        <>
          <h2 className="title">{agent.agency_name}</h2>
          <ColumnContainer>
            <ColumnLeft>
              <h3 className="heading">Your Broker</h3>
              <div className="agent-profile">
                {agent.username === 'ashley@csivegas.insure' ? (
                  <img src={agentAshleyIcon} alt="Agent" />
                ) : (
                  <div className="initials">{`${agent.firstname[0]}${agent.lastname[0]}`}</div>
                )}

                <div>
                  <h4>
                    {agent.firstname} {agent.lastname}
                  </h4>
                  <p>{agent.agency_name}</p>
                  {agent.license_number && agent.license_state && (
                    <p>
                      LIC: {agent.license_state}-{agent.license_number}
                    </p>
                  )}
                </div>
              </div>

              <div className="broker-quote-content">
                <h4>
                  “I got great rates with my same coverage with{' '}
                  {agent.agency_name === 'Hayek Insurance'
                    ? agent.agency_name
                    : agent.firstname}
                  ”
                </h4>

                <p>
                  Made specially for {agent.state}, Go Insurance gives you
                  exactly what you need, for less. Get the same coverage, the
                  same deductibles, but better service. All for an average of
                  50% off, click below to get your 60 second quote with{' '}
                  {agent.agency_name === 'Hayek Insurance'
                    ? agent.agency_name
                    : agent.firstname}
                  .
                </p>

                <button className="primary" onClick={handleStartQuoteClick}>
                  Get My Quote
                </button>
              </div>
            </ColumnLeft>
            <ColumnRight>
              <div style={{ textAlign: 'center' }}>
                <img
                  className="broker-quote-image"
                  src={appHomeImage}
                  alt="App home screen"
                />
              </div>
            </ColumnRight>
          </ColumnContainer>
        </>
      )}

      {loading && (
        <CenteredContainer>
          <div style={{ maxWidth: '150px', margin: '0 auto' }}>
            <LoadingIndicator text="Loading..." />
          </div>
        </CenteredContainer>
      )}
    </div>
  )
}
