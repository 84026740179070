import API from './API'
import Constants from './Constants'

class VehicleModel {
  addVehicle = async (userID, vehicle) => {
    const response = await API()
      .post('/v1/user/vehicle', vehicle)
      .then((res) => {
        console.log('API res[addVehicle]:', res)
        if (res.status === 200) {
          return {
            vehicle: res.data,
            err: null,
          }
        }

        return { vehicle: null, err: Constants.genericNetworkError() }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[addVehicle]:', error)
          return { vehicle: null, err: error }
        }

        console.log('API err[addVehicle]:', error.response.data)
        return { vehicle: null, err: error.response.data }
      })

    return response
  }

  removeVehicle = async (userID, vehicleID) => {
    return API()
      .delete('/v3/vehicles/' + vehicleID)
      .then((resp) => {
        console.log('API res:', resp)
        if (resp.status === 200) {
          return true
        }
        return false
      })
  }

  updateVehicle = async (userID, vehicle) => {
    const response = await API()
      .put(`/v4/vehicles/${vehicle.id}`, vehicle)
      .then((res) => {
        console.log('API res[updateVehicle]:', res)
        if (res.status === 200) {
          return {
            vehicle: res.data,
            err: null,
          }
        }

        return { vehicle: null, err: Constants.genericNetworkError() }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[updateVehicle]:', error)
          return { vehicle: null, err: error }
        }

        console.log('API err[updateVehicle]:', error.response.data)
        return { vehicle: null, err: error.response.data }
      })

    return response
  }

  getVehicleByVIN = async (userID, vin) => {
    const [vehicleDetails, err] = await API()
      .get(`/v1/vehicle/${vin}`)
      .then((resp) => {
        console.log('API res:', resp)
        if (resp.status === 200) {
          return [resp.data, null]
        }

        return [null, 'Something went wrong']
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err:', error)
          return [null, error]
        }

        console.log('API err:', error.response.data)
        return [null, error.response.data]
      })

    return [vehicleDetails, err]
  }

  search = async (userID, query) => {
    const [vehicles, err] = await API()
      .get(`/v3/vehicles/search?q=${query}`)
      .then((resp) => {
        console.log('API res:', resp)
        if (resp.status === 200) {
          let data = resp.data.options
            .filter(function (item) {
              let yearMakeModel = `${
                item.year
              } ${item.make.toLowerCase()} ${item.model.toLowerCase()}`
              return yearMakeModel.startsWith(query.toLowerCase())
            })
            .map(function ({ id, year, make, model }) {
              return { id, year, make, model }
            })
          return [data, null]
        }

        return [null, 'Something went wrong']
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err:', error)
          return [null, error]
        }

        console.log('API err:', error.response.data)
        return [null, error.response.data]
      })

    return [vehicles, err]
  }

  createUWNotification = async (email) => {
    const response = await API()
      .post('/v3/vehicles/uw_notify', {
        email,
      })
      .then((resp) => {
        console.log('API res[createUWNotification]:', resp)
        if (resp.status === 200) {
          return {
            uwNotification: resp.data,
            err: null,
          }
        }

        return { uwNotification: null, err: Constants.genericNetworkError() }
      })
      .catch((error) => {
        console.log('API error[createUWNotification]:', error)
        if (!error.response) {
          return { uwNotification: null, err: error }
        }

        return { uwNotification: null, err: error.response.data }
      })

    return response
  }

  getUWNotification = async () => {
    const response = await API()
      .get('/v3/vehicles/uw_notify')
      .then((resp) => {
        console.log('API res[getUWNotification]:', resp)
        if (resp.status === 200) {
          return {
            uwNotification: resp.data,
            err: null,
          }
        }

        return { uwNotification: null, err: Constants.genericNetworkError() }
      })
      .catch((error) => {
        console.log('API error[getUWNotification]:', error)
        if (!error.response) {
          return { uwNotification: null, err: error }
        }

        return { uwNotification: null, err: error.response.data }
      })

    return response
  }
}

export default new VehicleModel()
