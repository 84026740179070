import '../styles/AppFirst.css'
import { useState } from 'react'
import showRoomScreen from '../assets/appfirst-vshowroom@3x.png'
import homeScreen from '../assets/appfirst-home@3x.png'
import claimsScreen from '../assets/appfirst-claims@3x.png'
import mockIphone from '../assets/iphone.svg'
import mockAndroid from '../assets/android.svg'
import { useParams } from 'react-router-dom'
import UserModel from '../models/UserModel'

export default function AppFirst(props) {
  const { userID } = useParams()

  const platform = {
    ios: 'ios',
    android: 'android',
  }

  const [hasUser, setHasUser] = useState(userID ? true : false)
  const [showDeviceSelector, setShowDeviceSelector] = useState(false)
  const [selectedPlatform, setSelectedPlatform] = useState(() =>
    props.location.state && props.location.state.platform
      ? props.location.state.platform
      : ''
  )

  const handleStartQuoting = async () => {
    if (hasUser) {
      const err = await UserModel.updateUser(
        userID,
        null,
        null,
        selectedPlatform
      )
      if (err) {
        alert('Something went wrong. Try again later.')
        return
      }

      window.location = `/quote/${userID}`
      return
    }

    props.history.push('/info', { platform: selectedPlatform })
  }

  return (
    <div className="container">
      {!showDeviceSelector && (
        <>
          <h2 className="title app-first">Three reasons customers love Go</h2>

          <div className="app-first-container">
            <div className="column">
              <img className="app-screen" src={showRoomScreen} alt="" />
              <div className="content">
                <h4>Your Own Virtual Showroom</h4>
                <p>
                  Now showrooms are not just for{' '}
                  <a
                    className="green"
                    href="https://www.hotcars.com/a-detailed-look-at-rihanas-car-collection"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Rihanna
                  </a>{' '}
                  or{' '}
                  <a
                    className="green"
                    href="https://www.architecturaldigest.com/story/ralph-lauren-vintage-cars-lime-rock-article"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Ralph Lauren
                  </a>{' '}
                  any more. With Go, you get the only app with a virtual
                  showroom — letting you protect the value of your car with a
                  perfect digital record.
                </p>
              </div>
            </div>
            {/* end of column 1 */}

            <div className="column">
              <img className="app-screen" src={homeScreen} alt="" />
              <div className="content">
                <h4>Everything in an app</h4>
                <p>
                  You no longer have to call, or ever wait on hold. You can do
                  everything right in the app — or Support is just a chat away
                  to do it for you.
                </p>
              </div>
            </div>

            {/* end of column 2 */}
            <div className="column">
              <img className="app-screen" src={claimsScreen} alt="" />
              <div className="content">
                <h4>State-of-the-art Claims</h4>
                <p>
                  In the unfortunate event you’re ever in an accident, you have
                  the state-of-the-art claims experience. You have video, a
                  verified adjuster, real-time status, and can use your virtual
                  showroom as a record for pre-existing damage.
                </p>
              </div>
            </div>
            {/* end of column 3 */}
          </div>

          <div style={{ textAlign: 'center' }}>
            <button
              className="primary app-first"
              onClick={() => setShowDeviceSelector(true)}
            >
              Continue
            </button>
          </div>
        </>
      )}

      {/* device selection screen */}
      {showDeviceSelector && (
        <>
          <h2 className="title app-first device-selector">Your Device Type</h2>
          <div className="lead-select-container">
            <ul className="device-selector">
              <li
                className={selectedPlatform === platform.ios ? 'active' : ''}
                onClick={() => setSelectedPlatform(platform.ios)}
              >
                <img src={mockIphone} alt="iphone" />
                <div>iPhone</div>
              </li>
              <li
                className={
                  selectedPlatform === platform.android ? 'active' : ''
                }
                onClick={() => setSelectedPlatform(platform.android)}
              >
                <img src={mockAndroid} alt="android phone" />
                <div>Android</div>
              </li>
            </ul>

            <button
              className="primary app-first device-selector"
              disabled={selectedPlatform === ''}
              onClick={handleStartQuoting}
            >
              Continue
            </button>
          </div>
        </>
      )}
    </div>
  )
}
