const RepairStateType = {
  SelectShop: 'repair_select_shop',
  Estimate: 'repair_estimate',
  EstimateValidation: 'repair_estimate_validation',
  ApproveEstimate: 'repair_approve_estimate',
  InProgress: 'repair_in_progress',
  Payment: 'repair_payment',
  ReadyForPickup: 'repair_ready_for_pickup',
  Done: 'repair_done',
}

export { RepairStateType }
