import React, { useEffect, useState } from 'react'
import { CenteredContainer } from '../../components/Container'
import { useLocation, useParams } from 'react-router-dom'
import PolicyModel from '../../models/PolicyModel'
import checkIcon from '../../assets/checkmark-2.svg'
import crossIcon from '../../assets/icn-x-out.svg'
import LoadingIndicator from '../../components/LoadingIndicator'

const RenewalConfirmation = () => {
  const location = useLocation()
  const { user_id : userId} = useParams()
  const params = new URLSearchParams(location.search)
  const policyNumber = params.get('policy')
  const approvalFlag = JSON.parse(params.get('approved'))
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!policyNumber || !userId) return
    const confirmRenewal = async () => {
      setIsLoading(true)
      const err = await PolicyModel.unauthedConfirmRenewal(
        userId,
        policyNumber,
        approvalFlag ?? false
      )
      if(err){
        alert('Failed to perform action, please try again')
        return
      }
      setIsLoading(false)
    }
    confirmRenewal()
  }, [policyNumber, approvalFlag, userId])

  return (
    <div className="container">
      <CenteredContainer className="renewal-approval-container">
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 16,
          }}
        >
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <>
              <img
                src={approvalFlag ? checkIcon : crossIcon}
                width="60"
                alt=""
              />
              <h1 style={{ fontSize: '40px' }}>
                {approvalFlag
                  ? "You've selected to renew"
                  : "You've selected not to renew"}
              </h1>
            </>
          )}
        </div>
      </CenteredContainer>
    </div>
  )
}

export default RenewalConfirmation
