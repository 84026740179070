import React, { useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import { Label } from './Label'
import Constants from '../models/Constants'
import driverIcon from '../assets/driver.svg'

const stripePromise = loadStripe(Constants.stripeKey())

const PaymentForm = ({
  firstName,
  lastName,
  onCreatePayment,
  enabled,
  binding,
  text,
}) => {
  const stripe = useStripe()
  const elements = useElements()

  const [isProcessing, setIsProcessing] = useState(false)
  const [formComplete, setFormComplete] = useState(false)
  const [formValues, setFormValues] = useState({
    cardFirstName: firstName || '',
    cardLastName: lastName || '',
  })

  const handleInputChange = (e) => {
    const fieldName = e.target.name
    const fieldValue = e.target.value

    setFormValues({
      ...formValues,
      [fieldName]: fieldValue,
    })
  }

  const handleChange = async (event) => {
    if (!event.complete && formComplete) {
      setFormComplete(false)
    }

    if (event.complete) {
      setFormComplete(true)
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setIsProcessing(true)

    const cardElement = elements.getElement(CardElement)

    const { token, err } = await stripe.createToken(cardElement, {
      name: `${formValues.cardFirstName} ${formValues.cardLastName}`,
    })
    if (err) {
      console.log('stripe token error:', err)
      setIsProcessing(false)
      onCreatePayment(null, err)
      return
    }

    console.log('stripe token:', token)
    setIsProcessing(false)
    onCreatePayment(token.id, null)
  }

  return (
    <form className="checkout-form" onSubmit={handleSubmit}>
      <h4>Payment</h4>
      <div className="form-row">
        <div className="form-column">
          <Label icon={driverIcon} text="FIRST NAME"></Label>
          <input
            name="cardFirstName"
            value={formValues.cardFirstName}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-column">
          <Label icon={driverIcon} text="LAST NAME"></Label>
          <input
            name="cardLastName"
            value={formValues.cardLastName}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-column">
          <CardElement className="card-element" onChange={handleChange} />
        </div>
      </div>
      {binding ? (
        <button className="primary" disabled>
          Creating policy...
        </button>
      ) : (
        <button
          className="primary"
          type="submit"
          disabled={!stripe || isProcessing || !enabled || !formComplete}
        >
          {text}
        </button>
      )}
    </form>
  )
}

function CheckoutForm({
  firstName,
  lastName,
  onCreatePayment,
  enabled,
  binding,
  text = 'Checkout',
}) {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm
        firstName={firstName}
        lastName={lastName}
        onCreatePayment={onCreatePayment}
        binding={binding}
        enabled={enabled}
        text={text}
      />
    </Elements>
  )
}

export default CheckoutForm
