import { useState } from 'react'
import { CenteredContainer } from '../../components/Container'
import NumberFormat from 'react-number-format'
import { Label } from '../../components/Label'
import icnPhone from '../../assets/icn-phone.svg'
import icnCode from '../../assets/icn-code.svg'

export default function Verify() {
  const [showCodeInput, setShowCodeInput] = useState(false)
  const [phone, setPhone] = useState('')
  const [code, setCode] = useState('')

  const onPhoneSubmit = (e) => {
    e.preventDefault()

    setShowCodeInput(true)
  }

  return (
    <div className="container">
      <CenteredContainer style={{ paddingTop: '10vh' }}>
        {/* enter phone number section  */}
        {!showCodeInput && (
          <>
            <h1 className="title">Verify your phone number</h1>
            <p className="sub-title">
              Enter a phone number to get a text message with a verification
              code.
            </p>

            <form onSubmit={onPhoneSubmit}>
              <div className="form-row">
                <div className="form-column">
                  <Label icon={icnPhone} text="PHONE NUMBER" />
                  <NumberFormat
                    type="tel"
                    format="### ### ####"
                    name="phoneNumber"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>

              <button className="primary" type="submit">
                Continue
              </button>
            </form>
          </>
        )}

        {/* enter code verification section  */}
        {showCodeInput && (
          <>
            <h1 className="title">Enter the 4 digit code sent to you</h1>
            <p className="sub-title">
              Proving you are not a bot will allow Go Insurance to pay your
              claim.
            </p>

            <form onSubmit={() => {}}>
              <div className="form-row">
                <div className="form-column">
                  <Label icon={icnCode} text="ENTER CODE" />
                  <input
                    type="number"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
              </div>

              <button className="link grey">Get help</button>
            </form>
          </>
        )}
      </CenteredContainer>
    </div>
  )
}
