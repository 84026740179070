import '../../styles/Claims.css'

import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { CenteredContainer } from '../../components/Container'
import PolicyIcon from '../../assets/icn-confirmation.svg'
import BackIcon from '../../assets/icn-back-nav.svg'
import DriverIcon from '../../assets/driver.svg'
import { Label, ErrorLabel } from '../../components/Label'
import CodeVerifyInput from '../../components/CodeVerifyInput'
import ClaimModel from '../../models/ClaimModel'
import Store from '../../models/Store'

export default function ClaimsStartClaim() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const [policyNumber, setPolicyNumber] = useState(() => {
    return params.get('policy') || ''
  })
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [policyError, setPolicyError] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [requestedCode, setRequestedCode] = useState(false)
  const [isGoCustomer,] = useState(() => {
    return Store.getClaimReporterType() === 'GO_CUSTOMER'
  })

  const handleClaimsLogin = async (e) => {
    e.preventDefault()
    await requestCode(policyNumber)
  }

  const requestCode = async (policyNumber) => {
    const { phoneNumber, err } = await ClaimModel.requestCode(policyNumber)
    if (err) {
      if (err.err && err.err.toString() === 'request was not valid') {
        setPolicyError('Please enter a valid policy number')
        return
      }

      setPolicyError(
        'Sorry, we ran into an unexpected issue and we’re still figuring it out.'
      )
      return
    }

    setPhoneNumber(phoneNumber)
    setRequestedCode(true)
  }

  const handlePolicyOnChange = async (e) => {
    setPolicyNumber(e.target.value.toUpperCase())
    
    if (policyError) {
      setPolicyError('')
    }
  }

  const handleFirstNameOnChange = async (e) => {
    setFirstName(e.target.value)
    
    if (policyError) {
      setPolicyError('')
    }
  }

  const handleLastNameOnChange = async (e) => {
    setLastName(e.target.value)
    
    if (policyError) {
      setPolicyError('')
    }
  }

  const handleCodeVerify = async (code) => {
    const { err } = await ClaimModel.login(policyNumber, code)
    if (err) {
      alert('Invalid verification code')
      return
    }

    Store.setClaimUnauthedPolicyNumber("")
    Store.setClaimUnauthedUserID("")
    Store.setClaimUnauthedPolicyID("")
    Store.setClaimUnauthedDriver({ driverIsPrimary: false, driverID: null })


    if (params.get('redirect')) {
      window.location = params.get('redirect')
      return
    }

    window.location = '/claims/list'
  }

  const handleContinueAsNonGoCustomer = async () => {
    var { isValid, userID, policyID, driverIsPrimary, driverID } = await ClaimModel.validateUnauthedPolicyNumber(policyNumber,firstName,lastName)
    if (isValid) {
      setPolicyError('')
      Store.setClaimUnauthedPolicyNumber(policyNumber)
      Store.setClaimUnauthedUserID(userID)
      Store.setClaimUnauthedPolicyID(policyID)
      Store.setClaimUnauthedDriver({ driverIsPrimary, driverID })
      window.location = '/claims/your-info'
    } else (
      // TODO implement: after three? failed attempts show how to contact support
      setPolicyError('No match to policy number, first name, and last name')
    )
  }

  useEffect(() => {
    const policyParam = params.get('policy')
    if (policyParam) {
      requestCode(policyParam)
    }
    // eslint-disable-next-line
  }, [])

  if (isGoCustomer) {
    return (
      <div className="container">
        <div className="back-nav">
          <Link to="/claims">
            <img src={BackIcon} alt="back button" /> Back
          </Link>
        </div>
        {!requestedCode && (
          <CenteredContainer>
            <h2 className="display">Claims</h2>
            <div className="form-container" style={{ marginTop: '48px' }}>
              <form onSubmit={handleClaimsLogin}>
                <div className="form-row" style={{ padding: '0px' }}>
                  <div className="form-column">
                    <Label icon={PolicyIcon} text="POLICY NUMBER"></Label>
                    <input
                      className={policyError ? 'error' : ''}
                      type="text"
                      name="policyNumber"
                      value={policyNumber}
                      onChange={handlePolicyOnChange}
                    />
                    <ErrorLabel errorMessage={policyError} />
                  </div>
                </div>
    
                <button
                  className="primary"
                  type="submit"
                  style={{ width: '100%', marginTop: '35px' }}
                  autoComplete="false"
                  autoCorrect="false"
                  disabled={policyNumber.length === 0}
                >
                  Continue
                </button>
              </form>
            </div>
          </CenteredContainer>
        )}
        {requestedCode && (
          <CenteredContainer
            style={{ maxWidth: '467px', margin: '0 auto', textAlign: 'center' }}
          >
            <h2 className="display" style={{ marginBottom: '4px' }}>
              Enter the 4 digit code sent to ***-{phoneNumber}
            </h2>
            <p className="title-text">This code enables us to verify identity.</p>
  
            <CodeVerifyInput
              style={{ marginTop: '35px' }}
              onComplete={handleCodeVerify}
              disabled={policyNumber.length === 0 || policyError}
            />
          </CenteredContainer>
        )}
      </div>
    )
  } else {
    return (
      <div className="container">
        <div className="back-nav">
          <Link to="/claims">
            <img src={BackIcon} alt="back button" /> Back
          </Link>
        </div>
        <CenteredContainer>
          <h2 className="display">Claims</h2>
          <p className="claims-info-text">
            Start a claim online by entering the policy number
            of the Go Insurance customer involved in this incident.
          </p>
          <div className="form-container" style={{ marginTop: '48px' }}>
            <div className="form-row" style={{ padding: '0px' }}>
              <div className="form-column">
                <Label icon={PolicyIcon} text="POLICY NUMBER"></Label>
                <input
                  className={policyError ? 'error' : ''}
                  type="text"
                  name="policyNumber"
                  value={policyNumber}
                  onChange={handlePolicyOnChange}
                />
                <ErrorLabel errorMessage={policyError} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-column">
                <Label icon={DriverIcon} text="FIRST NAME"></Label>
                <input
                  className={policyError ? 'error' : ''}
                  type="text"
                  name="firstName"
                  value={firstName}
                  onChange={handleFirstNameOnChange}
                />
              </div>
              <div className="form-column">
                <Label icon={DriverIcon} text="LAST NAME"></Label>
                <input
                  className={policyError ? 'error' : ''}
                  type="text"
                  name="lastName"
                  value={lastName}
                  onChange={handleLastNameOnChange}
                />
              </div>
            </div>
            <button
              className="primary"
              style={{ width: '100%', marginTop: '35px' }}
              autoComplete="false"
              autoCorrect="false"
              disabled={policyNumber.length === 0}
              onClick={handleContinueAsNonGoCustomer}
            >
              Continue
            </button>
          </div>
        </CenteredContainer>
      </div>
    )
  }
}