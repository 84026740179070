import API from './API'
import Constants from './Constants'
import Store from './Store'

class ClaimModel {
  validateUnauthedPolicyNumber = async (policyNumber, firstName, lastName) => {
    const response = await API()
      .post(`/v3/policies/${policyNumber}/validate`, {
        first_name: firstName,
        last_name: lastName,
      })
      .then((res) => {
        console.log('API res[validate]:', res)
        if (res.status === 200) {
          return {
            data: res.data,
            err: null,
          }
        }

        return {
          data: null,
          err: Constants.genericNetworkError(),
        }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[validate]:', error)
          return { data: null, err: error }
        }

        console.log('API err[validate]:', error.response.data)
        return { data: null, err: error.response.data }
      })
      
    if (response.data && response.data.is_valid) {
      return {
        isValid: response.data.is_valid,
        userID: response.data.user_id,
        policyID: response.data.policy_id,
        driverIsPrimary: response.data.driver_is_primary, // bool
        driverID: response.data.driver_id, // string or null
      }
    }
    return {
      isValid: false,
      userID: "",
      policyID: "",
      driverIsPrimary: false,
      driverID: null,
    }
  }

  validateVehicle = async (policyID, text, textIsVin) => {
    const body = textIsVin ? {
      vin: text
    } : {
      year_make_model: text
    }
    const response = await API()
      .post(`/v3/policies/${policyID}/validate_vehicle`, body)
      .then((res) => {
        console.log('API res[validate_vehicle]:', res)
        if (res.status === 200) {
          return {
            data: res.data,
            err: null,
          }
        }

        return {
          data: null,
          err: Constants.genericNetworkError(),
        }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[validate_vehicle]:', error)
          return { data: null, err: error }
        }

        console.log('API err[validate_vehicle]:', error.response.data)
        return { data: null, err: error.response.data }
      })
      
    if (response.data && response.data.vehicle_id) {
      return {
        vehicleID: response.data.vehicle_id
      }
    }
    return {
      vehicleID: ""
    }
  }

  requestCode = async (
    policyNumber,
    useExactNumber = false,
    claimsPaymentID = null
  ) => {
    const response = await API()
      .post('/v3/claims/request_code', {
        policy_number: policyNumber,
        use_exact_number: useExactNumber,
        claims_payment_id: claimsPaymentID,
      })
      .then((res) => {
        console.log('API res[requestCode]:', res)
        if (res.status === 200) {
          return {
            phoneNumber: res.data.phone_number,
            err: null,
          }
        }

        return {
          phoneNumber: null,
          err: Constants.genericNetworkError(),
        }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API error[requestCode]:', error)
          return {
            phoneNumber: null,
            err: error,
          }
        }

        console.log('API error[requestCode]:', error.response.data)
        return {
          phoneNumber: null,
          err: error.response.data,
        }
      })

    return response
  }

  login = async (
    policyNumber,
    verificationCode,
    useExactNumber = false,
    claimsPaymentID = null
  ) => {
    const response = await API()
      .post('/v3/claims/login', {
        policy_number: policyNumber,
        verification_code: verificationCode,
        use_exact_number: useExactNumber,
        claims_payment_id: claimsPaymentID,
      })
      .then((res) => {
        console.log('API res[login]:', res)
        if (res.status === 200) {
          const user = {
            userID: res.data.user_id,
            policyID: res.data.policy_id,
            token: res.data.token,
            isVendor: res.data.is_vendor,
            isThirdParty: res.data.is_third_party,
            claimsPaymentID: res.data.claims_payment_id || null,
          }

          Store.setUser(user)

          return {
            user: user,
            err: null,
          }
        }

        return { user: null, err: Constants.genericNetworkError() }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[login]:', error)
          return { user: null, err: error }
        }

        console.log('API err[login]:', error.response.data)
        return { user: null, err: error.response.data }
      })

    return response
  }

  getClaims = async () => {
    const response = await API()
      .get('/v3/claims')
      .then((res) => {
        console.log('API res[getClaims]:', res)
        if (res.status === 200) {
          return { claims: res.data, err: null }
        }

        return { claims: null, err: Constants.genericNetworkError() }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[getClaims]:', error)
          return { claims: null, err: error }
        }

        console.log('API err[getClaims]:', error.response.data)
        return { claims: null, err: error.response.data }
      })

    return response
  }

  getClaimByID = async (claimID) => {
    const response = await API()
      .get(`/v3/claims/${claimID}`)
      .then((res) => {
        console.log('API res[getClaimByID]:', res)
        if (res.status === 200) {
          return { claim: res.data, err: null }
        }

        return { claim: null, err: Constants.genericNetworkError() }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[getClaimByID]:', error)
          return { claim: null, err: error }
        }

        console.log('API err[getClaimByID]:', error.response.data)
        return { claim: null, err: error.response.data }
      })

    return response
  }

  createClaim = async (claimRequest) => {
    // clean up
    if (claimRequest.driver_is_primary) {
      claimRequest.driver_id = null
    }
    for (const property in claimRequest) {
      if (claimRequest[property] === '') {
        claimRequest[property] = null
      }
    }
    for (const property in claimRequest.extended_info) {
      if (claimRequest.extended_info[property] === '') {
        claimRequest.extended_info[property] = null
      }
    }

    const response = await API()
      .post('/v3/claims', claimRequest)
      .then((res) => {
        console.log('API res[createClaim]:', res)
        if (res.status === 200) {
          return {
            claim: res.data,
            err: null,
          }
        }

        return {
          claim: null,
          err: Constants.genericNetworkError(),
        }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[createClaim]:', error)
          return { claim: null, err: error }
        }

        console.log('API err[createClaim]:', error.response.data)
        return { claim: null, err: error.response.data }
      })

    return response
  }

  // returns { claim, err }
  createClaimThirdParty = async (claimRequest, reporterType, representativeInfo,claimantInfo) => {
    //
    // Filed by Go Representative:
    //
    // {
    //     "filed_by_go_representative": true,
    //     "representative_info": {
    //          "type": "Attorney", // Possible values are Attorney, Agent
    //          "name": "Julia Bobberson",
    //          "email": "julia.bobberson@gmail.com",
    //          "phone_number": "6509998888"
    //     },
    //     "police_reports": { // Police Reports will be stored in claims_documents and claims_document_files, refer structure here - https://github.com/gomaps/lib/blob/ditaya/third-party-claims-apis/api/claims_documents.go#L69
    //        "type": "Police Report",
    //        "files": [ // this is specified as an array since the front end allows multiple files to be uploaded
    //              {
    //                  "name": "Sample Police Accident Report.pdf",
    //                  "mime_type": "application/pdf",
    //                  "data": "" // base 64 encoded value of the police report file data
    //              }
    //         ]
    //     }
    // }
    //
    // Filed by Third Party:
    //
    // {
    //     "filed_by_third_party": true,
    //     "representative_info": {
    //          "type": "Claimant", // Possible values are Claimant, Attorney, Agent, Insurance Carrier
    //          "name": "Julia Bobberson",
    //          "email": "julia.bobberson@gmail.com",
    //          "phone_number": "6509998888",
    //          "vin": "WAUAFAFL4FN022392",
    //          "insurance_company": "Geico",
    //          "dl_state": "TX",
    //          "dl_number": "12345678"
    //      },
    //      "police_reports": { // Police Reports will be stored in claims_documents and claims_document_files, refer structure here - https://github.com/gomaps/lib/blob/ditaya/third-party-claims-apis/api/claims_documents.go#L69
    //        "type": "Police Report",
    //        "files": [ // this is specified as an array since the front end allows multiple files to be uploaded
    //              {
    //                  "name": "Sample Police Accident Report.pdf",
    //                  "mime_type": "application/pdf",
    //                  "data": "" // base 64 encoded value of the police report file data
    //              }
    //         ]
    //     }
    // }

    if (reporterType !== 'GO_CUSTOMER_REPRESENTATIVE'
      && reporterType !== 'THIRD_PARTY')
    {
      console.log(`Error: Unsupported reporter type '${reporterType}'`)
      return { 
        claim: null,
        error: "Unsupported reporter type",
      }
    }

    if (claimRequest.driver_is_primary) {
      claimRequest.driver_id = null
    }
    for (const property in claimRequest) {
      if (claimRequest[property] === '') {
        claimRequest[property] = null
      }
    }
    for (const property in claimRequest.extended_info) {
      if (claimRequest.extended_info[property] === '') {
        claimRequest.extended_info[property] = null
      }
    }

    if (reporterType === 'GO_CUSTOMER_REPRESENTATIVE') {
      if (representativeInfo.type !== "Attorney"
        && representativeInfo.type !== "Agent" ) {
          console.log("Error: Unsupported representative type")
          return { 
            claim: null,
            error: "Unsupported representative type",
          }
      }
      claimRequest.filed_by_go_representative = true;
      claimRequest.representative_info = {
        type: representativeInfo.type,
        name: representativeInfo.name,
        email: representativeInfo.email,
        phone_number: representativeInfo.phone,
      }
    } else {
      claimRequest.filed_by_third_party = true;
      claimRequest.representative_info = {
        type: representativeInfo.type,
        name: representativeInfo.name,
        email: representativeInfo.email,
        phone_number: representativeInfo.phone,
        vin: claimantInfo.vin,
        insurance_company: claimantInfo.insuranceCompany,
        dl_state: claimantInfo.driversLicenseState,
        dl_number: claimantInfo.driversLicenseNumber,
      }
    }

    const response = await API()
      .post('/v3/claims/third_party', claimRequest)
      .then((res) => {
        console.log('API res[createClaimThirdParty]:', res)
        if (res.status === 200) {
          return {
            claim: res.data,
            err: null,
          }
        }

        return {
          claim: null,
          err: Constants.genericNetworkError(),
        }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[createClaimThirdParty]:', error)
          return { claim: null, err: error }
        }

        console.log('API err[createClaimThirdParty]:', error.response.data)
        return { claim: null, err: error.response.data }
      })

    return response
  }

  createExtendedInfo = async (claimID, extendedInfo) => {
    for (const property in extendedInfo) {
      if (extendedInfo[property] === '') {
        extendedInfo[property] = null
      }
    }

    const response = await API()
      .post(`/v3/claims/${claimID}/extended_info`, { ...extendedInfo })
      .then((res) => {
        console.log('API res[createExtendedInfo]:', res)
        if (res.status === 200) {
          return { extendedInfo: res.data, err: null }
        }

        return {
          extendedInfo: null,
          err: Constants.genericNetworkError(),
        }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[createExtendedInfo]:', error)
          return { extendedInfo: null, err: error }
        }

        console.log('API err[createExtendedInfo]:', error.response.data)
        return { extendedInfo: null, err: error.response.data }
      })

    return response
  }

  getClaimDocuments = async (claimID) => {
    const response = await API()
      .get(`/v3/claims/${claimID}/documents`)
      .then((res) => {
        console.log('API res[getClaimDocuments]:', res)
        if (res.status === 200) {
          return { documents: res.data.documents, err: null }
        }

        return { documents: [], err: Constants.genericNetworkError() }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[getClaimDocuments]:', error)
          return { documents: [], err: error }
        }

        console.log('API err[getClaimDocuments]:', error.response.data)
        return { documents: [], err: error.response.data }
      })

    return response
  }

  uploadClaimDocuments = async (claimID, type, files) => {
    const err = await API()
      .post(`/v3/claims/${claimID}/documents`, { type, files })
      .then((res) => {
        if (res.status === 200) {
          return null
        }

        return Constants.genericNetworkError()
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[uploadClaimDocuments]:', error)
          return error
        }

        console.log('API err[uploadClaimDocuments]:', error.response.data)
        return error.response.data
      })

    return err
  }
}

export default new ClaimModel()
