import '../styles/VehicleVerification.css'
import videoCamIcon from '../assets/vidcam.svg'
import { base64Encode, objectURL } from '../utils/Files'
import { Label } from './Label'
import LoadingIndicator from '../components/LoadingIndicator'
import UserModel from '../models/UserModel'
import React, { useState, useEffect } from 'react'
import Utils from '../utils/Utils'
import FileUpload, { FilePreview } from './FileUpload'

const VehicleVerification = ({ userID, active, onSubmit, style }) => {
  const [loading, setLoading] = useState(true)
  const [hasUWRequest, setHasUWRequest] = useState(false)
  const [vehicles, setVehicles] = useState([])
  const [hasAllPhotos, setHasAllPhotos] = useState(false)
  const [uploadingVehicleID, setUploadingVehicleID] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const fetchVehiclePhotos = async () => {
    const { hasUWRequest, vehicles, err } = await UserModel.getVehiclePhotos(
      userID
    )

    setUploadingVehicleID('')

    if (err) {
      console.log('error getting user:', err)
      setLoading(false)
      return
    }

    if (hasUWRequest) {
      setHasUWRequest(true)
    } else {
      setHasUWRequest(false)
    }

    if (vehicles) {
      for (let i = 0; i < vehicles.length; i++) {
        for (let j = 0; j < vehicles[i].photos.length; j++) {
          let photo = vehicles[i].photos[j]

          const objURL = await objectURL(photo.photo_url)

          photo = {
            ...photo,
            imageSrc: objURL,
          }

          vehicles[i].photos[j] = photo
        }
      }

      setVehicles(vehicles)

      const withoutPhotos = vehicles.some(
        (vehicle) => vehicle.photos.length === 0
      )

      if (withoutPhotos) {
        setHasAllPhotos(false)
      } else {
        setHasAllPhotos(true)
      }
    }

    setLoading(false)
  }

  useEffect(() => {
    fetchVehiclePhotos()
    // eslint-disable-next-line
  }, [])

  const handlePhotoUpload = async (files, id) => {
    setUploadingVehicleID(id)

    const file = files[0]
    console.log(`FileName: ${file.name} Type: ${file.type}`)

    const { data, err } = await base64Encode(file)
    if (err) {
      setUploadingVehicleID('')
      console.log('base64Encode err:', err)
      alert('Failed to upload video')
      return
    }

    const uploadErr = await UserModel.uploadVehiclePhoto(
      userID,
      id,
      data,
      file.type,
      true
    )
    if (uploadErr) {
      setUploadingVehicleID('')
      console.log('error uploading vehicle video:', err)
      alert('Failed to upload video')
      return
    }

    fetchVehiclePhotos()
  }

  const handlePhotoDelete = async (id) => {
    const err = await UserModel.deleteVehiclePhoto(userID, id)
    if (err) {
      console.error('failed to delete video:', err)
      alert('Failed to delete video')
      return
    }

    fetchVehiclePhotos()
  }

  const handlePhotosSubmit = async () => {
    setSubmitting(true)
    const err = await UserModel.submitVehiclePhotos(
      userID,
      'vehicle_verification'
    )
    if (err) {
      setSubmitting(false)
      console.error('failed to submit videos:', err)
      alert('Failed to submit videos')
      return
    }

    fetchVehiclePhotos()
    setSubmitting(false)
    onSubmit(true)
  }

  return (
    <div
      className={
        active ? 'vehicle-verification active' : 'vehicle-verification'
      }
      style={style}
    >
      <h3 className="heading">Car Verification</h3>

      {loading ? (
        <LoadingIndicator text="loading..." />
      ) : (
        <div>
          <p>
            Take 360-degree videos of the exterior of your cars. Once uploaded,
            your policy will be ready to purchase.
          </p>

          <div className="photos-container">
            <Label icon={videoCamIcon} text="Video" />

            {vehicles.map((vehicle, i) => {
              const photos = vehicle.photos

              return (
                <div key={i} className="photos-item">
                  <h4>{Utils.getVehicleTitle(vehicle)}</h4>

                  {photos.map((photo) => (
                    <FilePreview
                      key={photo.id}
                      id={photo.id}
                      imageURL={photo.imageSrc}
                      text={photo.file_display_name}
                      onDelete={handlePhotoDelete}
                    />
                  ))}

                  {photos.length === 0 && (
                    <FileUpload
                      id={vehicle.id}
                      buttonText={'Take Video'}
                      fileTypes={'.mov,.mp4'}
                      useCamera={true}
                      isUploading={uploadingVehicleID === vehicle.id}
                      onSelect={handlePhotoUpload}
                    />
                  )}
                </div>
              )
            })}

            {!hasUWRequest && (
              <button
                className="primary"
                disabled={!hasAllPhotos || submitting}
                onClick={handlePhotosSubmit}
              >
                {submitting ? 'Submitting photos...' : 'Submit'}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default VehicleVerification
