class Zendesk {
  setup = (user) => {
    if (!user) {
      return
    }
    const email = user.email
    if (window.zE) {
      window.zE('webWidget', 'identify', {
        email: email,
      })
    }
    window.zESettings = {
      webWidget: {
        chat: {
          menuOptions: {
            emailTranscript: false,
          },
        },
        contactForm: {
          fields: [{ id: 'email', prefill: { '*': user.email } }],
        },
      },
    }
  }
}

export default new Zendesk()
