import './styles/App.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute'
import { AuthProvider } from './context/AuthContext'
import Header from './components/Header'
import LicenseInfo from './pages/LicenseInfo'
import Cars from './pages/Cars'
import Drivers from './pages/Drivers'
import Login from './pages/Login'
import Verify from './pages/Verify'
import Coverages from './pages/Coverages'
import Quote from './pages/Quote'
import Policy from './pages/Policy'
import FinalizeQuote from './pages/FinalizeQuote'
import FinalizePolicy from './pages/FinalizePolicy'
import BrokerQuote from './pages/BrokerQuote'
import BrokerQuoteVerify from './pages/BrokerQuoteVerify'
import AppFirst from './pages/AppFirst'
import PolicyAuth from './pages/PolicyAuth'
import ClaimsWhoAreYou from './pages/claims.app/ClaimsWhoAreYou'
import ClaimsStartClaim from './pages/claims.app/ClaimsStartClaim'
import ClaimsList from './pages/claims.app/ClaimsList'
import ClaimsYourInfo from './pages/claims.app/ClaimsYourInfo'
import ClaimsNewClaim from './pages/claims.app/ClaimsNewClaim'
import ClaimsSubmissionSent from './pages/claims.app/ClaimsSubmissionSent'
import ClaimDetails from './pages/claims.app/ClaimDetails'
import ClaimsPay from './pages/claimspay.app/ClaimsPay'
import ClaimsPayVerify from './pages/claimspay.app/ClaimsPayVerify'
import ClaimsPayVerifyBank from './pages/claimspay.app/ClaimsPayVerifyBank'
import ClaimsPayNoPending from './pages/claimspay.app/ClaimsPayNoPending'
import ClaimsPaySuccess from './pages/claimspay.app/ClaimsPaySuccess'
import ClaimsPaySchedule from './pages/claimspay.app/ClaimsPaySchedule'
import ClaimsPayLicense from './pages/claimspay.app/ClaimsPayLicense'
import ClaimsPayEIN from './pages/claimspay.app/ClaimsPayEIN'
import ClaimsGoCloud from './pages/claims.app/ClaimsGoCloud'
import ClaimsRepair from './pages/claims.app/ClaimsRepair'
import Embark from './pages/claims.app/Embark'
import { Verify as PolicyVerify, RenewalApproval } from './pages/renewals.app'
import {
  SubrogationClaim,
  SubrogationStatus,
  DemandPacketViewer,
} from './pages/subrogation.app'
import {
  Intro as VendorIntro,
  Verify as VendorVerify,
  Register as VendorRegister,
  LicenseCapture as VendorLicenseCapture,
  SelectBank as VendorSelectBank,
  VerifyBank as VendorVerifyBank,
} from './pages/vendor.app'
import { UploadsLogin, Uploads } from './pages/uploads.app'
import RenewalConfirmation from './pages/renewals.app/RenewalConfirmation'

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Router>
          <Header />
          <Switch>
            <Route path="/login" exact component={Login} />
            <Route path="/verify" exact component={Verify} />
            <Route path="/claims" exact component={ClaimsWhoAreYou} />
            <Route
              path="/subrogation/:demandID/demand_packets"
              exact
              component={DemandPacketViewer}
            />
            <Route
              path="/subrogation/:demandID/status"
              exact
              component={SubrogationStatus}
            />
            <Route
              path="/subrogation/:demandID"
              exact
              component={SubrogationClaim}
            />
            <Route exact path="/business" component={VendorIntro} />
            <Route exact path="/business/verify" component={VendorVerify} />
            <Route exact path="/business/register" component={VendorRegister} />
            <Route exact path="/business/bank" component={VendorSelectBank} />
            <Route
              exact
              path="/business/verify-bank"
              component={VendorVerifyBank}
            />
            <Route
              exact
              path="/business/license"
              component={VendorLicenseCapture}
            />

            <PrivateRoute path="/app-first/:userID" component={AppFirst} />
            <PrivateRoute path="/app-first" component={AppFirst} />
            <PrivateRoute path="/" exact component={LicenseInfo} />
            <PrivateRoute path="/info" component={LicenseInfo} />
            <PrivateRoute path="/drivers" component={Drivers} />
            <PrivateRoute path="/cars" component={Cars} />
            <PrivateRoute path="/coverages" component={Coverages} />
            <Route path="/quote/:user_id" component={Quote} />
            <PrivateRoute path="/quote" component={Quote} />
            <PrivateRoute path="/quote-verify" component={BrokerQuoteVerify} />
            <PrivateRoute path="/policy-auth/:user_id" component={PolicyAuth} />
            <PrivateRoute path="/policy" component={Policy} />

            <Route
              path="/renewals_confirmation/:user_id"
              exact
              component={RenewalConfirmation}
            />
            {/* renewals approval routes */}
            <Route path="/renewals/verify" exact component={PolicyVerify} />
            <PrivateRoute
              path="/renewals/approve"
              component={RenewalApproval}
            />
            {/* end of renewals approval routes */}

            {/* claims vendor routes  */}

            {/* end of claims vendor routes  */}

            {/* claims routes */}
            <Route path="/claims/start" component={ClaimsStartClaim} />
            <Route path="/claims/list" component={ClaimsList} />
            <Route path="/claims/your-info" component={ClaimsYourInfo} />
            <Route path="/claims/new" component={ClaimsNewClaim} />
            <Route path="/claims/gocloud/:claimID" component={ClaimsGoCloud} />
            <Route path="/claims/submission-sent" component={ClaimsSubmissionSent} />
            <PrivateRoute path="/claims/embark" component={Embark} />
            <PrivateRoute path="/claims/edit/:claimID" component={ClaimsNewClaim} />
            <PrivateRoute
              path="/claims/repairs/:claimID"
              component={ClaimsRepair}
            />
            <PrivateRoute path="/claims/:claim_id" component={ClaimDetails} />
            {/* end of claims routes */}

            {/* claims pay routes */}
            <PrivateRoute
              path="/claims-pay/no-pending"
              component={ClaimsPayNoPending}
            />
            <PrivateRoute
              path="/claims-pay/success"
              component={ClaimsPaySuccess}
            />
            <PrivateRoute
              path="/claims-pay/ein/:claims_payment_id"
              component={ClaimsPayEIN}
            />
            <PrivateRoute
              path="/claims-pay/license/:claims_payment_id"
              component={ClaimsPayLicense}
            />

            <PrivateRoute
              path="/claims-pay/verify-bank/:claims_payment_id"
              component={ClaimsPayVerifyBank}
            />
            <PrivateRoute
              path="/claims-pay/schedule/:claims_payment_id"
              component={ClaimsPaySchedule}
            />
            <Route path="/claims-pay/verify" component={ClaimsPayVerify} />
            <Route path="/claims-pay" component={ClaimsPay} />
            {/* end of claims pay routes */}

            {/* Uploads app routes */}
            <Route path="/uploads/verify/:policyID" component={UploadsLogin} />
            <Route path="/uploads/verify" component={UploadsLogin} />
            <PrivateRoute path="/uploads/:userID" exact component={Uploads} />
            {/* End of Uploads app routes */}

            <Route path="/finalize_quote/:user_id" component={FinalizeQuote} />
            <Route
              path="/finalize_profile/:user_id"
              component={FinalizePolicy}
            />
            <Route path="/:share_code" exact component={BrokerQuote} />
          </Switch>
        </Router>
      </AuthProvider>
    </div>
  )
}

export default App
