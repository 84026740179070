import './SubrogationClaim.css'
import { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { CenteredContainer } from '../../components/Container'
import { Label } from '../../components/Label'
import icnDriver from '../../assets/icn-driver.svg'
import icnClaims from '../../assets/icn-claims.svg'
import icnPaid from '../../assets/icn-paid.svg'
import icnStatus from '../../assets/icn-status.svg'
import icnDocs from '../../assets/icn-docs-sm.svg'
import icnAgreement from '../../assets/icn-agreement.svg'
import FileUpload, {
  FileItemVariant,
  FilePreview,
} from '../../components/FileUpload'
import InputRange from '../../components/InputRange'
import Currency from '../../utils/Currency'
import SubrogationModel from '../../models/SubrogationModel'
import LoadingIndicator from '../../components/LoadingIndicator'
import Strings from '../../utils/Strings'
import { SubrogationWaitingResponse } from './SubrogationStatusType'
import { base64Encode } from '../../utils/Files'

const StatusAccept = 'accept'
const StatusAcceptPartial = 'accept_partial'
const StatusDeny = 'deny'

export default function SubrogationClaim() {
  const { demandID } = useParams()
  const history = useHistory()
  const location = useLocation()

  const [isLoading, setIsLoading] = useState(true)
  const [demand, setDemand] = useState(null)
  const [demandAmount, setDemandAmount] = useState(0)
  const [status, setStatus] = useState('')
  const [percentage, setPercentage] = useState(100)
  const [acceptedAmount, setAcceptedAmount] = useState(0)
  const [response, setResponse] = useState('')
  const [goCustomer, setGoCustomer] = useState(null)
  const [files, setFiles] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onStatusChange = (e) => {
    const value = e.target.value
    switch (value) {
      case StatusAccept:
        setAcceptedAmount(demandAmount)
        setPercentage(100)
        break
      case StatusAcceptPartial:
        setAcceptedAmount(Math.round(demandAmount / 2))
        setPercentage(50.0)
        break
      case StatusDeny:
        setAcceptedAmount(0)
        setPercentage(0)
        break
      default:
        break
    }
    if (value === StatusAcceptPartial) {
    }
    setStatus(value)
  }

  const handleAmountPercentage = (e) => {
    const pct = e.target.value
    const pctAmount = Math.round(Number((demandAmount * pct) / 100))
    setAcceptedAmount(pctAmount)
    setPercentage(pct)
  }

  const fetchDemand = async (demandID) => {
    const { status, demand, responses, goCustomer, err } =
      await SubrogationModel.getDemand(demandID)
    if (err) {
      alert('Sorry, there was an error on fetching subrogation demands.')
      return
    }

    if (status !== SubrogationWaitingResponse.label) {
      history.push(`/subrogation/${demandID}/status`)
      return
    }

    setDemand(demand)
    setGoCustomer(goCustomer)

    let amount = demand.amount
    if (responses?.length > 0) {
      const goRenegotiation = responses.find(
        (r) => r.type === 'go_renegotiation'
      )
      if (goRenegotiation) {
        amount = goRenegotiation.amount
      }
    }
    setDemandAmount(amount)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchDemand(demandID)
    // eslint-disable-next-line
  }, [demandID])

  const onClickDemandPacket = (e) => {
    history.push(`/subrogation/${demandID}/demand_packets`, {
      from: location.pathname,
      claimFiles: demand.claim_files || [],
    })
  }

  const isValid = () => {
    if (!status) {
      return false
    }

    if (status !== StatusAccept && !response) {
      return false
    }

    if (isSubmitting) {
      return false
    }

    return true
  }

  const onSelectFile = async (files) => {
    if (files.length === 0) {
      return
    }

    let fileDataList = []
    for (const file of files) {
      const { data, err } = await base64Encode(file)
      if (err) {
        alert('Error processing file.')
        return
      }

      let previewURL = ''
      if (!file.type.includes('application/') && !file.type.includes('text/')) {
        previewURL = URL.createObjectURL(file)
      }

      const fileData = {
        name: file.name,
        mime_type: file.type,
        url: previewURL,
        data: data,
      }

      fileDataList.push(fileData)
    }

    setFiles((prevFiles) => [...prevFiles, ...fileDataList])
  }

  const onDeleteFile = (id, fileName) => {
    let filteredFiles = files.filter((f) => f.name !== fileName)
    setFiles([...filteredFiles])
  }

  const onSubmit = async () => {
    setIsSubmitting(true)
    const err = await SubrogationModel.demandResponse(
      demandID,
      acceptedAmount,
      percentage,
      response,
      files
    )
    if (err) {
      alert(`Sorry, an error occured while submitting, please try again later`)
      setIsSubmitting(false)
      return
    }

    history.push(`/subrogation/${demandID}/status`)
  }

  return (
    <div className="container">
      <h1 className="title">Subrogated Claim</h1>
      <CenteredContainer>
        {isLoading && <LoadingIndicator text={'Loading...'} />}

        {!isLoading && (
          <>
            <div className="claim-details-list">
              <div className="item">
                <Label icon={icnDriver} text="YOUR CUSTOMER" iconSize={25} />
                <div className="item-details">
                  <div className="title">
                    {Strings.titleCase(
                      `${demand.policy_holder_first_name} ${demand.policy_holder_last_name}`
                    )}
                  </div>
                </div>
              </div>

              <div className="item">
                <Label
                  icon={icnClaims}
                  text="YOUR POLICY NUMBER"
                  iconSize={25}
                />
                <div className="item-details">
                  <div className="title">#{demand.policy_number}</div>
                </div>
              </div>

              <div className="item">
                <Label
                  icon={icnClaims}
                  text="YOUR CLAIM NUMBER"
                  iconSize={25}
                />
                <div className="item-details">
                  <div className="title">#{demand.claim_number}</div>
                </div>
              </div>

              <div className="item">
                <Label icon={icnDriver} text="Go CUSTOMER" iconSize={25} />
                <div className="item-details">
                  <div className="title">
                    {Strings.titleCase(`${goCustomer.name}`)}
                  </div>
                </div>
              </div>

              <div className="item">
                <Label icon={icnClaims} text="GO POLICY NUMBER" iconSize={25} />
                <div className="item-details">
                  <div className="title">#{goCustomer.policy_number}</div>
                </div>
              </div>

              <div className="item">
                <Label icon={icnClaims} text="GO CLAIM NUMBER" iconSize={25} />
                <div className="item-details">
                  <div className="title">#{goCustomer.claim_number}</div>
                </div>
              </div>

              <div className="item">
                <Label icon={icnPaid} text="AMOUNT OF DEMAND" iconSize={25} />
                <div className="item-details">
                  <div className="title">
                    ${Currency.format(demandAmount, true)}
                  </div>
                  <button className="link" onClick={onClickDemandPacket}>
                    View Demand Packet
                  </button>
                </div>
              </div>

              <div className="item">
                <Label icon={icnStatus} text="STATUS" iconSize={25} />
                <select
                  className={!status ? 'placeholder' : ''}
                  value={status}
                  onChange={onStatusChange}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value={StatusAccept}>Accept</option>
                  <option value={StatusAcceptPartial}>Partially Accept</option>
                  <option value={StatusDeny}>Deny</option>
                </select>
              </div>

              {status === StatusAcceptPartial && (
                <div className="item">
                  <Label
                    icon={icnPaid}
                    text="AMOUNT BEING ACCEPTED"
                    iconSize={25}
                  />
                  <div className="item-details">
                    <div className="title">
                      ${Currency.format(acceptedAmount, true)}
                    </div>
                    <InputRange
                      value={percentage}
                      isPercentage={true}
                      min={0.1}
                      max={99.9}
                      step={0.1}
                      onChange={handleAmountPercentage}
                      style={{ marginTop: '8px' }}
                    />
                  </div>
                </div>
              )}

              {status !== StatusAccept && (
                <div className="item">
                  <Label icon={icnAgreement} text="REASONING" iconSize={25} />
                  <textarea
                    value={response}
                    onChange={(e) => setResponse(e.target.value)}
                  ></textarea>
                </div>
              )}

              <div className="item">
                <Label icon={icnDocs} text="DOCUMENTS" iconSize={25} />

                {files.map((file, i) => (
                  <FilePreview
                    key={i}
                    text={file.name}
                    imageURL={file.url}
                    callbackData={file.name}
                    showCheckmark={false}
                    variant={FileItemVariant.LARGE}
                    onDelete={onDeleteFile}
                  />
                ))}

                <FileUpload
                  variant={FileItemVariant.LARGE}
                  text="Drag and drop document"
                  buttonText="Choose File"
                  fileTypes={'.pdf,.png,.jpeg,.txt,.rtf,.doc,.docx'}
                  allowMultiple={true}
                  onSelect={onSelectFile}
                />
              </div>
              <button
                className="primary"
                disabled={!isValid()}
                onClick={onSubmit}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </>
        )}
      </CenteredContainer>
    </div>
  )
}
