import '../../styles/ClaimsPay.css'
import { useState, useEffect } from 'react'
import ClaimsPayModel from '../../models/ClaimsPayModel'
import LoadingIndicator from '../../components/LoadingIndicator'
import Currency from '../../utils/Currency'
import Calendar from 'react-calendar'
import closeBtnIcon from '../../assets/close-btn-icon.svg'
import nextIcon from '../../assets/icn-chevron-right-black.svg'
import prevIcon from '../../assets/icn-chevron-left-black.svg'
import Dates from '../../utils/Dates'

export default function ClaimsPaySchedule(props) {
  const claimsPaymentID = props.match.params.claims_payment_id

  const [isLoading, setIsLoading] = useState(true)
  const [errMsg, setErrMsg] = useState('')
  const [amount, setAmount] = useState(0.0)
  const [name, setName] = useState('')
  const [claimNumber, setClaimNumber] = useState('')
  const [selectedDate, setSelectedDate] = useState(null)
  const [unavailableDates, setUnavailableDates] = useState([])
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  let minDate = new Date()
  minDate.setDate(minDate.getDate() + 1)
  minDate.setHours(0, 0, 0, 0)
  const [calenderValue, setCalenderValue] = useState(minDate)
  const [calenderActiveDate, setCalenderActiveDate] = useState(minDate)

  const fetchPaymentSchedules = async (claimsPaymentID) => {
    const { paymentSchedules, err } = await ClaimsPayModel.getPaymentSchedules(
      claimsPaymentID
    )
    if (err) {
      setErrMsg(`There was an error verifying your bank account.`)
      setIsLoading(false)
      return
    }

    if (
      paymentSchedules.claims_payment_id !== claimsPaymentID &&
      paymentSchedules.scheduled_date
    ) {
      props.history.push('/claims-pay/success', { paymentSchedules })
      return
    }

    setAmount(paymentSchedules.amount)
    setName(paymentSchedules.name)
    setClaimNumber(paymentSchedules.claim_number)
    setUnavailableDates(paymentSchedules.unavailable_dates)
    if (paymentSchedules.first_available_date) {
      const firstAvailableDate = Dates.toDate(
        paymentSchedules.first_available_date
      )
      setCalenderValue(firstAvailableDate)
      setCalenderActiveDate(firstAvailableDate)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchPaymentSchedules(claimsPaymentID)
  }, [claimsPaymentID])

  function formatDate(date) {
    const dateOption = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    }

    const dateFormatter = new Intl.DateTimeFormat('en-US', dateOption)

    return dateFormatter.format(date)
  }

  const onClickSelectDate = () => {
    setShowDatePicker(true)
  }

  const onChangeCalender = (date) => {
    setCalenderValue(date)
    setSelectedDate(date)
    setShowDatePicker(false)
  }

  const onClickNextMonth = () => {
    setCalenderActiveDate((activeDate) => {
      let d = new Date(activeDate)
      d.setMonth(d.getMonth() + 1)
      return d
    })
  }

  const onClickPrevMonth = () => {
    setCalenderActiveDate((activeDate) => {
      let d = new Date(activeDate)
      d.setMonth(d.getMonth() - 1)
      return d
    })
  }

  const isCalenderTileDisabled = ({ activeStartDate, date, view }) => {
    if (date.getDay() === 0 || date.getDay() === 6) {
      return true
    }

    if (date < minDate) {
      return true
    }

    const unavailableDate = unavailableDates.find(
      (d) => d === Dates.toString(date)
    )
    if (unavailableDate) {
      return true
    }

    return false
  }

  function isValid() {
    if (!selectedDate) {
      return false
    }

    return true
  }

  const handleTransferFunds = async () => {
    setIsSubmitting(true)
    const { paymentSchedules, err } = await ClaimsPayModel.postPaymentDate(
      claimsPaymentID,
      Dates.toString(selectedDate)
    )
    if (err) {
      setErrMsg(`There was an error scheduling payment.`)
      setIsSubmitting(false)
      return
    }

    setIsSubmitting(false)
    props.history.push('/claims-pay/success', { paymentSchedules })
  }

  return (
    <div className="container claims-pay-summary">
      <h2 className="title">Claim Payment Summary</h2>
      {isLoading && (
        <div className="loading-wrapper">
          <LoadingIndicator
            text="Loading..."
            style={{ justifyContent: 'center' }}
          />
        </div>
      )}

      {!isLoading && (
        <>
          <h3 className="claims-amount">${Currency.format(amount)}</h3>

          <div className="summary-list">
            <div className="row">
              <div className="text">Pay to</div>
              <div className="text">{name}</div>
            </div>

            <div className="row">
              <div className="text">Claim Number</div>
              <div className="text">{claimNumber}</div>
            </div>

            <div className="row">
              <div className="text">Payment Date</div>
              {selectedDate ? (
                <div
                  className="text"
                  style={{ cursor: 'pointer' }}
                  onClick={onClickSelectDate}
                >
                  {formatDate(selectedDate)}
                </div>
              ) : (
                <div className="date-selector-btn" onClick={onClickSelectDate}>
                  Select
                </div>
              )}
            </div>
          </div>

          {errMsg && <div className="claims-pay-error-text">{errMsg}</div>}

          <button
            className="primary"
            disabled={!isValid() || isSubmitting}
            onClick={handleTransferFunds}
          >
            {isSubmitting ? 'Processing...' : 'Transfer Funds'}
          </button>

          {showDatePicker && (
            <div className="modal">
              <div className="date-picker-modal-box">
                <img
                  className="close-btn"
                  src={closeBtnIcon}
                  alt="close button"
                  onClick={() => setShowDatePicker(false)}
                />

                <div className="title">Select a Date</div>

                <nav className="date-picker-nav">
                  <div className="active-month">
                    {Dates.getShortMonthString(calenderActiveDate.getMonth())}{' '}
                    {calenderActiveDate.getFullYear()}
                  </div>
                  <div className="next-prev-btns">
                    <img
                      src={prevIcon}
                      alt="previous button"
                      onClick={onClickPrevMonth}
                    />
                    <img
                      src={nextIcon}
                      alt="next button"
                      onClick={onClickNextMonth}
                    />
                  </div>
                </nav>

                <div className="calender-wrapper">
                  <Calendar
                    locale="en-US"
                    calendarType="US"
                    defaultView="month"
                    onChange={onChangeCalender}
                    value={calenderValue}
                    minDate={minDate}
                    activeStartDate={calenderActiveDate}
                    showNavigation={false}
                    tileDisabled={isCalenderTileDisabled}
                    tileClassName="calender-tile"
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}
