import API from './API'
import Constants from '../models/Constants'

class PriorInsuranceModel {
  getProofOfCoverage = async () => {
    const response = await API()
      .get('v3/prior_insurances/proof_of_coverage')
      .then((res) => {
        console.log('API res[getProofOfCoverage]:', res)
        if (res.status === 200) {
          return {
            lapseOverride: res.data,
            err: null,
          }
        }

        return { lapseOverride: null, err: Constants.genericNetworkError() }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[getProofOfCoverage]:', error)
          return { lapseOverride: null, err: error }
        }

        console.log('API err[getProofOfCoverage]:', error.response.data)
        return { lapseOverride: null, err: error.response.data }
      })

    return response
  }

  uploadProofOfCoverage = async (fileData, mimeType) => {
    const response = await API()
      .post('v3/prior_insurances/proof_of_coverage', {
        file: fileData,
        mime_type: mimeType,
      })
      .then((res) => {
        console.log('API res[uploadProofOfCoverage]:', res)
        if (res.status === 200 || res.status === 201) {
          return {
            lapseOverride: res.data,
            err: null,
          }
        }

        return { lapseOverride: null, err: Constants.genericNetworkError() }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[uploadProofOfCoverage]:', error)
          return { lapseOverride: null, err: error }
        }

        console.log('API err[uploadProofOfCoverage]:', error.response.data)
        return { lapseOverride: null, err: error.response.data }
      })

    return response
  }

  updateProofOfCoverage = async (insurer, premium, limits) => {
    const response = await API()
      .put('v3/prior_insurances/proof_of_coverage', {
        insurer,
        premium,
        limits,
      })
      .then((res) => {
        console.log('API res[updateProofOfCoverage]:', res)
        if (res.status === 200 || res.status === 201) {
          return {
            lapseOverride: res.data,
            err: null,
          }
        }

        return { lapseOverride: null, err: Constants.genericNetworkError() }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[updateProofOfCoverage]:', error)
          return { lapseOverride: null, err: error }
        }

        console.log('API err[updateProofOfCoverage]:', error.response.data)
        return { lapseOverride: null, err: error.response.data }
      })

    return response
  }

  deleteProofOfCoverage = async () => {
    const err = await API()
      .delete('v3/prior_insurances/proof_of_coverage')
      .then((res) => {
        if (res.status === 200) {
          return null
        }

        return Constants.genericNetworkError()
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[deleteProofOfCoverage]:', error)
          return error
        }

        console.log('API err[deleteProofOfCoverage]:', error.response.data)
        return error.response.data
      })

    return err
  }

  getInsurers = async () => {
    const insurers = await API()
      .get('v3/insurers')
      .then((res) => {
        if (res.status === 200) {
          return res.data.insurer_list
        }
      })

    return insurers
  }
}

export default new PriorInsuranceModel()
