import './InputRange.css'
import { useState } from 'react'

export default function InputRange({
  value = 0,
  onChange,
  min = 0,
  max = 100,
  step = 1,
  isPercentage = false,
  style,
  className,
}) {
  const [outputStyle, setOutputStyle] = useState({
    left: `calc(${value}% + (${8 - value * 0.35}px))`,
  })

  let classes = 'range-wrap'
  if (className) {
    classes += className
  }

  const handleOnChange = (e) => {
    if (onChange instanceof Function) {
      onChange(e)

      const range = e.target
      const val = range.value
      const min = range.min ? range.min : 0
      const max = range.max ? range.max : 100
      const leftPos = Number(((val - min) * 100) / (max - min))

      setOutputStyle({
        left: `calc(${leftPos}% + (${8 - leftPos * 0.35}px))`,
      })
    }
  }

  return (
    <div className={classes} style={style}>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleOnChange}
      />
      <output className="output" style={outputStyle}>
        {value}
        {isPercentage ? '%' : ''}
      </output>
    </div>
  )
}
