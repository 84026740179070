import '../../styles/ClaimsGoCloud.css'
import { useParams, useLocation } from 'react-router-dom'
import BackButton from '../../components/BackButton'
import { CenteredContainer } from '../../components/Container'
import { Label } from '../../components/Label'
import Store from '../../models/Store'
import ClaimModel from '../../models/ClaimModel'
import goCloudImg from '../../assets/icn-claims-go-cloud.svg'
import claimIcon from '../../assets/icn-agreement.svg'
import filesIcon from '../../assets/icn-docs-sm.svg'
import uploadFileIcon from '../../assets/icn-upload-file.svg'
import placeholderImg from '../../assets/placeholder-img.svg'
import { useEffect, useState } from 'react'
import FileUpload, {
  FileItemVariant,
  FilePreview,
} from '../../components/FileUpload'
import { base64Encode } from '../../utils/Files'
import LoadingIndicator from '../../components/LoadingIndicator'
import Constants from '../../models/Constants'
import { set } from 'lodash'

export default function ClaimsGoCloud() {
  const { claimID } = useParams()
  const location = useLocation()
  //const claimState = location.state?.claim || null

  const ClaimsDocumentTypes = {
    RentalReceipt: 'Rental Receipt',
    MedicalReceipt: 'Medical Receipt',
    PoliceReport: 'Police Report',
    LetterOfExperience: 'Letter Of Experience',
    Other: 'Other',
  }
  const acceptedFileTypes = [
    '.jpeg',
    '.jpg',
    '.png',
    '.pdf',
    '.doc',
    '.docx',
    '.mov',
    '.html',
    '.rtf',
    '.mp4',
    '.3gpp',
    '.xls',
    '.xlsx',
  ]

  const [isLoading, setIsLoading] = useState(true)
  const [claim, setClaim] = useState(location.state?.claim || null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [documents, setDocuments] = useState([])
  const [selectedDocType, setSelectedDocType] = useState('')
  const [selectedFiles, setSelectedFiles] = useState([])

  const handleFileOnSelect = async (files) => {
    if (files.length === 0) {
      return
    }

    let documents = []
    for (const file of files) {
      const { data, err } = await base64Encode(file)
      if (err) {
        alert('Error processing file.')
        return
      }

      let previewURL = ''
      if (
        !file.type.includes('video/') &&
        !file.type.includes('application/') &&
        !file.type.includes('text/')
      ) {
        previewURL = URL.createObjectURL(file)
      }

      const document = {
        name: file.name,
        mime_type: file.type,
        url: previewURL,
        data: data,
      }

      documents.push(document)
    }

    setSelectedFiles((prevDocs) => [...prevDocs, ...documents])
  }

  const handleFileOnDelete = (id, fileName) => {
    let filteredFiles = selectedFiles.filter(
      (document) => document.name !== fileName
    )
    setSelectedFiles([...filteredFiles])
  }

  const fetchDocuments = async (claimID) => {
    if (!claim) {
      const { claim, err } = await ClaimModel.getClaimByID(claimID)
      if (err) {
        alert(Constants.genericNetworkError())
        return
      }
      setClaim(claim)
    }
    const { documents, err } = await ClaimModel.getClaimDocuments(claimID)
    if (err) {
      alert(`Error fetching documents. Please try again.`)
      return
    }
    setDocuments(documents)
    setIsLoading(false)
  }

  const handleOnSubmit = async () => {
    setIsSubmitting(true)

    const err = await ClaimModel.uploadClaimDocuments(
      claimID,
      selectedDocType,
      selectedFiles
    )
    if (err) {
      alert('Error uploading to Go Cloud. Please try again.')
      setIsSubmitting(false)
      return
    }

    if (Store.getClaimReporterType() === 'GO_CUSTOMER') {
      await fetchDocuments(claimID)
      setSelectedDocType('')
      setSelectedFiles([])
      setIsSubmitting(false)
    } else {
      window.location ='/claims/submission-sent'
    }
  }

  useEffect(() => {
    if (Store.getClaimReporterType() === 'GO_CUSTOMER') {
      fetchDocuments(claimID)
    }
  }, [claimID])

  if (!claim) {
    return null
  }

  return (
    <div className="container">
      <BackButton to={`/claims/${claimID}`} text="Claim Summary" />

      <CenteredContainer className="claims-go-cloud-container">
        <header className="claims-go-cloud-header">
          <img src={goCloudImg} alt="cloud" />
          <h2 className="display">Go Cloud</h2>
        </header>

        <p className="claims-go-cloud-text">
          Upload encrypted and secure files for your claim. Whether it’s
          reimbursement, a helpful file, or just another photo to share, Go
          Cloud keeps everything light and fast.
        </p>

        <ul className="claim-summary-list">
          <li>
            <img className="icon" src={claimIcon} alt="" />
            <div>
              <p className="item">CLAIM</p>
              <p>#{claim.claim_number}</p>
            </div>
          </li>
        </ul>

        {isLoading && (
          <div style={{ margin: '30px 0' }}>
            <LoadingIndicator text={'Loading...'} />
          </div>
        )}

        {documents.map((document, i) => (
          <GoCloudFileGroup document={document} key={i} />
        ))}

        <div className="add-file-widget">
          <h4 className="heading">Add file</h4>

          <div className="form-row">
            <div className="form-column">
              <Label icon={filesIcon} text="FILE TYPE" />
              <select
                className={selectedDocType || 'placeholder'}
                value={selectedDocType}
                onChange={(e) => setSelectedDocType(e.target.value)}
              >
                <option value={''} disabled>
                  Select
                </option>
                {Object.keys(ClaimsDocumentTypes).map((t, i) => (
                  <option key={i}>{ClaimsDocumentTypes[t]}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="form-row">
            <div className="form-column">
              <Label icon={uploadFileIcon} text="FILES" />
              {selectedFiles.map((document, i) => (
                <FilePreview
                  key={i}
                  text={document.name}
                  imageURL={document.url}
                  callbackData={document.name}
                  showCheckmark={false}
                  variant={FileItemVariant.LARGE}
                  onDelete={handleFileOnDelete}
                />
              ))}
              <FileUpload
                text={'Drag and drop file'}
                buttonText={'Choose File'}
                variant={FileItemVariant.LARGE}
                allowMultiple={true}
                fileTypes={acceptedFileTypes.join()}
                isUploading={false}
                onSelect={handleFileOnSelect}
              />
            </div>
          </div>

          <button
            className="primary"
            disabled={
              !selectedDocType || selectedFiles.length === 0 || isSubmitting
            }
            onClick={handleOnSubmit}
          >
            {isSubmitting ? 'Uploading...' : 'Upload'}
          </button>
        </div>
      </CenteredContainer>
    </div>
  )
}

function GoCloudFileGroup({ document }) {
  const filesCount = document.files.length
  const createdAt = new Date(document.created_at)
  const dateOptions = {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
  }
  const formattedDate = new Intl.DateTimeFormat('en-US', dateOptions).format(
    createdAt
  )

  let previewURL = ''
  const previewFile = document.files[0]
  if (
    !previewFile.mime_type.includes('application/') &&
    !previewFile.mime_type.includes('text/')
  ) {
    previewURL = previewFile.file_url
  }

  if (previewFile.mime_type.includes('application/')) {
    previewURL = previewFile.thumbnail_url || ''
  }

  return (
    <div className="go-cloud-file-group">
      {previewFile.mime_type.includes('video/') ? (
        <video autoPlay={false}>
          {/* #t=0.01 hack to show thumbnail on iOS Safari */}
          <source src={previewURL + `#t=0.01`} />
        </video>
      ) : (
        <img
          src={previewURL || placeholderImg}
          alt="rental receipt files"
          loading="lazy"
        />
      )}

      <div className="group-details">
        <h3>{document.type}</h3>
        <p>
          {filesCount} {filesCount === 1 ? `file` : `files`} — {formattedDate}
        </p>
      </div>
    </div>
  )
}
