const IncidentTypes = [
  { label: 'Glass Only', value: 'glass_only' },
  { label: 'Accident or Collision', value: 'collision' },
  { label: 'Weather Related', value: 'weather' },
  { label: 'Animal', value: 'animal' },
  { label: 'Unoccupied Vehicle', value: 'unoccupied' },
]

// GlassIncidentTypes is a detail type for IncidentType - glass_only
const GlassIncidentTypes = [
  { label: 'Windshield Only', value: 'windshield_only' },
  { label: 'Side or Rear', value: 'side_or_rear' },
  { label: 'Both', value: 'both' },
]

// CollisionIncidentTypes is a detail type for IncidentType - collision
const CollisionIncidentTypes = [
  { label: 'Rear End', value: 'rear_end' },
  { label: 'Parking', value: 'parking' },
  { label: 'Backing Up', value: 'backing' },
  { label: 'Hit and Run', value: 'hit_and_run' },
  { label: 'Lane Change', value: 'lane_change' },
  { label: 'Intersection', value: 'intersection' },
  { label: 'Car Entered the Roadway', value: 'entering_roadway' },
  { label: 'Single Car', value: 'single_vehicle' },
]

// VehicleMovingTypes is sub detail type for CollisionIncidentTypes - parking/lane_change/entering_roadway
const VehicleMovingTypes = [
  { label: 'My car', value: 'insured' },
  { label: 'The other car only', value: 'other' },
  { label: 'Both cars', value: 'both' },
]

// VehiclePositionTypes is sub detail type for CollisionIncidentType - rear_end
const VehiclePositionTypes = [
  { label: 'Front', value: 'front' },
  { label: 'Middle', value: 'middle' },
  { label: 'Rear', value: 'rear' },
]

// SingleVehicleTypes is sub detail type for CollisionIncidentType - single_vehicle
const SingleVehicleTypes = [
  { label: 'Something fell on my car', value: 'object_fell_on_vehicle' },
  { label: 'My car hit a pothole', value: 'vehicle_hit_pothole' },
  { label: 'My car hit an object', value: 'vehicle_hit_object' },
  { label: 'My car went off the road', value: 'vehicle_went_off_road' },
]

// WeatherIncidentTypes is a detail type for IncidentType - weather
const WeatherIncidentTypes = [
  { label: 'Hailstorm', value: 'hail' },
  { label: 'Flood', value: 'flood' },
  { label: 'Water damage not from a flood', value: 'non_flood_water_damage' },
  { label: 'Wind, Hurricane, or Tornado', value: 'wind_hurricane_tornado' },
  { label: 'Wet, Snowy, or Icy Road', value: 'wet_snowy_icy_road' },
]

// WetSnowyIcyRoadTypes is sub detail type for WeatherIncidentType - wet_snowy_icy_road
const WetSnowyIcyRoadTypes = [
  {
    label: 'Struck a stationary object',
    value: 'vehicle_hit_stationary_object',
  },
  {
    label: 'Went off road, into a ditch, or rolled over',
    value: 'vehicle_offroad_ditch_rollover',
  },
  { label: 'Struck another car', value: 'vehicle_hit_vehicle' },
]

// AnimalIncidentTypes is a detail type for IncidentType - animal
const AnimalIncidentTypes = [
  { label: 'Struck or struck by an animal', value: 'collided' },
  { label: 'Swerved to avoid an animal', value: 'swerved' },
  { label: 'Damaged while parked', value: 'parked' },
]

// UnoccupiedIncidentTypes is a detail type for IncidentType - unoccupied
const UnoccupiedIncidentTypes = [
  { label: 'Somehing hit the car', value: 'object_hit_vehicle' },
  { label: 'Something fell on the car', value: 'object_fell_on_vehicle' },
  { label: 'The car was vandalized', value: 'vehicle_vandalized' },
  { label: 'The entire car was stolen', value: 'vehicle_stolen' },
  { label: 'Parts of the car were stolen', value: 'vehicle_parts_stolen' },
  { label: 'The car rolled away', value: 'vehicle_rolled_away' },
  {
    label: 'The car was damaged by an animal',
    value: 'vehicle_damaged_by_animal',
  },
]

// WetSnowyIcyRoadTypes is sub detail type for UnoccupiedIncidentType - vehicle_parts_stolen
const VehiclePartsStolenTypes = [
  { label: 'Stereo', value: 'stereo' },
  { label: 'Catalytic Converter', value: 'catalytic_converter' },
  { label: 'Parts other than Stereo', value: 'other' },
  { label: 'Stereo and other items', value: 'stereo_plus' },
  { label: 'Attempted theft but nothing was stolen', value: 'attempted' },
]

// VehicleRolledAwayTypes is sub detail type for UnoccupiedIncidentType - vehicle_rolled_away
const VehicleRolledAwayTypes = [
  { label: 'The car struck another car', value: 'vehicle' },
  {
    label: 'The car struck property that was not a car',
    value: 'non_vehicle_property',
  },
  { label: 'The car struck multiple objects', value: 'multiple_objects' },
]

export default function IncidentDetailTypesFor(incidentType) {
  switch (incidentType) {
    case 'glass_only':
      return GlassIncidentTypes
    case 'collision':
      return CollisionIncidentTypes
    case 'weather':
      return WeatherIncidentTypes
    case 'animal':
      return AnimalIncidentTypes
    case 'unoccupied':
      return UnoccupiedIncidentTypes
    default:
      return null
  }
}

export {
  IncidentTypes,
  GlassIncidentTypes,
  CollisionIncidentTypes,
  WetSnowyIcyRoadTypes,
  VehiclePartsStolenTypes,
  VehicleRolledAwayTypes,
  VehicleMovingTypes,
  VehiclePositionTypes,
  SingleVehicleTypes,
}
