class Currency {
  format(currency, noLocale = false) {
    // remove any decimals
    currency = Math.round(currency)

    var cents = currency % 100
    if (cents < 10) {
      cents = '0' + cents
    }

    if (noLocale) {
      return Math.floor(currency / 100) + '.' + cents
    }

    return Math.floor(currency / 100).toLocaleString() + '.' + cents
  }

  parseCents(dollar) {
    dollar = dollar.toFixed(2)
    return dollar * 100
  }

  dollars(currency) {
    return Math.floor(currency / 100).toLocaleString()
  }

  cents(currency) {
    var cents = currency % 100
    if (cents < 10) {
      cents = '0' + cents
    }
    return cents
  }
}

export default new Currency()
