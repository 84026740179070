import React from 'react'
import agentAshleyIcon from '../assets/profile-ashley-lawrence@3x.png'

export default function BrokerInfo({ agent, style }) {
  if (!agent || !agent.username) {
    return null
  }

  return (
    <div style={style}>
      <h3 className="heading" style={{ marginTop: '0px' }}>
        Your Broker
      </h3>
      <div className="agent-profile">
        {agent.username === 'ashley@csivegas.insure' ? (
          <img src={agentAshleyIcon} alt="Agent" />
        ) : (
          <div className="initials">{`${agent.firstname[0]}${agent.lastname[0]}`}</div>
        )}
        <div>
          <h4>
            {agent.firstname} {agent.lastname}
          </h4>
          <p>{agent.agency_name}</p>
          {agent.license_number && agent.license_state && (
            <p>
              LIC: {agent.license_state}-{agent.license_number}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
