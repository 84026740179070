import '../../styles/Claims.css'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CenteredContainer } from '../../components/Container'
import LoadingIndicator from '../../components/LoadingIndicator'
import ClaimModel from '../../models/ClaimModel'
import fasterClaimsImg from '../../assets/icn-faster-claims.svg'
import UserModel from '../../models/UserModel'
import Store from '../../models/Store'

export default function ClaimsList() {
  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const [claims, setClaims] = useState([])
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [selectedClaim, setSelectedClaim] = useState(null)
  const [showEmbark, setShowEmbark] = useState(false)
  const user = Store.getUser()
  const userID = user ? user.userID : ''

  const fetchClaims = async () => {
    const { claims, err } = await ClaimModel.getClaims()
    if (err) {
      alert('Error fetching claims')
      return
    }

    if (claims) {
      setClaims(claims)
      setLoading(false)
    }
  }
  const fetchUser = async () => {
    const { policy, err } = await UserModel.getUser(
      userID,
      'policy'
    )

    if (err) {
      alert('Error loading user details, please contact support.')
      return
    }
    if (policy['is_embark_enabled']) {
      setShowEmbark(policy['is_embark_enabled'])
    }
  }

  useEffect(() => {
    fetchClaims()
    fetchUser()
  }, [])

  const onClickClaim = (claim) => {
    if (claim.claim_state !== 'Closed' && !claim.extended_info) {
      setSelectedClaim(claim)
      setShowUpdateModal(true)
      return
    }

    if (claim.repair_states) {
      history.push(`/claims/repairs/${claim.id}`)
      return
    }

    history.push(`/claims/${claim.id}`)
  }

  const onCloseUpdateModal = () => {
    setShowUpdateModal(false)
    setSelectedClaim(null)
  }

  return (
    <div className="container">
      <CenteredContainer>
        <h2 className="display">Claims</h2>

        {loading && (
          <ul className="claims-list">
            <li>
              <LoadingIndicator text="Loading..." />
            </li>
          </ul>
        )}

        {!loading && claims && (
          <ul className="claims-list">
            {claims.map((claim, i) => (
              <li key={i} onClick={() => onClickClaim(claim)}>
                <div className="text">
                  Claim #{claim.claim_number || ' N/A'}
                </div>
                {claim.claim_state === 'Closed' ? (
                  <div className="status closed">Closed</div>
                ) : (
                  <div className="status">{claim.claim_state}</div>
                )}
              </li>
            ))}
            {claims.length === 0 && <li>You have no claims</li>}
          </ul>
        )}

        <button
          className="primary bold"
          onClick={() => history.push(showEmbark ? '/claims/embark' : '/claims/new')}
          disabled={loading}
        >
          Submit New Claim
        </button>
      </CenteredContainer>

      {showUpdateModal && (
        <UpdateClaimModal claim={selectedClaim} onClose={onCloseUpdateModal} />
      )}
    </div>
  )
}

function UpdateClaimModal({ claim, onClose }) {
  return (
    <div className="update-claim modal">
      <div className="modal-box">
        <div className="close-button" onClick={onClose}></div>
        <img className="faster-claims-img" src={fasterClaimsImg} alt="" />
        <h3 className="modal-title">Faster Claims</h3>

        <p className="modal-text">
          Now Go lets you add more detail to your claim, enabling it to be
          processed faster.
        </p>

        <button
          className="primary"
          onClick={() => (window.location = `/claims/edit/${claim.id}`)}
        >
          Update my claim
        </button>
      </div>
    </div>
  )
}
