import React from 'react'
import { Link } from 'react-router-dom'
import Utils from '../utils/Utils'
import Strings from '../utils/Strings'
import licenseIcon from '../assets/breadcrumb-license-info.svg'
import driverIcon from '../assets/breadcrumb-drivers.svg'
import vehiclesIcon from '../assets/breadcrumb-vehicles.svg'
import coveragesIcon from '../assets/breadcrumb-coverages.svg'
import quoteIcon from '../assets/breadcrumb-quotes.svg'
import policyIcon from '../assets/breadcrumb-policy.svg'
import driverIconUnvisited from '../assets/breadcrumb-drivers-unvisited.svg'
import vehiclesIconUnvisited from '../assets/breadcrumb-vehicles-unvisited.svg'
import coveragesIconUnvisited from '../assets/breadcrumb-coverages-unvisited.svg'
import quoteIconUnvisited from '../assets/breadcrumb-quotes-unvisited.svg'
import policyIconUnvisited from '../assets/breadcrumb-policy-unvisited.svg'
import Store from '../models/Store'

function Breadcrumb({ page, userState, license }) {
  if (userState === 'policy' && page !== 'policy') {
    if (Store.getUser().userID) {
      window.location = `/policy/${Store.getUser().userID}`
      return null
    }
  }

  if (userState === 'policy' || page === 'policy') {
    return (
      <div>
        <ul className="breadcrumb-desktop">
          <li className="active">Confirmation</li>
        </ul>
        <div className="breadcrumb-mobile">
          <img
            src={licenseIcon}
            className="breadcrumb-unselected"
            alt="info page tab"
          />
          <img
            src={driverIcon}
            className="breadcrumb-unselected"
            alt="drivers page tab"
          />
          <img
            src={vehiclesIcon}
            className="breadcrumb-unselected"
            alt="cars page tab"
          />
          <img
            src={coveragesIcon}
            className="breadcrumb-unselected"
            alt="coverages page tab"
          />
          <img
            src={quoteIcon}
            className="breadcrumb-unselected"
            alt="quote page tab"
          />
          <img
            src={policyIcon}
            className="breadcrumb-selected"
            alt="policy page tab"
          />
          <span className="breadcrumb-bar" />
        </div>
      </div>
    )
  }

  if (!userState || !page) {
    return null
  }

  const userStateList = ['info', 'drivers', 'cars', 'coverages', 'quote']
  const userStateIndex = userStateList.findIndex((state) => state === userState)

  const desktopLinks = userStateList.map((state, i) => {
    if (i <= userStateIndex) {
      if (state === 'info') {
        if (state === page) {
          return (
            <li className="active" key={i}>
              {Utils.getFullName(license, 'New Quote')}
            </li>
          )
        }

        return (
          <li key={i}>
            <Link to={'/info'}>{Utils.getFullName(license, 'New Quote')}</Link>
          </li>
        )
      }

      if (state === page) {
        return (
          <li className="active" key={i}>
            {Strings.titleCase(state)}
          </li>
        )
      }

      return (
        <li key={i}>
          <Link to={`/${state}`}>{Strings.titleCase(state)}</Link>
        </li>
      )
    }

    return null
  })

  const mobileList = ['info', 'drivers', 'cars', 'coverages', 'quote', 'policy']
  const icons = [
    licenseIcon,
    driverIcon,
    vehiclesIcon,
    coveragesIcon,
    quoteIcon,
    policyIcon,
  ]
  const unvisitedIcons = [
    null,
    driverIconUnvisited,
    vehiclesIconUnvisited,
    coveragesIconUnvisited,
    quoteIconUnvisited,
    policyIconUnvisited,
  ]
  const mobileLinks = mobileList.map((state, i) => {
    if (i > userStateIndex) {
      return (
        <img
          key={i}
          src={unvisitedIcons[i]}
          className="breadcrumb-unvisited"
          alt=""
        />
      )
    }
    return (
      <Link to={`/${state}`} key={i}>
        <img
          src={icons[i]}
          alt=""
          className={
            'breadcrumb-' + (state === page ? 'selected' : 'unselected')
          }
        />
      </Link>
    )
  })

  return (
    <div>
      <ul className="breadcrumb-desktop">{desktopLinks}</ul>
      <div className="breadcrumb-mobile">
        {mobileLinks}
        <span className="breadcrumb-bar" />
      </div>
    </div>
  )
}

export default Breadcrumb
