import '../../styles/ClaimsRepair.css'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import BackButton from '../../components/BackButton'
import ContactCard from '../../components/ContactCard'
import {
  ColumnContainer,
  ColumnLeft,
  ColumnRight,
} from '../../components/Container'
import ClaimModel from '../../models/ClaimModel'
import ClaimsRepairFlowModel from '../../models/ClaimsRepairFlowModel'
import Dates from '../../utils/Dates'
import { RepairStateType } from './RepairStateTypes'
import { Label } from '../../components/Label'
import repairShopGreenIcon from '../../assets/icn-repair-shop-green.svg'
import repairShopIcon from '../../assets/icn-repair-shop.svg'
import driverIcon from '../../assets/driver.svg'
import emailIcon from '../../assets/email.svg'
import phoneIcon from '../../assets/phone-number.svg'
import debounce from 'lodash.debounce'
import { useForm, Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import Strings from '../../utils/Strings'
import Currency from '../../utils/Currency'
import docsIcon from '../../assets/icn-docs.svg'
import rightArrowIcon from '../../assets/icn-chevron-right.svg'
import LoadingIndicator from '../../components/LoadingIndicator'

export default function ClaimsRepair(props) {
  const history = useHistory()
  const { claimID } = props.match.params

  const [isLoading, setIsLoading] = useState(true)
  const [claim, setClaim] = useState(null)
  const [repairStates, setRepairStates] = useState([])
  const [repairState, setRepairState] = useState(null)
  const [repairShop, setRepairShop] = useState(null)
  const [isUpdatingRepairShop, setIsUpdatingRepairShop] = useState(false)
  const [repairEstimate, setRepairEstimate] = useState(null)
  const [comesearchEstimate, setComsearchEstimate] = useState(null)
  const [isApprovingEstimate, setIsApprovingEstimate] = useState(false)
  const [paymentAmount, setPaymentAmount] = useState(0)
  const [intervalID, setIntervalID] = useState(0)

  const fetchClaim = async (claimID, showLoading = true) => {
    setIsLoading(showLoading)
    const { claim, err } = await ClaimModel.getClaimByID(claimID)
    if (err) {
      stopPolling()
      alert('There were errors retrieving claim. Please try again')
      return
    }

    setClaim(claim)
    if (!claim.repair_states) {
      history.push(`/claims/${claim.id}`)
      return
    }
    if (!claim.repair_states) {
      return
    }

    const repairStates = claim.repair_states
    setRepairStates(repairStates)
    const lastRepairState = repairStates[repairStates.length - 1]
    setRepairState(lastRepairState)
    if (lastRepairState.name === RepairStateType.Done) {
      stopPolling()
    }

    const repairSelectShop = repairStates.find(
      (s) => s.name === RepairStateType.SelectShop
    )
    if (repairSelectShop) {
      const shop =
        repairSelectShop.claim_flow_repair_shop?.claim_repair_shop || null
      if (shop) {
        setIsUpdatingRepairShop(false)
        setRepairShop(shop)
      }
    }

    const repairEstimate = repairStates.find(
      (s) => s.name === RepairStateType.Estimate
    )
    if (repairEstimate) {
      setRepairEstimate(repairEstimate.estimate || null)
    }

    const repairEstimateValidation = repairStates.find(
      (s) => s.name === RepairStateType.EstimateValidation
    )
    if (repairEstimateValidation) {
      setComsearchEstimate(repairEstimateValidation.comsearch_estimate || null)
    }

    const repairPayment = repairStates.find(
      (s) => s.name === RepairStateType.Payment
    )
    if (repairPayment) {
      setPaymentAmount(repairPayment.payment_amount || 0)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    fetchClaim(claimID)
    // eslint-disable-next-line
  }, [claimID])

  useEffect(() => {
    startPolling()

    return () => stopPolling()
    // eslint-disable-next-line
  }, [])

  const startPolling = () => {
    if (intervalID !== 0) {
      console.log('already polling')
      return
    }

    const id = setInterval(() => {
      fetchClaim(claimID, false)
    }, 7000)
    setIntervalID(id)
    console.log('started polling...')
  }

  const stopPolling = () => {
    if (!intervalID) {
      return
    }
    clearInterval(intervalID)
    console.log('stopped polling')
  }

  const approveEstimate = async () => {
    setIsApprovingEstimate(true)
    const { err } = await ClaimsRepairFlowModel.createState(
      claimID,
      repairState.claimant_id,
      RepairStateType.InProgress
    )
    if (err) {
      alert(
        'Sorry, we encountered an error on approving estimate. Try again later.'
      )
      setIsApprovingEstimate(false)
      return
    }
    fetchClaim(claimID, false)
  }

  const getDate = (stateName) => {
    const repairState = repairStates.find((s) => s.name === stateName)
    if (!repairState) {
      return
    }
    return Dates.toLongDateWithoutYear(repairState.created_at)
  }

  const isActive = (stateType, currentStateType) => {
    const stateTypes = Object.values(RepairStateType)
    const i = stateTypes.findIndex((s) => s === stateType)
    const j = stateTypes.findIndex((s) => s === currentStateType)
    if (i <= j) {
      return true
    }

    return false
  }

  return (
    <div className="container">
      <BackButton text={'All Claims'} to="/claims/list" />
      <h2 className="title">
        Claim #{isLoading ? ' Loading...' : claim.claim_number}
      </h2>
      {!isLoading && (
        <ColumnContainer className="repair-timeline-container">
          <ColumnLeft className="repair-timeline-wrapper">
            {/* repair shop */}
            {isUpdatingRepairShop && (
              <div className="repair-shop-display">
                <img src={repairShopGreenIcon} alt="" />
                <div className="shop-info">
                  <LoadingIndicator text="Updating..." />
                </div>
              </div>
            )}
            {repairShop && (
              <div className="repair-shop-display">
                <img src={repairShopGreenIcon} alt="" />
                <div className="shop-info">
                  <h3 className="heading">{repairShop.name}</h3>
                  <p className="sub-heading">{formatShopAddress(repairShop)}</p>
                </div>
              </div>
            )}

            {/* timeline */}
            <ul className="repair-timeline">
              {/* submitted */}
              <li className="checked">
                <div className="repair-state-title-wrapper">
                  <div className="repair-state">Submitted</div>
                  <div className="date">
                    {Dates.toLongDateWithoutYear(claim.created_at)}
                  </div>
                </div>
              </li>

              {/* repair shop  */}
              <li
                className={
                  isActive(RepairStateType.SelectShop, repairState.name)
                    ? 'checked'
                    : ''
                }
              >
                <div className="repair-state-title-wrapper">
                  <div className="repair-state">Repair Shop</div>
                  <div className="date">
                    <div className="date">
                      {getDate(RepairStateType.SelectShop)}
                    </div>
                  </div>
                </div>

                {repairState.name === RepairStateType.SelectShop && (
                  <SelectRepairShop
                    repairState={repairState}
                    hideButton={isUpdatingRepairShop}
                    onComplete={() => {
                      setIsUpdatingRepairShop(true)
                      fetchClaim(claimID, false)
                    }}
                  />
                )}
              </li>

              {/* repair estimate */}
              <li
                className={
                  isActive(RepairStateType.Estimate, repairState.name)
                    ? 'checked'
                    : ''
                }
              >
                <div className="repair-state-title-wrapper">
                  <div className="repair-state">
                    Repair Estimate
                    {repairEstimate &&
                      repairEstimate.amount !== 0 &&
                      `: $${Currency.format(repairEstimate?.amount)}`}
                  </div>
                  <div className="date">
                    <div className="date">
                      {getDate(RepairStateType.Estimate)}
                    </div>
                  </div>
                </div>
                {repairState.name === RepairStateType.Estimate && (
                  <div className="info">We are getting an estimate.</div>
                )}
                {repairEstimate && repairEstimate.amount !== 0 && (
                  <DocumentViewer estimate={repairEstimate} />
                )}
              </li>

              {/* validation */}
              <li
                className={
                  isActive(RepairStateType.EstimateValidation, repairState.name)
                    ? 'checked'
                    : ''
                }
              >
                <div className="repair-state-title-wrapper">
                  <div className="repair-state">
                    Validation
                    {comesearchEstimate &&
                      comesearchEstimate.amount !== 0 &&
                      `: $${Currency.format(comesearchEstimate.amount)}`}
                  </div>
                  <div className="date">
                    <div className="date">
                      {getDate(RepairStateType.EstimateValidation)}
                    </div>
                  </div>
                </div>

                {repairState.name === RepairStateType.EstimateValidation && (
                  <div className="info">We are validating the estimate.</div>
                )}
                {comesearchEstimate && comesearchEstimate.amount !== 0 && (
                  <DocumentViewer estimate={comesearchEstimate} />
                )}
                {repairState.name === RepairStateType.ApproveEstimate && (
                  <button
                    className="primary"
                    onClick={approveEstimate}
                    disabled={isApprovingEstimate}
                  >
                    {isApprovingEstimate ? 'Approving...' : 'Approve Estimate'}
                  </button>
                )}
              </li>

              {/* repair */}
              <li
                className={
                  isActive(RepairStateType.InProgress, repairState.name)
                    ? 'checked'
                    : ''
                }
              >
                <div className="repair-state-title-wrapper">
                  <div className="repair-state">Repair</div>
                  <div className="date">
                    <div className="date">
                      {getDate(RepairStateType.InProgress)}
                    </div>
                  </div>
                </div>
              </li>

              {/* payment */}
              <li
                className={
                  isActive(RepairStateType.Payment, repairState.name)
                    ? 'checked'
                    : ''
                }
              >
                <div className="repair-state-title-wrapper">
                  <div className="repair-state">
                    Payment
                    {paymentAmount !== 0 &&
                      `: $${Currency.format(paymentAmount)}`}
                  </div>
                  <div className="date">
                    <div className="date">
                      {getDate(RepairStateType.Payment)}
                    </div>
                  </div>
                </div>

                {repairState.name === RepairStateType.Payment && (
                  <div className="info">Waiting for confirmation.</div>
                )}
                {paymentAmount !== 0 && (
                  <div className="info">Your payment has been made.</div>
                )}
              </li>

              {/* ready */}
              <li
                className={
                  isActive(RepairStateType.ReadyForPickup, repairState.name)
                    ? 'checked'
                    : ''
                }
              >
                <div className="repair-state-title-wrapper">
                  <div className="repair-state">Ready</div>
                  <div className="date">
                    <div className="date">
                      {repairState.name === RepairStateType.ReadyForPickup &&
                        getDate(RepairStateType.ReadyForPickup)}
                      {repairState.name === RepairStateType.Done &&
                        getDate(RepairStateType.Done)}
                    </div>
                  </div>
                </div>

                {repairState.name === RepairStateType.ReadyForPickup && (
                  <div className="info">Waiting for pickup confirmation.</div>
                )}
                {repairState.name === RepairStateType.Done && (
                  <div className="info">Your car is ready for pickup.</div>
                )}
              </li>
            </ul>

            {/* claims summary */}
            <div
              className="claim-summary-nav"
              onClick={() => (window.location = `/claims/${claimID}`)}
            >
              <img className="docs-icon" src={docsIcon} alt="" />
              <div>Claim Summary</div>
              <img className="arrow-icon" src={rightArrowIcon} alt="" />
            </div>
          </ColumnLeft>

          <ColumnRight className="repair-contact-wrapper">
            {claim.go_contact && (
              <>
                <h2 className="heading">Go Contact</h2>
                <ContactCard contact={claim.go_contact} />
              </>
            )}
          </ColumnRight>
        </ColumnContainer>
      )}
    </div>
  )
}

function SelectRepairShop({ repairState, hideButton = false, onComplete }) {
  const [showModal, setShowModal] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [repairShops, setRepairShops] = useState([])
  const [selectedShop, setSelectedShop] = useState(null)

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { isValid, isSubmitting },
    watch,
  } = useForm({ mode: 'onChange' })

  const search = async (q) => {
    const { repairShops, err } = await ClaimsRepairFlowModel.repairShopSearch(
      q,
      4
    )
    if (err) {
      alert(err)
      return
    }

    setRepairShops(repairShops || [])
  }

  const debouncedSearch = useCallback(
    debounce((q) => search(q), 500),
    []
  )

  const onSearch = (e) => {
    const value = e.target.value
    setSearchTerm(value)

    if (selectedShop?.name !== value) {
      setSelectedShop(null)
    }

    debouncedSearch(value)
  }

  const onSelectShop = (shop) => {
    setSelectedShop(shop)
    setSearchTerm(shop.name)
    if (shop.contact_phone_number) {
      setValue('contact_phone', shop.contact_phone_number.replace('+1'))
    }
    setRepairShops([])
  }

  const onSubmit = async (data) => {
    let repairShop = selectedShop
    repairShop.contact_name = data.contact_name
    repairShop.contact_email = data.contact_email
    repairShop.contact_phone_number = data.contact_phone.replace(/\s/g, '')

    const err = await ClaimsRepairFlowModel.createRepairShop(
      repairState.claim_id,
      repairState.claimant_id,
      repairShop
    )
    if (err) {
      alert(
        'Sorry, we encountered an error on saving repair shop. Try again later.'
      )
      return
    }

    setShowModal(false)
    onComplete(true)
  }

  const isFormValid = () => {
    if (!selectedShop) {
      return false
    }

    if (!isValid) {
      return false
    }

    if (
      watch('contact_email') &&
      !Strings.isValidEmail(watch('contact_email'))
    ) {
      return false
    }

    if (
      watch('contact_phone') &&
      !Strings.isValidPhone(watch('contact_phone'))
    ) {
      return false
    }

    return true
  }

  return (
    <div>
      {!hideButton && (
        <button className="primary" onClick={() => setShowModal(true)}>
          Select Repair Shop
        </button>
      )}

      {showModal && (
        <div className="modal select-repair-shop">
          <div className="modal-box">
            <div
              className="close-button"
              onClick={() => setShowModal(false)}
            ></div>
            <h3 className="modal-title">Select Repair Shop</h3>

            <form
              className="select-repair-shop-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-row">
                <div className="form-column">
                  <Label icon={repairShopIcon} text="REPAIR SHOP" />
                  <div className="repair-shop-auto-complete">
                    <input
                      name="repair_shop"
                      autoComplete="off"
                      value={searchTerm}
                      onChange={onSearch}
                      onFocus={() => search(searchTerm)}
                    />
                    {repairShops.length > 0 && (
                      <ul className="shops-list">
                        {repairShops.map((shop, i) => (
                          <li key={i} onClick={() => onSelectShop(shop)}>
                            {shop.name} <span>{formatShopAddress(shop)}</span>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-column">
                  <Label icon={driverIcon} text="CONTACT NAME" />
                  <input {...register('contact_name', { required: true })} />
                </div>
              </div>

              <div className="form-row">
                <div className="form-column">
                  <Label icon={emailIcon} text="SHOP EMAIL" />
                  <input
                    type="email"
                    {...register('contact_email', { required: true })}
                  />
                </div>

                <div className="form-column">
                  <Label icon={phoneIcon} text="SHOP PHONE" />
                  <Controller
                    name="contact_phone"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <NumberFormat
                        {...field}
                        ref={null}
                        type="tel"
                        format="### ### ####"
                        onValueChange={(values) => {
                          field.onChange(values.value)
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              <button
                className="primary"
                type="submit"
                disabled={!isFormValid() || isSubmitting}
              >
                {isSubmitting ? 'Saving...' : 'Continue'}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}

function DocumentViewer({ estimate }) {
  const [showModal, setShowModal] = useState(false)

  const { innerHeight: height } = window
  const iframeHeight = height - height * 0.25

  return (
    <div className="repairs-document-viewer">
      <button className="link" onClick={() => setShowModal(true)}>
        View Estimate
      </button>

      {showModal && (
        <div className="modal repair-document">
          <div className="modal-box">
            <div
              className="close-button"
              onClick={() => setShowModal(false)}
            ></div>
            <h3 className="modal-title">Estimate</h3>

            <iframe
              frameBorder="0"
              className="repairs-pdf-viewer"
              src={estimate.estimate_url + `#toolbar=0&view=Fit`}
              title={estimate.estimate_file_name}
              height={iframeHeight}
            ></iframe>
          </div>
        </div>
      )}
    </div>
  )
}

const formatShopAddress = (shop) => {
  const { address1, address2, city, state } = shop
  if (address2) {
    return `${address1}, ${address2}, ${city}, ${state}`
  }
  return `${address1}, ${city}, ${state}`
}
