import '../../styles/ClaimsPay.css'
import scheduledIcon from '../../assets/icn-claim-payment-paid.svg'
import safeLabsSeal from '../../assets/go-safe-labs-seal.svg'
import Currency from '../../utils/Currency'
import Dates from '../../utils/Dates'

export default function ClaimsPaySuccess(props) {
  const paymentSchedules =
    props.location.state && props.location.state.paymentSchedules
      ? props.location.state.paymentSchedules
      : null

  function formatDate(date) {
    const d = Dates.toDate(date)
    const dateOption = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    }

    const dateFormatter = new Intl.DateTimeFormat('en-US', dateOption)

    return dateFormatter.format(d)
  }

  if (!paymentSchedules) {
    return null
  }

  return (
    <div className="container claims-pay-summary">
      <h2 className="title">Payment Scheduled</h2>

      <div className="payment-success-checkmark">
        <img src={scheduledIcon} alt="calender" />

        <h3 className="claims-amount">
          ${Currency.format(paymentSchedules.amount)}
        </h3>
        <p className="title-text">
          Schedule payment on {formatDate(paymentSchedules.scheduled_date)}
        </p>
      </div>

      <div className="summary-list">
        <div className="row">
          <div className="text">Pay to</div>
          <div className="text">{paymentSchedules.name}</div>
        </div>

        <div className="row">
          <div className="text">Claim Number</div>
          <div className="text">{paymentSchedules.claim_number}</div>
        </div>

        <div className="row">
          <div className="text">Payment Scheduled</div>
          <div className="text">
            {formatDate(paymentSchedules.scheduled_date)}
          </div>
        </div>
      </div>

      <div className="safe-labs-details">
        <img src={safeLabsSeal} alt="Go Safe Labs Seal" />
        <p>
          Go Safe Labs thanks you for saving up to 29gm of CO2 by not using
          paper mail and checks.
        </p>
      </div>

      <p className="bill-com-details">
        Your scheduled payment is secured via Bill.com. Bill.com applies an
        additional level of encryption to protect access to sensitive customer
        data from malicious applications.
      </p>
    </div>
  )
}
