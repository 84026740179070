import { useState } from 'react'
import { Link } from 'react-router-dom'
import { CenteredContainer } from '../../components/Container'
import { Label } from '../../components/Label'
import StateSelector from '../../components/StateSelector'
import Store from '../../models/Store'
import DriverIcon from '../../assets/driver.svg'
import EmailIcon from '../../assets/email.svg'
import PhoneIcon from '../../assets/phone-number.svg'
import CarIcon from '../../assets/cars.svg'
import PolicyIcon from '../../assets/icn-confirmation.svg'
import FlagIcon from '../../assets/flag.svg'
import CardBackIcon from '../../assets/cardback.svg'
import BackIcon from '../../assets/icn-back-nav.svg'

export default function ClaimsYourInfo() {
  const [representativeTypes,] = useState(() => {
    var reporterType = Store.getClaimReporterType()
    if (reporterType === 'GO_CUSTOMER_REPRESENTATIVE') {
      return ['Agent', 'Attorney']
    }
    if (reporterType === 'THIRD_PARTY') {
      return ['Claimant', 'Agent', 'Attorney', 'Insurance Carrier']
    }
  })
  const [representativeType, setRepresentativeType] = useState(() => {
    var { type } = Store.getClaimRepresentativeInfo();
    if (type) {
      return type;
    }
    var reporterType = Store.getClaimReporterType()
    if (reporterType === 'GO_CUSTOMER_REPRESENTATIVE') {
      return 'Agent'
    }
    if (reporterType === 'THIRD_PARTY') {
      return 'Claimant'
    }
  })
  const [pageNumber, setPageNumber] = useState(1)

  const [representativeName, setRepresentativeName] = useState(Store.getClaimRepresentativeInfo().name)
  const [representativeEmail, setRepresentativeEmail] = useState(Store.getClaimRepresentativeInfo().email)
  const [representativePhone, setRepresentativePhone] = useState(Store.getClaimRepresentativeInfo().phone)
  const [claimantVin,  setClaimantVin] = useState(Store.getClaimClaimantInfo().vin)
  const [claimantInsuranceCompany, setClaimantInsuranceCompany] = useState(Store.getClaimClaimantInfo().insuranceCompany)
  const [claimantState, setClaimantState] = useState(Store.getClaimClaimantInfo().driversLicenseState)
  const [claimantDriversLicense, setClaimantDriversLicense] = useState(Store.getClaimClaimantInfo().driversLicenseNumber)

  const handleRepresentativeNameOnChange = (e) =>
  {
    setRepresentativeName(e.target.value)
  }

  const handleRepresentativeEmailOnChange = (e) =>
  {
    setRepresentativeEmail(e.target.value)
  }

  const handleRepresentativePhoneOnChange = (e) =>
  {
    setRepresentativePhone(e.target.value)
  }

  const handleClaimantVinOnChange = (e) =>
  {
    setClaimantVin(e.target.value)
  }

  const handleClaimantInsuranceCompanyOnChange = (e) =>
  {
    setClaimantInsuranceCompany(e.target.value)
  }

  const handleClaimantStateOnChange = (e) =>
  {
    setClaimantState(e.target.value)
  }

  const handleClaimantDriversLicenseOnChange = (e) =>
  {
    setClaimantDriversLicense(e.target.value)
  }

  const handlePageOneContinue = () => {
    Store.setClaimRepresentativeInfo(representativeType, representativeName, representativeEmail, representativePhone)
    var reporterType = Store.getClaimReporterType()
    if (reporterType === 'THIRD_PARTY') {
      console.log("about to set page 2")
      setPageNumber(2)
    } else {
      console.log("about to /claims/new")
      Store.setClaimClaimantInfo("", "", "", "")
      window.location = '/claims/new'
    }
  }

  const handlePageTwoContinue = () => {
    Store.setClaimClaimantInfo(claimantVin, claimantInsuranceCompany, claimantState, claimantDriversLicense)
    window.location = '/claims/new'
  }

  const handlePageTwoBack = () => {
    setPageNumber(1)
  }

  if (pageNumber === 1) {
    return (
      <div className="container">
        <div className="back-nav">
          <Link to="/claims/start">
            <img src={BackIcon} alt="back button" /> Back
          </Link>
        </div>
        <CenteredContainer>
          <h2 className="title">Your Information</h2>
          <div className="form-container">
            <div className="form-row">
              <div className="form-column">
                <Label icon={DriverIcon} text="REPRESENTATIVE TYPE" />
                <select value={representativeType} onChange={e => setRepresentativeType(e.target.value)}>
                  {representativeTypes.map((val, index) => (
                    <option key = {index} value={val}>
                      {val.toUpperCase()}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-column">
                <Label icon={DriverIcon} text="NAME"></Label>
                <input
                  type="text"
                  name="representativeName"
                  value={representativeName}
                  onChange={handleRepresentativeNameOnChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-column">
                <Label icon={EmailIcon} text="EMAIL"></Label>
                <input
                  type="text"
                  name="representativeEmail"
                  value={representativeEmail}
                  onChange={handleRepresentativeEmailOnChange}
                />
              </div>
              <div className="form-column">
                <Label icon={PhoneIcon} text="PHONE NUMBER"></Label>
                <input
                  type="text"
                  name="representativePhone"
                  value={representativePhone}
                  onChange={handleRepresentativePhoneOnChange}
                />
              </div>
            </div>
            <button
              className="primary"
              style={{ width: '100%', marginTop: '35px' }}
              autoComplete="false"
              autoCorrect="false"
              disabled={representativeName.length === 0 || representativeEmail.length === 0 || representativePhone.length === 0}
              onClick={handlePageOneContinue}
            >
              Continue
            </button>
          </div>
        </CenteredContainer>
      </div>
    );
  } else {
    return (
      <div className="container">
        <div className="back-nav">
          <Link onClick={handlePageTwoBack}>
            <img src={BackIcon} alt="back button" /> Back
          </Link>
        </div>
        <CenteredContainer>
          <h2 className="title">Claimant Information</h2>
          <div className="form-container">
            <div className="form-row">
              <div className="form-column">
                <Label icon={CarIcon} text="VEHICLE IDENTIFICATION NUMBER (VIN)" />
                  <input
                    type="text"
                    name="claimantVin"
                    value={claimantVin}
                    onChange={handleClaimantVinOnChange}
                  />
              </div>
            </div>
            <div className="form-row" style={{ padding: '0px' }}>
              <div className="form-column">
                <Label icon={PolicyIcon} text="INSURANCE COMPANY"></Label>
                <input
                  type="text"
                  name="claimantInsuranceCompany"
                  value={claimantInsuranceCompany}
                  onChange={handleClaimantInsuranceCompanyOnChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-column">
                <Label icon={FlagIcon} text="DRIVER'S LICENSE STATE"></Label>
                <StateSelector
                  name="claimantState"
                  value={claimantState}
                  onChange={handleClaimantStateOnChange}
                />
              </div>
              <div className="form-column">
                <Label icon={CardBackIcon} text="DRIVER'S LICENSE"></Label>
                <input
                  type="text"
                  name="claimantDriversLicense"
                  value={claimantDriversLicense}
                  onChange={handleClaimantDriversLicenseOnChange}
                />
              </div>
            </div>

            <button
              className="primary"
              style={{ width: '100%', marginTop: '35px' }}
              autoComplete="false"
              autoCorrect="false"
              disabled={claimantVin.length === 0 || claimantInsuranceCompany.length === 0 || claimantState.length === 0 || claimantDriversLicense.length === 0}
              onClick={handlePageTwoContinue}
            >
              Continue
            </button>
          </div>
        </CenteredContainer>
      </div>
    );
  }
}