import '../../styles/Claims.css'

import { CenteredContainer } from '../../components/Container'
import checkmarkIcon from '../../assets/icn-checkmark-inset.svg'

export default function ClaimsSubmissionSent() {
  return (
    <div className="container">
      <CenteredContainer>
        <div className="submission-sent-header">
          <h2 style={{ display: "flex", justifyContent: "center" }}>
            <img src={checkmarkIcon} alt="checkmark" />
          </h2>
          <h2 style={{ display: "flex", justifyContent: "center" }}>
              Your submission has been sent
          </h2>
        </div>
      </CenteredContainer>
    </div>
  )
}