import { useEffect, useState } from 'react'
import { CenteredContainer } from '../../components/Container'
import codeIcon from '../../assets/code.svg'
import { ErrorLabel, Label } from '../../components/Label'
import NumberFormat from 'react-number-format'
import { useMachine } from '@xstate/react'
import fetchMachine from '../../machines/fetch'
import PolicyModel from '../../models/PolicyModel'
import UserModel from '../../models/UserModel'
import { useParams, useLocation } from 'react-router-dom'
import LoadingIndicator from '../../components/LoadingIndicator'
import useHasCapture from '../../hooks/useHasCapture'
import Constants from '../../models/Constants'

export default function Login() {
  const hasCapture = useHasCapture()
  const { policyID } = useParams()

  let useUserID = false
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const userID = params.get('userID')
  if (userID) useUserID = true

  const isDeviceCheckDisabled =
    params.get('disableDeviceCheck') === 'true' && !Constants.isProduction()

  const requestCodeFn = () => {
    if (useUserID) {
      return UserModel.requestCodeByID(userID)
    } else {
      return PolicyModel.requestCodeByID(policyID)
    }
  }

  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState('')
  const [isVerifying, setIsVerifying] = useState(false)

  const [state, send] = useMachine(fetchMachine, {
    services: {
      fetchData: async (context, event) => {
        const { phoneNumber, err } = await requestCodeFn()

        if (err) {
          send({ type: 'REJECT', error: err })
          return
        }

        send({ type: 'RESOLVE', response: phoneNumber })
      },
    },
  })

  const isIdle = state.matches('idle')
  const isLoading = state.matches('loading')
  const onFailure = state.matches('failure')
  const phoneNumber = state.context.response || ''

  useEffect(() => {
    if (isIdle && hasCapture) {
      send('FETCH')
    }
  }, [send, isIdle, hasCapture])

  const onChangeCode = async (values) => {
    if (codeError) {
      setCodeError('')
    }

    const codeValue = values.value
    setCode(codeValue)

    if (codeValue.length === 4) {
      verifyCode(codeValue)
    }
  }

  const verifyCodeFn = (code) => {
    if (useUserID) {
      return UserModel.verifyUser(code)
    } else {
      return PolicyModel.loginByID(policyID, code)
    }
  }

  const verifyCode = async (code) => {
    setIsVerifying(true)

    const { user, err } = await verifyCodeFn(code)
    if (err) {
      if (err.err?.toString() === 'invalid code') {
        setCodeError(`Sorry, couldn't validate your phone number.`)
      } else {
        setCodeError(
          `Sorry, we ran into an unexpected issue and we're still figuring it out.`
        )
      }
      setIsVerifying(false)
      return
    }

    if (isDeviceCheckDisabled) {
      window.location = `/uploads/${user.userID}?disableDeviceCheck=true`
    } else {
      window.location = `/uploads/${user.userID}`
    }
  }

  if (!hasCapture) {
    return (
      <div className="container">
        {!hasCapture && (
          <CenteredContainer>
            <div className="center-center" style={{ color: '#000' }}>
              Device not supported. Please use your mobile phone to view this
              page.
            </div>
          </CenteredContainer>
        )}
      </div>
    )
  }

  return (
    <div className="container">
      {isLoading && (
        <CenteredContainer>
          <div className="center-center">
            <LoadingIndicator
              text={'Loading...'}
              style={{ fontSize: '21px' }}
            />
          </div>
        </CenteredContainer>
      )}

      {!isLoading && (
        <CenteredContainer>
          <h2
            className="display"
            style={{ marginBottom: '4px', fontSize: '31px' }}
          >
            Enter the 4 digit code sent to ***-{phoneNumber}
          </h2>
          <p className="title-text">This code enables us to verify identity.</p>

          <form onSubmit={(e) => e.preventDefault()}>
            <div className="form-row">
              <div className="form-column">
                <Label icon={codeIcon} text="ENTER CODE" />
                <NumberFormat
                  className={codeError && 'error'}
                  format="####"
                  name="code"
                  value={code}
                  isNumericString={true}
                  onValueChange={onChangeCode}
                  inputMode="numeric"
                  autoComplete="one-time-code"
                  disabled={isVerifying}
                />
                {codeError && <ErrorLabel errorMessage={codeError} />}
              </div>
            </div>
          </form>

          {onFailure && (
            <ErrorLabel
              errorMessage={
                'There was an error sending code to your phone. Please try again later.'
              }
            />
          )}
        </CenteredContainer>
      )}
    </div>
  )
}
