import React, { useState, useEffect } from 'react'
import { CenteredContainer } from '../components/Container'
import { Label, ErrorLabel } from '../components/Label'
import StateSelector from '../components/StateSelector'
import closeBtnIcon from '../assets/close-btn-icon.svg'
import driverIcon from '../assets/driver.svg'
import timeIcon from '../assets/time.svg'
import genderIcon from '../assets/gender.svg'
import flagIcon from '../assets/flag.svg'
import cardBackIcon from '../assets/cardback.svg'
import addIcon from '../assets/add.svg'
import spouseIcon from '../assets/spouse.svg'
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import DriverModel from '../models/DriverModel'
import UserModel from '../models/UserModel'
import Strings from '../utils/Strings'
import Utils from '../utils/Utils'
import Breadcrumb from '../components/Breadcrumb'
import LicenseModel from '../models/LicenseModel'
import Store from '../models/Store.js'
import StackedMobile from '../components/StackedMobile'
import Sentry from '../logger/Sentry'
import LoadingIndicator from '../components/LoadingIndicator'
import BrokerInfo from '../components/BrokerInfo'

function Drivers(props) {
  const user = Store.getUser()
  const userID = user ? user.userID : ''

  const [agent, setAgent] = useState(null)
  const [licenseError, setLicenseError] = useState(null)
  const [birthDayError, setBirthDayError] = useState('')
  const [userState, setUserState] = useState(null)
  const [license, setLicense] = useState(null)
  const [editingDriver, setEditingDriver] = useState(null)
  const [drivers, setDrivers] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    birthDay: '',
    gender: '',
    licenseState: '',
    license: '',
  })
  const [toDelete, setToDelete] = useState(null)
  const [isRideshareConsentChecked, setIsRideshareConsentChecked] = useState(
    true
  )
  const [isShowingCallout, setIsShowingCallout] = useState(false)
  const [showLoading, setShowLoading] = useState(false)

  const fetchUser = async () => {
    setShowLoading(true)
    const {
      user,
      license,
      drivers,
      userState,
      agent,
      err,
    } = await UserModel.getUser(userID, 'drivers')
    if (err) {
      console.log('error getting user:', err)
      return
    }

    console.log('drivers', drivers)

    if (userState) {
      setUserState(userState)
    }

    if (license) {
      setLicense(license)
    }

    if (drivers) {
      setDrivers(drivers)
    }

    if (agent) {
      setAgent(agent)
    }

    setShowLoading(false)

    if (user && license) {
      Utils.sendGTMEvent('Drivers', user, license)
    }
  }

  useEffect(() => {
    const getUser = async () => {
      fetchUser()
    }
    getUser()
    // eslint-disable-next-line
  }, [])

  const fullName = (driver) => {
    return driver['first_name'] + ' ' + driver['last_name']
  }

  const handleInputChange = (e) => {
    const fieldName = e.target.name
    const fieldValue = e.target.value

    setFormValues({
      ...formValues,
      [fieldName]: fieldValue,
    })
  }

  const handleSpouse = async (e) => {
    var driverID = e.target.value
    if (!driverID) {
      driverID = null
    }
    let success = await DriverModel.setSpouse(userID, driverID)
    if (success) {
      fetchUser()
    }
  }

  const handleLicenseBlur = async (e) => {
    const isValid = await LicenseModel.validateLicense(
      userID,
      formValues.license,
      formValues.licenseState
    )

    if (!isValid) {
      setLicenseError('Please enter a valid license number')
    } else {
      setLicenseError('')
    }
  }

  const handleBirthDayBlur = (e) => {
    const dateStr = Strings.inputToDateString(formValues.birthDay)

    if (!Strings.isValidDate(dateStr)) {
      setBirthDayError('Please enter a valid date')
    } else {
      setBirthDayError('')
    }
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault()

    let driver = {
      first_name: formValues.firstName,
      last_name: formValues.lastName,
      birth_date: Strings.inputToDateString(formValues.birthDay),
      gender: formValues.gender,
      dl_state: formValues.licenseState,
      dl_number: formValues.license,
      license_id: license.id,
    }
    if (editingDriver) {
      driver.id = editingDriver.id
      const { err } = await DriverModel.updateDriver(userID, driver)
      if (err) {
        if (err.err && err.err === 'driver exists with active policy') {
          alert('This driver already have an active policy with Go')
        } else {
          alert('Something went wrong while adding driver. Try again.')
        }
        toggleAddMore()
        resetForm()
        Sentry.error('EditDriverError', 'edit driver error', {
          ...driver,
        })
        return
      }
      fetchUser()
      toggleAddMore()
      setEditingDriver(null)
      resetForm()
      Sentry.info('EditedDriver', 'edited driver', { driver })
    } else {
      const { err } = await DriverModel.addDriver(userID, driver)
      if (err) {
        if (err.err && err.err === 'driver exists with active policy') {
          alert('This driver already have an active policy with Go')
        } else {
          alert('Something went wrong while adding driver. Try again.')
        }
        toggleAddMore()
        resetForm()
        Sentry.error('AddDriverError', 'add driver error', {
          ...driver,
        })
        return
      }

      fetchUser()
      toggleAddMore()
      resetForm()
      Sentry.info('AddedDriver', 'added driver', { driver })
    }
  }

  const editDriver = (driver) => {
    setEditingDriver(driver)
    setFormValues({
      firstName: driver.first_name,
      lastName: driver.last_name,
      birthDay: Strings.dateStringToInput(driver.birth_date),
      gender: driver.gender,
      licenseState: driver.dl_state,
      license: driver.dl_number,
    })
    setShowForm(!showForm)
  }

  const toggleAddMore = () => {
    setShowForm(!showForm)
    if (!showForm) {
      resetForm()
      setEditingDriver(null)
    }
  }

  const resetForm = () => {
    setFormValues({
      firstName: '',
      lastName: '',
      birthDay: '',
      gender: '',
      licenseState: '',
      license: '',
    })
  }

  const isValid = () => {
    return (
      formValues.firstName.length > 0 &&
      formValues.lastName.length > 0 &&
      formValues.birthDay.length > 0 &&
      formValues.gender.length > 0 &&
      formValues.licenseState.length > 0 &&
      formValues.license.length > 0 &&
      !licenseError
    )
  }

  const removeDriver = async function (driverID) {
    let success = await DriverModel.removeDriver(userID, driverID)
    if (success) {
      fetchUser()
      Sentry.info('DeletedDriver', 'deleted driver', { driver_id: driverID })
    }
  }

  const handleRideshareCheckboxChanged = () => {
    setIsRideshareConsentChecked(!isRideshareConsentChecked)
  }

  const toggleCallout = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsShowingCallout(!isShowingCallout)
  }

  const driversList = drivers.map((driver) => (
    <div className="list-item" key={driver.id || driver.license_id}>
      <p>{fullName(driver)}</p>
      {driver.id && (
        <div className="list-controls">
          <button className="delete" onClick={() => setToDelete(driver['id'])}>
            Delete
          </button>
          <button onClick={() => editDriver(driver)}>Edit</button>
        </div>
      )}
      {toDelete === driver['id'] && (
        <div className="overlay">
          <div className="remove-dialog">
            <div className="dialog-title">Delete Driver</div>
            <br />
            <p>Are you sure you want to delete {fullName(driver)}?</p>
            <br />
            <div>
              <button className="cancel" onClick={() => setToDelete(null)}>
                Cancel
              </button>
            </div>
            <div>
              <button
                className="primary"
                onClick={() => removeDriver(driver['id'])}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  ))

  return (
    <div className="container">
      <Breadcrumb page={'drivers'} userState={userState} license={license} />

      <CenteredContainer>
        <div className="mobile-page-heading">Drivers</div>
        {agent && <BrokerInfo agent={agent} style={{ margin: '28px 0' }} />}

        <div className="interactive-list">
          {showLoading && (
            <LoadingIndicator text="Loading..." style={{ margin: '20px 0' }} />
          )}
          {driversList}
          {!showForm ? (
            <div className="add-more">
              <button onClick={toggleAddMore}>
                <img src={addIcon} alt="add icon" /> Add Driver
              </button>
            </div>
          ) : (
            <div className="overlay">
              <form className="add-more-form" onSubmit={handleFormSubmit}>
                <button className="add-more-close" onClick={toggleAddMore}>
                  <img src={closeBtnIcon} alt="close button" />
                </button>
                <h3>{editingDriver ? 'Edit Driver' : 'Add Driver'}</h3>
                <div className="add-more-content">
                  <StackedMobile
                    left={
                      <div>
                        <Label icon={driverIcon} text="FIRST NAME"></Label>
                        <input
                          name="firstName"
                          type="text"
                          value={formValues.firstName}
                          onChange={handleInputChange}
                        />
                      </div>
                    }
                    right={
                      <div>
                        <Label icon={driverIcon} text="LAST NAME"></Label>
                        <input
                          name="lastName"
                          type="text"
                          value={formValues.lastName}
                          onChange={handleInputChange}
                        />
                      </div>
                    }
                  />

                  <div className="form-row">
                    <div className="form-column">
                      <Label
                        icon={timeIcon}
                        text="BIRTHDAY (MM DD YYYY)"
                      ></Label>
                      <NumberFormat
                        format="## ## ####"
                        mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
                        name="birthDay"
                        value={formValues.birthDay}
                        onChange={handleInputChange}
                        onBlur={handleBirthDayBlur}
                      />
                      <ErrorLabel errorMessage={birthDayError} />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-column">
                      <Label icon={genderIcon} text="GENDER"></Label>
                      <select
                        className={
                          formValues.gender === '' ? 'placeholder' : ''
                        }
                        name="gender"
                        value={formValues.gender}
                        onChange={handleInputChange}
                      >
                        <option value="">Select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>
                  </div>

                  <StackedMobile
                    left={
                      <div>
                        <Label
                          icon={flagIcon}
                          text="DRIVER'S LICENSE STATE"
                        ></Label>
                        <StateSelector
                          excludeMichigan="true"
                          className={
                            formValues.licenseState === '' ? 'placeholder' : ''
                          }
                          name="licenseState"
                          value={formValues.licenseState}
                          onChange={handleInputChange}
                          onBlur={handleLicenseBlur}
                        />
                      </div>
                    }
                    right={
                      <div>
                        <Label
                          icon={cardBackIcon}
                          text="DRIVER'S LICENSE NUMBER"
                        ></Label>
                        <input
                          name="license"
                          type="text"
                          value={formValues.license}
                          onChange={handleInputChange}
                          onBlur={handleLicenseBlur}
                        />
                        <ErrorLabel errorMessage={licenseError} />
                      </div>
                    }
                  />
                </div>
                <button className="primary" type="submit" disabled={!isValid()}>
                  Done
                </button>
              </form>
            </div>
          )}
        </div>
        {drivers.length > 1 && (
          <div>
            <Label icon={spouseIcon} text="SPOUSE"></Label>
            <select
              className="spouse-select"
              name="spouse"
              value={(drivers.find((d) => d['married']) || {})['id']}
              onChange={handleSpouse}
            >
              <option value="">None</option>
              {drivers
                .filter((d) => !d.primary || d.primary === false)
                .map((driver) => (
                  <option
                    key={driver['id']}
                    value={driver['id']}
                    selected={driver['married']}
                  >
                    {fullName(driver)}
                  </option>
                ))}
            </select>
          </div>
        )}

        <div
          className="checkbox-container"
          onClick={handleRideshareCheckboxChanged}
        >
          <input type="checkbox" checked={isRideshareConsentChecked} readOnly />
          <label />
          <div
            style={{ color: '#7e7e7e' }}
            onMouseOver={toggleCallout}
            onMouseOut={toggleCallout}
          >
            {' '}
            I understand if I’m insured with Go Insurance then I am not covered
            for
            <br />
            ridesharing.
          </div>
          {isShowingCallout && (
            <div className="callout top">
              <div className="dialog-title"> Ridesharing </div>
              <div
                className="sub-heading"
                style={{
                  fontWeight: 'normal',
                  color: '#000000',
                  marginTop: '20px',
                }}
              >
                {`Sorry, if you are an Uber or Lyft driver we can't provide insurance for you.`}
              </div>
              <div
                style={{
                  fontWeight: 'normal',
                  color: '#727272',
                  marginTop: '20px',
                }}
              >
                {`“Ridesharing activity” means the use of any vehicle to provide transportation of persons in connection with
                  a transportation network company from the time a driver logs on to, or signs in to, any online‐enabled application,
                  software, website or system until the time the driver logs out of, or signs off of, any such online‐enabled application,
                  software, website or system, whether or not the driver has accepted any passenger(s), including the time the driver is
                  on the way to pick up any passenger(s), or is transporting any passenger(s).`}
              </div>
            </div>
          )}
        </div>

        <Link to={`/cars`}>
          <button className="primary" disabled={!isRideshareConsentChecked}>
            Next
          </button>
        </Link>
      </CenteredContainer>
    </div>
  )
}

export default Drivers
