const SubrogationWaitingResponse = {
  label: 'Waiting',
  progress: 0,
}

const SubrogationSubmitted = {
  label: 'Submitted',
  progress: 11,
}

const SubrogationApproved = {
  label: 'Approved',
  progress: 50,
}

const SubrogationPaid = {
  label: 'Paid',
  progress: 100,
}

const SubrogationCompleted = {
  label: 'Completed',
  progress: 100,
}

const SubrogationStatuses = [
  SubrogationWaitingResponse,
  SubrogationSubmitted,
  SubrogationApproved,
  SubrogationPaid,
]

const getStatusFromLabel = (label) => {
  switch (label) {
    case SubrogationWaitingResponse.label:
      return SubrogationWaitingResponse
    case SubrogationSubmitted.label:
      return SubrogationSubmitted
    case SubrogationApproved.label:
      return SubrogationApproved
    case SubrogationPaid.label:
      return SubrogationPaid
    case SubrogationCompleted.label:
      return SubrogationCompleted
    default:
      return null
  }
}

export {
  SubrogationWaitingResponse,
  SubrogationSubmitted,
  SubrogationApproved,
  SubrogationPaid,
  SubrogationCompleted,
  SubrogationStatuses,
  getStatusFromLabel,
}
