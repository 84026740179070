import './LicenseCapture.css'
import { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CenteredContainer } from '../../components/Container'
import ImageCapture from '../../components/ImageCapture'
import imgLicenseFront from '../../assets/icn-license-front.svg'
import imgLicenseBack from '../../assets/icn-license-back.svg'

const StateFrontPrimer = 'Front Primer'
const StateFrontCapture = 'Front Capture'
const StateBackPrimer = 'Back Primer'
const StateBackCapture = 'Back Capture'

export default function LicenseCapture() {
  const history = useHistory()

  const [state, setState] = useState(StateFrontPrimer)
  const [frontSrc, setFrontSrc] = useState(null)
  const [frontFile, setFrontFile] = useState(null)
  const [backSrc, setBackSrc] = useState(null)
  const [backFile, setBackFile] = useState(null)

  let viewPortWidth = window.innerWidth
  viewPortWidth -= 32 // minus container padding

  const mediaConfig = useMemo(
    () => ({
      video: {
        facingMode: 'environment',
        width: viewPortWidth,
        height: viewPortWidth,
      },
    }),
    [viewPortWidth]
  )

  const onCaptureFront = (imageData) => {
    setFrontSrc(imageData.jpeg)
    setFrontFile(imageData.file)
  }

  const onCaptureBack = (imageData) => {
    setBackSrc(imageData.jpeg)
    setBackFile(imageData.file)
  }

  const onCaptureError = useCallback((error) => {
    console.log(error)
  }, [])

  return (
    <div className="container">
      <CenteredContainer className="vendor-license-wrapper">
        {/* Front primer  */}
        {state === StateFrontPrimer && (
          <>
            <h1 className="title">Add the front of your driver’s license</h1>
            <p className="sub-title">
              Take a photo of the front of your license.
            </p>

            <div className="img-license-primer">
              <img src={imgLicenseFront} alt="" />
            </div>

            <button
              className="primary"
              onClick={() => setState(StateFrontCapture)}
            >
              Take photo
            </button>
          </>
        )}

        {/* Front capture  */}
        {state === StateFrontCapture && (
          <>
            <h1 className="title">Add the front of your driver’s license</h1>

            <div className="vendor-license-capture">
              {!frontSrc && (
                <ImageCapture
                  onCapture={onCaptureFront}
                  onError={onCaptureError}
                  userMediaConfig={mediaConfig}
                  width={viewPortWidth}
                  height={viewPortWidth}
                  btnText={'Take photo'}
                />
              )}

              {frontSrc && <img src={frontSrc} alt="captured license" />}

              {frontSrc && (
                <button
                  className="link grey btn-replace"
                  onClick={() => setFrontSrc(null)}
                >
                  Replace
                </button>
              )}
            </div>

            {frontSrc && (
              <button
                className="primary"
                onClick={() => setState(StateBackPrimer)}
              >
                Add front
              </button>
            )}
          </>
        )}

        {/* Back primer  */}
        {state === StateBackPrimer && (
          <>
            <h1 className="title">Add the back of your driver’s license</h1>
            <p className="sub-title">
              Take a photo of the back of your license.
            </p>

            <div className="img-license-primer">
              <img src={imgLicenseBack} alt="" />
            </div>

            <button
              className="primary"
              onClick={() => setState(StateBackCapture)}
            >
              Take photo
            </button>
          </>
        )}

        {/* Back capture  */}
        {state === StateBackCapture && (
          <>
            <h1 className="title">Add the back of your driver’s license</h1>

            <div className="vendor-license-capture">
              {!backSrc && (
                <ImageCapture
                  onCapture={onCaptureBack}
                  onError={onCaptureError}
                  userMediaConfig={mediaConfig}
                  width={viewPortWidth}
                  height={viewPortWidth}
                  btnText={'Take photo'}
                />
              )}

              {backSrc && <img src={backSrc} alt="captured license" />}

              {backSrc && (
                <button
                  className="link grey btn-replace"
                  onClick={() => setBackSrc(null)}
                >
                  Replace
                </button>
              )}
            </div>

            {backSrc && (
              <button
                className="primary"
                onClick={() => history.push('/business/bank')}
              >
                Add back
              </button>
            )}
          </>
        )}
      </CenteredContainer>
    </div>
  )
}
