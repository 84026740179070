import React, { useState, useEffect } from 'react'
import {
  ColumnContainer,
  ColumnLeft,
  ColumnRight,
} from '../components/Container'
import appCheckoutImage from './../assets/checkout-2@3x.png'
import codeIcon from './../assets/code.svg'
import UserModel from '../models/UserModel'
import NumberFormat from 'react-number-format'
import { Label, ErrorLabel } from '../components/Label'
import closeBtnIcon from '../assets/close-btn-icon.svg'
import phoneIcon from './../assets/phone-number.svg'
import { useAuthContext } from '../context/AuthContext'
import StackedMobile from '../components/StackedMobile'
import Strings from '../utils/Strings'
import Sentry from '../logger/Sentry'

function Verify(props) {
  const { setAuth } = useAuthContext()

  const [email, setEmail] = useState(() => {
    const params = new URLSearchParams(props.location.search)
    return params.get('email')
  })

  const [userIDParam] = useState(() => {
    const params = new URLSearchParams(props.location.search)
    return params.get('userID')
  })

  const [phoneNumber, setPhoneNumber] = useState('')
  const [code, setCode] = useState('')
  const [showHelp, setShowHelp] = useState(false)
  const [codeError, setCodeError] = useState('')

  useEffect(() => {
    if (email) {
      if (!Strings.isValidEmail(email)) {
        props.history.push('/login')
      }
    }
    // eslint-disable-next-line
  }, [email])

  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value)
  }

  const handleCodeChange = (e) => {
    setCodeError('')
    setCode(e.target.value)
  }

  const isValid = () => {
    if (email) {
      return Strings.isValidPhone(phoneNumber)
    }

    return code.trim().length === 4
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault()

    if (!isValid()) {
      return
    }

    if (email) {
      const { userID, err } = await UserModel.login(
        email,
        phoneNumber.replace(/\s/g, ''),
        userIDParam
      )
      if (err) {
        console.log('login failed:', err)
        setCodeError(
          `Sorry, we ran into an unexpected issue and we're still figuring it out.`
        )

        Sentry.error('LoginError', err, {
          email,
          phoneNumber,
          userIDParam,
          error: err,
        })
        return
      }

      console.log('moving to verify userID:', userID)

      Sentry.info('VerifyUserDone', 'moving to verify user', {
        email,
        phone: phoneNumber,
      })

      setEmail(null)
      props.history.push(`/verify?phone=${phoneNumber.replace(/\s/g, '')}`)
    } else {
      const { user, err } = await UserModel.verifyUser(code)
      if (err) {
        console.log('verification failed:', err)
        setCodeError('Please enter a valid code')

        Sentry.error('VerifyError', err, {
          code,
          error: err,
        })
        return
      }

      if (user.token) {
        setAuth(user.token)
        Sentry.info('SetsUserAuth', 'verified user and user auth set', user)
        props.history.push('/app-first', { platform: user.platform || '' })
      }
    }
  }

  const resendCode = async (e) => {
    const { err } = await UserModel.verifyUser('', true)
    if (err) {
      console.log('resend code request failed:', err)
      Sentry.error('ResendCodeError', err, { error: err })
      return
    }

    setShowHelp(false)
  }

  const editPhoneNumber = (e) => {
    props.history.push('/login')
  }

  return (
    <ColumnContainer>
      <ColumnLeft>
        <div className="verify-header">
          {email ? (
            <h2 className="display">
              Enter your phone number and we will send you a code
            </h2>
          ) : (
            <h2 className="display">Enter the 4 digit code sent to you</h2>
          )}
          <p>
            Proving you are not a bot will allow Go Insurance to give you a real
            quote.
          </p>
        </div>
        <div className="form-container" style={{ marginTop: '32px' }}>
          <form onSubmit={handleFormSubmit}>
            <StackedMobile
              left={
                email ? (
                  <div>
                    <Label icon={phoneIcon} text="PHONE NUMBER"></Label>
                    <NumberFormat
                      type="tel"
                      format="### ### ####"
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={handlePhoneChange}
                      inputMode="tel"
                      autoComplete="tel"
                    />
                    <ErrorLabel errorMessage={codeError} />
                  </div>
                ) : (
                  <div>
                    <Label icon={codeIcon} text="ENTER CODE"></Label>
                    <NumberFormat
                      className={codeError && 'error'}
                      format="####"
                      name="code"
                      value={code}
                      onChange={handleCodeChange}
                      inputMode="numeric"
                      autoComplete="one-time-code"
                    />
                    <ErrorLabel errorMessage={codeError} />
                    <div
                      className="get-help"
                      onClick={(e) => setShowHelp(true)}
                    >
                      Get help
                    </div>
                  </div>
                )
              }
              right={
                <div className="verify-container">
                  <button
                    className="primary"
                    type="submit"
                    disabled={!isValid()}
                  >
                    Verify
                  </button>
                </div>
              }
              leftWidth="75%"
              rightWidth="25%"
            />
          </form>
        </div>
        {showHelp && (
          <div className="overlay">
            <div className="help-dialog">
              <button
                className="help-close"
                onClick={(e) => setShowHelp(false)}
              >
                <img src={closeBtnIcon} alt="close button" />
              </button>
              <div className="dialog-title">Need help?</div>
              <p
                style={{
                  marginTop: '27px',
                  marginBottom: '20px',
                  color: '#000000',
                }}
              >
                If you didn’t receive the code we can resend it to
                <br />
                you or you can edit recipient phone number.
              </p>
              <div>
                <button
                  className="primary"
                  style={{ marginBottom: '-8px' }}
                  onClick={resendCode}
                >
                  Resend the code
                </button>
                <button className="primary" onClick={editPhoneNumber}>
                  Edit phone number
                </button>
              </div>
            </div>
          </div>
        )}
      </ColumnLeft>
      <ColumnRight className="no-mobile">
        <img
          className="app-checkout-img"
          src={appCheckoutImage}
          alt="App checkout screen"
        />
      </ColumnRight>
    </ColumnContainer>
  )
}

export default Verify
