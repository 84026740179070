import { CenteredContainer } from '../../components/Container'

export default function ClaimsPayNoPending(props) {
  return (
    <div className="container">
      <CenteredContainer>
        <div className="claims-pay-verify">
          <h2 className="title">Claim Payments</h2>
          <p className="title-text">
            There are no pending payments for your claim. If this doesn't seem
            accurate please reach out to your adjuster.
          </p>
        </div>
      </CenteredContainer>
    </div>
  )
}
