import '../../styles/ClaimsPay.css'
import { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { CenteredContainer } from '../../components/Container'
import codeIcon from '../../assets/code.svg'
import { ErrorLabel, Label } from '../../components/Label'
import NumberFormat from 'react-number-format'
import ClaimModel from '../../models/ClaimModel'
import { useState } from 'react'
import LoadingIndicator from '../../components/LoadingIndicator'
import ClaimsPayModel from '../../models/ClaimsPayModel'
import Store from '../../models/Store'

export default function ClaimsPayVerify(props) {
  let location = useLocation()
  let history = useHistory()

  const policyNumber =
    location.state && location.state.policyNumber
      ? location.state.policyNumber
      : ''

  const claimNumber =
    location.state && location.state.claimNumber
      ? location.state.claimNumber
      : ''

  const claimsPaymentID =
    location.state && location.state.claimsPaymentID
      ? location.state.claimsPaymentID
      : ''

  const [isLoading, setIsLoading] = useState(true)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState('')
  const [isVerifying, setIsVerifying] = useState(false)

  const requestCode = async (policyNumber) => {
    const { phoneNumber, err } = await ClaimModel.requestCode(
      policyNumber,
      true,
      claimsPaymentID
    )
    if (err) {
      if (err.err && err.err.toString() === 'request was not valid') {
        setCodeError(`Sorry, couldn't validate your phone number.`)
        setIsLoading(false)
        return
      }

      setCodeError(
        `Sorry, we ran into an unexpected issue and we’re still figuring it out.`
      )
      setIsLoading(false)
      return
    }

    setPhoneNumber(phoneNumber)
    setIsLoading(false)
  }

  const claimsPayRequestCode = async (claimNumber) => {
    const { phoneNumber, err } = await ClaimsPayModel.requestCodeToVerify(
      claimNumber,
      claimsPaymentID
    )
    if (err) {
      if (err.err && err.err.toString() === 'request was not valid') {
        setCodeError(`Sorry, couldn't validate your phone number.`)
        setIsLoading(false)
        return
      }

      setCodeError(
        `Sorry, we ran into an unexpected issue and we’re still figuring it out.`
      )
      setIsLoading(false)
      return
    }

    setPhoneNumber(phoneNumber)
    setIsLoading(false)
  }

  const fetchPayments = async () => {
    const user = Store.getUser()
    const { claimsPayment, err } = await ClaimsPayModel.getPendingPayments(
      user.policyID,
      claimsPaymentID
    )
    if (err) {
      window.location = '/claims-pay/no-pending'
      return
    }

    console.log({ claimsPayment })

    if (user.isVendor) {
      history.push(`/claims-pay/ein/${claimsPayment.claims_payment_id}`, {
        claimsPayment,
      })
      return
    }

    if (claimsPayment.license_verified) {
      window.location = `/claims-pay/verify-bank/${claimsPayment.claims_payment_id}`
      return
    }

    window.location = `/claims-pay/license/${claimsPayment.claims_payment_id}`
  }

  const verifyCode = async (code) => {
    setIsVerifying(true)
    const { err } = await ClaimModel.login(
      policyNumber,
      code,
      true,
      claimsPaymentID
    )
    if (err) {
      setCodeError('Invalid verification code')
      setIsVerifying(false)
      return
    }

    await fetchPayments()
  }

  const claimsPayVerifyCode = async (code) => {
    setIsVerifying(true)
    const { err } = await ClaimsPayModel.verify(
      claimNumber,
      claimsPaymentID,
      code
    )
    if (err) {
      setCodeError('Invalid verification code')
      setIsVerifying(false)
      return
    }

    await fetchPayments()
  }

  const handleCodeChange = async (values) => {
    if (codeError) {
      setCodeError('')
    }

    const codeValue = values.value
    setCode(codeValue)
    if (codeValue.length === 4) {
      if (claimNumber) {
        claimsPayVerifyCode(codeValue)
      } else {
        verifyCode(codeValue)
      }
    }
  }

  useEffect(() => {
    if (policyNumber && !phoneNumber) {
      requestCode(policyNumber)
    }

    if (claimNumber && !phoneNumber) {
      claimsPayRequestCode(claimNumber)
    }
    // eslint-disable-next-line
  }, [policyNumber])

  if (!policyNumber && !claimNumber) {
    window.location = '/claims-pay'
    return
  }

  return (
    <div className="container">
      <CenteredContainer>
        {isLoading && (
          <LoadingIndicator style={{ marginTop: '10vh' }} text="Loading..." />
        )}
        {!isLoading && (
          <div className="claims-pay-verify">
            <h2 className="title">
              Enter the 4 digit code sent to your registered number •••
              {phoneNumber}
            </h2>
            <p className="title-text">
              Proving you are not a bot will allow Go Insurance to pay your
              claim.
            </p>

            <form onSubmit={(e) => e.preventDefault()}>
              <div>
                <Label icon={codeIcon} text="ENTER CODE"></Label>
                <NumberFormat
                  className={codeError && 'error'}
                  format="####"
                  name="code"
                  value={code}
                  isNumericString={true}
                  onValueChange={handleCodeChange}
                  inputMode="numeric"
                  autoComplete="one-time-code"
                  disabled={isVerifying}
                />
                {isVerifying && (
                  <p className="verifying-code-text">Verifying code...</p>
                )}
                <ErrorLabel errorMessage={codeError} />
                {/* <div
                  className="get-help"
                  // onClick={(e) => setShowHelp(true)}
                >
                  Get help
                </div> */}
              </div>
            </form>
          </div>
        )}
      </CenteredContainer>
    </div>
  )
}
