import '../styles/ContactCard.css'
import { useState } from 'react'
import Strings from '../utils/Strings'
import fallbackImg from '../assets/profile-photo-default.svg'

export default function ContactCard({ contact }) {
  const [showHoursList, setShowHoursList] = useState(false)

  return (
    <div className="contact-card">
      <div className="profile-image-wrapper">
        <img
          className="profile-img"
          src={contact.image_url || fallbackImg}
          alt="contact person"
        />
      </div>
      <div className="contact-details-wrapper">
        <div className="name">{contact.title}</div>
        <div>{contact.sub_title}</div>
        <div>{Strings.formatPhoneNumber(contact.phone)}</div>
        <div>{contact.email}</div>
        {!contact.is_unassigned && (
          <div className="hours">
            Hours:{' '}
            {contact.hours[0].is_open_now ? (
              <span className="online">Online</span>
            ) : (
              <span className="offline">Offline</span>
            )}{' '}
            •{' '}
            <div
              className="selector"
              onClick={() => setShowHoursList(!showHoursList)}
            >
              {getAvailabilityText(contact.hours)}
            </div>
            {showHoursList && (
              <ul className="open-hours-list">
                {contact.hours.map((hour, i) => {
                  if (i === 0) {
                    return null
                  }
                  return (
                    <li key={i}>
                      <span>{hour.weekday}</span>
                      {hour.start && hour.end ? (
                        <span>
                          {formatToTime(hour.start)}-{formatToTime(hour.end)}
                        </span>
                      ) : (
                        <span>Offline</span>
                      )}
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

function getAvailabilityText(hours) {
  if (!hours || hours.length === 0) {
    return ''
  }

  if (hours[0].is_open_now) {
    return `Offline ${formatToTime(hours[0].end)}`.replace(/:00/g, '')
  }

  const nextHourIndex = hours.findIndex(
    (hour, i) => i !== 0 && hour.start && hour.end
  )

  return `Online ${formatToTime(hours[nextHourIndex].start)} ${hours[
    nextHourIndex
  ].weekday.substring(0, 3)}`.replace(/:00/g, '')
}

function formatToTime(dateTimeStr) {
  const t = new Date(dateTimeStr)

  const timeOption = {
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  }
  const timeFormatter = new Intl.DateTimeFormat('en-US', timeOption)

  return timeFormatter.format(t).replace(/\s/g, '')
}
