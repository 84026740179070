class Store {
  constructor() {
    this.user = {
      userID: '',
      token: '',
    }

    this.agent = {}
  }

  setUser = (user) => {
    this.user = user
    localStorage.setItem('user', JSON.stringify(user))
  }

  getUser = () => {
    let user = localStorage.getItem('user')

    return JSON.parse(user)
  }

  setAgent = (agent) => {
    this.agent = agent
    localStorage.setItem('agent', JSON.stringify(agent))
  }

  getAgent = () => {
    let agent = localStorage.getItem('agent')

    return JSON.parse(agent)
  }

  clearAll = () => {
    localStorage.clear()
  }
  
  //
  // ---- Begin claim reporting data ----
  //
  setClaimUnauthedPolicyNumber = (policy) => {
    localStorage.setItem('claimUnauthedPolicyNumber', policy)
  }

  getClaimUnauthedPolicyNumber = () => {
    let val = localStorage.getItem('claimUnauthedPolicyNumber')
    if (val) {
      return val
    }
    return ""
  }

  setClaimUnauthedPolicyID = (policyID) => {
    localStorage.setItem('claimUnauthedPolicyID', policyID)
  }

  getClaimUnauthedPolicyID = () => {
    let val = localStorage.getItem('claimUnauthedPolicyID')
    if (val) {
      return val
    }
    return ""
  }

  setClaimUnauthedUserID = (userID) => {
    localStorage.setItem('claimUnauthedUserID', userID)
  }

  getClaimUnauthedUserID = () => {
    let val = localStorage.getItem('claimUnauthedUserID')
    if (val) {
      return val
    }
    return ""
  }

  setClaimUnauthedDriver = ({ driverIsPrimary, driverID }) => {
    localStorage.setItem('claimUnauthedDriver', JSON.stringify({ driverIsPrimary, driverID }))
  }

  getClaimUnauthedDriver = () => {
    let driver = localStorage.getItem('claimUnauthedDriver')

    return JSON.parse(driver)
  }

  setClaimReporterType = (reporterType) => {
    // NOTE: one of 'GO_CUSTOMER', 'GO_CUSTOMER_REPRESENTATIVE', 'THIRD_PARTY'
    localStorage.setItem('claimReporterType', reporterType)
  }
  
  getClaimReporterType = () =>{
    // NOTE: one of 'GO_CUSTOMER', 'GO_CUSTOMER_REPRESENTATIVE', 'THIRD_PARTY'
    let val = localStorage.getItem('claimReporterType')
    if (val) {
      return val
    }
    return "GO_CUSTOMER"
  }
  
  setClaimRepresentativeInfo = (representativeType, representativeName, representativeEmail, representativePhone) => {
    // NOTE: representativeType one of 'Attorney', 'Agent', 'Claimant', 'Insurance Carrier'
    let info = {
      type: representativeType,
      name: representativeName,
      email: representativeEmail,
      phone: representativePhone,
    }
    localStorage.setItem('claimRepresentativeInfo', JSON.stringify(info))
  }
  
  getClaimRepresentativeInfo = () => {
    let json = localStorage.getItem('claimRepresentativeInfo')
    if (json) {
      return JSON.parse(json)
    }
    return {
      type: "Agent",
      name: "",
      email: "",
      phone: "",
    }
  }
    
  setClaimClaimantInfo = (vin, insuranceCompany, driversLicenseState, driversLicenseNumber) => {
    let info = {
      vin,
      insuranceCompany,
      driversLicenseState,
      driversLicenseNumber,
    }
    localStorage.setItem('claimClaimantInfo', JSON.stringify(info))
  }
    
  getClaimClaimantInfo = () => {
    let json = localStorage.getItem('claimClaimantInfo')
    if (json) {
      return JSON.parse(json)
    }
    return {
      vin: "",
      insuranceCompany: "",
      driversLicenseState: "TX",
      driversLicenseNumber: "",
    }
  }
  //
  // ---- End claim reporting data ----
  //

}
export default new Store()
