import React, { createContext, useState, useContext } from 'react'
import Store from './../models/Store'
import Zendesk from '../models/Zendesk'

export const AuthContext = createContext()

export function useAuthContext() {
  return useContext(AuthContext);
}

export const AuthProvider = (props) => {
  let user = Store.getUser()
  const [auth, setAuth] = useState(user ? user.token : '')

  Zendesk.setup(user)
  return (
    <AuthContext.Provider value={{auth, setAuth}}>
      {props.children}
    </AuthContext.Provider>
  )
}
