import '../styles/LoadingIndicator.css'
import loadingIcon from '../assets/loading.gif'
import React from 'react'

const LoadingIndicator = ({ text, style }) => {
  return (
    <div className="loading-indicator" style={style}>
      <img src={loadingIcon} alt="loading icon" />
      <p>{text}</p>
    </div>
  )
}

export default LoadingIndicator
