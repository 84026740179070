import React, { useState, useEffect } from 'react'
import UserModel from '../models/UserModel'
import {
  ColumnContainer,
  ColumnLeft,
  ColumnRight,
} from '../components/Container'
import Summary from '../components/Summary'
import Currency from '../utils/Currency'
import getCheckIcon from '../assets/get-check.svg'
import Breadcrumb from '../components/Breadcrumb'
import Store from '../models/Store.js'
import StackedMobile from '../components/StackedMobile'
import Utils from '../utils/Utils'
import Sentry from '../logger/Sentry'
import Dates from '../utils/Dates'
import AlertIcon from '../assets/icn-alert.svg'
import BrokerInfo from '../components/BrokerInfo'
import LoadingIndicator from '../components/LoadingIndicator'

function Policy(props) {
  const u = Store.getUser()
  const userID = u ? u.userID : ''

  const [agent, setAgent] = useState(null)
  const [user, setUser] = useState(null)
  const [license, setLicense] = useState(null)
  const [address, setAddress] = useState(null)
  const [drivers, setDrivers] = useState([])
  const [cars, setCars] = useState([])
  const [policyCoverages, setPolicyCoverages] = useState(null)
  const [vehicleCoverages, setVehicleCoverages] = useState(null)
  const [quote, setQuote] = useState(null)
  const [policy, setPolicy] = useState(null)
  const [hasGET, setHasGET] = useState(false)
  const [discountGET, setDiscountGET] = useState(null)
  const [discountInfo, setDiscountInfo] = useState('')
  const [retry, setRetry] = useState(0)
  const [processing, setProcessing] = useState(true)
  const [coverageOptions, setCoverageOptions] = useState(null)
  const [hasPayLater, setHasPayLater] = useState(false)

  const fetchUser = async () => {
    const {
      user,
      license,
      address,
      drivers,
      vehicles,
      coverageOptions,
      policyCoverages,
      vehicleCoverages,
      quote,
      policy,
      agent,
      err,
    } = await UserModel.getUser(userID, 'policy')
    if (err) {
      console.log('error getting user:', err)
      return
    }

    setCoverageOptions(coverageOptions)
    if (user) {
      setUser(user)
    }

    if (license) {
      setLicense(license)
    }

    if (address) {
      setAddress(address)
    }

    if (drivers) {
      setDrivers(drivers)
    }

    if (vehicles) {
      setCars(vehicles)
    }

    if (policyCoverages) {
      setPolicyCoverages(policyCoverages)
    }

    if (vehicleCoverages) {
      setVehicleCoverages(vehicleCoverages)
    }

    if (agent) {
      setAgent(agent)
    }

    if (quote) {
      setQuote(quote)
    }

    if (policy) {
      if (policy.status === 'PENDING' && policy.payment_auth === true) {
        props.history.push(`/policy-auth/${userID}`)
        return
      }

      setPolicy(policy)
      const hasGET =
        policy.has_essential_worker_discount ||
        policy.has_essential_worker_family_discount
      setHasGET(hasGET)

      if (hasGET) {
        const discountPercentage =
          policy.config.app_config.GET_discount_percentage
        setDiscountGET(discountPercentage / 100)
        let discountInfo = `${discountPercentage}% ESSENTIAL WORKER DISCOUNT`
        if (address.state !== 'TX') {
          discountInfo = `${discountPercentage}% ${Utils.getStateFullName(
            address.state
          ).toUpperCase()} DISCOUNT`
        }
        setDiscountInfo(discountInfo)
      }

      if (policy.policy_number || policy.status === 'PENDING') {
        if (policy.status && policy.is_effective_to_friday) {
          setHasPayLater(
            Dates.toDate(policy.effective_date) !== Dates.dateToday()
          )
        }

        setProcessing(false)
        if (user && license) {
          Utils.sendGTMEvent('Policy', user, license)
          Sentry.info(
            'GotPolicyNumber',
            'created policy and got policy number',
            { policy }
          )
        }
      }
    }
  }

  useEffect(() => {
    const getUser = async () => {
      fetchUser()
    }

    if (!policy || !policy.policy_number) {
      if (policy && policy.status === 'PENDING') {
        setProcessing(false)
        return
      }
      setTimeout(() => {
        console.log('polling...')
        getUser()
        setRetry(retry + 1)
      }, 2000)
    }
    // eslint-disable-next-line
  }, [retry])

  return (
    <div className="container">
      <Breadcrumb page={'policy'} />

      <StackedMobile
        inverted={true}
        left={
          <div>
            {agent && <BrokerInfo agent={agent} style={{ margin: '28px 0' }} />}
            {quote && (
              <div
                className="title"
                style={{ color: '#000000', marginBottom: '20px' }}
              >
                {`${license.first_name} ${license.last_name}`}
              </div>
            )}

            {!processing ? (
              <div>
                {policy.policy_number && <p>Policy # {policy.policy_number}</p>}
                <br />

                <div className="billing-item disabled">
                  <div>
                    <p className="pricing-text">
                      {hasGET && (
                        <span
                          className="discount-text"
                          style={{ marginRight: '8px' }}
                        >
                          $
                          {Currency.format(
                            policy.down_payment / (1 - discountGET)
                          )}
                        </span>
                      )}{' '}
                      ${Currency.format(policy.down_payment)}{' '}
                      {policy.installments === 1 ? 'for 6 months' : 'per month'}
                    </p>
                    {discountInfo && (
                      <p className="discount-info">
                        <img src={getCheckIcon} alt="get discount check" />{' '}
                        {discountInfo}
                      </p>
                    )}
                  </div>
                </div>

                {hasPayLater && (
                  <div className="pay-later-box">
                    <img src={AlertIcon} className="alert-icon" alt="" />
                    <p>
                      Due to high customer volume you will be charged tomorrow{' '}
                      {Dates.toLongDateString(policy.effective_date)} and will
                      be effective tomorrow at 12:01am.
                    </p>
                  </div>
                )}
              </div>
            ) : (
              <div className="billing-item">
                <LoadingIndicator text="Loading..." />
              </div>
            )}

            <div
              className="divider"
              style={{ marginTop: '40px', marginBottom: '50px' }}
            />

            <Summary
              user={user}
              license={license}
              address={address}
              drivers={drivers}
              cars={cars}
              policyCoverages={policyCoverages}
              vehicleCoverages={vehicleCoverages}
              coverageOptions={coverageOptions}
            />
          </div>
        }
        right={
          <div className="policy-right">
            <h3 className="heading">Next Steps</h3>
            <div className="green-border">
              <ul>
                <li>
                  Go Insurance will send you an email confirmation with a link
                  to download the 5 star rated Go Insurance app.
                </li>
                <li>
                  Once you download the app and scan your driver’s license, you
                  will have access to your insurance ID Card, policy summary,
                  payments, and claims.
                </li>
                <li>
                  Go Insurance will verify mileage directly with the customer
                  after their first renewal.
                </li>
              </ul>
            </div>
          </div>
        }
      />
      <ColumnContainer>
        <ColumnLeft></ColumnLeft>
        <ColumnRight></ColumnRight>
      </ColumnContainer>
    </div>
  )
}

export default Policy
