import '../styles/BrokerQuote.css'
import React, { useState, useEffect } from 'react'
import ReactHtmlParser from 'react-html-parser'
import Summary from '../components/Summary'
import selectedCheckIcon from '../assets/selected-check.svg'
import getCheckIcon from '../assets/get-check.svg'
import loadingIcon from '../assets/loading.gif'
import CheckoutForm from '../components/CheckoutForm'
import Currency from '../utils/Currency'
import UserModel from '../models/UserModel'
import PolicyModel from '../models/PolicyModel'
import Breadcrumb from '../components/Breadcrumb'
import Store from '../models/Store.js'
import StackedMobile from '../components/StackedMobile'
import Utils from '../utils/Utils'
import Dates from '../utils/Dates'
import VehicleVerification from '../components/VehicleVerification'
import Sentry from '../logger/Sentry'
import AlertIcon from '../assets/icn-alert.svg'
import AgentModel from '../models/AgentModel'
import { Link, useHistory } from 'react-router-dom'
import applicationIcon from '../assets/icn-application.svg'
import BrokerInfo from '../components/BrokerInfo'
import BookModel from '../models/BookModel'
import infoIcon from '../assets/icon-info.svg'
import closeBtnIcon from '../assets/close-btn-icon.svg'
import ProofOfCoverage from '../components/ProofOfCoverage'

function Quote(props) {
  const history = useHistory()

  const userIDParam = props.match.params.user_id

  const u = Store.getUser()
  if (u && userIDParam) {
    Store.clearAll()
  }
  const userID = u ? u.userID : ''
  const usesShareCode = u ? u.usesShareCode : false

  const [doPayLater, setDoPayLater] = useState(false)
  const [agent, setAgent] = useState(null)
  const [user, setUser] = useState(null)
  const [license, setLicense] = useState(null)
  const [address, setAddress] = useState(null)
  const [policyCoverages, setPolicyCoverages] = useState(null)
  const [vehicleCoverages, setVehicleCoverages] = useState(null)
  const [userState, setUserState] = useState(null)
  const [drivers, setDrivers] = useState([])
  const [cars, setCars] = useState([])
  const [quote, setQuote] = useState(null)
  const [isGET, setIsGET] = useState(true)
  const [isGETChecked, setIsGETChecked] = useState(true)
  const [hasGET, setHasGET] = useState(false)
  const [discountInfo, setDiscountInfo] = useState('')
  const [fetchQuote, setFetchQuote] = useState(false)
  const [processing, setProcessing] = useState(true)
  const [binding, setBinding] = useState(false)
  const [quoteError, setQuoteError] = useState(null)
  const [firstNonGETQuote, setFirstNonGETQuote] = useState(true)
  const [coverageOptions, setCoverageOptions] = useState(null)
  const [needsVehicleVideos, setNeedsVehicleVideos] = useState(false)
  const [hasVehicleVideos, setHasVehicleVideos] = useState(false)
  const [payInFullSelected, setPayInFullSelected] = useState(false)
  const [showCheckout, setShowCheckout] = useState(false)
  const [agentQuoteVerified, setAgentQuoteVerified] = useState(false)
  const [loadingVerification, setLoadingVerification] = useState(usesShareCode)
  const [hasMissingFields, setHasMissingFields] = useState(false)
  const [isPaymentAuth, setIsPaymentAuth] = useState(false)
  const [isTCChecked, setIsTCChecked] = useState(false)
  const [showTCCallout, setShowTCCallout] = useState(false)
  const [focusProofOfCoverage, setFocusProofOfCoverage] = useState(false)
  const [needsProofOfCoverage, setNeedsProofOfCoverage] = useState(false)
  const [proofOfCoverageProvided, setProofOfCoverageProvided] = useState(false)
  const [proofOfCoverageErrMsg, setProofOfCoverageErrMsg] = useState('')

  const toggleBillingSelection = () => {
    setPayInFullSelected(!payInFullSelected)
  }

  const showCheckoutForm = () => {
    if (!showCheckout) {
      setShowCheckout(true)
    }
  }

  const fetchUser = async () => {
    if (userIDParam) {
      const newUserAuth = await UserModel.createUserAuth(userIDParam)
      if (newUserAuth.err) {
        window.location = '/login'
        return
      }

      // reload page so private routes work after user auth is set
      window.location = '/quote'
      return
    }

    const {
      user,
      license,
      address,
      drivers,
      vehicles,
      coverageOptions,
      quote,
      policyCoverages,
      vehicleCoverages,
      userState,
      isBookQuote,
      agent,
      err,
    } = await UserModel.getUser(userID, 'quote', fetchQuote, isGET)
    if (err) {
      console.log('error getting user:', err)
      if (err.title) {
        setQuoteError(err.title)

        Sentry.error('QuoteError', err.title, {
          isGET,
          error: err,
        })
      } else {
        setQuoteError(
          'Sorry, this applicant requires additional underwriting and cannot bind at this time. Please chat Go Support.'
        )

        Sentry.error('QuoteError', err, {
          isGET,
          error: err,
        })
      }
      setProcessing(false)
      return
    }

    setCoverageOptions(coverageOptions)
    setUser(user)
    if (user && user.platform !== 'ios' && user.platform !== 'android') {
      window.location = `/app-first/${userID}`
      return
    }
    setLicense(license)
    setAddress(address)
    setDrivers(drivers)
    setCars(vehicles)
    setPolicyCoverages(policyCoverages)
    setVehicleCoverages(vehicleCoverages)
    setUserState(userState)

    if (agent) {
      setAgent(agent)
    }

    if (quote) {
      setQuote(quote)

      if (isBookQuote) {
        //setDoPayLater(Dates.toDate(quote.start_date) != Dates.dateToday())
      }

      // if (quote.is_effective_to_friday) {
      //   setDoPayLater(Dates.toDate(quote.start_date) != Dates.dateToday())
      // }

      const hasGET =
        quote.has_essential_worker_discount ||
        quote.has_essential_worker_family_discount

      setHasGET(hasGET)

      if (hasGET) {

        let discountInfo = `ESSENTIAL WORKER DISCOUNT`
        if (address.state !== 'TX') {
          discountInfo = `${Utils.getStateFullName(
            address.state
          ).toUpperCase()} DISCOUNT`
        }
        setDiscountInfo(discountInfo)
      }

      setIsPaymentAuth(quote.use_payment_auth)

      if (quote.needs_vehicle_videos) {
        setNeedsVehicleVideos(quote.needs_vehicle_videos)
        setHasVehicleVideos(quote.has_vehicle_videos)
      } else {
        setNeedsVehicleVideos(false)
        setHasVehicleVideos(false)
      }

      if (quote.needs_proof_of_coverage) {
        setNeedsProofOfCoverage(true)
        setProofOfCoverageProvided(quote.proof_of_coverage_provided)
        setFocusProofOfCoverage(
          quote.needs_proof_of_coverage && !quote.proof_of_coverage_provided
        )
        setProofOfCoverageErrMsg(quote.proof_of_coverage_err_msg || '')
      } else {
        setNeedsProofOfCoverage(false)
        setProofOfCoverageProvided(false)
        setFocusProofOfCoverage(false)
      }

      Sentry.info('GotQuote', 'got quote for user', { quote })
    }

    if (fetchQuote) {
      setProcessing(false)
    } else {
      setFetchQuote(true)
    }

    if (user && license) {
      Utils.sendGTMEvent('Quote', user, license)
    }
  }

  const fetchQuoteVerification = async () => {
    if (!usesShareCode) {
      return
    }

    const { isVerified, err } = await AgentModel.getQuoteVerification()
    if (err) {
      setQuoteError('Something went wrong, please contact your broker.')
      return
    }

    if (isVerified) {
      setAgentQuoteVerified(isVerified)
      setShowCheckout(true)
    } else {
      setAgentQuoteVerified(false)
    }

    setLoadingVerification(false)
  }

  useEffect(() => {
    if (firstNonGETQuote && quote) {
      setProcessing(true)
      setFirstNonGETQuote(false)
    }

    const getUser = async () => {
      fetchUser()
    }
    getUser()

    fetchQuoteVerification()
    // eslint-disable-next-line
  }, [fetchQuote, isGET])

  const fetchMissingFields = async () => {
    const { response, err } = await BookModel.getMissingFields(userID)
    if (err) {
      console.log('error fetching missing fields:', err)
      return
    }

    if (
      response &&
      response.missing_fields &&
      response.missing_fields.length > 0
    ) {
      setHasMissingFields(true)
    } else {
      setHasMissingFields(false)
    }
  }

  useEffect(() => {
    if (!processing) {
      fetchMissingFields()
    }
    // eslint-disable-next-line
  }, [processing])

  const handleProofOfCoverageCompleted = (err) => {
    if (err) {
      alert('Sorry, there was an error uploading document.')
      return
    }

    setProcessing(true)
    //setHasLapseError(false)
    fetchUser()
  }

  const getVehicleCoverage = (vehicleID) => {
    const cov = vehicleCoverages.find((vehCov) => {
      return vehCov.vehicle_id === vehicleID
    })

    return cov
  }

  const onCreatePayment = async (token, error) => {
    if (error) {
      console.log('stripe returned error:', error)
      setQuoteError(true)

      Sentry.error('PaymentError', error, { error })
      return
    }

    console.log('stripe payment token:', token)
    setBinding(true)

    const [policy, policyErr] = await PolicyModel.createPolicy(
      userID,
      user.email,
      user.phone_number,
      quote.id,
      token,
      isPaymentAuth,
      payInFullSelected,
      doPayLater
    )

    if (policyErr) {
      console.log('error creating policy:', policyErr)
      setBinding(false)

      Sentry.error('PolicyBindError', policyErr, {
        email: user.email,
        phone: user.phone_number,
        quote_id: quote.id,
        payInFullSelected,
        error: policyErr,
      })

      return
    }

    console.log('created policy:', policy)
    props.history.push(`/policy/${policy.user_id}`)
  }

  const onVehicleVerificationSubmit = (hasVehicleVideos) => {
    setHasVehicleVideos(hasVehicleVideos)
  }

  const isValid = () => {
    if (hasMissingFields) {
      return false
    }

    if (needsVehicleVideos && !hasVehicleVideos) {
      return false
    }

    if (!isTCChecked) {
      return false
    }

    return !isGET || (isGET && isGETChecked)
  }

  const requestQuoteVerification = async () => {
    setLoadingVerification(true)

    const err = await AgentModel.createQuoteVerification()
    if (err) {
      setLoadingVerification(false)
      alert('Error contacting broker')
      return
    }

    history.push('/quote-verify', { agent: agent })
  }

  return (
    <div className="container">
      <Breadcrumb page={'quote'} userState={userState} license={license} />
      <div className="mobile-page-heading">Quote</div>

      <StackedMobile
        inverted={true}
        left={
          <div>
            {agent && <BrokerInfo agent={agent} style={{ margin: '28px 0' }} />}

            {hasMissingFields && (
              <Link to={`/finalize_quote/${userID}`}>
                <button
                  className="primary"
                  style={{ margin: '10px 0', maxWidth: '50%' }}
                >
                  Finalize Quote
                </button>
              </Link>
            )}

            <h3 className="heading" style={{ marginTop: '50px' }}>
              Policy Summary
            </h3>

            <Summary
              user={user}
              license={license}
              address={address}
              drivers={drivers}
              cars={cars}
              policyCoverages={policyCoverages}
              vehicleCoverages={vehicleCoverages}
              coverageOptions={coverageOptions}
            />
          </div>
        }
        right={
          <div style={{ marginTop: '50px' }}>
            {quote && !processing ? (
              <div>
                <h3 className="heading">
                  {isPaymentAuth
                    ? 'Billing Cycle and Authorization'
                    : 'Billing Cycle'}
                </h3>
                <div
                  className={`billing-item ${
                    !payInFullSelected && !focusProofOfCoverage
                      ? 'selected'
                      : ''
                  }`}
                  onClick={toggleBillingSelection}
                >
                  <div>
                    <p className="pricing-text">
                      {hasGET && (
                        <span
                          className="discount-text"
                          style={{ marginRight: '8px' }}
                        >
                          $
                          {Currency.format(quote.non_get_monthly_price)}
                        </span>
                      )}{' '}
                      ${Currency.format(quote.down_payment)} per month
                    </p>
                    {hasGET && (
                      <p className="discount-info">
                        <img src={getCheckIcon} alt="get discount check" />{' '}
                        {discountInfo}
                      </p>
                    )}
                  </div>
                  <img
                    className="selection-check"
                    src={selectedCheckIcon}
                    alt="selection check icon"
                  />
                </div>
                <div
                  className={`billing-item ${
                    payInFullSelected && !focusProofOfCoverage ? 'selected' : ''
                  }`}
                  onClick={toggleBillingSelection}
                >
                  <div>
                    <p className="pricing-text">
                      {hasGET && (
                        <span
                          className="discount-text"
                          style={{ marginRight: '8px' }}
                        >
                          $
                          {Currency.format(quote.non_get_pay_in_full_price)}
                        </span>
                      )}{' '}
                      ${Currency.format(quote.pay_in_full_amount)} for 6 months
                    </p>
                    {hasGET && (
                      <p className="discount-info">
                        <img src={getCheckIcon} alt="get discount check" />{' '}
                        {discountInfo}
                      </p>
                    )}
                  </div>
                  <img
                    className="selection-check"
                    src={selectedCheckIcon}
                    alt="selection check icon"
                  />
                </div>
                {address.state === 'TX' && !focusProofOfCoverage && (
                  <div>
                    <div style={{ marginTop: '30px', color: '#333333' }}>
                      Are you an Essential Worker or a family
                      <br />
                      member of an Essential Worker?
                    </div>
                    <div className="yesno-container">
                      <div className="yesno" onClick={(e) => setIsGET(true)}>
                        <div className={isGET ? 'circle-selected' : 'circle'} />
                        YES
                      </div>
                      <div className="yesno" onClick={(e) => setIsGET(false)}>
                        <div
                          className={!isGET ? 'circle-selected' : 'circle'}
                        />
                        NO
                      </div>
                    </div>
                    {isGET && (
                      <div
                        className="checkbox-container"
                        onClick={(e) => setIsGETChecked(!isGETChecked)}
                      >
                        <input
                          type="checkbox"
                          checked={isGETChecked}
                          readOnly
                        />
                        <label />
                        <div style={{ color: '#7e7e7e', marginBottom: '20px' }}>
                          Yes, I will add a paystub or fill in the family
                          <br />
                          discount form in the Go Insurance app
                          <br />
                          within 72 hours to keep the 50% discount.
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {needsProofOfCoverage && (
                  <div style={{ marginTop: '25.6px' }}>
                    <ProofOfCoverage
                      hasProvidedProof={proofOfCoverageProvided}
                      errMsg={proofOfCoverageErrMsg}
                      onComplete={handleProofOfCoverageCompleted}
                      coverageOptions={coverageOptions}
                    />
                  </div>
                )}

                {!focusProofOfCoverage && (
                  <div className="tc-text">
                    {isPaymentAuth && (
                      <p>
                        Your card will be authorized for the amount above, once
                        you activate your policy you will be charged.
                      </p>
                    )}

                    <div
                      className="checkbox-container"
                      style={{ position: 'relative', marginTop: '17px' }}
                    >
                      <input type="checkbox" checked={isTCChecked} readOnly />
                      <label onClick={() => setIsTCChecked(!isTCChecked)} />
                      {isPaymentAuth ? (
                        <p>
                          Yes, you authorize your card will be charged once you
                          activate your policy and agree to the E-Delivery Terms
                          & Conditions{' '}
                          <img
                            src={infoIcon}
                            alt="info"
                            style={{
                              verticalAlign: 'middle',
                              cursor: 'pointer',
                            }}
                            onMouseOver={() => setShowTCCallout(true)}
                          />
                        </p>
                      ) : (
                        <p>
                          Yes, you agree to the E-Delivery Terms & Conditions{' '}
                          <img
                            src={infoIcon}
                            alt="info"
                            style={{
                              verticalAlign: 'middle',
                              cursor: 'pointer',
                            }}
                            onMouseOver={() => setShowTCCallout(true)}
                          />
                        </p>
                      )}
                      {showTCCallout && (
                        <div className="tc-callout">
                          <img
                            className="close-btn"
                            src={closeBtnIcon}
                            alt="close button"
                            onClick={() => setShowTCCallout(false)}
                          />
                          <div className="overflow">
                            <h4 className="title">
                              E-Delivery Terms & Conditions
                            </h4>

                            {EDeliveryTerms()}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {needsVehicleVideos && !focusProofOfCoverage && (
                  <VehicleVerification
                    userID={userID}
                    active={!hasVehicleVideos}
                    onSubmit={onVehicleVerificationSubmit}
                  />
                )}
              </div>
            ) : (
              <div>
                {processing ? (
                  <div className="billing-item">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <img
                              style={{ width: '22px', height: 'auto' }}
                              src={loadingIcon}
                              alt="loading..."
                            />
                          </td>
                          <td style={{ paddingBottom: '5px' }}>
                            &nbsp;Loading Quote...
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="error-dialog">
                    {ReactHtmlParser(quoteError)}
                  </div>
                )}
              </div>
            )}

            {doPayLater && (
              <div className="pay-later-box">
                <img src={AlertIcon} className="alert-icon" alt="" />
                <p>
                  Due to high customer volume you will be charged tomorrow{' '}
                  {Dates.toLongDateString(quote.start_date)} and will be
                  effective tomorrow at 12:01am.
                </p>
              </div>
            )}

            {!processing && showCheckout ? (
              <div style={{ marginTop: '30px' }}>
                {usesShareCode && (
                  <div className="agent-quote-verified">
                    <img src={applicationIcon} alt="check mark" />{' '}
                    <p>Broker verified application.</p>
                  </div>
                )}

                <CheckoutForm
                  firstName={license.first_name}
                  lastName={license.last_name}
                  onCreatePayment={onCreatePayment}
                  binding={binding}
                  enabled={isValid()}
                  text={isPaymentAuth ? 'Confirm Authorization' : 'Checkout'}
                />
              </div>
            ) : (
              <>
                {!processing && usesShareCode ? (
                  <>
                    {!loadingVerification && !agentQuoteVerified && (
                      <div className="primary checkout-btn">
                        <p className="agent-context-text">
                          To purchase this policy get your verification code
                          from {agent.firstname}.
                        </p>
                        <button
                          className="primary"
                          onClick={requestQuoteVerification}
                        >
                          Request code from {agent.firstname}
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {!focusProofOfCoverage && (
                      <button
                        className="primary checkout-btn"
                        onClick={showCheckoutForm}
                        disabled={processing || !isValid()}
                      >
                        {isPaymentAuth ? 'Confirm Authorization' : 'Checkout'}
                      </button>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        }
      />
    </div>
  )
}

function EDeliveryTerms() {
  return (
    <>
      <p>
        By voluntarily opting-in to electronic delivery of notices and documents
        the custoer is affirmatively consenting to these terms and conditions.
      </p>

      <p>
        These terms and conditions may change from time to time and all updates
        will be emailed to the customer, and it is the customer’s responsibility
        to review this information. When we do change these terms and
        conditions, we may also revise the “Revised” date and may notify the
        customer or post a message on the Website and/or through the service.
      </p>

      <p>
        Hardware requirement: The customer will need to be able to access the
        internet and have access to an iOS smartphone.
      </p>

      <p>
        Software requirement: The customer will need to have either an iOS
        version 13 and above or Android version 7 and above.
      </p>

      <p>
        We will inform the customer of any changes to these hardware and
        software requirements. The customer will need to review the new
        requirements and contact us should we make any changes to the hardware
        or software requirements for the e-delivery option such that the
        customer may no longer be capable of accessing or retaining your
        electronic insurance documents.
      </p>

      <p>
        The customer is responsible to inform us of any changes to their email
        address, as well as keeping their email address active and capable of
        receiving new emails. Their email address can be updated in Go at any
        time. We are not responsible for issues due to emails sent to inactive
        or out-of-date email addresses, unless we are solely negligent for using
        an incorrect address.
      </p>

      <p>
        Their consent to these terms and conditions, confirms that they are
        consenting to receiving all policies, endorsements, notices and/or
        documents from us electronically. However, there may be some documents
        that we continue to send via mail due to legal or technological
        constraints. The customer has the right to request any of these
        documents in paper by contacting us through business chat in app or by
        emailing us at <a href="mailto:help@goclub.com">help@goclub.com</a>.
      </p>
    </>
  )
}

export default Quote
