import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CenteredContainer } from '../../components/Container'
import { Label } from '../../components/Label'
import LocationSearchInput from '../../components/LocationSearchInput'
import icnDriver from '../../assets/icn-driver.svg'
import icnMail from '../../assets/icn-mail.svg'
import icnAddress from '../../assets/icn-address-pin.svg'
import icnCode from '../../assets/icn-code.svg'
import NumberFormat from 'react-number-format'

const StateBusinessName = 'Business Name'
const StateContact = 'Contact'
const StateAddress = 'Address'
const StateEIN = 'EIN'

export default function Register() {
  const history = useHistory()

  const [state, setState] = useState(StateBusinessName)
  const [businessName, setBusinessName] = useState('')
  const [contactName, setContactName] = useState('')
  const [contailEmail, setContailEmail] = useState('')
  const [ein, setEin] = useState('')

  return (
    <div className="container">
      <CenteredContainer style={{ paddingTop: '10vh' }}>
        {/* Businsess name  */}
        {state === StateBusinessName && (
          <>
            <h1 className="title">Business</h1>

            <div className="form-row">
              <div className="form-column">
                <Label icon={icnDriver} text="BUSINESS NAME" />
                <input
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                />
              </div>
            </div>

            <button className="primary" onClick={() => setState(StateContact)}>
              Continue
            </button>
          </>
        )}

        {/* Contact details  */}
        {state === StateContact && (
          <>
            <h1 className="title">Contact</h1>

            <div className="form-row">
              <div className="form-column">
                <Label icon={icnDriver} text="CONTACT NAME" />
                <input
                  value={contactName}
                  onChange={(e) => setContactName(e.target.value)}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-column">
                <Label icon={icnMail} text="EMAIL" />
                <input
                  value={contailEmail}
                  onChange={(e) => setContailEmail(e.target.value)}
                />
              </div>
            </div>

            <button className="primary" onClick={() => setState(StateAddress)}>
              Continue
            </button>
          </>
        )}

        {/* Address  */}
        {state === StateAddress && (
          <>
            <h1 className="title">Business Address</h1>

            <div className="form-row">
              <div className="form-column">
                <Label icon={icnAddress} text="ADDRESS" />
                <LocationSearchInput onChange={() => {}} />
              </div>
            </div>

            <button className="primary" onClick={() => setState(StateEIN)}>
              Continue
            </button>
          </>
        )}

        {/* EIN  */}
        {state === StateEIN && (
          <>
            <h1 className="title">EIN</h1>
            <p className="sub-title">
              Also known as a “Tax ID number,” the EIN is a business federal tax
              ID that works like a Social Security Number for your business.
              This is tp ensure payment is being sent to the right business
              account.
            </p>

            <div className="form-row">
              <div className="form-column">
                <Label icon={icnCode} text="EMPLOYER IDENTIFICATION NUMBER" />
                <NumberFormat
                  format="##-#######"
                  isNumericString={true}
                  name="ein"
                  value={ein}
                  onValueChange={(values) => setEin(values.value)}
                />
              </div>
            </div>

            <button
              className="primary"
              onClick={() => history.push('/business/license')}
            >
              Continue
            </button>
          </>
        )}
      </CenteredContainer>
    </div>
  )
}
