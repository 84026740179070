import validateDate from 'validate-date'

let Strings = {
  titleCase: function (str) {
    return str.toLowerCase().replace(/\b(\w)/g, (k) => k.toUpperCase())
  },

  isValidEmail: function (email) {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regexp.test(email)
  },

  isValidPhone: function (phone) {
    return phone.replace(/\D/g, '').length === 10
  },

  formatPhoneNumber: function (phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
  },

  inputToDateString: function (value) {
    let dateStr = ''
    const d = value.split(' ')
    if (d.length === 3) {
      dateStr = `${d[2]}-${d[0]}-${d[1]}`
    }

    return dateStr
  },

  dateStringToInput: function (value) {
    let inputStr = ''
    const d = value.split('-')
    if (d.length === 3) {
      inputStr = `${d[1]} ${d[2]} ${d[0]}`
    }

    return inputStr
  },

  dateStringToDisplay: function (value) {
    let inputStr = ''
    const d = value.split('-')
    if (d.length === 3) {
      inputStr = `${d[1]}/${d[2]}/${d[0]}`
    }

    return inputStr
  },

  isValidDate: function (dateStr) {
    return validateDate(dateStr, 'boolean')
  },

  isValidVIN: function (vin) {
    function transliterate(c) {
      return '0123456789.ABCDEFGH..JKLMN.P.R..STUVWXYZ'.indexOf(c) % 10
    }

    function get_check_digit(vin) {
      var map = '0123456789X'
      var weights = '8765432X098765432'
      var sum = 0
      for (var i = 0; i < 17; ++i)
        sum += transliterate(vin[i]) * map.indexOf(weights[i])
      return map[sum % 11]
    }

    if (vin.length !== 17) {
      return false
    }
    vin = vin.toUpperCase()
    if (vin.includes('I') || vin.includes('O') || vin.includes('Q')) {
      return false
    }
    return get_check_digit(vin) === vin[8]
  },

  prettyAddressString: function (address) {
    let addressStr = `${address.address1}`
    if (address.address2) {
      addressStr += `, ${address.address2}`
    }

    addressStr += `, ${address.city}, ${address.state} ${address.zipcode}`

    return addressStr
  },
}

export default Strings
