import '../styles/ProofOfCoverage.css'
import { useEffect, useState } from 'react'
import checkmarkIcon from '../assets/checkmark-2.svg'
import { Label } from './Label'
import docsIcon from '../assets/icn-docs.svg'
import insurerIcon from '../assets/icn-insurance.svg'
import premiumIcon from '../assets/icn-premium.svg'
import coverageIcon from '../assets/icn-coverage.svg'
import FileUpload, { FilePreview } from './FileUpload'
import { base64Encode } from '../utils/Files'
import PriorInsuranceModel from '../models/PriorInsuranceModel'
import NumberFormat from 'react-number-format'
import Currency from '../utils/Currency'

export default function ProofOfCoverage({
  hasProvidedProof,
  errMsg,
  coverageOptions,
  onComplete,
}) {
  const [isUploading, setIsUploading] = useState(false)
  const [lapseOverride, setLapseOverride] = useState(null)
  const [insurerList, setInsurerList] = useState([])
  const [selectedInsurer, setSelectedInsurer] = useState('')
  const [selectedLimits, setSelectedLimits] = useState('')
  const [premium, setPremium] = useState('')
  const [premiumCents, setPremiumCents] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handlePremiumOnChange = (values) => {
    setPremium(values.value)
    setPremiumCents(
      values.floatValue ? Currency.parseCents(values.floatValue) : 0
    )
  }

  const isValid = () => {
    if (!lapseOverride) {
      return false
    }

    if (
      selectedInsurer.length === 0 ||
      selectedLimits.length === 0 ||
      premiumCents === 0
    ) {
      return false
    }

    return true
  }

  const fetchProofOfCoverage = async () => {
    const { lapseOverride, err } =
      await PriorInsuranceModel.getProofOfCoverage()
    if (!err && lapseOverride) {
      setLapseOverride(lapseOverride)
    }
  }

  const fetchInsurersList = async () => {
    const insurers = await PriorInsuranceModel.getInsurers()
    setInsurerList(insurers)
  }

  const handleOnSelect = async (files) => {
    if (files.length === 0) {
      return
    }

    setIsUploading(true)

    const file = files[0]
    console.log(`FileName: ${file.name} Type: ${file.type}`)

    const { data, err } = await base64Encode(file)
    if (err) {
      console.log('base64Encode err:', err)
      setIsUploading(false)
      return
    }

    const response = await PriorInsuranceModel.uploadProofOfCoverage(
      data,
      file.type
    )
    if (response.err) {
      console.log('error uploading proof of coverage:', err)
      setIsUploading(false)
      onComplete(response.err)
      return
    }

    fetchProofOfCoverage()
    setIsUploading(false)
  }

  const handleOnDelete = async () => {
    setLapseOverride(null)
    const err = await PriorInsuranceModel.deleteProofOfCoverage()
    if (err) {
      alert('Sorry, error deleting file.')
      fetchProofOfCoverage()
      return
    }
  }

  const handleOnSubmit = async () => {
    if (!isValid()) {
      return
    }

    setIsSubmitting(true)

    const response = await PriorInsuranceModel.updateProofOfCoverage(
      selectedInsurer,
      premiumCents,
      selectedLimits
    )
    if (response.err) {
      setIsSubmitting(false)
      alert('Sorry, there was an error on submitting proof of coverage.')
      return
    }

    setIsSubmitting(false)
    onComplete(null)
  }

  useEffect(() => {
    if (hasProvidedProof) {
      return
    }
    fetchProofOfCoverage()
    fetchInsurersList()
    // eslint-disable-next-line
  }, [])

  return (
    <div className="coverage-proof-container">
      {hasProvidedProof && (
        <div className="coverage-proof-verified">
          <h3 className="heading">Prior Insurance Verified</h3>
          <img src={checkmarkIcon} alt="checkmark" />
        </div>
      )}

      {!hasProvidedProof && (
        <>
          <div className="coverage-proof-card">
            <h3 className="heading">Prior Insurance</h3>
            {errMsg && <p className="err-msg">{errMsg}</p>}
            <div className="form-container">
              <div
                className="form-row"
                style={{ marginBottom: lapseOverride ? '8px' : '28px' }}
              >
                <div className="form-column">
                  <Label icon={docsIcon} text="PRIOR INSURANCE"></Label>
                  {lapseOverride ? (
                    <FilePreview
                      id="coverage-proof-file-preview"
                      fileURL={lapseOverride.file_url}
                      text={lapseOverride.file_display_name}
                      onDelete={handleOnDelete}
                    />
                  ) : (
                    <FileUpload
                      id="coverage-proof-file"
                      text="Drag and drop"
                      fileTypes={'.jpg,.png,.pdf'}
                      isUploading={isUploading}
                      onSelect={handleOnSelect}
                    />
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="form-column">
                  <Label icon={insurerIcon} text="INSURER"></Label>
                  <select
                    className={selectedInsurer ? '' : 'placeholder'}
                    name="insurer"
                    value={selectedInsurer}
                    onChange={(e) => setSelectedInsurer(e.target.value)}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {insurerList.map((insurer, i) => (
                      <option key={i} value={insurer.name}>
                        {insurer.name}
                      </option>
                    ))}
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <div className="form-row">
                <div className="form-column">
                  <Label icon={premiumIcon} text="PREMIUM (MONTHLY)"></Label>
                  <NumberFormat
                    name="premium"
                    prefix="$"
                    decimalScale="2"
                    fixedDecimalScale="true"
                    allowLeadingZeros="false"
                    value={premium}
                    onValueChange={handlePremiumOnChange}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="form-row" style={{ marginBottom: '0' }}>
                <div className="form-column">
                  <Label icon={coverageIcon} text="LIMITS"></Label>
                  <select
                    className={selectedLimits ? '' : 'placeholder'}
                    name="limits"
                    value={selectedLimits}
                    onChange={(e) => setSelectedLimits(e.target.value)}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {coverageOptions.BI.map((option, i) => (
                      <option
                        key={i}
                        value={`${option.individual}/${option.occurrence}`}
                      >
                        {option.individual}/{option.occurrence}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <button
            className="primary"
            disabled={!isValid() || isSubmitting}
            onClick={handleOnSubmit}
          >
            {isSubmitting ? 'Submitting proof...' : 'Continue'}
          </button>
        </>
      )}
    </div>
  )
}
