import Strings from './Strings'

let Dates = {
  validStartDate: (startDate) => {
    let hasError = false

    if (!Strings.isValidDate(startDate)) {
      return 'Please specify a valid date'
    }

    const d = startDate.split('-')

    if (d.length !== 3) {
      return 'Invalid date format'
    }

    const date = new Date(d[0], d[1] - 1, d[2])
    date.setHours(0, 0, 0, 0)

    let today = Dates.dateToday()

    if (date < today) {
      hasError = true
    }

    const maxDate = new Date(new Date().setMonth(today.getMonth() + 1))
    maxDate.setHours(0, 0, 0, 0)

    if (date > maxDate) {
      hasError = true
    }

    if (hasError) {
      return `Please specify a date between today and ${Dates.getMonthString(
        maxDate.getMonth()
      )} ${maxDate.getDate()}`
    }

    return null
  },

  dateToday: () => {
    let today = new Date()
    today.setHours(0, 0, 0, 0)
    return today
  },

  toString: (date) => {
    let month = `${date.getMonth() + 1}`

    if (month.length === 1) {
      month = '0' + month
    }

    let day = `${date.getDate()}`
    if (day.length === 1) {
      day = '0' + day
    }

    return `${date.getFullYear()}-${month}-${day}`
  },

  toDate: (dateStr) => {
    const d = dateStr.split('-')

    if (d.length !== 3) {
      return 'invalid date format'
    }

    const date = new Date(d[0], parseInt(d[1]) - 1, parseInt(d[2]))
    date.setHours(0, 0, 0, 0)

    return date
  },

  getMonthString: (monthValue) => {
    const months = {
      0: 'January',
      1: 'February',
      2: 'March',
      3: 'April',
      4: 'May',
      5: 'June',
      6: 'July',
      7: 'August',
      8: 'September',
      9: 'October',
      10: 'November',
      11: 'December',
    }

    return months[monthValue]
  },

  getShortMonthString: (monthValue) => {
    const months = {
      0: 'Jan',
      1: 'Feb',
      2: 'Mar',
      3: 'Apr',
      4: 'May',
      5: 'Jun',
      6: 'Jul',
      7: 'Aug',
      8: 'Sep',
      9: 'Oct',
      10: 'Nov',
      11: 'Dec',
    }

    return months[monthValue]
  },

  toLongDateString: (dateStr, longMonth = false, showTime = false) => {
    const date = new Date(dateStr)

    if (isNaN(date)) {
      return 'invalid date'
    }

    let options = {
      month: longMonth ? 'long' : 'short',
      day: 'numeric',
      year: 'numeric',
    }

    const formatter = new Intl.DateTimeFormat('en-US', options)
    const formattedDate = formatter.format(date)
    if (!showTime) {
      return formattedDate
    }

    options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }
    const timeFormatter = new Intl.DateTimeFormat('en-US', options)

    return `${formattedDate} ${timeFormatter.format(date)}`
  },

  toLongDateWithoutYear: (dateStr) => {
    const date = new Date(dateStr)

    if (isNaN(date)) {
      return 'invalid date'
    }

    const options = { month: 'short', day: 'numeric' }

    const formatter = new Intl.DateTimeFormat('en-US', options)

    return formatter.format(date)
  },
}

export default Dates
