import React from 'react'
import Strings from '../utils/Strings'
import Utils from '../utils/Utils'

function Summary({
  user,
  license,
  address,
  drivers,
  cars,
  policyCoverages,
  vehicleCoverages,
  coverageOptions,
}) {
  if (
    !user ||
    !license ||
    !address ||
    !drivers ||
    !cars ||
    !policyCoverages ||
    !vehicleCoverages ||
    !coverageOptions
  ) {
    return null
  }

  const additionalDrivers = drivers.filter((driver) => {
    return driver.primary !== true
  })

  const getVehicleCoverage = (vehicleID) => {
    const cov = vehicleCoverages.find((vehCov) => {
      return vehCov.vehicle_id === vehicleID
    })

    return cov
  }

  const rentalDisplay = (vehicleID) => {
    let cov = getVehicleCoverage(vehicleID)
    if (!cov.has_rental_car) {
      return 'None'
    }
    if (cov.rental_car_day) {
      return (
        '$' +
        cov.rental_car_day.toLocaleString() +
        ' per day / $' +
        cov.rental_car_occurrence.toLocaleString()
      )
    } else {
      return '$' + cov.rental_car_occurrence.toLocaleString()
    }
  }

  return (
    <div>
      {user.platform && (
        <>
          <h4 className="sub-heading sub-heading-background">Device Type</h4>
          {user.platform === 'ios' && <p className="simple-list">iPhone</p>}
          {user.platform === 'android' && (
            <p className="simple-list">Android</p>
          )}
        </>
      )}
      <h4 className="sub-heading sub-heading-background">Primary Driver</h4>
      <p className="simple-list">{`${license.first_name} ${license.last_name}`}</p>
      <p className="simple-list">
        Birthday{' '}
        {license.birth_date
          ? Strings.dateStringToDisplay(license.birth_date)
          : '--'}
      </p>
      {license.gender && (
        <p className="simple-list">{Strings.titleCase(license.gender)}</p>
      )}
      <p className="simple-list">{Strings.prettyAddressString(address)}</p>
      <p className="simple-list">
        Driver’s License {`${license.state} ${license.number}`}
      </p>
      <p className="simple-list">{user.email}</p>
      <p className="simple-list">
        {Strings.formatPhoneNumber(user.phone_number)}
      </p>

      {additionalDrivers.length > 0 && (
        <div>
          <h4 className="sub-heading sub-heading-background">
            Additional {additionalDrivers.length === 1 ? 'Driver' : 'Drivers'}
          </h4>
          {additionalDrivers.map((driver, i) => (
            <p
              className="simple-list"
              key={i}
            >{`${driver.first_name} ${driver.last_name}`}</p>
          ))}
        </div>
      )}

      <h4 className="sub-heading sub-heading-background">
        {cars.length === 1 ? 'Car' : 'Cars'}
      </h4>
      {cars.map((car, i) => {
        return (
          <p className="simple-list" key={i}>
            {Utils.getVehicleTitle(car)}
          </p>
        )
      })}

      <h4 className="sub-heading sub-heading-background">Coverage</h4>
      <p className="simple-list">
        Bodily Injury: $
        {policyCoverages.bodily_injury_individual_limit.toLocaleString()} / $
        {policyCoverages.bodily_injury_occurrence_limit.toLocaleString()}
      </p>
      <p className="simple-list">
        Property Damage: $
        {policyCoverages.property_damage_limit.toLocaleString()}
      </p>
      {coverageOptions && coverageOptions.PIP && (
        <p className="simple-list">
          Personal Injury Protection:{' '}
          {policyCoverages.personal_injury_limit
            ? `$${policyCoverages.personal_injury_limit.toLocaleString()}`
            : 'None'}
        </p>
      )}
      {coverageOptions.MP && (
        <p className="simple-list">
          Medical Payments:{' '}
          {policyCoverages.medical_payment_limit
            ? `$${policyCoverages.medical_payment_limit.toLocaleString()}`
            : 'None'}
        </p>
      )}
      <p className="simple-list">
        Uninsured Bodily Injury:{' '}
        {policyCoverages.uninsured_bodily_injury_individual_limit
          ? `$${policyCoverages.uninsured_bodily_injury_individual_limit.toLocaleString()} / $${policyCoverages.uninsured_bodily_injury_occurrence_limit.toLocaleString()}`
          : 'None'}
      </p>

      {cars.map((car, i) => (
        <div key={i}>
          <h4 className="sub-heading">{Utils.getVehicleTitle(car)}</h4>
          <p className="simple-list">
            Comprehensive:{' '}
            {getVehicleCoverage(car.id).has_comprehensive
              ? '$' +
                getVehicleCoverage(
                  car.id
                ).comprehensive_deductible.toLocaleString()
              : 'None'}
            {getVehicleCoverage(car.id).has_glass && ' w/ Glass'}
          </p>
          <p className="simple-list">
            Collision:{' '}
            {getVehicleCoverage(car.id).has_collision
              ? '$' +
                getVehicleCoverage(car.id).collision_deductible.toLocaleString()
              : 'None'}{' '}
            {getVehicleCoverage(car.id).has_collision_deductible_waiver &&
              'w/ CDW'}
          </p>
          <p className="simple-list">Rental: {rentalDisplay(car.id)}</p>
          {coverageOptions && coverageOptions.UMUIMPD && (
            <p className="simple-list">
              Uninsured Motorist Property Damage:
              {getVehicleCoverage(car.id).uninsured_property_limit
                ? ` $${getVehicleCoverage(
                    car.id
                  ).uninsured_property_limit.toLocaleString()}`
                : ' None'}
            </p>
          )}
          {coverageOptions && coverageOptions.TOW && (
            <p className="simple-list">
              Roadside Assistance:{' '}
              {getVehicleCoverage(car.id).has_towing ? ` Included` : ' None'}
            </p>
          )}
        </div>
      ))}
    </div>
  )
}

export default Summary
