import '../../styles/ClaimsPay.css'
import './Renewals.css'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CenteredContainer } from '../../components/Container'
import { ErrorLabel, Label } from '../../components/Label'
import codeIcon from '../../assets/code.svg'
import NumberFormat from 'react-number-format'
import PolicyModel from '../../models/PolicyModel'

export default function Verify() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const policyNumber = params.get('policy')
  const approvalFlag = params.get('approved')

  const [isLoading, setIsLoading] = useState(true)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState('')
  const [isVerifying, setIsVerifying] = useState(false)

  const requestCode = async (policyNumber) => {
    const { phoneNumber, err } = await PolicyModel.requestCode(
      policyNumber,
      true
    )
    if (err) {
      if (err.err && err.err.toString() === 'request was not valid') {
        setCodeError(`Sorry, couldn't validate your phone number.`)
        setIsLoading(false)
        return
      }

      setCodeError(
        `Sorry, we ran into an unexpected issue and we’re still figuring it out.`
      )
      setIsLoading(false)
      return
    }

    setPhoneNumber(phoneNumber)
    setIsLoading(false)
  }

  const verifyCode = async (code) => {
    setIsVerifying(true)
    const { err } = await PolicyModel.login(code, policyNumber, true)
    if (err) {
      setCodeError('Invalid verification code')
      setIsVerifying(false)
      return
    }

    if (approvalFlag) {
      window.location = `/renewals/approve?approved=${approvalFlag}`
      return
    }

    window.location = '/renewals/approve'
  }

  useEffect(() => {
    if (policyNumber) {
      requestCode(policyNumber)
    }
  }, [policyNumber])

  const onChangeCode = async (values) => {
    if (codeError) {
      setCodeError('')
    }

    const code = values.value
    setCode(code)
    if (code.length === 4) {
      verifyCode(code)
    }
  }

  if (!policyNumber) {
    return null
  }

  return (
    <div className="container">
      <CenteredContainer className="renewals-verify-container">
        <div className="claims-pay-verify">
          <h2 className="title">
            Enter the 4 digit code sent to your registered number •••
            {!isLoading && phoneNumber}
          </h2>
        </div>

        <form onSubmit={(e) => e.preventDefault()}>
          <div>
            <Label icon={codeIcon} text="ENTER CODE"></Label>
            <NumberFormat
              className={codeError && 'error'}
              format="####"
              name="code"
              value={code}
              isNumericString={true}
              onValueChange={onChangeCode}
              inputMode="numeric"
              autoComplete="one-time-code"
              disabled={isVerifying}
            />
            {isVerifying && (
              <p className="verifying-code-text">Verifying code...</p>
            )}
            <ErrorLabel errorMessage={codeError} />
          </div>
        </form>
      </CenteredContainer>
    </div>
  )
}
