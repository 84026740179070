import './DemandPacketViewer.css'
import { useLocation } from 'react-router-dom'
import BackButton from '../../components/BackButton'
import { CenteredContainer } from '../../components/Container'
import placeholderImg from '../../assets/placeholder-img.svg'
import Constants from '../../models/Constants'

export default function DemandPacketViewer() {
  const location = useLocation()
  const { from, claimFiles } = location?.state

  let sellUserID = '3387895'
  if (!Constants.isProduction()) {
    sellUserID = '3682040'
  }

  const onClickDocument = (file) => {
    if (file.id) {
      let hostname = 'https://agents.gocarinsurance.com'
      if (Constants.environment() !== 'production') {
        hostname = 'https://agents-dev.gocarinsurance.com'
      }
      const url = `${hostname}/sell/claims/${file.claim_id}/documents/${file.ID}?auth=${sellUserID}`
      window.open(url, '_blank')
      return
    }
    window.open(file.file_url, '_blank')
  }

  return (
    <div className="container">
      <BackButton text={'Back'} to={from} />
      <h1 className="title">Demand Packet</h1>

      <CenteredContainer width={600}>
        <div className="document-viewer-preview">
          {claimFiles.map((file, i) => {
            if (file.mime_type.includes('video/')) {
              return (
                <div
                  className="document-item"
                  key={i}
                  title={file.name}
                  onClick={() => onClickDocument(file)}
                >
                  <video key={i} className="thumbnail">
                    <source src={file.file_url} />
                  </video>
                  <div className="document-title">{file.name}</div>
                </div>
              )
            }
            if (file.mime_type.includes('image/')) {
              return (
                <div
                  className="document-item"
                  key={i}
                  title={file.name}
                  onClick={() => onClickDocument(file)}
                >
                  <img src={file.file_url} alt={file.name} />
                  <div className="document-title">{file.name}</div>
                </div>
              )
            }
            if (file.thumbnail_url) {
              return (
                <div
                  className="document-item"
                  key={i}
                  title={file.name}
                  onClick={() => onClickDocument(file)}
                >
                  <img src={file.thumbnail_url} alt={file.name} />
                  <div className="document-title">{file.name}</div>
                </div>
              )
            }

            return (
              <div
                className="document-item"
                key={i}
                title={file.name}
                onClick={() => onClickDocument(file)}
              >
                <img src={placeholderImg} alt={file.name} />
                <div className="document-title">{file.name}</div>
              </div>
            )
          })}
        </div>
      </CenteredContainer>
    </div>
  )
}
