import API from './API'
import Constants from './Constants'
import Store from './Store'

class AgentModel {
  getAgentFromShareCode = async (shareCode) => {
    const response = await API()
      .get(`/v3/agents/${shareCode}/agent`)
      .then((res) => {
        console.log('API res[getAgentFromShareCode]:', res.data)
        if (res.status === 200) {
          Store.setAgent(res.data)

          return {
            response: res.data,
            err: null,
          }
        }

        return { response: null, err: Constants.genericNetworkError() }
      })
      .catch((error) => {
        console.log('API error[getAgentFromShareCode]:', error)
        if (!error.response) {
          return { response: null, err: error }
        }

        return { response: null, err: error.response.data }
      })

    return response
  }

  createAgentUser = async (agentToken, shareCode) => {
    const headers = {
      'Authorization-Agent': agentToken,
    }

    const response = await API()
      .post(
        '/v3/users',
        {
          platform: 'web',
          agent_share_code: shareCode,
        },
        {
          headers,
        }
      )
      .then((res) => {
        console.log('API res[createAgentUser]:', res)
        if (res.status === 200) {
          const user = {
            userID: res.data.user_id,
            token: res.data.token,
            usesShareCode: true,
            shareCode,
          }

          Store.setUser(user)

          return {
            userID: res.data.user_id,
            err: null,
          }
        }

        return { response: null, err: Constants.genericNetworkError() }
      })
      .catch((error) => {
        console.log('API error[createAgentUser]:', error)
        if (!error.response) {
          return { response: null, err: error }
        }

        return { response: null, err: error.response.data }
      })

    return response
  }

  getQuoteVerification = async () => {
    const response = await API()
      .get('/v3/agents/share_codes/verification')
      .then((res) => {
        console.log('API res[getQuoteVerification]:', res)
        if (res.status === 200) {
          return {
            agent: res.data.agent,
            isVerified: res.data.is_verified,
            err: null,
          }
        }
        return {
          agent: null,
          isVerified: null,
          err: Constants.genericNetworkError(),
        }
      })
      .catch((error) => {
        console.log('API error[getQuoteVerification]:', error)
        if (!error.response) {
          return { agent: null, isVerified: null, err: error }
        }

        return { agent: null, isVerified: null, err: error.response.data }
      })

    return response
  }

  createQuoteVerification = async () => {
    const err = await API()
      .post('/v3/agents/share_codes/verification', {})
      .then((res) => {
        console.log('API res[createQuoteVerification]:', res)
        if (res.status === 200) {
          return null
        }

        return Constants.genericNetworkError()
      })
      .catch((error) => {
        console.log('API error[createQuoteVerification]:', error)
        if (!error.response) {
          return error
        }

        return error.response.data
      })

    return err
  }

  verifyQuote = async (verificationCode) => {
    const err = await API()
      .post('/v3/agents/share_code/quote_verify', {
        verification_code: verificationCode,
      })
      .then((res) => {
        console.log('API res[verifyQuote]:', res)
        if (res.status === 200) {
          return null
        }

        return Constants.genericNetworkError()
      })
      .catch((error) => {
        console.log('API error[verifyQuote]:', error)
        if (!error.response) {
          return error
        }

        return error.response.data
      })

    return err
  }
}

export default new AgentModel()
