import { useLocation } from 'react-router-dom'
import goLogo from './../assets/app-icon.svg'
import swissReLogo from './../assets/swiss-re.svg'
import ambestLogo from './../assets/ambest-logo.svg'

function Header() {
  const location = useLocation()

  if (location.pathname === '/claims-pay') {
    return null
  }

  return (
    <div>
      <header>
        <a href="https://gocarinsurance.com">
          <div className="brand">
            <img className="go-logo" src={goLogo} alt="go logo" />
          </div>
        </a>
      </header>
    </div>
  )
}

export default Header
