import API from './API'
import Constants from './Constants'

function SubrogationModel() {
  this.getDemand = async (demandID) => {
    const { status, demand, responses, goCustomer, err } = await API()
      .get(`/v3/claims/subrogation/demands/${demandID}`)
      .then((res) => {
        console.log('API res[getDemand]:', res)
        if (res.status === 200) {
          return {
            status: res.data.status,
            demand: res.data.demand,
            responses: res.data.responses,
            goCustomer: res.data.go_customer,
            err: null,
          }
        }

        return {
          status: null,
          demand: null,
          responses: null,
          goCustomer: null,
          err: Constants.genericNetworkError(),
        }
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[getDemand]:', error)
          return {
            status: null,
            demand: null,
            responses: null,
            goCustomer: null,
            err: error,
          }
        }

        console.log('API err[getDemand]:', error.response.data)
        return {
          status: null,
          demand: null,
          responses: null,
          goCustomer: null,
          err: error.response.data,
        }
      })

    return { status, demand, responses, goCustomer, err }
  }

  this.demandResponse = async (
    demandID,
    amount,
    percentage,
    response = null,
    files = []
  ) => {
    const err = await API()
      .post(`/v3/claims/subrogation/demands/${demandID}`, {
        amount,
        percentage,
        response,
        files,
      })
      .then((res) => {
        console.log('API res[demandResponse]:', res)
        if (res.status === 200) {
          return null
        }

        return Constants.genericNetworkError()
      })
      .catch((error) => {
        if (!error.response) {
          console.log('API err[demandResponse]:', error)
          return error
        }

        console.log('API err[demandResponse]:', error.response.data)
        return error.response.data
      })

    return err
  }
}

export default new SubrogationModel()
