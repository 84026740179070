import '../styles/AppFirst.css'
import licenseScanScreen from '../assets/nextsteps-licscan@3x.png'
import showRoomScreen from '../assets/nextsteps-showrrom@3x.png'
import homeScreen from '../assets/appfirst-home@3x.png'

export default function PolicyAuth(props) {
  return (
    <div className="container">
      <h2 className="title  app-first">
        How to activate your Go Insurance policy
      </h2>

      <div className="app-first-container">
        <div className="column">
          <img className="app-screen" src={licenseScanScreen} alt="" />
          <div>
            <h4>Log in with your license</h4>
            <p>
              Scan your driver's license on the Go Insurance app to log in and
              see your cars ready for the Virtual Showroom.
            </p>
          </div>
        </div>
        {/* end of column 1 */}

        <div className="column" style={{ flexBasis: '399px' }}>
          <img className="app-screen" src={showRoomScreen} alt="" />
          <div>
            <h4>Add your cars to the virtual showroom</h4>
            <p>
              Now showrooms are not just for{' '}
              <a
                className="green"
                href="https://www.hotcars.com/a-detailed-look-at-rihanas-car-collection"
                rel="noopener noreferrer"
                target="_blank"
              >
                Rihanna
              </a>{' '}
              or{' '}
              <a
                className="green"
                href="https://www.architecturaldigest.com/story/ralph-lauren-vintage-cars-lime-rock-article"
                rel="noopener noreferrer"
                target="_blank"
              >
                Ralph Lauren
              </a>{' '}
              any more. With Go, you get the only app with a virtual showroom —
              letting you protect the value of your car with a perfect digital
              record. Just take a video, name your car if you want, and you’re
              done.
            </p>
          </div>
        </div>
        {/* end of column 2 */}

        <div className="column">
          <img className="app-screen" src={homeScreen} alt="" />
          <div>
            <h4>Your policy is active</h4>
            <p>
              Now on your home screen you can see your policy is active —
              including your cars in your virtual showroom, the drivers on the
              policy, and everything else is just a chat away.
            </p>
          </div>
        </div>
        {/* end of column 3 */}
      </div>
    </div>
  )
}
