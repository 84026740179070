import '../../styles/Claims.css'

import { useState } from 'react'
import ClaimsIcon from '../../assets/icn-claims.svg'
import DriverIcon from '../../assets/icn-driver.svg'
import { CenteredContainer } from '../../components/Container'
import { Label } from '../../components/Label'
import Store from '../../models/Store'

export default function ClaimsWhoAreYou() {
  const [reporterType, setReporterType] = useState(Store.getClaimReporterType());

  const handleContinue = () => {
    Store.setClaimReporterType(reporterType)
    window.location = '/claims/start'
  }

  return (
    <CenteredContainer style={{ maxWidth: '467px', margin: '0 auto', textAlign: 'left' }}>
      <div className="heading-with-icon">
        <img src={ClaimsIcon} alt="claims icon" />
        <h2 className="display">Go Claims</h2>
      </div>

      <p className="claims-info-text">
        We know that claims are important to our customers. We want you to
        know we’re here for every step of the process. You can expect these
        three things:
      </p>

      <ul className="claims-info-text">
        <li>
          We will always set clear expectations and let you know what’s next
        </li>
        <li>We care about every step of the process</li>
        <li>We will take care of this claim efficiently</li>
      </ul>

      <div className="form-container" style={{ marginTop: '48px' }}>
        <div className="form-row" style={{ padding: '0px' }}>
          <div className="form-column">
            <Label icon={DriverIcon} text="TELL US A LITTLE ABOUT YOURSELF" />
            <select value={reporterType} onChange={e => setReporterType(e.target.value)}>
              <option value={'GO_CUSTOMER'}>
                A Go Insurance customer
              </option>
              <option value={'GO_CUSTOMER_REPRESENTATIVE'}>
                A representative of a Go Insurance customer
              </option>
              <option value={'THIRD_PARTY'}>
                Filing against a Go Insurance customer
              </option>
            </select>
          </div>
        </div>
        <button
          className="primary"
          style={{ width: '100%', marginTop: '35px' }}
          autoComplete="false"
          autoCorrect="false"
          onClick={handleContinue}
        >
          Continue
        </button>
      </div>
    </CenteredContainer>
  );
}