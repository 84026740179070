import React, { useState, useEffect } from 'react'
import { CenteredContainer } from '../components/Container'
import { Label, ErrorLabel } from '../components/Label'
import LocationSearchInput from '../components/LocationSearchInput'
import StateSelector from '../components/StateSelector'
import NumberFormat from 'react-number-format'
import Strings from '../utils/Strings'
import deviceIcon from '../assets/icn-device.svg'
import driverIcon from '../assets/driver.svg'
import timeIcon from '../assets/time.svg'
import genderIcon from '../assets/gender.svg'
import addressIcon from '../assets/address.svg'
import unitIcon from '../assets/unit.svg'
import flagIcon from '../assets/flag.svg'
import cardIcon from '../assets/cardback.svg'
import UserModel from '../models/UserModel'
import LicenseModel from '../models/LicenseModel'
import Breadcrumb from '../components/Breadcrumb'
import StackedMobile from '../components/StackedMobile'
import Store from '../models/Store.js'
import Sentry from '../logger/Sentry'
import emailIcon from '../assets/email.svg'
import phoneIcon from '../assets/phone-number.svg'
import BrokerInfo from '../components/BrokerInfo'

function Info(props) {
  const user = Store.getUser()
  const userID = user ? user.userID : ''
  const usesShareCode = user ? user.usesShareCode : false

  const selectedPlatform =
    props.location.state && props.location.state.platform
      ? props.location.state.platform
      : ''

  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    birthDay: '',
    gender: '',
    addressLine1: '',
    city: '',
    unit: '',
    licenseState: '',
    addressState: '',
    zipCode: '',
    license: '',
    email: '',
    phoneNumber: '',
    platform: selectedPlatform || '',
  })

  const [agent, setAgent] = useState(null)
  const [addressValue, setAddressValue] = useState('')
  const [licenseError, setLicenseError] = useState('')
  const [birthDayError, setBirthDayError] = useState('')
  const [submitError, setSubmitError] = useState('')
  const [userState, setUserState] = useState(null)
  const [license, setLicense] = useState()

  useEffect(() => {
    const fetchUser = async () => {
      const {
        user,
        license,
        address,
        userState,
        agent,
        err,
      } = await UserModel.getUser(userID, 'info')
      if (err) {
        console.log('error getting user:', err)
        return
      }

      let newFormValues = formValues

      if (user) {
        newFormValues = {
          ...formValues,
          email: user.email || '',
          phoneNumber: user.phone_number || '',
          platform: selectedPlatform || user.platform || '',
        }
      }

      if (license && address) {
        newFormValues = {
          ...newFormValues,
          firstName: license.first_name,
          lastName: license.last_name,
          birthDay: Strings.dateStringToInput(license.birth_date),
          gender: license.gender,
          addressLine1: license.address1,
          unit: license.address2,
          city: license.city,
          licenseState: license.state,
          addressState: address.state,
          zipCode: address.zipcode,
          license: license.number,
        }

        setAddressValue(
          `${address.address1}, ${address.city}, ${address.state} ${address.zipcode}`
        )

        setLicense(license)
      }

      setFormValues(newFormValues)

      if (userState) {
        setUserState(userState)
      }

      if (agent) {
        setAgent(agent)
      }
    }

    fetchUser()
    Sentry.info('StartingQuote', 'starting quote by user')
    // eslint-disable-next-line
  }, [])

  const handleInputChange = (e) => {
    const fieldName = e.target.name
    const fieldValue = e.target.value

    setFormValues({
      ...formValues,
      [fieldName]: fieldValue,
    })
  }

  const handleAddressChange = (parsedAddress) => {
    if (!parsedAddress) {
      setFormValues({
        ...formValues,
        addressLine1: '',
        city: '',
        addressState: '',
        zipCode: '',
        licenseState: '',
      })
      return
    }

    let licenseState =
      formValues.licenseState === ''
        ? parsedAddress.stateAbbreviation
        : formValues.licenseState
    setFormValues({
      ...formValues,
      addressLine1: parsedAddress.addressLine1,
      city: parsedAddress.placeName,
      addressState: parsedAddress.stateAbbreviation,
      zipCode: parsedAddress.zipCode,
      licenseState: licenseState,
    })
  }

  const handleLicenseBlur = async (e) => {
    const isValid = await LicenseModel.validateLicense(
      userID,
      formValues.license,
      formValues.licenseState
    )

    if (!isValid) {
      setLicenseError('Please enter a valid license number')
    } else {
      setLicenseError('')
    }
  }

  const handleBirthDayBlur = (e) => {
    const dateStr = Strings.inputToDateString(formValues.birthDay)

    if (!Strings.isValidDate(dateStr)) {
      setBirthDayError('Please enter a valid date')
    } else {
      setBirthDayError('')
    }
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault()

    if (!isValid()) {
      return
    }

    const phoneNumber = formValues.phoneNumber.replace(/\s/g, '')

    const err = await UserModel.updateUser(
      userID,
      formValues.email,
      phoneNumber,
      formValues.platform
    )
    if (err) {
      console.error('failed updating user:', err)
      if (err.err) {
        setSubmitError(err.err)
      } else {
        setSubmitError(
          'Sorry, we ran into an unexpected issue and we’re still figuring it out.'
        )
      }

      return
    }

    console.log('updated user')

    const license = {
      first_name: formValues.firstName,
      last_name: formValues.lastName,
      gender: formValues.gender,
      birth_date: Strings.inputToDateString(formValues.birthDay),
      number: formValues.license,
      state: formValues.licenseState,
      address1: formValues.addressLine1,
      address2: formValues.unit,
      city: formValues.city,
      zipcode: formValues.zipCode,
    }

    const address = {
      address1: formValues.addressLine1,
      address2: formValues.unit,
      city: formValues.city,
      state: formValues.addressState,
      zipcode: formValues.zipCode,
    }

    const [newUserID, licenseErr] = await LicenseModel.createLicense(
      userID,
      license,
      address
    )
    if (licenseErr) {
      console.error('failed creating license:', licenseErr)
      if (licenseErr.err) {
        if (licenseErr.err === 'driver exists with active policy') {
          setSubmitError('This customer is already in an active policy.')
        } else {
          setSubmitError(licenseErr.err)
        }
      } else {
        setSubmitError(
          'Sorry, we ran into an unexpected issue and we’re still figuring it out.'
        )
      }

      Sentry.error('CreateLicenseError', licenseErr, {
        ...formValues,
        error: licenseErr,
      })
      return
    }

    console.log('created license')
    Sentry.info('LicenseDone', 'created license', {
      license,
      address,
    })

    window.location = '/drivers'
  }

  const isValid = () => {
    if (usesShareCode) {
      if (!Strings.isValidEmail(formValues.email)) {
        return false
      }
      if (!Strings.isValidPhone(formValues.phoneNumber)) {
        return false
      }
    }

    return (
      formValues.firstName.length > 0 &&
      formValues.lastName.length > 0 &&
      formValues.birthDay.length > 0 &&
      formValues.gender.length > 0 &&
      formValues.addressLine1.length > 0 &&
      formValues.city.length > 0 &&
      formValues.licenseState.length > 0 &&
      formValues.license.length > 0 &&
      formValues.platform.length > 0 &&
      !licenseError &&
      !birthDayError
    )
  }

  return (
    <div className="container">
      <Breadcrumb page={'info'} userState={userState} license={license} />
      {userState && (
        <CenteredContainer>
          <div className="mobile-page-heading">New Quote</div>
          {agent && <BrokerInfo agent={agent} />}
          <div className="form-container">
            <form onSubmit={handleFormSubmit}>
              <div className="error-label show">
                <p>{submitError}</p>
              </div>

              <div className="form-row">
                <div className="form-column">
                  <Label
                    icon={deviceIcon}
                    text="DEVICE TYPE"
                    iconSize={25}
                  ></Label>
                  <select
                    name="platform"
                    value={formValues.platform}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="ios">iPhone</option>
                    <option value="android">Android</option>
                  </select>
                </div>
              </div>

              <StackedMobile
                left={
                  <div className="form-column">
                    <Label icon={driverIcon} text="First Name"></Label>
                    <input
                      name="firstName"
                      type="text"
                      value={formValues.firstName}
                      onChange={handleInputChange}
                    />
                  </div>
                }
                right={
                  <div className="form-column">
                    <Label icon={driverIcon} text="Last Name"></Label>
                    <input
                      name="lastName"
                      type="text"
                      value={formValues.lastName}
                      onChange={handleInputChange}
                    />
                  </div>
                }
              />

              <div className="form-row">
                <div className="form-column">
                  <Label icon={timeIcon} text="BIRTHDAY (MM DD YYYY)"></Label>
                  <NumberFormat
                    className={birthDayError && 'error'}
                    format="## ## ####"
                    mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
                    name="birthDay"
                    value={formValues.birthDay}
                    onChange={handleInputChange}
                    onBlur={handleBirthDayBlur}
                  />
                  <ErrorLabel errorMessage={birthDayError} />
                </div>
              </div>

              <div className="form-row">
                <div className="form-column">
                  <Label icon={genderIcon} text="Gender"></Label>
                  <select
                    className={formValues.gender === '' ? 'placeholder' : ''}
                    name="gender"
                    value={formValues.gender}
                    onChange={handleInputChange}
                  >
                    <option value="">Select</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
              </div>

              <div className="form-row">
                <div className="form-column">
                  <Label icon={addressIcon} text="Address"></Label>
                  <LocationSearchInput
                    value={addressValue}
                    onChange={handleAddressChange}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-column">
                  <Label icon={unitIcon} text="Unit (Optional)"></Label>
                  <input
                    name="unit"
                    type="text"
                    value={formValues.unit}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <StackedMobile
                left={
                  <div>
                    <Label
                      icon={flagIcon}
                      text="Driver's License State"
                    ></Label>
                    <StateSelector
                      excludeMichigan="true"
                      className={
                        formValues.licenseState === '' ? 'placeholder' : ''
                      }
                      name="licenseState"
                      value={formValues.licenseState}
                      onChange={handleInputChange}
                    />
                  </div>
                }
                right={
                  <div>
                    <Label
                      icon={cardIcon}
                      text="Driver's License Number"
                    ></Label>
                    <input
                      className={licenseError && 'error'}
                      name="license"
                      type="text"
                      value={formValues.license}
                      onChange={handleInputChange}
                      onBlur={handleLicenseBlur}
                    />
                    <ErrorLabel errorMessage={licenseError} />
                  </div>
                }
              />

              {usesShareCode && (
                <StackedMobile
                  left={
                    <div className="form-column">
                      <Label icon={emailIcon} text="Email"></Label>
                      <input
                        name="email"
                        type="text"
                        value={formValues.email}
                        onChange={handleInputChange}
                      />
                    </div>
                  }
                  right={
                    <div className="form-column">
                      <Label icon={phoneIcon} text="Phone Number"></Label>
                      <NumberFormat
                        type="tel"
                        format="### ### ####"
                        name="phoneNumber"
                        value={formValues.phoneNumber}
                        onChange={handleInputChange}
                      />
                    </div>
                  }
                />
              )}

              <button className="primary" type="submit" disabled={!isValid()}>
                Next
              </button>
            </form>
          </div>
        </CenteredContainer>
      )}
    </div>
  )
}

export default Info
