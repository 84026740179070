import { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useHistory, useParams } from 'react-router-dom'
import { CenteredContainer } from '../../components/Container'
import LoadingIndicator from '../../components/LoadingIndicator'
import { Label } from '../../components/Label'
import Store from '../../models/Store'
import ClaimModel from '../../models/ClaimModel'
import UserModel from '../../models/UserModel'
import Utils from '../../utils/Utils'
import IncidentDetailTypesFor, {
  IncidentTypes,
  SingleVehicleTypes,
  VehicleMovingTypes,
  VehiclePartsStolenTypes,
  VehiclePositionTypes,
  VehicleRolledAwayTypes,
  WetSnowyIcyRoadTypes,
} from './IncidentTypes'
import DateTimePicker from '../../components/DateTimePicker'
import LocationSearchInput from '../../components/LocationSearchInput'
import FileUpload from '../../components/FileUpload'
import { base64Encode } from '../../utils/Files'
import backIcon from '../../assets/icn-back-nav.svg'
import carsIcon from '../../assets/cars.svg'
import driverIcon from '../../assets/driver.svg'
import alertIcon from '../../assets/icn-alert-grey.svg'
import detailsIcon from '../../assets/icn-details.svg'
import wetRoadIcon from '../../assets/claims-wet-road.svg'
import damageIcon from '../../assets/claims-hit-pole.svg'
import injuryIcon from '../../assets/icn-injury.svg'
import timeIcon from '../../assets/icn-time.svg'
import locationIcon from '../../assets/icn-lienholder-address.svg'
import descriptionIcon from '../../assets/icn-agreement.svg'
import photosIcon from '../../assets/icn-photo.svg'
import theftIcon from '../../assets/claims-attempted-theft.svg'
import carHitIcon from '../../assets/claims-car-hit.svg'
import lanesIcon from '../../assets/claims-lane-change.svg'
import carPositionIcon from '../../assets/claims-position-middle.svg'
import carMovingIcon from '../../assets/claims-moving-my-car.svg'
import hitObjectIcon from '../../assets/claims-hit-object-in-road.svg'
import potholeIcon from '../../assets/claims-pothole.svg'
import deleteIcon from '../../assets/icn-delete.svg'

export default function ClaimsNewClaim() {
  let history = useHistory()
  let { claimID } = useParams()

  const user = Store.getUser()
  const userID = user ? user.userID : ''

  const [isGoCustomer,] = useState(() => {
    return Store.getClaimReporterType() === 'GO_CUSTOMER'
  })

  const [loading, setLoading] = useState(true)
  const [license, setLicense] = useState(null)
  const [policy, setPolicy] = useState(null)
  const [vehicles, setVehicles] = useState([])
  const [drivers, setDrivers] = useState([])
  const [videos, setVideos] = useState([])
  const [isUploadingVideo, setIsUploadingVideo] = useState(false)
  const [vehicleYearMakeModel, setVehicleYearMakeModel] = useState("")

  const [isEditing] = useState(!claimID ? false : true)
  const [claim, setClaim] = useState(null)

  const {
    register,
    resetField,
    handleSubmit,
    control,
    watch,
    getValues,
    setValue,
    trigger,
    formState: { isValid, isSubmitting },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      driver_is_primary: false,
      incident_reason: '',
      is_glass: false,
      was_anyone_injured: '',
      address: '',
      street: '',
      city: '',
      state: '',
      zipcode: '',
      extended_info: {
        incident_type: '',
        incident_detail: '',
        wet_snowy_icy_road_type: '',
        stolen_parts_type: '',
        rolled_away_collision_type: '',
        vehicle_count: 0,
        vehicle_moving_type: '',
        vehicle_position_type: '',
        single_vehicle_type: '',
        vehicle_object_struck: false,
        object_moving: false,
        vehicle_damage_type: '',
      },
    },
  })

  const driverID = watch('driver_id')
  useEffect(() => {
    if (driverID) {
      setValue('driver_is_primary', driverID === license?.id)
    }
  }, [driverID, setValue, license])

  const incidentType = watch('extended_info.incident_type')
  useEffect(() => {
    if (incidentType) {
      setValue('is_glass', incidentType === 'glass_only')
      const incidentInstance = IncidentTypes.find(
        (i) => i.value === incidentType
      )
      setValue('incident_reason', incidentInstance.label)
    }
  }, [incidentType, setValue])

  const fetchUser = async (userID, isEditing = false) => {
    const { license, vehicles, drivers, policy, err } = await UserModel.getUser(
      userID,
      'policy'
    )

    if (err) {
      alert('Error loading user details, please contact support.')
      return
    }

    if (vehicles) {
      setVehicles(vehicles)
    }

    if (license) {
      setLicense(license)

      const primaryDriver = {
        license_id: license.id,
        primary: true,
        first_name: license.first_name,
        last_name: license.last_name,
        birth_date: license.birth_date,
        gender: license.gender,
        dl_number: license.number,
        dl_state: license.state,
        age_licensed: license.age_licensed,
      }

      if (drivers?.length > 0) {
        setDrivers([primaryDriver, ...drivers])
      } else {
        setDrivers([primaryDriver])
      }
    }

    if (policy) {
      setPolicy(policy)
    }

    if (!isEditing) {
      setLoading(false)
    }
  }

  const fetchClaim = async (claimID) => {
    const { claim, err } = await ClaimModel.getClaimByID(claimID)
    if (err) {
      alert('There were errors retrieving the claim. Please try again')
      return
    }

    setClaim(claim)
    if (claim.extended_info) {
      window.location = `/claims/${claimID}`
      return
    }

    setLoading(false)
  }

  useEffect(() => {
    if (Store.getClaimReporterType() === 'GO_CUSTOMER')
    {
      fetchUser(userID, isEditing)

      if (isEditing) {
        fetchClaim(claimID)
      }
    } else {
      setLoading(false)
    }
  }, [userID, isEditing, claimID])

  const resetIncidentDetailSubTypes = () => {
    resetField('extended_info.wet_snowy_icy_road_type')
    resetField('extended_info.vehicle_count')
    resetField('extended_info.wet_snowy_icy_road_type')
    resetField('extended_info.stolen_parts_type')
    resetField('extended_info.rolled_away_collision_type')
    resetField('extended_info.vehicle_moving_type')
    resetField('extended_info.vehicle_position_type')
    resetField('extended_info.single_vehicle_type')
    resetField('extended_info.object_moving')
  }

  const resetIncidentDetails = () => {
    resetField('extended_info.incident_detail')
    resetIncidentDetailSubTypes()
  }

  const handleIncidentTimeOnChange = (value) => {
    if (!value || value.length === 0) {
      setValue('incident_time', '')
      return
    }

    const incidentTime = new Date(value).toISOString()
    setValue('incident_time', incidentTime)
  }

  const handleAddressChange = (parsedAddress) => {
    setValue('street', parsedAddress?.addressLine1 || '')
    setValue('city', parsedAddress?.placeName || '')
    setValue('state', parsedAddress?.stateAbbreviation || '')
    setValue('zipcode', parsedAddress?.zipCode || '')

    if (parsedAddress) {
      let addressValue = `${parsedAddress.addressLine1}, ${parsedAddress.placeName}, ${parsedAddress.stateAbbreviation} ${parsedAddress.zipCode}`
      setValue('address', addressValue)
    } else {
      setValue('address', '')
    }
  }

  const handleAddressOnBlur = (parsedAddress) => {
    if (!parsedAddress) {
      setValue('address', '')
      setValue('street', '')
      setValue('city', '')
      setValue('state', '')
      setValue('zipcode', '')
      setValue('address', '')
    }
    trigger('address')
  }

  const handleVideoUpload = (files, id) => {
    setIsUploadingVideo(true)

    const file = files[0]
    console.log(`FileName: ${file.name} Type: ${file.type}`)

    setVideos([...videos, file])
    setIsUploadingVideo(false)
  }

  const handleVideoDelete = (id) => {
    const index = parseInt(id)

    let files = videos
    files.splice(index, 1)
    setVideos([...files])
  }

  const handleVehicleYearMakeModelOnChange = (e) => {
    setVehicleYearMakeModel(e.target.value)
  }

  const onSubmit = async (data) => {
    if (isEditing) {
      onSubmitExtendedInfo(data)
      return
    }
    let videosData = []
    for (let i = 0; i < videos.length; i++) {
      const video = videos[i]

      const { data, err } = await base64Encode(video)
      if (err) {
        console.log('base64Encode err:', err)
        alert('Error processing videos for claim. Please contact support')
        return
      }
      videosData.push({ data: data, mime_type: video.type })
    }

    if (isGoCustomer) {
      const claimRequest = {
        ...data,
        user_id: userID,
        policy_id: policy.id,
        videos_with_type: videosData,
      }

      const { claim, err } = await ClaimModel.createClaim(claimRequest)
      if (err) {
        alert('Error submitting claim. Please contact support')
        return
      }
  
      if (claim.repair_states) {
        history.push(`/claims/repairs/${claim.id}`)
        return
      }
  
      history.push(`/claims/${claim.id}`)
    } else {
      //
      // Not go customer
      //

      let driver = Store.getClaimUnauthedDriver()
      let userID = Store.getClaimUnauthedUserID()
      let policyID = Store.getClaimUnauthedPolicyID()
      let { vehicleID } = await ClaimModel.validateVehicle(policyID, vehicleYearMakeModel, false);

      const claimRequest = {
        ...data,
        user_id: userID,
        policy_id: policyID,
        driver_is_primary: driver.driverIsPrimary,
        driver_id: driver.driverID,
        videos_with_type: videosData,
      }

      if (vehicleID) {
        claimRequest.vehicle_id = vehicleID
      } else {
        claimRequest.description = `Vehicle: ${vehicleYearMakeModel ? vehicleYearMakeModel : "Unspecified"}\n${claimRequest.description}`
      }

      let reporterType = Store.getClaimReporterType()
      let representativeInfo = Store.getClaimRepresentativeInfo()
      let claimantInfo = Store.getClaimClaimantInfo()

      const { claim, err } = await ClaimModel.createClaimThirdParty(
        claimRequest, reporterType, representativeInfo, claimantInfo)
      if (err) {
        alert('Error submitting claim. Please contact support')
        return
      }

      history.push('/claims/submission-sent')
    }
  }

  const onSubmitExtendedInfo = async (data) => {
    const { err } = await ClaimModel.createExtendedInfo(
      claimID,
      data.extended_info
    )
    if (err) {
      alert('Error updating claim. Please contact support')
      return
    }

    history.push(`/claims/${claim.id}`)
  }

  // console.log(watch())
  // console.log('errors:', errors)
  // console.log('isValid:', isValid)

  return (
    <div className="container">
      <div className="back-nav">
        {isGoCustomer && <Link to="/claims/list">
          <img src={backIcon} alt="back button" /> All Claims
        </Link>}
        {!isGoCustomer && <Link to="/claims/your-info">
          <img src={backIcon} alt="back button" /> Your Info
        </Link>}
      </div>

      {!isEditing && <h2 className="title">Incident Details</h2>}
      {isEditing && !loading && (
        <h2 className="title">Claim #{claim.claim_number}</h2>
      )}
      {loading && (
        <CenteredContainer>
          <LoadingIndicator text="Loading..." />
        </CenteredContainer>
      )}

      {!loading && (
        <CenteredContainer>
          <div className="form-container">
            <form onSubmit={handleSubmit(onSubmit)}>
              {!isEditing && (
                <>
                  {/* vehicle */}
                  {isGoCustomer && <div className="form-row">
                    <div className="form-column">
                      <Label icon={carsIcon} text="Car" />
                      <select {...register('vehicle_id')}>
                        {vehicles.map((vehicle, i) => (
                          <option key={i} value={vehicle.id}>
                            {Utils.getVehicleTitle(vehicle)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>}
                  {!isGoCustomer &&<div className="form-row">
                    <div className="form-column">
                      <Label icon={carsIcon} text="GO CUSTOMER'S CAR: YEAR MAKE MODEL" />
                      <input
                        type="text"
                        name="vehicleYearMakeModel"
                        value={vehicleYearMakeModel}
                        onChange={handleVehicleYearMakeModelOnChange}
                      />
                    </div>
                  </div>}

                  {/* driver */}
                  {isGoCustomer && <div className="form-row">
                    <div className="form-column">
                      <Label icon={driverIcon} text="Driver" />
                      <select {...register('driver_id')}>
                        {drivers.map((driver, i) => (
                          <option
                            key={i}
                            value={
                              driver.primary ? driver.license_id : driver.id
                            }
                          >
                            {driver.first_name} {driver.last_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>}
                </>
              )}

              {/* incident type */}
              <div className="form-row">
                <div className="form-column">
                  <Label icon={alertIcon} text="Incident Type" />
                  <select
                    {...register('extended_info.incident_type', {
                      required: true,
                      onChange: resetIncidentDetails,
                    })}
                    className={
                      watch('extended_info.incident_type') === ''
                        ? 'placeholder'
                        : ''
                    }
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {IncidentTypes.map((t, i) => (
                      <option key={i} value={t.value}>
                        {t.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* incident details */}
              {watch('extended_info.incident_type') && (
                <div className="form-row">
                  <div className="form-column">
                    <Label icon={detailsIcon} text="Incident Detail" />
                    <select
                      {...register('extended_info.incident_detail', {
                        required: true,
                        onChange: resetIncidentDetailSubTypes,
                      })}
                      className={
                        watch('extended_info.incident_detail') === ''
                          ? 'placeholder'
                          : ''
                      }
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      {IncidentDetailTypesFor(
                        watch('extended_info.incident_type')
                      ).map((t, i) => (
                        <option key={i} value={t.value}>
                          {t.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              {/* incident details - sub type: what happened */}
              {watch('extended_info.incident_detail') ===
                'wet_snowy_icy_road' && (
                <div className="form-row">
                  <div className="form-column">
                    <Label icon={wetRoadIcon} text="What happened" />
                    <select
                      {...register('extended_info.wet_snowy_icy_road_type', {
                        required: true,
                      })}
                      className={
                        watch('extended_info.wet_snowy_icy_road_type') === ''
                          ? 'placeholder'
                          : ''
                      }
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      {WetSnowyIcyRoadTypes.map((t, i) => (
                        <option key={i} value={t.value}>
                          {t.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              {/* incident details - sub type: what was stolen */}
              {watch('extended_info.incident_detail') ===
                'vehicle_parts_stolen' && (
                <div className="form-row">
                  <div className="form-column">
                    <Label icon={theftIcon} text="What was stolen?" />
                    <select
                      {...register('extended_info.stolen_parts_type', {
                        required: true,
                      })}
                      className={
                        watch('extended_info.stolen_parts_type') === ''
                          ? 'placeholder'
                          : ''
                      }
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      {VehiclePartsStolenTypes.map((t, i) => (
                        <option key={i} value={t.value}>
                          {t.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              {/* incident details - sub type: what did the car hit */}
              {watch('extended_info.incident_detail') ===
                'vehicle_rolled_away' && (
                <div className="form-row">
                  <div className="form-column">
                    <Label icon={carHitIcon} text="What did the car hit?" />
                    <select
                      {...register('extended_info.rolled_away_collision_type', {
                        required: true,
                      })}
                      className={
                        watch('extended_info.rolled_away_collision_type') === ''
                          ? 'placeholder'
                          : ''
                      }
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      {VehicleRolledAwayTypes.map((t, i) => (
                        <option key={i} value={t.value}>
                          {t.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              {/* vehicles involved */}
              {watch('extended_info.incident_type') === 'collision' &&
                watch('extended_info.incident_detail') !== 'single_vehicle' && (
                  <div className="form-row">
                    <div className="form-column">
                      <Label icon={carsIcon} text="Cars Involved" />
                      <select
                        {...register('extended_info.vehicle_count', {
                          required: true,
                        })}
                        className={
                          watch('extended_info.vehicle_count') === 0
                            ? 'placeholder'
                            : ''
                        }
                      >
                        <option value={0} disabled>
                          Select
                        </option>
                        <option value={2}>2 cars</option>
                        <option value={3}>3 or more cars</option>
                      </select>
                    </div>
                  </div>
                )}

              {/* incident details - sub type: changing lanes/vehicle moving/entering roadway */}
              {(watch('extended_info.incident_detail') === 'lane_change' ||
                watch('extended_info.incident_detail') === 'parking' ||
                watch('extended_info.incident_detail') ===
                  'entering_roadway') && (
                <div className="form-row">
                  <div className="form-column">
                    <Label
                      icon={
                        getValues('extended_info.incident_detail') ===
                        'lane_change'
                          ? lanesIcon
                          : carMovingIcon
                      }
                      text={
                        getValues('extended_info.incident_detail') ===
                        'lane_change'
                          ? `Which car was changing lanes`
                          : `Which car was moving?`
                      }
                    />
                    <select
                      {...register('extended_info.vehicle_moving_type', {
                        required: true,
                      })}
                      className={
                        watch('extended_info.vehicle_moving_type') === ''
                          ? 'placeholder'
                          : ''
                      }
                    >
                      <option value={''} disabled>
                        Select
                      </option>
                      {VehicleMovingTypes.map((t, i) => (
                        <option key={i} value={t.value}>
                          {t.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              {/* incident details - sub type: car position */}
              {watch('extended_info.incident_detail') === 'rear_end' && (
                <div className="form-row">
                  <div className="form-column">
                    <Label icon={carPositionIcon} text="Car position" />
                    <select
                      {...register('extended_info.vehicle_position_type', {
                        required: true,
                      })}
                      className={
                        watch('extended_info.vehicle_position_type') === ''
                          ? 'placeholder'
                          : ''
                      }
                    >
                      <option value={''} disabled>
                        Select
                      </option>
                      {VehiclePositionTypes.map((t, i) => (
                        <option key={i} value={t.value}>
                          {t.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              {/* incident details - sub type: single_vehicle */}
              {watch('extended_info.incident_detail') === 'single_vehicle' && (
                <div className="form-row">
                  <div className="form-column">
                    <Label icon={potholeIcon} text="What happened" />
                    <select
                      {...register('extended_info.single_vehicle_type', {
                        required: true,
                        onChange: () => {
                          resetField('extended_info.vehicle_object_struck')
                          resetField('extended_info.object_moving')
                        },
                      })}
                      className={
                        watch('extended_info.single_vehicle_type') === ''
                          ? 'placeholder'
                          : ''
                      }
                    >
                      <option value={''} disabled>
                        Select
                      </option>
                      {SingleVehicleTypes.map((t, i) => (
                        <option key={i} value={t.value}>
                          {t.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              {/* incident details - sub type: object struck */}
              {(watch('extended_info.single_vehicle_type') ===
                'vehicle_hit_pothole' ||
                watch('extended_info.single_vehicle_type') ===
                  'vehicle_went_off_road') && (
                <div className="form-row">
                  <div className="form-column">
                    <Label
                      icon={hitObjectIcon}
                      text="Did your car hit an object or car?"
                    />
                    <select
                      {...register('extended_info.vehicle_object_struck', {
                        required: true,
                        onChange: (e) =>
                          setValue(
                            'extended_info.vehicle_object_struck',
                            JSON.parse(e.target.value)
                          ),
                      })}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                </div>
              )}

              {/* incident details - sub type: object moving */}
              {watch('extended_info.single_vehicle_type') ===
                'vehicle_hit_object' && (
                <div className="form-row">
                  <div className="form-column">
                    <Label icon={hitObjectIcon} text="Was the object moving?" />
                    <select
                      {...register('extended_info.object_moving', {
                        required: true,
                        onChange: (e) =>
                          setValue(
                            'extended_info.object_moving',
                            JSON.parse(e.target.value)
                          ),
                      })}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                </div>
              )}

              {/* damage type */}
              {watch('extended_info.incident_type') &&
                watch('extended_info.incident_detail') !== 'vehicle_stolen' && (
                  <>
                    <div className="form-row">
                      <div className="form-column">
                        <Label
                          icon={damageIcon}
                          text="How would you rate the damage"
                        />
                        <select
                          {...register('extended_info.vehicle_damage_type', {
                            required: true,
                          })}
                          className={
                            watch('extended_info.vehicle_damage_type') === ''
                              ? 'placeholder'
                              : ''
                          }
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          <option value="light">Light</option>
                          <option value="medium">Medium</option>
                          <option value="heavy">Heavy</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-column">
                        <Label icon={carsIcon} text="Is your car drivable?" />
                        <select
                          defaultValue={''}
                          {...register('extended_info.is_drivable', {
                            required: true,
                          })}
                          className={
                            watch('extended_info.is_drivable') === ''
                              ? 'placeholder'
                              : ''
                          }
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                          <option value="unknown">Not Sure</option>
                        </select>
                      </div>
                    </div>
                  </>
                )}

              {!isEditing && (
                <>
                  {/* anyone injured */}
                  <div className="form-row">
                    <div className="form-column">
                      <Label icon={injuryIcon} text="Anyone Injured?" />
                      <select
                        {...register('was_anyone_injured', {
                          required: !isEditing ? true : false,
                        })}
                        className={
                          watch('was_anyone_injured') === ''
                            ? 'placeholder'
                            : ''
                        }
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="Not Sure">Not Sure</option>
                      </select>
                    </div>
                  </div>

                  {/* incident time */}
                  <div className="form-row">
                    <div className="form-column">
                      <Label icon={timeIcon} text="Time" />
                      <Controller
                        name="incident_time"
                        control={control}
                        rules={{ required: !isEditing ? true : false }}
                        render={({ field }) => (
                          <DateTimePicker
                            {...field}
                            ref={null}
                            max={new Date()}
                            id="incident_date"
                            onChange={(value) => {
                              field.onChange(value)
                              handleIncidentTimeOnChange(value)
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>

                  {/* location  */}
                  <div className="form-row">
                    <div className="form-column">
                      <Label icon={locationIcon} text="Location" />
                      <Controller
                        name="address"
                        control={control}
                        rules={{ required: !isEditing ? true : false }}
                        render={({ field }) => (
                          <LocationSearchInput
                            {...field}
                            ref={null}
                            onChange={(value) => {
                              field.onChange(value)
                              handleAddressChange(value)
                            }}
                            onBlur={(value) => {
                              field.onBlur(value)
                              handleAddressOnBlur(value)
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>

                  {/* description */}
                  <div className="form-row">
                    <div className="form-column">
                      <Label icon={descriptionIcon} text="Description" />
                      <textarea
                        {...register('description', {
                          required: !isEditing ? true : false,
                        })}
                      ></textarea>
                    </div>
                  </div>

                  {/* videos */}
                  <div className="form-row">
                    <div className="form-column">
                      <Label icon={photosIcon} text="Videos" />
                      {videos.map((video, i) => {
                        const src = URL.createObjectURL(video)
                        return (
                          <div className="video-upload-preview" key={i}>
                            <div className="left">
                              <video autoPlay={false}>
                                <source src={src} />
                              </video>
                              <div className="file-details">{video.name}</div>
                            </div>
                            <div className="right">
                              <img
                                className="trash"
                                src={deleteIcon}
                                alt="delete icon"
                                onClick={() => handleVideoDelete(i)}
                              />
                            </div>
                          </div>
                        )
                      })}
                      <FileUpload
                        text={'Upload a 360-degree video of your car'}
                        buttonText={'Choose File'}
                        fileTypes={'.mp4,.mov,.3gpp'}
                        useCamera={true}
                        isUploading={isUploadingVideo || isSubmitting}
                        onSelect={handleVideoUpload}
                      />
                    </div>
                  </div>
                </>
              )}

              {!isEditing ? (
                <button
                  className="primary bold"
                  disabled={!isValid || (isGoCustomer && videos.length === 0) || isSubmitting}
                >
                  {isSubmitting ? 'Submitting Claim...' : 'Submit Claim'}
                </button>
              ) : (
                <button
                  className="primary bold"
                  disabled={!isValid || isSubmitting}
                >
                  {isSubmitting ? 'Updating Claim...' : 'Update Claim'}
                </button>
              )}
            </form>
          </div>
        </CenteredContainer>
      )}
    </div>
  )
}
