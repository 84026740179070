import './SelectBank.css'
import { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CenteredContainer } from '../../components/Container'
import icnBank from '../../assets/icn-bank.svg'
import { BanksList } from './BanksList'

export default function SelectBank() {
  const history = useHistory()

  const [showPrimer, setShowPrimer] = useState(true)
  const [searchText, setSearchText] = useState('')

  const filteredBanks = useMemo(
    () =>
      BanksList.filter((bank) =>
        bank.name.toLowerCase().includes(searchText.toLowerCase())
      ),
    [searchText]
  )

  return (
    <div className="container">
      <CenteredContainer className="vendor-select-bank-wrapper">
        {/* select bank primer  */}
        {showPrimer && (
          <div className="select-bank-primer">
            <img src={icnBank} alt="bank building" />
            <h1 className="title">Bank Verification</h1>
            <p className="sub-title">
              Set up your bank to get your verification.
            </p>

            <button className="primary" onClick={() => setShowPrimer(false)}>
              Continue
            </button>
          </div>
        )}

        {/* search and select bank */}
        {!showPrimer && (
          <>
            <h1 className="title" style={{ marginBottom: '21px' }}>
              Select your bank
            </h1>

            <input
              type="search"
              placeholder="Search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />

            <ul className="list-vendor-banks">
              {filteredBanks.map((bank, i) => (
                <li
                  key={i}
                  onClick={() =>
                    history.push('/business/verify-bank', { bank })
                  }
                >
                  <img src={bank.logo} alt="" />
                  <div>
                    <div className="bank-name">{bank.name}</div>
                    <div className="bank-url">{bank.url}</div>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
      </CenteredContainer>
    </div>
  )
}
