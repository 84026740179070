import '../styles/LocationSearchInput.css'
import React, { useState } from 'react'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import addresser from 'addresser'

function LocationSearchInput({ value, onChange, onBlur }) {
  const [address, setAddress] = useState(value)
  const [addressInFocus, setAddressInFocus] = useState(false)

  const handleChange = (address) => {
    setAddress(address)
  }

  const handleBlur = (e) => {
    setAddressInFocus(false)
    handleSelect(address, true)
  }

  const handleSelect = async (address, fromOnBlur = false) => {
    if (address.length === 0) {
      if (fromOnBlur && onBlur && typeof onBlur === 'function') {
        onBlur('')
      }
      return
    }

    const parsedAddress = await geocodeByAddress(address)
      .then((results) => {
        if (results.length === 0) {
          return null
        }

        const addressComponents = results[0].address_components

        const zipCode = addressComponents.find((a) =>
          a.types.includes('postal_code')
        )

        let parsedAddress = addresser.parseAddress(address)

        parsedAddress = {
          ...parsedAddress,
          zipCode: zipCode && zipCode.long_name ? zipCode.long_name : '',
        }

        return parsedAddress
      })
      .catch((error) => {
        console.error('Error:', error)
        try {
          const parsedAddress = addresser.parseAddress(address)
          if (parsedAddress) {
            return parsedAddress
          }
        } catch (e) {
          return null
        }
      })

    if (parsedAddress && parsedAddress.zipCode) {
      setAddress(address.replace(/, USA/g, ` ${parsedAddress.zipCode}`))
    } else {
      setAddress(address.replace(/, USA/g, ''))
    }

    onChange(parsedAddress)
    if (onBlur && typeof onBlur === 'function') {
      onBlur(parsedAddress)
    }
  }

  const searchOptions = {
    types: ['address'],
    componentRestrictions: {
      country: ['us'],
    },
  }

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={searchOptions}
      debounce={300}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="suggestion-container">
          <input
            {...getInputProps({ autoComplete: 'no' })}
            onFocus={(e) => setAddressInFocus(true)}
            onBlur={handleBlur}
          />

          {suggestions.length > 0 && addressInFocus && (
            <ul className="suggestion-list">
              {suggestions.map((suggestion, i) => {
                let secondaryText = suggestion.formattedSuggestion.secondaryText
                if (secondaryText.length > 0) {
                  secondaryText = secondaryText.replace(/, USA/g, '')
                }

                const style = {
                  backgroundColor: suggestion.active ? '#f6f6f6' : '#ffffff',
                }

                if (!suggestion.id) {
                  suggestion.id = i
                }

                return (
                  <li {...getSuggestionItemProps(suggestion, { style })}>
                    <span className="main-text">
                      {suggestion.formattedSuggestion.mainText}
                    </span>{' '}
                    {secondaryText}
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  )
}

export default LocationSearchInput
