import './Uploads.css'
import { useEffect, useRef, useState } from 'react'
import { useMachine } from '@xstate/react'
import fetchMachine from '../../machines/fetch'
import { CenteredContainer } from '../../components/Container'
import { ErrorLabel, Label } from '../../components/Label'
import vidCamIcon from '../../assets/icn-vidcam.svg'
import UserModel from '../../models/UserModel'
import { useParams } from 'react-router-dom'
import LoadingIndicator from '../../components/LoadingIndicator'
import VehicleVideoModel from '../../models/VehicleVideoModel'
import { FilePreview, FileUploadButton } from '../../components/FileUpload'
import { base64Encode } from '../../utils/Files'
import useHasCapture from '../../hooks/useHasCapture'

export default function Uploads() {
  const hasCapture = useHasCapture()
  const { userID } = useParams()

  const [state, send] = useMachine(fetchMachine, {
    services: {
      fetchData: async (context, event) => {
        const response = await UserModel.getUser(userID)

        if (response.err) {
          send({ type: 'REJECT', error: response.err })
          return
        }

        send({ type: 'RESOLVE', response: response })
      },
    },
  })

  const initRef = useRef(null)
  const initVideosRef = useRef(null)
  const isIdle = state.matches('idle')
  const isLoading = state.matches('loading')
  const onFailure = state.matches('failure')
  const onSuccess = state.matches('success')
  const { user, license } = state.context.response || {}

  const [isFetchingVideos, setIsFetchingVideos] = useState(true)
  const [vehicleVideos, setVehicleVideos] = useState([])
  const [videosErr, setVideosErr] = useState('')
  const [uploadingVehicleID, setUploadingVehicleID] = useState('')

  useEffect(() => {
    if (initRef.current === null && hasCapture) {
      send('FETCH')
      initRef.current = 'inited'
    }
  }, [send, hasCapture])

  const fetchVehicleVideos = async () => {
    const { vehicleVideos, err } = await VehicleVideoModel.getVehicleVideos(
      user.is_in_application_process
    )
    if (err) {
      setVideosErr(
        `Sorry, we ran into an unexpected issue and we're still figuring it out.`
      )
      setIsFetchingVideos(false)
      return
    }

    setVehicleVideos(vehicleVideos || [])
    setIsFetchingVideos(false)
  }

  // get vehicle videos and GET discount after fetching user
  if (onSuccess && initVideosRef.current === null) {
    fetchVehicleVideos()
    initVideosRef.current = 'inited'
  }

  const onVideoCapture = async (files, id) => {
    setUploadingVehicleID(id)
    const file = files[0]
    console.log(`FileName: ${file.name} Type: ${file.type}`)

    const { data, err } = await base64Encode(file)
    if (err) {
      setUploadingVehicleID('')
      console.log('base64Encode err:', err)
      alert('Error processing video')
      return
    }

    const uploadErr = await VehicleVideoModel.uploadVideo(id, data, file.type)
    if (uploadErr) {
      setUploadingVehicleID('')
      console.log('error uploading vehicle video:', err)
      alert(
        `Sorry, we ran into an unexpected issue and we're still figuring it out.`
      )
      return
    }

    await fetchVehicleVideos()
    setUploadingVehicleID('')
  }

  const onDeleteVideo = (id) => {
    const videos = vehicleVideos.map((v) => {
      if (v.vehicle_id === id) {
        return {
          ...v,
          status: 'pending_upload',
        }
      }

      return v
    })

    setVehicleVideos([...videos])
  }

  if (!hasCapture) {
    return (
      <div className="container">
        {!hasCapture && (
          <CenteredContainer>
            <div className="center-center" style={{ color: '#000' }}>
              Device not supported. Please use your mobile phone to view this
              page.
            </div>
          </CenteredContainer>
        )}
      </div>
    )
  }

  return (
    <div className="uploads container">
      <CenteredContainer>
        {(isIdle || isLoading) && (
          <div className="center-center">
            <LoadingIndicator text="Loading..." />
          </div>
        )}

        {onFailure && (
          <ErrorLabel
            errorMessage={`Sorry, we ran into an unexpected issue and we're still figuring it out.`}
          />
        )}

        {onSuccess && (
          <>
            <h2 className="display">Welcome {license.first_name}</h2>
            <h3 className="title">Get Verified</h3>
            <p className="details">
              Please complete the following verification items to activate your
              policy. Upload a current video of your car(s) and any additional
              documentation below.
            </p>

            {/* Vehicle videos card */}
            <div className="uploads-card">
              <h4 className="title">Virtual Showroom</h4>

              <p>
                Letting you protect the value of your car with a perfect digital
                record.
              </p>

              {isFetchingVideos && (
                <div className="files-loading">
                  <LoadingIndicator text={'Loading...'} />
                </div>
              )}

              {!isFetchingVideos && (
                <>
                  {videosErr && (
                    <div className="file-item">
                      <ErrorLabel errorMessage={videosErr} />
                    </div>
                  )}

                  {vehicleVideos.map((vv, i) => (
                    <div className="file-item" key={i}>
                      <Label icon={vidCamIcon} text={vv.vehicle_name} />
                      {vv.status !== 'pending_upload' &&
                      vv.status !== 'pending_reupload' ? (
                        <FilePreview
                          id={vv.vehicle_id}
                          fileURL={vv.video_url}
                          text={`${vv.vehicle_name}.mp4`}
                          onDelete={onDeleteVideo}
                        />
                      ) : (
                        <FileUploadButton
                          id={vv.vehicle_id}
                          buttonText="Add Video"
                          fileTypes="video/*"
                          isUploading={uploadingVehicleID === vv.vehicle_id}
                          useCamera={true}
                          onSelect={onVideoCapture}
                        />
                      )}
                    </div>
                  ))}
                  {vehicleVideos.length === 0 && (
                    <div className="file-item">
                      <ErrorLabel errorMessage={'No vehicles to verify'} />
                    </div>
                  )}
                </>
              )}
            </div>
            {/* End of Vehicle videos card */}

            {!user?.is_in_application_process && (
              <button
                className="primary"
                onClick={() => (window.location = `/policy/${user?.id}`)}
              >
                View Policy Status
              </button>
            )}
          </>
        )}
      </CenteredContainer>
    </div>
  )
}
