import React from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { useAuthContext } from '../context/AuthContext'

function PrivateRoute({ component: Component, ...rest }) {
  const { auth } = useAuthContext()
  const location = useLocation()

  let loginPath = '/login'
  if (location.pathname.includes('/claims/')) {
    loginPath = '/claims'
    const claimsPattern = /\/claims\/[\w/-]*/g
    if (location.pathname.match(claimsPattern)) {
      let params = new URLSearchParams()
      params.append('redirect', location.pathname)

      if (location.search) {
        const policy = new URLSearchParams(location.search).get('policy')
        if (policy) {
          params.append('policy', policy)
        }
      }

      loginPath = `/claims?${params.toString()}`
    }
  }
  if (location.pathname.includes('/renewals/')) {
    loginPath = '/renewals/verify'
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        auth ? <Component {...props} /> : <Redirect to={loginPath} />
      }
    />
  )
}

export default PrivateRoute
