import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Constants from '../models/Constants'

/**
 * useHasCapture hook returns true if the device supports input file element with capture support for camera.
 *
 * e.g If device is desktop returns false, else mobile for returns true.
 *
 * @returns {boolean} hasCapture
 */
export default function useHasCapture() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const isDeviceCheckDisabled =
    params.get('disableDeviceCheck') === 'true' && !Constants.isProduction()

  const [hasCapture, setHasCapture] = useState(false)

  useEffect(() => {
    const input = document.createElement('input')
    setHasCapture(input.capture !== undefined || isDeviceCheckDisabled)
  }, [])

  return hasCapture
}
