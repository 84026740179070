import React from 'react'

function CenteredContainer(props) {
  var c = 'center-container'
  if (props.className) {
    c += ' ' + props.className
  }
  return (
    <div className={c} style={props.style}>
      <div
        className="center-container-content"
        style={props.width ? { width: `${props.width}px` } : {}}
      >
        {props.children}
      </div>
    </div>
  )
}

function ColumnContainer(props) {
  var c = 'column-container'
  if (props.className) {
    c += ' ' + props.className
  }
  return (
    <div className={c}>
      <div className="column-container-content">{props.children}</div>
    </div>
  )
}

function ColumnLeft(props) {
  var c = 'column-container-left'
  if (props.className) {
    c += ' ' + props.className
  }
  return <div className={c}>{props.children}</div>
}

function ColumnRight(props) {
  var c = 'column-container-right'
  if (props.className) {
    c += ' ' + props.className
  }
  return <div className={c}>{props.children}</div>
}

export { CenteredContainer, ColumnContainer, ColumnLeft, ColumnRight }
