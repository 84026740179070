import './SubrogationStatus.css'
import { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { CenteredContainer } from '../../components/Container'
import SubrogationModel from '../../models/SubrogationModel'
import LoadingIndicator from '../../components/LoadingIndicator'
import {
  SubrogationWaitingResponse,
  SubrogationSubmitted,
  SubrogationStatuses,
  getStatusFromLabel,
  SubrogationCompleted,
  SubrogationPaid,
} from './SubrogationStatusType'
import Dates from '../../utils/Dates'
import Currency from '../../utils/Currency'
import Strings from '../../utils/Strings'
import icnDriver from '../../assets/icn-driver.svg'
import icnClaims from '../../assets/icn-claims.svg'
import icnPaid from '../../assets/icn-paid.svg'
import icnStatus from '../../assets/icn-status.svg'
import icnTime from '../../assets/icn-time.svg'
import icnAgreement from '../../assets/icn-agreement.svg'
import icnDocs from '../../assets/icn-docs-sm.svg'
import placeholderImg from '../../assets/placeholder-img.svg'

export default function SubrogationStatus() {
  const { demandID } = useParams()
  const history = useHistory()
  const location = useLocation()

  const [isLoading, setIsLoading] = useState(true)
  const [demand, setDemand] = useState(null)
  const [response, setResponse] = useState(null)
  const [goCustomer, setGoCustomer] = useState(null)
  const [status, setStatus] = useState(null)

  const fetchDemand = async (demandID) => {
    const { status, demand, responses, goCustomer, err } =
      await SubrogationModel.getDemand(demandID)
    if (err) {
      alert('Sorry, there was an error on fetching subrogation demands.')
      return
    }

    if (status === SubrogationWaitingResponse.label) {
      history.push(`/subrogation/${demandID}`)
      return
    }
    if (responses.length === 0) {
      history.push(`/subrogation/${demandID}`)
      return
    }

    setStatus(getStatusFromLabel(status))
    setDemand(demand)
    setGoCustomer(goCustomer)
    const response = responses.find((r) => r.type === 'carrier_response')
    if (response) {
      setResponse(response)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchDemand(demandID)
    // eslint-disable-next-line
  }, [demandID])

  const onClickDemandPacket = (e) => {
    history.push(`/subrogation/${demandID}/demand_packets`, {
      from: location.pathname,
      claimFiles: demand.claim_files || [],
    })
  }

  return (
    <div className="container">
      <h1 className="title">Subrogated Claim</h1>
      <CenteredContainer className="subrogation-status-container">
        {isLoading && <LoadingIndicator text={'Loading...'} />}

        {!isLoading && (
          <>
            {/* progrss bar section */}
            <div className="subrogation-progress-wrap">
              <progress max={100} value={status.progress}></progress>
              <div className="progress-labels">
                {SubrogationStatuses.map((s, i) => {
                  if (s === SubrogationWaitingResponse) {
                    return null
                  }

                  if (
                    s === SubrogationPaid &&
                    status === SubrogationCompleted
                  ) {
                    return (
                      <div className="active" key={i}>
                        {SubrogationCompleted.label}
                      </div>
                    )
                  }

                  return (
                    <div className={s === status ? 'active' : ''} key={i}>
                      {s.label}
                      {s === status && (
                        <>
                          <br />${Currency.format(response.amount, true)}
                        </>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
            {/* end of progrss bar section */}

            {status !== SubrogationSubmitted.label && (
              <div className="go-claims-payment-address">
                <h2 className="heading">Send Payment to:</h2>
                <p>
                  Go Claims
                  <br />
                  4470 W Sunset Blvd Suite 107
                  <br />
                  PMB 95826
                  <br />
                  Los Angeles, CA 90027
                </p>
              </div>
            )}

            <ul className="claim-summary-list">
              {/* your customer name */}
              <li>
                <img className="icon" src={icnDriver} alt="" />
                <div>
                  <p className="item">YOUR CUSTOMER</p>
                  <p>
                    {Strings.titleCase(
                      `${demand.policy_holder_first_name} ${demand.policy_holder_last_name}`
                    )}
                  </p>
                </div>
              </li>

              {/* your policy number */}
              <li>
                <img className="icon" src={icnClaims} alt="" />
                <div>
                  <p className="item">YOUR POLICY NUMBER</p>
                  <p>#{demand.policy_number}</p>
                </div>
              </li>

              {/* your claim number */}
              <li>
                <img className="icon" src={icnClaims} alt="" />
                <div>
                  <p className="item">YOUR CLAIM NUMBER</p>
                  <p>#{demand.claim_number}</p>
                </div>
              </li>

              {/* go customer name */}
              <li>
                <img className="icon" src={icnDriver} alt="" />
                <div>
                  <p className="item">GO CUSTOMER</p>
                  <p>{Strings.titleCase(`${goCustomer.name}`)}</p>
                </div>
              </li>

              {/* go policy number */}
              <li>
                <img className="icon" src={icnClaims} alt="" />
                <div>
                  <p className="item">Go POLICY NUMBER</p>
                  <p>#{goCustomer.policy_number}</p>
                </div>
              </li>

              {/* go claim number */}
              <li>
                <img className="icon" src={icnClaims} alt="" />
                <div>
                  <p className="item">GO CLAIM NUMBER</p>
                  <p>#{goCustomer.claim_number}</p>
                </div>
              </li>

              {/* demand amount */}
              <li>
                <img className="icon" src={icnPaid} alt="" />
                <div>
                  <p className="item">AMOUNT OF DEMAND</p>
                  <p>${Currency.format(demand.amount, true)}</p>
                  <button className="link" onClick={onClickDemandPacket}>
                    View Demand Packet
                  </button>
                </div>
              </li>

              {/* accepted status */}
              <li>
                <img className="icon" src={icnStatus} alt="" />
                <div>
                  <p className="item">STATUS</p>
                  {response.percentage === 100 && <p>Accept</p>}
                  {response.percentage !== 100 && response.percentage !== 0 && (
                    <p>Partially Accept</p>
                  )}
                  {response.percentage === 0 && <p>Deny</p>}
                </div>
              </li>

              {/* time */}
              <li>
                <img className="icon" src={icnTime} alt="" />
                <div>
                  <p className="item">TIME</p>
                  <p>
                    {Dates.toLongDateString(response.created_at, true, true)}
                  </p>
                </div>
              </li>

              {/* amount accepted */}
              <li>
                <img className="icon" src={icnPaid} alt="" />
                <div>
                  <p className="item">AMOUNT BEING ACCEPTED</p>
                  <p>
                    ${Currency.format(response.amount, true)} (
                    {response.percentage}%)
                  </p>
                </div>
              </li>

              {/* response text */}
              {response.response && (
                <li>
                  <img className="icon" src={icnAgreement} alt="" />
                  <div>
                    <p className="item">REASONING</p>
                    <p>{response.response}</p>
                  </div>
                </li>
              )}

              {/* documents */}
              {response.claim_files?.length > 0 && (
                <li className="docs-list">
                  <div className="header">
                    <img className="icon" src={icnDocs} alt="" />
                    <p className="docs-list-title">DOCUMENTS</p>
                  </div>
                  <div className="docs-list-items">
                    {response.claim_files.map((file, i) => {
                      let previewURL = ''
                      if (
                        !file.mime_type.includes('application/') &&
                        !file.mime_type.includes('text/')
                      ) {
                        previewURL = file.file_url
                      }
                      if (file.mime_type.includes('application/')) {
                        previewURL = file.thumbnail_url || ''
                      }

                      return (
                        <a
                          key={i}
                          href={file.file_url}
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img
                            className="docs-list-item"
                            src={previewURL || placeholderImg}
                            alt={file.name}
                            title={file.name}
                            loading="lazy"
                          />
                        </a>
                      )
                    })}
                  </div>
                </li>
              )}
            </ul>
          </>
        )}
      </CenteredContainer>
    </div>
  )
}
