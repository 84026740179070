import axios from 'axios'
import Constants from './Constants'
import Store from './Store.js'

const API = ({ headers = {} } = {}) => {
  let user = Store.getUser()
  let token = user ? user.token : ''

  let axiosClient = axios.create({
    baseURL: Constants.apiHost(),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      'App-Version': '5.4',
      Platform: 'web',
      ...headers,
    },
  })

  axiosClient.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        Store.clearAll()

        if (window.location.pathname.includes('/claims/')) {
          window.location = '/claims'
          return
        }
        window.location = '/login'
      }
      return Promise.reject(error)
    }
  )

  return axiosClient
}

export default API
